var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "37",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.51 12.3198C33.2387 12.3198 34.64 10.9184 34.64 9.18979C34.64 7.46114 33.2387 6.05981 31.51 6.05981C29.7814 6.05981 28.38 7.46114 28.38 9.18979C28.38 10.9184 29.7814 12.3198 31.51 12.3198Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.31 20.5199C43.8067 20.5199 45.02 19.3066 45.02 17.8099C45.02 16.3132 43.8067 15.0999 42.31 15.0999C40.8133 15.0999 39.6 16.3132 39.6 17.8099C39.6 19.3066 40.8133 20.5199 42.31 20.5199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.8 9.18991C38.9101 9.18991 39.81 8.29002 39.81 7.17993C39.81 6.06984 38.9101 5.16992 37.8 5.16992C36.6899 5.16992 35.79 6.06984 35.79 7.17993C35.79 8.29002 36.6899 9.18991 37.8 9.18991Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.7 19.4799C34.0421 19.4799 35.13 18.3919 35.13 17.0499C35.13 15.7078 34.0421 14.6199 32.7 14.6199C31.358 14.6199 30.27 15.7078 30.27 17.0499C30.27 18.3919 31.358 19.4799 32.7 19.4799Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.77 12.1999C43.742 12.1999 44.53 11.4119 44.53 10.4399C44.53 9.46789 43.742 8.67993 42.77 8.67993C41.798 8.67993 41.01 9.46789 41.01 10.4399C41.01 11.4119 41.798 12.1999 42.77 12.1999Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.25 7.59985H37.25V26.9199H38.25V7.59985Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.79 16.1798L31.16 9.54984L31.87 8.83984L37.79 14.7698L42.42 10.1398L43.12 10.8498L37.79 16.1798Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.75 23.0199L32.35 17.6099L33.05 16.8999L37.75 21.5999L41.95 17.4099L42.66 18.1099L37.75 23.0199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.14899 41.45C4.69267 41.6372 4.20194 41.7258 3.70899 41.71C3.3428 41.7313 2.9763 41.6738 2.63431 41.5411C2.29232 41.4085 1.98282 41.2039 1.72679 40.9412C1.47075 40.6785 1.27416 40.3639 1.15032 40.0186C1.02647 39.6734 0.978278 39.3055 1.00899 38.94C0.987489 38.5592 1.04632 38.1781 1.18167 37.8215C1.31702 37.4649 1.52585 37.1407 1.79462 36.8701C2.06338 36.5994 2.38607 36.3883 2.74172 36.2505C3.09737 36.1127 3.47803 36.0512 3.85899 36.07C4.30583 36.0516 4.75083 36.1372 5.15898 36.32L4.98898 36.89C4.64289 36.7369 4.26735 36.6618 3.88899 36.67C3.59322 36.6483 3.29633 36.6926 3.01982 36.7998C2.7433 36.907 2.49408 37.0743 2.2902 37.2897C2.08632 37.505 1.9329 37.7631 1.84102 38.045C1.74914 38.327 1.72112 38.6259 1.75899 38.92C1.73135 39.2059 1.76561 39.4944 1.85946 39.7659C1.95331 40.0374 2.10453 40.2855 2.30284 40.4933C2.50115 40.7011 2.7419 40.8637 3.0087 40.9701C3.2755 41.0766 3.5621 41.1243 3.84899 41.11C4.24362 41.1194 4.63571 41.0444 4.99899 40.89L5.14899 41.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.38901 36.14L7.46901 38.45L7.85901 39.45C7.95901 39.17 8.05901 38.85 8.19901 38.45L9.04901 36.15H9.79901L8.64901 38.96C8.39237 39.6845 8.05703 40.3786 7.64901 41.03C7.51178 41.2335 7.3273 41.4007 7.11136 41.5174C6.89542 41.6341 6.65443 41.6967 6.409 41.7C6.28026 41.7256 6.14775 41.7256 6.019 41.7L6.089 41.13C6.17518 41.143 6.26283 41.143 6.34901 41.13C6.90901 41.13 7.24901 40.58 7.41901 40.19C7.44797 40.1398 7.46322 40.0829 7.46322 40.025C7.46322 39.9671 7.44797 39.9102 7.41901 39.86L5.60901 36.18L6.38901 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.389 36.22C10.8137 36.1356 11.246 36.0954 11.679 36.1C12.2076 36.05 12.7365 36.192 13.169 36.5C13.3238 36.6143 13.4472 36.7659 13.5277 36.9408C13.6081 37.1157 13.643 37.308 13.629 37.5C13.6207 37.7727 13.5263 38.0357 13.3592 38.2513C13.1921 38.4669 12.961 38.624 12.699 38.7C13.0158 38.7642 13.301 38.9353 13.5068 39.1847C13.7125 39.4342 13.8262 39.7467 13.829 40.07C13.832 40.2736 13.7927 40.4757 13.7135 40.6633C13.6343 40.851 13.517 41.0201 13.369 41.16C12.8233 41.5569 12.1503 41.7385 11.479 41.67C11.1147 41.6728 10.7507 41.6528 10.389 41.61V36.22ZM11.089 38.45H11.729C12.479 38.45 12.909 38.06 12.909 37.54C12.909 37.02 12.429 36.65 11.719 36.65C11.5093 36.6324 11.2986 36.6324 11.089 36.65V38.45ZM11.089 41.07C11.2854 41.083 11.4825 41.083 11.679 41.07C12.409 41.07 13.079 40.81 13.079 40.01C13.079 39.21 12.439 38.96 11.679 38.96H11.089V41.07Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.389 38.82C19.4345 39.1799 19.4027 39.5453 19.2958 39.8919C19.1888 40.2386 19.0091 40.5584 18.7687 40.83C18.5283 41.1017 18.2327 41.3189 17.9017 41.4672C17.5706 41.6155 17.2117 41.6914 16.849 41.69C16.5001 41.6865 16.1557 41.6099 15.8382 41.4653C15.5206 41.3206 15.2368 41.1111 15.0051 40.8502C14.7734 40.5893 14.5989 40.2827 14.4927 39.9503C14.3866 39.6179 14.3513 39.2669 14.389 38.92C14.35 38.5621 14.3861 38.2 14.4949 37.8569C14.6037 37.5137 14.7828 37.197 15.0209 36.9269C15.259 36.6569 15.5507 36.4394 15.8775 36.2884C16.2043 36.1374 16.559 36.0562 16.919 36.05C17.2688 36.0522 17.6142 36.1277 17.933 36.2717C18.2518 36.4157 18.5367 36.625 18.7695 36.8861C19.0023 37.1472 19.1778 37.4542 19.2845 37.7873C19.3912 38.1205 19.4268 38.4723 19.389 38.82ZM15.149 38.91C15.149 40.07 15.779 41.12 16.889 41.12C17.999 41.12 18.639 40.12 18.639 38.85C18.639 37.76 18.079 36.63 16.899 36.63C15.719 36.63 15.149 37.71 15.149 38.91Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.299 36.21C20.745 36.1342 21.1967 36.0974 21.649 36.1C22.2017 36.0566 22.7507 36.2206 23.189 36.56C23.3463 36.7038 23.47 36.8805 23.5512 37.0776C23.6325 37.2746 23.6693 37.4871 23.659 37.7C23.67 37.9131 23.6386 38.1263 23.5665 38.3272C23.4944 38.528 23.3831 38.7126 23.239 38.87C23.0146 39.0761 22.7512 39.2353 22.4645 39.3383C22.1777 39.4414 21.8733 39.4861 21.569 39.47C21.381 39.4755 21.193 39.4587 21.009 39.42V41.6H20.299V36.21ZM21.009 38.84C21.199 38.8846 21.3939 38.9048 21.589 38.9C22.439 38.9 22.959 38.49 22.959 37.73C22.959 36.97 22.449 36.66 21.669 36.66C21.4475 36.6556 21.2261 36.6757 21.009 36.72V38.84Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.209 38.82C29.2544 39.179 29.2229 39.5436 29.1165 39.8896C29.0101 40.2355 28.8313 40.5548 28.592 40.8263C28.3527 41.0977 28.0583 41.3152 27.7285 41.4641C27.3986 41.613 27.0409 41.69 26.679 41.69C26.33 41.6865 25.9857 41.6099 25.6681 41.4653C25.3506 41.3206 25.0668 41.1111 24.8351 40.8502C24.6034 40.5893 24.4288 40.2827 24.3227 39.9503C24.2166 39.6179 24.1813 39.2669 24.219 38.92C24.1815 38.5616 24.2189 38.1993 24.3289 37.8561C24.4389 37.5129 24.619 37.1963 24.8579 36.9265C25.0967 36.6566 25.389 36.4393 25.7163 36.2884C26.0436 36.1376 26.3987 36.0564 26.759 36.05C27.1076 36.0534 27.4515 36.1299 27.7687 36.2747C28.0858 36.4194 28.369 36.6291 28.5999 36.8902C28.8309 37.1513 29.0044 37.458 29.1093 37.7904C29.2142 38.1229 29.2482 38.4736 29.209 38.82ZM24.969 38.91C24.969 40.07 25.609 41.12 26.719 41.12C27.829 41.12 28.469 40.12 28.469 38.85C28.469 37.76 27.899 36.63 26.719 36.63C25.539 36.63 24.969 37.71 24.969 38.91Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.129 36.22C30.5504 36.1361 30.9793 36.0959 31.409 36.1C31.9407 36.0494 32.473 36.1913 32.909 36.5C33.0638 36.6143 33.1872 36.766 33.2677 36.9408C33.3481 37.1157 33.3829 37.308 33.369 37.5C33.3607 37.7727 33.2663 38.0357 33.0992 38.2513C32.9321 38.4669 32.701 38.624 32.439 38.7C32.7558 38.7642 33.041 38.9353 33.2468 39.1848C33.4525 39.4342 33.5662 39.7467 33.569 40.07C33.572 40.2736 33.5327 40.4757 33.4535 40.6633C33.3743 40.851 33.257 41.0201 33.109 41.16C32.5633 41.5569 31.8903 41.7385 31.219 41.67C30.8547 41.6728 30.4907 41.6528 30.129 41.61V36.22ZM30.829 38.45H31.469C32.219 38.45 32.649 38.06 32.649 37.54C32.649 37.02 32.169 36.65 31.449 36.65C31.2427 36.6322 31.0353 36.6322 30.829 36.65V38.45ZM30.829 41.07C31.0254 41.083 31.2225 41.083 31.419 41.07C32.149 41.07 32.819 40.81 32.819 40.01C32.819 39.21 32.179 38.96 31.409 38.96H30.829V41.07Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.269 41.45C37.8094 41.638 37.3152 41.7266 36.819 41.71C36.4532 41.7316 36.087 41.674 35.7455 41.5413C35.4039 41.4086 35.095 41.2038 34.8397 40.9409C34.5844 40.6781 34.3888 40.3633 34.2662 40.018C34.1435 39.6727 34.0967 39.305 34.129 38.94C34.1059 38.5588 34.1636 38.177 34.2984 37.8197C34.4332 37.4624 34.642 37.1376 34.9111 36.8666C35.1802 36.5956 35.5035 36.3845 35.8599 36.2473C36.2162 36.11 36.5976 36.0496 36.979 36.07C37.4227 36.0498 37.8649 36.1355 38.269 36.32L38.099 36.89C37.7535 36.735 37.3775 36.6598 36.999 36.67C36.7032 36.6483 36.4063 36.6926 36.1298 36.7998C35.8533 36.907 35.6041 37.0743 35.4002 37.2897C35.1963 37.5051 35.0429 37.7631 34.951 38.045C34.8591 38.327 34.8311 38.6259 34.869 38.92C34.8412 39.2068 34.8757 39.4962 34.9701 39.7684C35.0645 40.0407 35.2166 40.2893 35.4161 40.4972C35.6155 40.7052 35.8575 40.8676 36.1255 40.9734C36.3936 41.0792 36.6813 41.1258 36.969 41.11C37.3636 41.1195 37.7557 41.0444 38.119 40.89L38.269 41.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.839 36.14V38.57H40.059L41.929 36.14H42.789L40.719 38.65C41.0881 38.6991 41.433 38.8609 41.7068 39.1132C41.9806 39.3655 42.17 39.6961 42.249 40.06C42.4179 40.5849 42.6183 41.0992 42.849 41.6H42.089C41.8907 41.1864 41.7235 40.7585 41.589 40.32C41.5283 39.9661 41.3403 39.6466 41.0603 39.4219C40.7802 39.1971 40.4276 39.0826 40.069 39.1H39.839V41.6H39.149V36.14H39.839Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.199 36.14V38.45C44.199 39.32 44.199 40.03 44.139 40.76C44.419 40.19 44.759 39.6 45.139 38.96L46.909 36.14H47.619V41.6H46.949V39.28C46.949 38.4 46.949 37.75 47.019 37.06C46.7222 37.6925 46.3883 38.3069 46.019 38.9L44.309 41.61H43.559V36.15L44.199 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.529 36.14V38.45C49.529 39.32 49.529 40.03 49.469 40.76C49.749 40.19 50.089 39.6 50.469 38.96L52.239 36.14H52.949V41.6H52.279V39.28C52.279 38.4 52.279 37.75 52.349 37.06C52.0522 37.6925 51.7183 38.3069 51.349 38.9L49.639 41.61H48.889V36.15L49.529 36.14ZM50.419 35C50.419 35.25 50.539 35.5 50.919 35.5C51.299 35.5 51.379 35.27 51.409 35H51.949C51.949 35.54 51.589 35.87 50.899 35.87C50.7749 35.8855 50.6489 35.8746 50.5293 35.8381C50.4097 35.8017 50.2991 35.7404 50.2047 35.6583C50.1104 35.5762 50.0344 35.4752 49.9817 35.3617C49.929 35.2483 49.9008 35.125 49.899 35H50.419Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.859 36.14V41.6H59.149V36.74H56.619V41.6H55.909V36.14H59.859Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.969 39.89L61.389 41.6H60.659L62.519 36.14H63.389L65.249 41.6H64.499L63.919 39.89H61.969ZM63.759 39.34L63.229 37.76C63.1144 37.4321 63.0176 37.0983 62.939 36.76C62.849 37.08 62.769 37.41 62.659 37.76L62.129 39.34H63.759Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M66.039 36.21C66.4883 36.1338 66.9433 36.097 67.399 36.1C67.9484 36.0581 68.4938 36.2221 68.929 36.56C69.0876 36.7033 69.2128 36.8797 69.2957 37.0767C69.3786 37.2736 69.4173 37.4864 69.409 37.7C69.4181 37.9138 69.3849 38.1272 69.3111 38.328C69.2372 38.5288 69.1244 38.713 68.979 38.87C68.7561 39.0753 68.4945 39.2341 68.2094 39.3372C67.9244 39.4402 67.6217 39.4853 67.319 39.47C67.1277 39.4759 66.9364 39.4591 66.749 39.42V41.6H66.039V36.21ZM66.749 38.84C66.9391 38.884 67.1339 38.9042 67.329 38.9C68.179 38.9 68.699 38.49 68.699 37.73C68.699 36.97 68.189 36.66 67.409 36.66C67.1875 36.6556 66.9661 36.6757 66.749 36.72V38.84Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.049 36.14V38.57H71.269L73.139 36.14H73.989L71.919 38.65C72.2881 38.6991 72.633 38.8609 72.9068 39.1132C73.1806 39.3655 73.3699 39.6961 73.449 40.06C73.6223 40.5833 73.8226 41.0973 74.049 41.6H73.289C73.0983 41.1843 72.9347 40.7567 72.799 40.32C72.7383 39.9661 72.5503 39.6466 72.2702 39.4219C71.9902 39.1971 71.6376 39.0826 71.279 39.1H71.049V41.6H70.389V36.14H71.049Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }