<template>
  <map-layout style="width: 100%; height: 100%; max-height: 800px">
    <map-icon-main-street transform="translate(641,307)" />

    <svg-arrow-hint
      ref="hint"
      hint-path-clone="hintPathCloneHarmony"
      hint-arrow="hintArrowHarmony"
      hint-path="hintPathHarmony"
    />

    <MapIconMetroKuncovo
      class="map-icon__development"
      transform="translate(546,228)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 322],
            [589, 293],
            [575, 266],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovo
      class="map-icon__development"
      transform="translate(490,268)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 302],
            [589, 293],
            [530, 279],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoMcd
      class="map-icon__development"
      transform="translate(619,378)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 330],
            [770, 342],
            [710, 473],
            [648, 420],
          ],
          hint: '6 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoBkl
      class="map-icon__development"
      transform="translate(529,298)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [585, 282],
            [566, 288],
            [560, 295],
          ],
          hint: '7 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroScolkovo
      class="map-icon__development"
      transform="translate(126,505)"
      @click="
        $refs.hint.animate({
          points: [
            [645, 340],
            [595, 392],
            [490, 543],
            [174, 515],
          ],
          hint: '10 минут',
          reverse: true,
          dy: -5,
          electric: true,
        })
      "
    />

    <MapIconSroptClub
      class="map-icon__development"
      transform="translate(581,195)"
      @click="
        $refs.hint.animate({
          points: [
            [656, 306],
            [604, 292],
            [623, 249],
            [623, 246],
          ],
          hint: '5 минут',
          reverse: true,
          car: true,
        })
      "
    />

    <MapIconManorKuncovo
      class="map-icon__development"
      transform="translate(681,175)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 306],
            [694, 282],
            [696, 243],
            [697, 230],
          ],
          hint: '7 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconCinemaKoro
      class="map-icon__development"
      transform="translate(787,72)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 306],
            [784, 192],
            [786, 160],
            [796, 128],
          ],
          hint: '15 минут',
          car: true,
        })
      "
    />

    <MapIconInstituteModern
      class="map-icon__development"
      transform="translate(751,226)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 326],
            [724, 312],
            [741, 303],
            [767, 284],
          ],
          hint: '5 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconGrooveSchool
      class="map-icon__development"
      transform="translate(881,250)"
      @click="
        $refs.hint.animate({
          points: [
            [687, 326],
            [724, 332],
            [801, 323],
            [907, 297],
          ],
          hint: '5 минут',
          car: true,
        })
      "
    />

    <MapIconWordClass
      class="map-icon__development"
      transform="translate(477,355)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 372],
            [576, 368],
            [525, 370],
          ],
          hint: '11 минут',
          manLeft: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconWordClass
      class="map-icon__development"
      transform="translate(337,225)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 392],
            [476, 388],
            [395, 280],
          ],
          hint: '10 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconGallery
      class="map-icon__development"
      transform="translate(679,458)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [730, 349],
            [735, 400],
            [720, 450],
          ],
          hint: '10 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconCinemaImax
      class="map-icon__development"
      transform="translate(953,320)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 329],
            [805, 350],
            [980, 335],
          ],
          hint: '13 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconSportWegym
      class="map-icon__development"
      transform="translate(1027,369)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 340],
            [750, 379],
            [815, 410],
            [1019, 385],
          ],
          hint: '13 минут',
          car: true,
        })
      "
    />

    <MapIconMuseumWin
      class="map-icon__development"
      transform="translate(1207,235)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 309],
            [915, 330],
            [1209, 255],
          ],
          hint: '15 минут',
          car: true,
        })
      "
    />

    <MapIconScoolSauls
      class="map-icon__development"
      transform="translate(551,520)"
      @click="
        $refs.hint.animate({
          points: [
            [688, 330],
            [690, 322],
            [698, 443],
            [606, 523],
          ],
          hint: '10 минут',
          car: true,
          reverse: true,
        })
      "
    />
  </map-layout>
</template>

<script>
import MapLayout from "@/components/elements/map/MapLayout";
import MapIconMainStreet from "@/components/elements/map/icons/MapIconMainStreet";
import SvgArrowHint from "@/components/elements/map/SVGArrowHint";
import MapIconMetroKuncovo from "@/components/elements/map/icons/MapIconMetroKuncovoDevelopment";
import MapIconMetroKuncovoBkl from "@/components/elements/map/icons/MapIconMetroKuncovoBkl";
import MapIconMetroKuncovoMcd from "@/components/elements/map/icons/MapIconMetroKuncovoMcd";
import MapIconMetroScolkovo from "@/components/elements/map/icons/MapIconMetroScolkovo";
import MapIconSroptClub from "@/components/elements/map/icons/MapIconSroptClub";
import MapIconManorKuncovo from "@/components/elements/map/icons/MapIconManorKuncovo";
import MapIconCinemaKoro from "@/components/elements/map/icons/MapIconCinemaKoro";
import MapIconInstituteModern from "@/components/elements/map/icons/MapIconInstituteModern";
import MapIconGrooveSchool from "@/components/elements/map/icons/MapIconGrooveSchool";
import MapIconWordClass from "@/components/elements/map/icons/MapIconWordClass";
import MapIconGallery from "@/components/elements/map/icons/MapIconGallery";
import MapIconCinemaImax from "@/components/elements/map/icons/MapIconCinemaImax";
import MapIconSportWegym from "@/components/elements/map/icons/MapIconSportWegym";
import MapIconMuseumWin from "@/components/elements/map/icons/MapIconMuseumWin";
import MapIconScoolSauls from "@/components/elements/map/icons/MapIconScoolSauls";

export default {
  name: "PointHarmony",
  components: {
    SvgArrowHint,
    MapIconMainStreet,
    MapLayout,
    MapIconMetroKuncovo,
    MapIconMetroKuncovoBkl,
    MapIconMetroKuncovoMcd,
    MapIconMetroScolkovo,
    MapIconSroptClub,
    MapIconManorKuncovo,
    MapIconCinemaKoro,
    MapIconInstituteModern,
    MapIconGrooveSchool,
    MapIconWordClass,
    MapIconGallery,
    MapIconCinemaImax,
    MapIconSportWegym,
    MapIconMuseumWin,
    MapIconScoolSauls,
  },
  data: () => ({
    animateStartDevelopment: false,
    points: [
      {
        points: [
          [646, 330],
          [599, 322],
          [589, 293],
          [575, 266],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [599, 302],
          [589, 293],
          [530, 279],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [686, 330],
          [770, 342],
          [710, 473],
          [648, 420],
        ],
        hint: "6 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [585, 282],
          [566, 288],
          [560, 295],
        ],
        hint: "7 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [645, 340],
          [595, 392],
          [490, 543],
          [174, 515],
        ],
        hint: "10 минут",
        reverse: true,
        dy: -5,
        electric: true,
      },
      {
        points: [
          [656, 306],
          [604, 292],
          [623, 249],
          [623, 246],
        ],
        hint: "5 минут",
        reverse: true,
        car: true,
      },
      {
        points: [
          [666, 306],
          [694, 282],
          [696, 243],
          [697, 230],
        ],
        hint: "7 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 306],
          [784, 192],
          [786, 160],
          [796, 128],
        ],
        hint: "15 минут",
        car: true,
      },
      {
        points: [
          [686, 326],
          [724, 312],
          [741, 303],
          [767, 284],
        ],
        hint: "5 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [687, 326],
          [724, 332],
          [801, 323],
          [907, 297],
        ],
        hint: "5 минут",
        car: true,
      },
      {
        points: [
          [646, 330],
          [625, 372],
          [576, 368],
          [525, 370],
        ],
        hint: "11 минут",
        manLeft: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 392],
          [476, 388],
          [395, 280],
        ],
        hint: "10 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [730, 349],
          [735, 400],
          [720, 450],
        ],
        hint: "10 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [750, 329],
          [805, 350],
          [980, 335],
        ],
        hint: "13 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 340],
          [750, 379],
          [815, 410],
          [1019, 385],
        ],
        hint: "13 минут",
        car: true,
      },
      {
        points: [
          [690, 330],
          [750, 309],
          [915, 330],
          [1209, 255],
        ],
        hint: "15 минут",
        car: true,
      },
      {
        points: [
          [688, 330],
          [690, 322],
          [698, 443],
          [606, 523],
        ],
        hint: "10 минут",
        car: true,
        reverse: true,
      },
    ],
  }),
  mounted() {
    this.animateDevelopment(this.points);
  },
  methods: {
    animateDevelopment(points = []) {
      const pointsToAnimate = [...points];
      const _this = this;
      const intervalID = setInterval(() => {
        const point = pointsToAnimate.pop();
        if (point === undefined) {
          clearInterval(intervalID);
          _this.animateDevelopment(_this.points);
        } else if (_this.animateStartDevelopment === true) {
          clearInterval(intervalID);
          setTimeout(() => {
            _this.animateStartDevelopment = false;
            _this.animateDevelopment(_this.points);
          }, 2000);
        } else {
          _this.$refs.hint.animate(point);
        }
      }, 4000);

      var elem = document.querySelectorAll(".map-icon__development");

      elem.forEach(function (item, i, arr) {
        item.addEventListener(
          "click",
          () => {
            _this.animateStartDevelopment = true;
          },
          { once: true }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
