<template>
  <g @click="$emit('click')">
    <circle
      cx="25"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M24.9001 23.1131C23.4246 23.1131 21.9823 22.6756 20.7555 21.8559C19.5287 21.0362 18.5725 19.8711 18.0079 18.5079C17.4433 17.1448 17.2956 15.6448 17.5834 14.1977C17.8713 12.7506 18.5817 11.4214 19.625 10.3781C20.6683 9.3348 21.9976 8.62431 23.4447 8.33646C24.8918 8.04862 26.3918 8.19635 27.7549 8.76098C29.118 9.32561 30.2831 10.2818 31.1028 11.5086C31.9225 12.7354 32.3601 14.1777 32.3601 15.6531C32.3574 17.6308 31.5706 19.5268 30.1722 20.9252C28.7737 22.3237 26.8778 23.1105 24.9001 23.1131ZM24.9001 8.90311C23.565 8.90311 22.26 9.299 21.15 10.0407C20.0399 10.7824 19.1748 11.8366 18.6639 13.07C18.153 14.3034 18.0193 15.6606 18.2798 16.97C18.5402 18.2793 19.1831 19.4821 20.1271 20.4261C21.0711 21.3701 22.2738 22.013 23.5832 22.2734C24.8926 22.5339 26.2498 22.4002 27.4832 21.8893C28.7166 21.3784 29.7708 20.5133 30.5125 19.4032C31.2542 18.2932 31.6501 16.9881 31.6501 15.6531C31.6474 13.8637 30.9354 12.1484 29.6701 10.8831C28.4048 9.61776 26.6895 8.90576 24.9001 8.90311Z"
      fill="#E73C14"
    />
    <path
      d="M24.9 21.0232C23.8384 21.0212 22.8011 20.7046 21.9194 20.1133C21.0376 19.5221 20.3508 18.6827 19.9459 17.7013C19.541 16.7199 19.4361 15.6405 19.6445 14.5995C19.8528 13.5584 20.365 12.6025 21.1164 11.8525C21.8678 11.1025 22.8247 10.5921 23.8661 10.3857C24.9075 10.1793 25.9867 10.2862 26.9674 10.6929C27.948 11.0997 28.7861 11.788 29.3757 12.6708C29.9653 13.5537 30.28 14.5915 30.28 15.6532C30.2774 17.0783 29.7094 18.4442 28.7007 19.451C27.6921 20.4578 26.3252 21.0232 24.9 21.0232ZM24.9 10.9932C23.9788 10.9952 23.0788 11.3002 22.3138 11.8134C21.5488 12.3267 20.953 13.0551 20.6019 13.9068C20.2507 14.7585 20.1599 15.6951 20.3408 16.5984C20.5217 17.5017 20.9663 18.3311 21.6184 18.9818C22.2706 19.6325 23.1009 20.0754 24.0046 20.2544C24.9083 20.4334 25.8447 20.3405 26.6956 19.9875C27.5465 19.6345 28.2738 19.0372 28.7854 18.2711C29.297 17.505 29.57 16.6044 29.57 15.6832C29.574 15.0679 29.4561 14.4579 29.223 13.8884C28.99 13.3189 28.6465 12.8012 28.2123 12.3651C27.7781 11.9291 27.2619 11.5833 26.6934 11.3479C26.1249 11.1124 25.5154 10.9919 24.9 10.9932Z"
      fill="#E73C14"
    />
    <path
      d="M15.34 23.7631C15.118 23.7605 14.9059 23.6712 14.7489 23.5142C14.592 23.3573 14.5026 23.1451 14.5 22.9231V8.35313C14.4995 8.30602 14.509 8.25935 14.5279 8.21623C14.5469 8.1731 14.5749 8.13453 14.61 8.10313C14.6451 8.06505 14.6889 8.03606 14.7377 8.01864C14.7865 8.00122 14.8387 7.9959 14.89 8.00314C15.3631 8.02125 15.8108 8.22201 16.139 8.56326C16.4672 8.9045 16.6504 9.35968 16.65 9.83314V15.8331C16.65 15.926 16.6131 16.015 16.5475 16.0806C16.4819 16.1463 16.3928 16.1831 16.3 16.1831H15.98L16.18 22.8931C16.1827 23.0056 16.163 23.1175 16.1221 23.2223C16.0812 23.327 16.0199 23.4227 15.9417 23.5036C15.8636 23.5845 15.7702 23.6491 15.6669 23.6937C15.5636 23.7382 15.4525 23.7618 15.34 23.7631ZM15.21 8.76313V22.9031C15.21 23.0431 15.47 23.0431 15.47 22.9031L15.26 15.8431C15.256 15.7951 15.2629 15.7467 15.2802 15.7017C15.2976 15.6567 15.3248 15.6161 15.36 15.5831C15.4257 15.5151 15.5155 15.4755 15.61 15.4731H15.94V9.81313C15.936 9.58796 15.8642 9.36922 15.734 9.18548C15.6037 9.00174 15.4211 8.86152 15.21 8.78314V8.76313Z"
      fill="#E73C14"
    />
    <path
      d="M34.4601 23.6632C34.2214 23.6632 33.9925 23.5684 33.8237 23.3996C33.6549 23.2308 33.5601 23.0019 33.5601 22.7632L33.8 13.6732C33.8025 13.6232 33.8153 13.5743 33.8376 13.5295C33.86 13.4848 33.8915 13.4452 33.9301 13.4132C33.9694 13.3792 34.0158 13.3543 34.066 13.3405C34.1162 13.3266 34.1688 13.3242 34.2201 13.3332C34.3787 13.3633 34.5415 13.3633 34.7 13.3332C34.7497 13.3262 34.8003 13.3296 34.8485 13.3433C34.8968 13.357 34.9416 13.3806 34.9802 13.4126C35.0188 13.4446 35.0503 13.4843 35.0727 13.5292C35.095 13.5741 35.1078 13.6231 35.11 13.6732L35.36 22.7532C35.3614 22.8723 35.3391 22.9904 35.2944 23.1007C35.2498 23.2111 35.1837 23.3115 35.1 23.3961C35.0163 23.4807 34.9166 23.5479 34.8068 23.5938C34.6969 23.6396 34.5791 23.6632 34.4601 23.6632ZM34.4601 15.7732L34.2701 22.7732C34.27 22.7977 34.275 22.822 34.2846 22.8445C34.2943 22.867 34.3085 22.8873 34.3263 22.9042C34.3441 22.921 34.3651 22.9341 34.3881 22.9425C34.4111 22.9509 34.4356 22.9546 34.4601 22.9532C34.5104 22.9532 34.5588 22.9332 34.5944 22.8976C34.63 22.8619 34.6501 22.8136 34.6501 22.7632L34.4601 15.7732Z"
      fill="#E73C14"
    />
    <path
      d="M34.46 14.0632H34.1001C33.6195 13.9793 33.181 13.7366 32.8547 13.374C32.5283 13.0114 32.333 12.5499 32.3 12.0632V11.9132C32.2901 11.8235 32.2901 11.7329 32.3 11.6432C32.3432 10.5688 32.6899 9.52861 33.3 8.64319C33.4338 8.46577 33.6037 8.31876 33.7985 8.21188C33.9933 8.105 34.2085 8.04069 34.43 8.02319C34.6493 8.03826 34.8626 8.10166 35.0545 8.20885C35.2464 8.31604 35.4122 8.46437 35.5401 8.64319C36.1444 9.5315 36.4905 10.57 36.5401 11.6432C36.545 11.7264 36.545 11.8099 36.5401 11.8932V12.1032C36.4956 12.576 36.2959 13.0207 35.9721 13.3681C35.6482 13.7154 35.2186 13.9458 34.75 14.0232L34.46 14.0632ZM34.46 8.74318C34.3398 8.75588 34.2239 8.79469 34.1203 8.85687C34.0166 8.91905 33.9278 9.00311 33.86 9.10318C33.3352 9.87578 33.0373 10.7799 33 11.7132C32.9953 11.7764 32.9953 11.8399 33 11.9032V12.0032C33.0244 12.3281 33.1568 12.6355 33.376 12.8766C33.5953 13.1176 33.8889 13.2783 34.21 13.3332C34.3686 13.3632 34.5315 13.3632 34.69 13.3332C35.0117 13.2775 35.3052 13.1149 35.5229 12.8717C35.7407 12.6286 35.87 12.319 35.89 11.9932C35.896 11.92 35.896 11.8464 35.89 11.7732C35.8476 10.8347 35.5503 9.92543 35.03 9.14319C34.9745 9.03932 34.8962 8.94936 34.8009 8.88008C34.7057 8.8108 34.596 8.764 34.48 8.74318H34.46Z"
      fill="#E73C14"
    />
    <path
      d="M13.04 35.103C13.3408 35.0537 13.6452 35.0303 13.95 35.033C14.3095 35.0146 14.6639 35.1245 14.95 35.343C15.0556 35.4407 15.1389 35.5601 15.1941 35.693C15.2493 35.8259 15.2752 35.9692 15.27 36.113C15.2824 36.4026 15.1821 36.6858 14.99 36.903C14.7783 37.0671 14.5345 37.1848 14.2744 37.2486C14.0142 37.3123 13.7436 37.3206 13.48 37.273V38.753H13L13.04 35.103ZM13.52 36.883C13.6494 36.901 13.7806 36.901 13.91 36.883C14.49 36.883 14.84 36.603 14.84 36.093C14.84 35.583 14.49 35.373 13.97 35.373C13.8208 35.3515 13.6692 35.3515 13.52 35.373V36.883Z"
      fill="black"
    />
    <path
      d="M17.87 37.023H16.43V38.353H18.03V38.753H15.95V35.063H17.95V35.463H16.43V36.623H17.87V37.023Z"
      fill="black"
    />
    <path
      d="M21.23 38.6329C20.9128 38.7611 20.5717 38.819 20.23 38.8029C19.9819 38.8198 19.733 38.7827 19.5006 38.6941C19.2681 38.6056 19.0577 38.4676 18.8838 38.2899C18.7098 38.1121 18.5765 37.8987 18.493 37.6645C18.4094 37.4302 18.3777 37.1806 18.4 36.9329C18.3855 36.6756 18.4255 36.4182 18.5173 36.1775C18.6092 35.9368 18.7509 35.7182 18.9331 35.536C19.1153 35.3538 19.3339 35.2121 19.5746 35.1203C19.8153 35.0284 20.0727 34.9884 20.33 35.0029C20.6313 34.9933 20.9313 35.0479 21.21 35.1629L21.09 35.5529C20.8577 35.448 20.6048 35.3968 20.35 35.4029C20.1501 35.3899 19.9499 35.421 19.7633 35.4938C19.5767 35.5666 19.4084 35.6795 19.2701 35.8244C19.1319 35.9693 19.0271 36.1428 18.9631 36.3326C18.8992 36.5224 18.8776 36.7239 18.9 36.9229C18.8809 37.1168 18.904 37.3126 18.9678 37.4968C19.0315 37.6809 19.1344 37.8491 19.2694 37.9897C19.4043 38.1303 19.568 38.2401 19.7494 38.3114C19.9308 38.3827 20.1254 38.4139 20.32 38.4029C20.5874 38.4055 20.8526 38.3545 21.1 38.2529L21.23 38.6329Z"
      fill="black"
    />
    <path
      d="M22.72 35.463H21.59V35.063H24.32V35.463H23.19V38.753H22.71L22.72 35.463Z"
      fill="black"
    />
    <path
      d="M27.71 36.8629C27.7413 37.107 27.7201 37.355 27.6479 37.5903C27.5758 37.8256 27.4542 38.0427 27.2914 38.2273C27.1286 38.4119 26.9283 38.5596 26.7039 38.6606C26.4795 38.7616 26.2361 38.8135 25.99 38.8129C25.7541 38.8087 25.5217 38.7555 25.3076 38.6565C25.0934 38.5576 24.9022 38.4152 24.7461 38.2384C24.5899 38.0616 24.4723 37.8542 24.4007 37.6294C24.329 37.4047 24.3049 37.1675 24.33 36.9329C24.3049 36.691 24.3306 36.4464 24.4054 36.2149C24.4801 35.9834 24.6023 35.7701 24.7642 35.5885C24.926 35.4069 25.124 35.261 25.3454 35.1602C25.5668 35.0594 25.8067 35.0058 26.05 35.0029C26.2849 35.0044 26.5169 35.0551 26.731 35.1518C26.9452 35.2485 27.1366 35.389 27.2931 35.5643C27.4495 35.7395 27.5674 35.9457 27.6392 36.1694C27.711 36.3931 27.7351 36.6293 27.71 36.8629ZM24.84 36.9229C24.84 37.7129 25.27 38.4229 26.02 38.4229C26.77 38.4229 27.2 37.7229 27.2 36.8929C27.2 36.0629 26.82 35.3929 26.02 35.3929C25.22 35.3929 24.84 36.1129 24.84 36.9329V36.9229Z"
      fill="black"
    />
    <path
      d="M28.33 35.103C28.6307 35.0537 28.9352 35.0303 29.24 35.033C29.5995 35.0146 29.9539 35.1245 30.24 35.343C30.3456 35.4407 30.4288 35.5601 30.484 35.693C30.5393 35.8259 30.5652 35.9692 30.56 36.113C30.5724 36.4026 30.472 36.6858 30.28 36.903C30.0665 37.0673 29.8209 37.1852 29.5591 37.2489C29.2974 37.3126 29.0251 37.3208 28.76 37.273V38.753H28.29L28.33 35.103ZM28.8 36.883C28.9327 36.9004 29.0672 36.9004 29.2 36.883C29.77 36.883 30.12 36.603 30.12 36.093C30.12 35.583 29.78 35.373 29.25 35.373C29.1007 35.3515 28.9492 35.3515 28.8 35.373V36.883Z"
      fill="black"
    />
    <path
      d="M31.38 37.593L31 38.753H30.5L31.76 35.063H32.33L33.59 38.753H33.08L32.69 37.593H31.38ZM32.59 37.213L32.23 36.153C32.15 35.913 32.09 35.693 32.04 35.483C31.98 35.703 31.92 35.923 31.85 36.153L31.49 37.213H32.59Z"
      fill="black"
    />
    <path
      d="M34.59 35.063V36.603H36.38V35.063H36.86V38.753H36.38V37.023H34.59V38.753H34.11V35.063H34.59Z"
      fill="black"
    />
    <path
      d="M0.560028 44.7329L1.65002 46.2229H1.07001L0 44.7329L1.07001 43.2429H1.65002L0.560028 44.7329ZM1.89001 44.7329L2.98001 46.2229H2.40002L1.33002 44.7329L2.40002 43.2429H2.98001L1.89001 44.7329Z"
      fill="black"
    />
    <path
      d="M3.68002 45.9329C4.05975 46.1646 4.49518 46.289 4.94 46.2929C5.66 46.2929 6.09003 45.9129 6.09003 45.3629C6.09003 44.8129 5.79003 44.5629 5.09003 44.2729C4.20003 43.9629 3.64001 43.4929 3.64001 42.7329C3.65077 42.5161 3.70611 42.3038 3.80255 42.1093C3.89899 41.9148 4.03448 41.7423 4.20056 41.6025C4.36664 41.4627 4.55973 41.3586 4.76782 41.2967C4.97591 41.2348 5.19452 41.2165 5.41 41.2429C5.82981 41.2277 6.24651 41.3207 6.62003 41.5129L6.42001 42.0929C6.11369 41.9239 5.76985 41.8345 5.42001 41.8329C4.67001 41.8329 4.42001 42.2729 4.42001 42.6429C4.42001 43.1629 4.75 43.4129 5.5 43.7029C6.43 44.0529 6.90002 44.5029 6.90002 45.3029C6.90002 46.1029 6.27001 46.8729 4.98001 46.8729C4.4954 46.8788 4.01722 46.7618 3.59003 46.5329L3.68002 45.9329Z"
      fill="black"
    />
    <path
      d="M7.77002 41.3829C8.21591 41.3066 8.66767 41.2698 9.12003 41.2729C9.66796 41.2301 10.2125 41.3903 10.65 41.7229C10.8088 41.8682 10.934 42.0463 11.0169 42.2449C11.0998 42.4435 11.1384 42.6579 11.13 42.8729C11.1397 43.0844 11.1076 43.2957 11.0355 43.4947C10.9635 43.6938 10.8529 43.8767 10.71 44.0329C10.4869 44.2409 10.2238 44.4014 9.93668 44.5045C9.64959 44.6077 9.34451 44.6513 9.04001 44.6329C8.85061 44.6542 8.6594 44.6542 8.47 44.6329V46.8129H7.77002V41.3829ZM8.47 44.0129C8.66348 44.0569 8.86165 44.0771 9.06003 44.0729C9.91003 44.0729 10.43 43.6529 10.43 42.9029C10.43 42.1529 9.92001 41.8329 9.14001 41.8329C8.91503 41.8239 8.68981 41.8441 8.47 41.8929V44.0129Z"
      fill="black"
    />
    <path
      d="M14.89 44.2129H12.77V46.2129H15.14V46.8029H12.07V41.3429H15.02V41.9329H12.77V43.6629H14.89V44.2129Z"
      fill="black"
    />
    <path
      d="M17.23 41.9329H15.57V41.3329H19.57V41.9329H17.9V46.7929H17.19L17.23 41.9329Z"
      fill="black"
    />
    <path
      d="M21.56 41.9329H19.89V41.3329H23.89V41.9329H22.22V46.7929H21.51L21.56 41.9329Z"
      fill="black"
    />
    <path
      d="M24.82 45.063L24.26 46.773H23.53L25.38 41.313H26.23L28.1 46.773H27.34L26.76 45.063H24.82ZM26.62 44.503L26.08 42.933C25.96 42.583 25.88 42.253 25.8 41.933C25.72 42.253 25.63 42.583 25.53 42.933L24.99 44.513L26.62 44.503Z"
      fill="black"
    />
    <path
      d="M32.5901 46.5929C32.1338 46.7801 31.643 46.8687 31.1501 46.8529C30.7844 46.8758 30.418 46.8196 30.076 46.6881C29.734 46.5566 29.4244 46.353 29.1682 46.0911C28.9119 45.8292 28.7152 45.5151 28.5912 45.1703C28.4673 44.8255 28.4192 44.458 28.4501 44.0929C28.4286 43.7121 28.4874 43.331 28.6228 42.9744C28.7581 42.6178 28.9669 42.2937 29.2357 42.023C29.5045 41.7524 29.8271 41.5413 30.1828 41.4034C30.5384 41.2656 30.9191 41.2041 31.3001 41.2229C31.7469 41.2045 32.1919 41.2901 32.6001 41.4729L32.4301 42.0429C32.0849 41.8861 31.7092 41.8075 31.3301 41.8129C31.0333 41.791 30.7355 41.8355 30.4581 41.9432C30.1808 42.051 29.931 42.2192 29.7269 42.4357C29.5228 42.6523 29.3697 42.9116 29.2785 43.1948C29.1874 43.478 29.1606 43.778 29.2001 44.0729C29.1723 44.3597 29.2067 44.6491 29.3012 44.9214C29.3956 45.1936 29.5478 45.4422 29.7472 45.6502C29.9466 45.8581 30.1886 46.0206 30.4566 46.1263C30.7246 46.2321 31.0124 46.2787 31.3001 46.2629C31.6941 46.2658 32.0849 46.1911 32.4501 46.0429L32.5901 46.5929Z"
      fill="black"
    />
    <path
      d="M37.97 43.9929C38.0156 44.3528 37.9838 44.7182 37.8768 45.0648C37.7699 45.4115 37.5902 45.7313 37.3498 46.0029C37.1094 46.2746 36.8138 46.4918 36.4827 46.6401C36.1517 46.7884 35.7928 46.8643 35.4301 46.8629C35.082 46.858 34.7387 46.7804 34.4224 46.6352C34.106 46.4899 33.8234 46.2802 33.5928 46.0194C33.3621 45.7587 33.1885 45.4526 33.083 45.1209C32.9775 44.7891 32.9424 44.439 32.98 44.0929C32.9426 43.7345 32.98 43.3722 33.09 43.029C33.2 42.6858 33.3801 42.3692 33.619 42.0994C33.8578 41.8295 34.1501 41.6122 34.4774 41.4613C34.8047 41.3105 35.1597 41.2293 35.5201 41.2229C35.8691 41.2247 36.2138 41.3002 36.5317 41.4444C36.8496 41.5887 37.1335 41.7984 37.3647 42.0598C37.596 42.3213 37.7694 42.6286 37.8738 42.9617C37.9781 43.2949 38.0109 43.6462 37.97 43.9929ZM33.73 44.0729C33.73 45.2429 34.36 46.2929 35.47 46.2929C36.58 46.2929 37.22 45.2929 37.22 44.0229C37.22 42.9329 36.66 41.8029 35.48 41.8029C34.3 41.8029 33.73 42.9329 33.73 44.0729Z"
      fill="black"
    />
    <path
      d="M38.89 41.313H39.6V46.183H41.89V46.773H38.89V41.313Z"
      fill="black"
    />
    <path
      d="M47.0601 43.9929C47.1056 44.3528 47.0738 44.7182 46.9668 45.0648C46.8599 45.4115 46.6802 45.7313 46.4398 46.0029C46.1993 46.2746 45.9038 46.4918 45.5727 46.6401C45.2417 46.7884 44.8828 46.8643 44.52 46.8629C44.172 46.858 43.8287 46.7804 43.5124 46.6352C43.196 46.4899 42.9134 46.2802 42.6828 46.0194C42.4521 45.7587 42.2785 45.4526 42.173 45.1209C42.0675 44.7891 42.0324 44.439 42.07 44.0929C42.0311 43.735 42.0671 43.3729 42.1759 43.0298C42.2847 42.6866 42.4639 42.3699 42.702 42.0998C42.94 41.8298 43.2317 41.6123 43.5586 41.4613C43.8854 41.3103 44.2401 41.2291 44.6 41.2229C44.9494 41.2249 45.2945 41.3004 45.6128 41.4445C45.9312 41.5887 46.2156 41.7982 46.4476 42.0594C46.6797 42.3207 46.8541 42.6279 46.9596 42.961C47.0651 43.2941 47.0993 43.6457 47.0601 43.9929ZM42.82 44.0729C42.82 45.2429 43.4501 46.2929 44.5601 46.2929C45.6701 46.2929 46.3101 45.2929 46.3101 44.0229C46.3101 42.9329 45.74 41.8029 44.57 41.8029C43.4 41.8029 42.82 42.9329 42.82 44.0729Z"
      fill="black"
    />
    <path
      d="M47.54 43.2429H48.1201L49.19 44.7329L48.1201 46.2229H47.55L48.63 44.7329L47.54 43.2429ZM48.86 43.2429H49.44L50.5201 44.7329L49.44 46.2229H48.89L49.97 44.7329L48.86 43.2429Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconRestorauntSpett",
};
</script>

<style scoped lang="scss"></style>
