var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "28",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.16 20.36C30.7559 20.36 30.3683 20.2002 30.0816 19.9154C29.795 19.6306 29.6326 19.244 29.63 18.84V14.24H30.63V18.84C30.6475 18.9667 30.7104 19.0829 30.8069 19.167C30.9034 19.251 31.027 19.2973 31.155 19.2973C31.283 19.2973 31.4066 19.251 31.5031 19.167C31.5996 19.0829 31.6625 18.9667 31.68 18.84V12.49C31.68 12.4217 31.6665 12.3541 31.6404 12.291C31.6143 12.2279 31.576 12.1706 31.5277 12.1223C31.4794 12.074 31.4221 12.0357 31.359 12.0096C31.2959 11.9834 31.2283 11.97 31.16 11.97H24.85C24.7121 11.97 24.5798 12.0248 24.4823 12.1223C24.3848 12.2198 24.33 12.3521 24.33 12.49V18.81C24.33 18.9479 24.3848 19.0801 24.4823 19.1777C24.5798 19.2752 24.7121 19.33 24.85 19.33C24.9879 19.33 25.1202 19.2752 25.2177 19.1777C25.3152 19.0801 25.37 18.9479 25.37 18.81V14.21H26.37V18.81C26.3117 19.1658 26.1288 19.4892 25.8541 19.7226C25.5793 19.9561 25.2305 20.0842 24.87 20.0842C24.5095 20.0842 24.1607 19.9561 23.8859 19.7226C23.6111 19.4892 23.4283 19.1658 23.37 18.81V12.49C23.37 12.0868 23.5301 11.7002 23.8152 11.4152C24.1002 11.1301 24.4869 10.97 24.89 10.97H31.16C31.5641 10.97 31.9517 11.1298 32.2383 11.4146C32.525 11.6993 32.6874 12.0859 32.69 12.49V18.81C32.6926 19.0126 32.655 19.2137 32.5793 19.4016C32.5036 19.5896 32.3913 19.7606 32.249 19.9048C32.1067 20.049 31.9371 20.1635 31.7502 20.2416C31.5632 20.3198 31.3626 20.36 31.16 20.36Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.37 14.2399H25.37V19.8599H26.37V14.2399Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.65 14.2399H29.65V19.8599H30.65V14.2399Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.01 10.78C27.487 10.782 26.9752 10.6286 26.5395 10.3392C26.1038 10.0499 25.7639 9.6377 25.5629 9.15489C25.3618 8.67209 25.3087 8.14045 25.4102 7.62741C25.5118 7.11437 25.7634 6.64305 26.1332 6.27324C26.503 5.90343 26.9744 5.65179 27.4874 5.55024C28.0004 5.4487 28.5321 5.50182 29.0149 5.70287C29.4977 5.90393 29.9099 6.24385 30.1992 6.67953C30.4885 7.1152 30.6419 7.62699 30.64 8.14998C30.6373 8.84669 30.3594 9.51411 29.8667 10.0068C29.3741 10.4994 28.7067 10.7773 28.01 10.78ZM28.01 6.50998C27.6852 6.508 27.3671 6.6025 27.0961 6.7815C26.8251 6.9605 26.6134 7.21593 26.4877 7.51542C26.362 7.81491 26.3281 8.14497 26.3903 8.46376C26.4524 8.78254 26.6078 9.0757 26.8368 9.30606C27.0657 9.53641 27.3579 9.69361 27.6763 9.7577C27.9947 9.82179 28.325 9.78991 28.6252 9.66608C28.9255 9.54225 29.1822 9.33206 29.3629 9.06215C29.5435 8.79224 29.64 8.47477 29.64 8.14998C29.644 7.93342 29.6047 7.71823 29.5246 7.51699C29.4445 7.31576 29.325 7.13251 29.1733 6.97796C29.0215 6.82341 28.8405 6.70065 28.6407 6.61687C28.441 6.53308 28.2266 6.48995 28.01 6.48998V6.50998Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: { d: "M19.35 15.2H18.35V22.86H19.35V15.2Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: { d: "M21.46 15.2H20.46V22.86H21.46V15.2Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: { d: "M18.85 18.08H17.7V19.08H18.85V18.08Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: { d: "M23.83 18.08H20.96V19.08H23.83V18.08Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: { d: "M30.15 18.08H25.87V19.08H30.15V18.08Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: {
          d: "M37.67 15.0499H36.67V22.7099H37.67V15.0499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.56 15.0499H34.56V22.7099H35.56V15.0499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.32 17.9299H37.17V18.9299H38.32V17.9299Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.06 17.9299H32.19V18.9299H35.06V17.9299Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.87 26.1499C25.4668 26.1499 25.0802 25.9897 24.7952 25.7047C24.5101 25.4196 24.35 25.033 24.35 24.6299V19.9899H25.35V24.6299C25.381 24.7385 25.4466 24.8341 25.5368 24.9022C25.627 24.9702 25.737 25.007 25.85 25.007C25.963 25.007 26.0729 24.9702 26.1631 24.9022C26.2533 24.8341 26.3189 24.7385 26.35 24.6299V20.0299H27.35V24.6299C27.3501 25.0262 27.1955 25.4068 26.919 25.6908C26.6426 25.9747 26.2661 26.1394 25.87 26.1499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.16 26.28C29.7576 26.2774 29.3725 26.1164 29.088 25.8319C28.8036 25.5474 28.6426 25.1623 28.64 24.76V20.16H29.64V24.76C29.6764 24.8635 29.744 24.9532 29.8336 25.0167C29.9231 25.0801 30.0302 25.1142 30.14 25.1142C30.2497 25.1142 30.3568 25.0801 30.4463 25.0167C30.5359 24.9532 30.6035 24.8635 30.64 24.76V19.86H31.64V24.76C31.6376 25.1555 31.4821 25.5348 31.2062 25.8182C30.9302 26.1015 30.5553 26.2671 30.16 26.28Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.0049 35V35.33C13.4216 35.3624 13.8107 35.5508 14.0946 35.8576C14.3784 36.1644 14.5361 36.567 14.5361 36.985C14.5361 37.403 14.3784 37.8056 14.0946 38.1124C13.8107 38.4192 13.4216 38.6076 13.0049 38.64V38.99H12.5649V38.64C12.3519 38.6477 12.1397 38.6103 11.9422 38.5302C11.7447 38.45 11.5665 38.329 11.4191 38.175C11.2717 38.0211 11.1587 37.8377 11.0873 37.6369C11.0159 37.4361 10.9878 37.2224 11.0049 37.01C10.9898 36.7956 11.0189 36.5804 11.0904 36.3777C11.1619 36.175 11.2743 35.9892 11.4205 35.8317C11.5668 35.6742 11.7438 35.5484 11.9406 35.4621C12.1375 35.3758 12.3499 35.3308 12.5649 35.33V35H13.0049ZM12.5448 35.67C12.3858 35.6808 12.2306 35.7243 12.0891 35.7977C11.9476 35.8712 11.8227 35.973 11.7223 36.0969C11.6219 36.2207 11.5481 36.364 11.5055 36.5177C11.4629 36.6713 11.4525 36.8321 11.4749 36.99C11.4569 37.1467 11.4705 37.3053 11.5149 37.4566C11.5593 37.6079 11.6335 37.7488 11.7333 37.8709C11.833 37.993 11.9562 38.0939 12.0956 38.1676C12.235 38.2413 12.3878 38.2863 12.5448 38.3V35.67ZM13.0049 38.3C13.1626 38.2892 13.3165 38.246 13.4568 38.173C13.5971 38.1001 13.7209 37.999 13.8204 37.8761C13.9199 37.7531 13.9929 37.611 14.035 37.4585C14.0771 37.3061 14.0872 37.1466 14.0649 36.99C14.0649 35.99 13.5349 35.7 13.0049 35.67V38.3Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.6248 35.15V36.7C15.6248 37.29 15.6248 37.77 15.6248 38.26C15.8048 37.88 16.0448 37.48 16.3048 37.04L17.5048 35.14H17.9748V38.83H17.5348V37.26C17.5348 36.66 17.5348 36.26 17.5348 35.75C17.3332 36.1766 17.1061 36.5907 16.8548 36.99L15.6948 38.83H15.1848V35.14L15.6248 35.15Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.4849 35.53H18.3649V35.13H21.0949V35.53H19.9649V38.82H19.4849V35.53Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.9848 35.15V36.69H23.7648V35.15H24.2548V38.84H23.7648V37.11H21.9848V38.84H21.5048V35.15H21.9848Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.9948 37.11H25.5648V38.44H27.1648V38.84H25.0848V35.15H27.0848V35.55H25.5648V36.71H27.0048L26.9948 37.11Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.3548 38.7199C30.0375 38.8475 29.6965 38.9055 29.3548 38.8899C29.1075 38.9053 28.8597 38.8671 28.6286 38.7779C28.3974 38.6886 28.1882 38.5505 28.0154 38.3729C27.8425 38.1953 27.7101 37.9825 27.6272 37.749C27.5442 37.5155 27.5127 37.2668 27.5348 37.02C27.5203 36.7627 27.5603 36.5053 27.6522 36.2646C27.744 36.0238 27.8857 35.8052 28.0679 35.623C28.2501 35.4408 28.4687 35.2992 28.7094 35.2073C28.9501 35.1155 29.2075 35.0755 29.4648 35.0899C29.7634 35.0733 30.0617 35.1282 30.3348 35.2499L30.2248 35.6399C29.9892 35.5343 29.7329 35.483 29.4748 35.49C29.2758 35.4785 29.0766 35.5107 28.8913 35.5842C28.7059 35.6577 28.5389 35.7708 28.4018 35.9155C28.2646 36.0602 28.1608 36.2331 28.0974 36.4222C28.034 36.6112 28.0126 36.8118 28.0348 37.0099C28.014 37.2042 28.0359 37.4008 28.099 37.5857C28.1621 37.7706 28.265 37.9395 28.4002 38.0805C28.5355 38.2215 28.7 38.3313 28.8822 38.402C29.0644 38.4727 29.2598 38.5027 29.4548 38.49C29.7222 38.4926 29.9874 38.4416 30.2348 38.3399L30.3548 38.7199Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.0548 37.1799V37.5299H30.6948V37.1799H32.0548Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.1048 35.15V36.78H33.2548L34.5148 35.15H35.0948L33.6948 36.84C33.9374 36.8803 34.1623 36.9923 34.3405 37.1617C34.5188 37.331 34.6422 37.5499 34.6948 37.79C34.8066 38.1479 34.9402 38.4985 35.0948 38.84H34.5848C34.4518 38.5581 34.3382 38.2674 34.2448 37.97C34.2022 37.7371 34.0783 37.5268 33.8952 37.3766C33.7121 37.2265 33.4816 37.1462 33.2448 37.15H33.0948V38.84H32.6248V35.15H33.1048Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.2248 35.15V38.84H37.7448V35.53H36.5648V36.64C36.5648 37.44 36.5648 38.34 36.0148 38.69C35.8421 38.7936 35.6461 38.8521 35.4448 38.86L35.3848 38.48C35.5293 38.4465 35.6633 38.3778 35.7748 38.28C36.0539 37.7961 36.1663 37.234 36.0948 36.68V35.13L38.2248 35.15Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.1948 35.15L39.9248 36.72C40.0248 36.94 40.1048 37.15 40.1948 37.37C40.2648 37.18 40.3348 36.97 40.4248 36.7L41.0048 35.15H41.5048L40.7348 37.04C40.5596 37.5318 40.3281 38.0016 40.0448 38.44C39.9513 38.5778 39.8263 38.6914 39.6803 38.7714C39.5342 38.8514 39.3712 38.8955 39.2048 38.9C39.1153 38.9135 39.0243 38.9135 38.9348 38.9L38.9848 38.51H39.1648C39.5448 38.51 39.7648 38.14 39.8848 37.88C39.906 37.8454 39.9172 37.8056 39.9172 37.765C39.9172 37.7244 39.906 37.6846 39.8848 37.65L38.6648 35.17L39.1948 35.15Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.9848 35.15V35.55H42.3848V36.55C42.538 36.5401 42.6916 36.5401 42.8448 36.55C43.1788 36.541 43.5085 36.6277 43.7948 36.8C43.9463 36.8944 44.0699 37.0277 44.1525 37.1859C44.2352 37.3442 44.2739 37.5217 44.2648 37.7C44.2717 37.8476 44.2477 37.995 44.1944 38.1328C44.141 38.2706 44.0594 38.3957 43.9548 38.5C43.5894 38.7972 43.1245 38.9438 42.6548 38.91C42.4084 38.9253 42.1612 38.9253 41.9148 38.91V35.23L43.9848 35.15ZM42.3848 38.47C42.5179 38.4819 42.6517 38.4819 42.7848 38.47C42.928 38.4805 43.0718 38.4601 43.2064 38.41C43.341 38.36 43.4632 38.2815 43.5648 38.18C43.633 38.1083 43.6859 38.0234 43.7203 37.9306C43.7547 37.8378 43.7698 37.7389 43.7648 37.64C43.7699 37.5228 43.7451 37.4061 43.6926 37.3011C43.6401 37.1961 43.5617 37.1063 43.4648 37.04C43.2554 36.9089 43.0118 36.8428 42.7648 36.85C42.6383 36.8405 42.5113 36.8405 42.3848 36.85V38.47Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.559998 45.8199L1.65001 47.31H1.07001L0 45.8199L1.07001 44.33H1.63L0.559998 45.8199ZM1.89 45.8199L2.97 47.31H2.40001L1.33 45.8199L2.40001 44.33H2.97L1.89 45.8199Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.57005 47.86L3.18005 42.4H3.93005L4.58005 45.16C4.74005 45.85 4.88006 46.53 4.98006 47.05C5.07006 46.51 5.24004 45.86 5.42004 45.16L6.15005 42.4H6.89005L7.55005 45.17C7.71005 45.82 7.85005 46.47 7.94005 47.04C8.06005 46.45 8.21005 45.84 8.38005 45.16L9.10005 42.4H9.82005L8.27005 47.86H7.54005L6.85005 45.02C6.69645 44.434 6.57625 43.8397 6.49005 43.24C6.3796 43.8408 6.23604 44.4351 6.06004 45.02L5.29005 47.86H4.57005Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.2001 45.0799C15.2439 45.4395 15.211 45.8043 15.1034 46.1502C14.9958 46.4961 14.816 46.8153 14.5759 47.0866C14.3359 47.3578 14.0409 47.5751 13.7107 47.7239C13.3804 47.8727 13.0223 47.9498 12.66 47.9499C12.312 47.945 11.9687 47.8674 11.6524 47.7222C11.336 47.577 11.0534 47.3672 10.8228 47.1065C10.5921 46.8457 10.4185 46.5397 10.313 46.2079C10.2075 45.8762 10.1724 45.526 10.21 45.1799C10.1711 44.822 10.2071 44.46 10.3159 44.1168C10.4247 43.7736 10.6039 43.4569 10.842 43.1868C11.08 42.9168 11.3718 42.6993 11.6986 42.5483C12.0254 42.3974 12.3801 42.3162 12.74 42.3099C13.0895 42.3119 13.4345 42.3874 13.7529 42.5316C14.0712 42.6757 14.3556 42.8852 14.5876 43.1465C14.8197 43.4077 14.9941 43.7149 15.0996 44.048C15.2051 44.3811 15.2393 44.7327 15.2001 45.0799ZM10.96 45.1599C10.96 46.3299 11.5901 47.3799 12.7001 47.3799C13.8101 47.3799 14.4501 46.3799 14.4501 45.1099C14.4501 43.8399 13.88 42.8899 12.71 42.8899C11.54 42.8899 10.96 43.9699 10.96 45.1599Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.11 42.53C16.5548 42.4449 17.0071 42.4047 17.46 42.41C18.0249 42.3514 18.5907 42.5125 19.04 42.86C19.1781 42.9869 19.2879 43.1416 19.362 43.314C19.4361 43.4863 19.4729 43.6724 19.47 43.86C19.4778 44.1703 19.3845 44.4746 19.2041 44.7272C19.0237 44.9797 18.766 45.1668 18.47 45.26C18.6882 45.357 18.8769 45.5097 19.0174 45.7028C19.1578 45.8959 19.2449 46.1225 19.27 46.36C19.363 46.8731 19.507 47.3756 19.7 47.86H18.97C18.806 47.4393 18.6821 47.004 18.6 46.56C18.44 45.81 18.15 45.56 17.51 45.5H16.84V47.86H16.14L16.11 42.53ZM16.81 45.01H17.54C18.29 45.01 18.77 44.6 18.77 44.01C18.77 43.42 18.26 43.01 17.51 43.01C17.2747 43.0058 17.0398 43.0293 16.81 43.08V45.01Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.47 42.4H21.17V47.27H23.51V47.86H20.51L20.47 42.4Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.29 42.53C24.786 42.45 25.2876 42.4099 25.79 42.41C26.5862 42.3494 27.3757 42.5923 28 43.09C28.268 43.3459 28.4761 43.6579 28.6092 44.0037C28.7424 44.3495 28.7973 44.7205 28.77 45.09C28.7873 45.4798 28.7264 45.8691 28.5907 46.235C28.455 46.6008 28.2474 46.9358 27.98 47.22C27.3098 47.7854 26.4445 48.0655 25.57 48C25.1426 48.0034 24.7153 47.9834 24.29 47.94V42.53ZM24.99 47.35C25.2295 47.3729 25.4706 47.3729 25.71 47.35C26.0223 47.3847 26.3383 47.3489 26.6349 47.2452C26.9315 47.1415 27.201 46.9726 27.4236 46.751C27.6462 46.5293 27.8163 46.2605 27.9212 45.9644C28.0262 45.6683 28.0634 45.3524 28.03 45.04C28.0477 44.7496 28.0018 44.4589 27.8956 44.1881C27.7893 43.9173 27.6253 43.6729 27.4148 43.472C27.2044 43.2711 26.9527 43.1185 26.6773 43.0249C26.4018 42.9313 26.1093 42.8989 25.82 42.93C25.5417 42.9257 25.2637 42.9492 24.99 43V47.35Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.21 47.6799C34.7544 47.8694 34.2631 47.9582 33.77 47.9399C33.4047 47.963 33.0387 47.9068 32.6972 47.7753C32.3556 47.6437 32.0465 47.4399 31.791 47.1778C31.5356 46.9157 31.3398 46.6014 31.2171 46.2566C31.0943 45.9118 31.0476 45.5445 31.08 45.1799C31.0569 44.7987 31.1146 44.4169 31.2494 44.0596C31.3842 43.7023 31.593 43.3775 31.8621 43.1065C32.1312 42.8355 32.4545 42.6244 32.8109 42.4872C33.1673 42.3499 33.5486 42.2895 33.93 42.3099C34.3737 42.2897 34.8159 42.3754 35.22 42.5599L35.05 43.1299C34.7055 42.9708 34.3294 42.8921 33.95 42.8999C33.6533 42.8779 33.3554 42.9224 33.0781 43.0302C32.8007 43.1379 32.5509 43.3062 32.3468 43.5227C32.1428 43.7392 31.9896 43.9986 31.8984 44.2818C31.8073 44.565 31.7805 44.865 31.82 45.1599C31.7922 45.4467 31.8267 45.7361 31.9211 46.0083C32.0155 46.2806 32.1677 46.5292 32.3671 46.7371C32.5665 46.9451 32.8085 47.1075 33.0766 47.2133C33.3446 47.3191 33.6323 47.3657 33.92 47.3499C34.3142 47.3544 34.7053 47.2796 35.07 47.1299L35.21 47.6799Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.1 42.4H36.8V47.27H39.13V47.86H36.13L36.1 42.4Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.8 46.15L40.24 47.86H39.51L41.36 42.4H42.21L44.08 47.86H43.32L42.74 46.15H40.8ZM42.59 45.59L42.06 44.02C41.94 43.67 41.86 43.34 41.78 43.02C41.7 43.34 41.61 43.67 41.5 44.02L40.97 45.6L42.59 45.59Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.78 46.9999C45.1592 47.233 45.595 47.3576 46.0401 47.3599C46.7601 47.3599 47.1801 46.9799 47.1801 46.4299C47.1801 45.8799 46.8901 45.6299 46.1801 45.3399C45.2801 45.0299 44.7301 44.5699 44.7301 43.7999C44.7408 43.5831 44.7961 43.3708 44.8926 43.1763C44.989 42.9818 45.1245 42.8093 45.2906 42.6695C45.4567 42.5297 45.6498 42.4256 45.8579 42.3637C46.066 42.3019 46.2846 42.2835 46.5 42.3099C46.9197 42.296 47.3361 42.3889 47.7101 42.5799L47.5101 43.1599C47.2031 42.9925 46.8597 42.9032 46.5101 42.8999C45.7601 42.8999 45.5101 43.3399 45.5101 43.7099C45.5101 44.0799 45.8401 44.4799 46.5901 44.7699C47.5201 45.1199 47.9901 45.5699 47.9901 46.3699C47.9901 47.1699 47.3601 47.9399 46.0701 47.9399C45.5854 47.9458 45.1072 47.8288 44.6801 47.5999L44.78 46.9999Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.7701 46.9999C49.1498 47.2317 49.5852 47.3561 50.03 47.3599C50.76 47.3599 51.1801 46.9799 51.1801 46.4299C51.1801 45.8799 50.8801 45.6299 50.1801 45.3399C49.2901 45.0299 48.7401 44.5699 48.7401 43.7999C48.7495 43.5836 48.8036 43.3715 48.8989 43.177C48.9943 42.9825 49.1288 42.8099 49.2941 42.67C49.4594 42.53 49.6519 42.4258 49.8594 42.3638C50.067 42.3019 50.2851 42.2835 50.5 42.3099C50.9199 42.2947 51.3365 42.3877 51.7101 42.5799L51.5101 43.1599C51.2037 42.9909 50.8599 42.9015 50.5101 42.8999C49.7601 42.8999 49.5101 43.3399 49.5101 43.7099C49.5101 44.0799 49.8401 44.4799 50.6001 44.7699C51.5201 45.1199 51.9901 45.5699 51.9901 46.3699C51.9901 47.1699 51.36 47.9399 50.08 47.9399C49.5922 47.9466 49.1105 47.8296 48.6801 47.5999L48.7701 46.9999Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.4301 44.33H53.0101L54.08 45.8199L53.0101 47.31H52.44L53.5201 45.8199L52.4301 44.33ZM53.75 44.33H54.33L55.41 45.8199L54.33 47.31H53.7601L54.8401 45.8199L53.75 44.33Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }