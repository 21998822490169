<template>
  <g @click="$emit('click')">
    <circle
      cx="44"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M38.51 12.3198C40.2387 12.3198 41.64 10.9184 41.64 9.18979C41.64 7.46114 40.2387 6.05981 38.51 6.05981C36.7814 6.05981 35.38 7.46114 35.38 9.18979C35.38 10.9184 36.7814 12.3198 38.51 12.3198Z"
      fill="#E73C14"
    />
    <path
      d="M49.31 20.5199C50.8067 20.5199 52.02 19.3066 52.02 17.8099C52.02 16.3132 50.8067 15.0999 49.31 15.0999C47.8133 15.0999 46.6 16.3132 46.6 17.8099C46.6 19.3066 47.8133 20.5199 49.31 20.5199Z"
      fill="#E73C14"
    />
    <path
      d="M44.8 9.18991C45.9101 9.18991 46.81 8.29002 46.81 7.17993C46.81 6.06984 45.9101 5.16992 44.8 5.16992C43.6899 5.16992 42.79 6.06984 42.79 7.17993C42.79 8.29002 43.6899 9.18991 44.8 9.18991Z"
      fill="#E73C14"
    />
    <path
      d="M39.7 19.4799C41.0421 19.4799 42.13 18.3919 42.13 17.0499C42.13 15.7078 41.0421 14.6199 39.7 14.6199C38.358 14.6199 37.27 15.7078 37.27 17.0499C37.27 18.3919 38.358 19.4799 39.7 19.4799Z"
      fill="#E73C14"
    />
    <path
      d="M49.77 12.1999C50.742 12.1999 51.53 11.4119 51.53 10.4399C51.53 9.46789 50.742 8.67993 49.77 8.67993C48.798 8.67993 48.01 9.46789 48.01 10.4399C48.01 11.4119 48.798 12.1999 49.77 12.1999Z"
      fill="#E73C14"
    />
    <path d="M45.25 7.59985H44.25V26.9199H45.25V7.59985Z" fill="#E73C14" />
    <path
      d="M44.79 16.1798L38.16 9.54984L38.87 8.83984L44.79 14.7698L49.42 10.1398L50.12 10.8498L44.79 16.1798Z"
      fill="#E73C14"
    />
    <path
      d="M44.75 23.0199L39.35 17.6099L40.05 16.8999L44.75 21.5999L48.95 17.4099L49.66 18.1099L44.75 23.0199Z"
      fill="#E73C14"
    />
    <path
      d="M0 36.22C0.424784 36.1361 0.857042 36.0959 1.29001 36.1C1.8217 36.0503 2.35367 36.1921 2.79001 36.5C2.94303 36.6154 3.06447 36.7675 3.14313 36.9423C3.22178 37.1171 3.2551 37.3089 3.23999 37.5C3.23093 37.771 3.13738 38.0323 2.97241 38.2475C2.80745 38.4627 2.57934 38.6209 2.32001 38.7C2.63589 38.7645 2.91984 38.936 3.1239 39.1856C3.32796 39.4352 3.43961 39.7476 3.44 40.07C3.44426 40.2738 3.4055 40.4762 3.32623 40.664C3.24697 40.8519 3.129 41.0209 2.98001 41.16C2.43514 41.5587 1.76146 41.7404 1.09 41.67C0.725784 41.6721 0.361785 41.652 0 41.61V36.22ZM0.700012 38.45H1.35001C2.09001 38.45 2.53 38.06 2.53 37.54C2.53 37.02 2.03999 36.64 1.32999 36.64C1.1189 36.6372 0.908026 36.654 0.700012 36.69V38.45ZM0.700012 41.07C0.899803 41.0829 1.1002 41.0829 1.29999 41.07C2.02999 41.07 2.70001 40.8 2.70001 40.01C2.70001 39.22 2.06001 38.96 1.29001 38.96H0.700012V41.07Z"
      fill="black"
    />
    <path
      d="M9.00004 38.82C9.04546 39.179 9.01392 39.5436 8.90754 39.8896C8.80116 40.2355 8.62239 40.5548 8.38307 40.8263C8.14375 41.0977 7.84938 41.3152 7.51954 41.4641C7.1897 41.613 6.83195 41.69 6.47004 41.69C6.12026 41.6879 5.77483 41.6123 5.45607 41.4683C5.1373 41.3243 4.85229 41.115 4.61949 40.8539C4.38669 40.5928 4.21127 40.2858 4.10456 39.9527C3.99786 39.6196 3.96226 39.2677 4.00004 38.92C3.96256 38.5616 4.00001 38.1993 4.11 37.8561C4.21998 37.5129 4.40009 37.1963 4.63893 36.9265C4.87777 36.6566 5.17011 36.4393 5.49739 36.2884C5.82466 36.1376 6.17973 36.0564 6.54005 36.05C6.88947 36.052 7.23455 36.1275 7.55287 36.2716C7.87119 36.4158 8.15559 36.6253 8.38762 36.8865C8.61964 37.1478 8.7941 37.455 8.89961 37.7881C9.00512 38.1212 9.03934 38.4728 9.00004 38.82ZM4.76005 38.91C4.76005 40.07 5.40005 41.12 6.51005 41.12C7.62005 41.12 8.26005 40.12 8.26005 38.85C8.26005 37.58 7.69005 36.63 6.51005 36.63C5.33005 36.63 4.72004 37.71 4.72004 38.91H4.76005Z"
      fill="black"
    />
    <path
      d="M9.88 36.21C10.3258 36.1332 10.7776 36.0964 11.23 36.1C11.7794 36.0581 12.3248 36.2221 12.76 36.56C12.9186 36.7033 13.0437 36.8797 13.1267 37.0767C13.2096 37.2736 13.2483 37.4864 13.24 37.7C13.2568 38.1297 13.1063 38.5491 12.82 38.87C12.3648 39.2834 11.7642 39.4992 11.15 39.47C10.9587 39.4755 10.7674 39.4587 10.58 39.42V41.6H9.88V36.21ZM10.58 38.84C10.7701 38.884 10.9649 38.9042 11.16 38.9C12.01 38.9 12.53 38.48 12.53 37.73C12.53 36.98 12.02 36.66 11.24 36.66C11.0185 36.656 10.7972 36.6761 10.58 36.72V38.84Z"
      fill="black"
    />
    <path
      d="M18.79 38.82C18.8339 39.1796 18.801 39.5444 18.6934 39.8903C18.5858 40.2362 18.406 40.5554 18.1659 40.8266C17.9258 41.0979 17.6309 41.3151 17.3007 41.464C16.9704 41.6128 16.6123 41.6898 16.25 41.69C15.9016 41.6849 15.558 41.6074 15.2412 41.4622C14.9243 41.3171 14.6412 41.1076 14.4098 40.847C14.1784 40.5864 14.0037 40.2805 13.897 39.9487C13.7903 39.617 13.7539 39.2666 13.79 38.92C13.7526 38.5616 13.79 38.1993 13.9 37.8561C14.01 37.5129 14.1901 37.1963 14.4289 36.9265C14.6678 36.6566 14.9601 36.4393 15.2874 36.2884C15.6147 36.1376 15.9697 36.0564 16.33 36.05C16.679 36.0535 17.0233 36.1301 17.3409 36.2747C17.6585 36.4194 17.9422 36.6289 18.174 36.8898C18.4057 37.1507 18.5802 37.4573 18.6863 37.7897C18.7924 38.1221 18.8277 38.4731 18.79 38.82ZM14.55 38.91C14.55 40.07 15.18 41.12 16.29 41.12C17.4 41.12 18.04 40.12 18.04 38.85C18.04 37.58 17.47 36.63 16.3 36.63C15.13 36.63 14.55 37.71 14.55 38.91Z"
      fill="black"
    />
    <path
      d="M20.4 36.14V41.01H22.17V36.14H22.87V41.01H24.63V36.14H25.34V41.6H19.7V36.14H20.4Z"
      fill="black"
    />
    <path
      d="M27.22 36.14V38.43C27.22 39.3 27.22 40.01 27.16 40.74C27.44 40.17 27.78 39.58 28.16 38.93L30 36.14H30.71V41.6H30V39.28C30 38.39 30 37.75 30.07 37.06C29.7772 37.6945 29.4432 38.3092 29.07 38.9L27.36 41.61H26.61V36.15L27.22 36.14Z"
      fill="black"
    />
    <path
      d="M35.5 36.14V41.6H34.79V36.73H33V38.43C33 39.61 32.95 40.93 32.18 41.43C31.932 41.5902 31.6451 41.6801 31.35 41.69L31.26 41.12C31.4723 41.0753 31.6689 40.9752 31.83 40.83C32.2438 40.1133 32.4123 39.2812 32.31 38.46V36.16L35.5 36.14Z"
      fill="black"
    />
    <path
      d="M41.39 38.82C41.4356 39.1799 41.4038 39.5453 41.2968 39.8919C41.1899 40.2386 41.0102 40.5584 40.7697 40.83C40.5293 41.1017 40.2338 41.3189 39.9027 41.4672C39.5717 41.6155 39.2128 41.6914 38.85 41.69C38.5019 41.6851 38.1587 41.6075 37.8424 41.4623C37.526 41.317 37.2434 41.1073 37.0128 40.8465C36.7821 40.5858 36.6085 40.2797 36.503 39.948C36.3975 39.6162 36.3624 39.2661 36.4 38.92C36.3626 38.5624 36.3998 38.2009 36.5092 37.8584C36.6185 37.5159 36.7978 37.1998 37.0356 36.9301C37.2733 36.6604 37.5644 36.4429 37.8905 36.2914C38.2166 36.1399 38.5706 36.0577 38.93 36.05C39.279 36.0535 39.6233 36.1301 39.9409 36.2747C40.2585 36.4194 40.5422 36.6289 40.7739 36.8898C41.0056 37.1507 41.1802 37.4573 41.2863 37.7897C41.3924 38.1221 41.4278 38.4731 41.39 38.82ZM37.15 38.91C37.15 40.07 37.78 41.12 38.89 41.12C40 41.12 40.64 40.12 40.64 38.85C40.64 37.58 40.08 36.63 38.9 36.63C37.72 36.63 37.15 37.71 37.15 38.91Z"
      fill="black"
    />
    <path
      d="M42.3 36.22C42.7248 36.1367 43.1571 36.0965 43.59 36.1C44.1218 36.0494 44.654 36.1913 45.09 36.5C45.243 36.6154 45.3645 36.7675 45.4431 36.9423C45.5218 37.1171 45.5551 37.3089 45.54 37.5C45.534 37.7718 45.4415 38.0346 45.2761 38.2504C45.1107 38.4661 44.8809 38.6236 44.62 38.7C44.9369 38.7642 45.2221 38.9353 45.4278 39.1847C45.6335 39.4342 45.7473 39.7467 45.75 40.07C45.7524 40.2745 45.7117 40.4771 45.6308 40.6649C45.5498 40.8527 45.4303 41.0213 45.28 41.16C44.7382 41.5571 44.0682 41.7389 43.4 41.67C43.0324 41.6725 42.6651 41.6524 42.3 41.61V36.22ZM43 38.43H43.64C44.38 38.43 44.82 38.04 44.82 37.52C44.82 37 44.33 36.62 43.62 36.62C43.4122 36.617 43.2046 36.6338 43 36.67V38.43ZM43 41.05C43.1964 41.0633 43.3936 41.0633 43.59 41.05C44.32 41.05 44.99 40.78 44.99 39.99C44.99 39.2 44.35 38.94 43.58 38.94H43V41.05Z"
      fill="black"
    />
    <path
      d="M50.44 41.43C49.9817 41.609 49.4919 41.6941 49 41.68C48.6344 41.7029 48.268 41.6466 47.926 41.5152C47.584 41.3837 47.2744 41.1801 47.0181 40.9182C46.7619 40.6563 46.5652 40.3422 46.4412 39.9974C46.3173 39.6526 46.2691 39.2851 46.3 38.92C46.2784 38.5383 46.3375 38.1563 46.4735 37.799C46.6094 37.4417 46.8191 37.117 47.089 36.8462C47.3589 36.5754 47.6828 36.3645 48.0397 36.2273C48.3965 36.0901 48.7783 36.0297 49.16 36.05C49.6038 36.0298 50.046 36.1155 50.4501 36.3L50.28 36.87C49.9348 36.7131 49.5592 36.6346 49.18 36.64C48.8833 36.618 48.5855 36.6625 48.3081 36.7703C48.0308 36.878 47.781 37.0463 47.5769 37.2628C47.3728 37.4793 47.2196 37.7387 47.1285 38.0219C47.0373 38.3051 47.0105 38.6051 47.05 38.9C47.0222 39.1868 47.0567 39.4762 47.1512 39.7484C47.2456 40.0207 47.3977 40.2693 47.5971 40.4772C47.7966 40.6852 48.0386 40.8476 48.3066 40.9534C48.5746 41.0592 48.8623 41.1058 49.15 41.09C49.5441 41.0929 49.9349 41.0182 50.3 40.87L50.44 41.43Z"
      fill="black"
    />
    <path
      d="M52 36.14V38.57H52.22L54.09 36.14H55L52.94 38.65C53.83 38.77 54.18 39.32 54.47 40.06C54.6344 40.5865 54.8349 41.1011 55.07 41.6H54.3C54.1093 41.1843 53.9457 40.7567 53.81 40.32C53.7494 39.9661 53.5613 39.6466 53.2812 39.4219C53.0012 39.1971 52.6487 39.0826 52.29 39.1H52V41.6H51.3V36.14H52Z"
      fill="black"
    />
    <path
      d="M56.38 36.14V38.43C56.38 39.3 56.38 40.01 56.31 40.74C56.58 40.17 56.93 39.58 57.31 38.93L59.08 36.12H59.78V41.58H59.12V39.26C59.12 38.37 59.12 37.73 59.18 37.04C58.8832 37.6725 58.5493 38.2869 58.18 38.88L56.47 41.59H55.71V36.13L56.38 36.14Z"
      fill="black"
    />
    <path
      d="M61.71 36.14V38.43C61.71 39.3 61.71 40.01 61.64 40.74C61.9406 40.1191 62.2744 39.5149 62.64 38.93L64.41 36.12H65.11V41.58H64.45V39.26C64.45 38.37 64.45 37.73 64.51 37.04C64.2132 37.6725 63.8793 38.2869 63.51 38.88L61.8 41.59H61V36.13L61.71 36.14ZM62.6 35C62.6 35.25 62.71 35.5 63.09 35.5C63.47 35.5 63.55 35.27 63.59 35H64.13C64.13 35.54 63.76 35.87 63.08 35.87C62.4 35.87 62.08 35.54 62.08 35H62.6Z"
      fill="black"
    />
    <path
      d="M72 36.14V41.6H71.3V36.73H68.76V41.6H68.06V36.14H72Z"
      fill="black"
    />
    <path
      d="M74.15 39.89L73.58 41.6H72.85L74.71 36.14H75.56L77.42 41.6H76.67L76.08 39.89H74.15ZM75.94 39.34L75.4 37.76C75.28 37.41 75.2 37.08 75.12 36.76C75.04 37.08 74.95 37.4133 74.85 37.76L74.31 39.34H75.94Z"
      fill="black"
    />
    <path
      d="M78.22 36.21C78.666 36.1342 79.1177 36.0974 79.57 36.1C80.1198 36.0552 80.6662 36.2195 81.1 36.56C81.2586 36.7033 81.3838 36.8797 81.4667 37.0767C81.5497 37.2736 81.5883 37.4864 81.58 37.7C81.591 37.9131 81.5596 38.1263 81.4875 38.3272C81.4154 38.528 81.3041 38.7125 81.16 38.87C80.9356 39.0761 80.6723 39.2353 80.3855 39.3383C80.0987 39.4414 79.7943 39.4861 79.49 39.47C79.2987 39.475 79.1075 39.4583 78.92 39.42V41.6H78.22V36.21ZM78.92 38.84C79.1135 38.884 79.3117 38.9042 79.51 38.9C80.36 38.9 80.88 38.48 80.88 37.73C80.88 36.98 80.37 36.66 79.59 36.66C79.3652 36.6562 79.1406 36.6763 78.92 36.72V38.84Z"
      fill="black"
    />
    <path
      d="M83.23 36.14V38.57H83.44L85.32 36.14H86.17L84.1 38.65C84.4691 38.6991 84.8141 38.8609 85.0879 39.1132C85.3617 39.3655 85.551 39.6961 85.63 40.06C85.7989 40.5849 85.9993 41.0992 86.23 41.6H85.47C85.2751 41.1861 85.1113 40.7583 84.98 40.32C84.72 39.59 84.38 39.1 83.45 39.1H83.23V41.6H82.53V36.14H83.23Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconParkVorosilovsk",
};
</script>

<style scoped lang="scss"></style>
