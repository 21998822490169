<template>
  <g @click="$emit('click')">
    <circle
      cx="33"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M35.2799 25.34H30.45V19.34H35.2799V25.34ZM31.45 24.34H34.2799V20.34H31.45V24.34Z"
      fill="#E73C14"
    />
    <path
      d="M42.73 25.34H23V15.4H24.28V16.4H24V24.34H41.73V16.4H41.6V15.4H42.73V25.34Z"
      fill="#E73C14"
    />
    <path
      d="M41.49 25.34H24.24V11.91H27.15V12.91H25.24V24.34H40.49V12.91H38.73V11.91H41.49V25.34Z"
      fill="#E73C14"
    />
    <path
      d="M39.5299 25.34H26.1899V11C26.1899 9.4087 26.8221 7.88257 27.9473 6.75735C29.0725 5.63214 30.5986 5 32.1899 5H33.58C35.1713 5 36.6974 5.63214 37.8226 6.75735C38.9478 7.88257 39.58 9.4087 39.58 11L39.5299 25.34ZM27.1899 24.34H38.5299V11C38.5299 9.67392 38.0032 8.40214 37.0655 7.46446C36.1278 6.52678 34.856 6 33.5299 6H32.14C30.8139 6 29.5421 6.52678 28.6044 7.46446C27.6667 8.40214 27.14 9.67392 27.14 11L27.1899 24.34Z"
      fill="#E73C14"
    />
    <path
      d="M37.36 18.36H28.36V12.67C28.3626 11.4774 28.8376 10.3343 29.6809 9.49098C30.5243 8.64764 31.6673 8.17269 32.86 8.17004C34.0526 8.17269 35.1957 8.64764 36.039 9.49098C36.8824 10.3343 37.3573 11.4774 37.36 12.67V18.36ZM29.36 17.36H36.36V12.67C36.36 11.7418 35.9912 10.8515 35.3348 10.1952C34.6785 9.53879 33.7882 9.17004 32.86 9.17004C31.9317 9.17004 31.0415 9.53879 30.3851 10.1952C29.7287 10.8515 29.36 11.7418 29.36 12.67V17.36Z"
      fill="#E73C14"
    />
    <path d="M37.1899 13.36H28.5499V14.36H37.1899V13.36Z" fill="#E73C14" />
    <path
      d="M36.6899 11.0599H29.0499V12.0599H36.6899V11.0599Z"
      fill="#E73C14"
    />
    <path d="M31.45 8.85999H30.45V17.86H31.45V8.85999Z" fill="#E73C14" />
    <path d="M33.36 9.17993H32.36V17.8599H33.36V9.17993Z" fill="#E73C14" />
    <path d="M35.28 8.85999H34.28V17.86H35.28V8.85999Z" fill="#E73C14" />
    <path
      d="M29.28 35.4321H28.15V35.0221H30.89V35.4321H29.75V38.7221H29.27L29.28 35.4321Z"
      fill="black"
    />
    <path
      d="M31.3 35.0721C31.6008 35.0228 31.9052 34.9994 32.21 35.0021C32.568 34.9835 32.9214 35.0895 33.21 35.3021C33.3169 35.401 33.4009 35.522 33.4562 35.6567C33.5115 35.7915 33.5367 35.9366 33.53 36.0821C33.543 36.3688 33.4424 36.6491 33.25 36.8621C33.0994 37.0037 32.9216 37.1131 32.7273 37.1836C32.533 37.254 32.3263 37.2842 32.12 37.2721C31.9936 37.2832 31.8664 37.2832 31.74 37.2721V38.7521H31.26L31.3 35.0721ZM31.78 36.8521C31.9097 36.8648 32.0403 36.8648 32.17 36.8521C32.75 36.8521 33.1 36.5721 33.1 36.0621C33.1 35.5521 32.75 35.3321 32.23 35.3321C32.0805 35.3157 31.9296 35.3157 31.78 35.3321V36.8521Z"
      fill="black"
    />
    <path
      d="M34.69 35.0221V38.3221H36.38V35.0221H36.87V38.3321H37.19V39.5921H36.75V38.7121H34.21V35.0121L34.69 35.0221Z"
      fill="black"
    />
    <path
      d="M0.75 45.702L1.75 47.192H1.17001L0.109985 45.702L1.17001 44.212H1.75L0.75 45.702ZM2.07999 45.702L3.17001 47.192H2.59L1.51999 45.702L2.59 44.212H3.17001L2.07999 45.702Z"
      fill="black"
    />
    <path
      d="M4.62003 42.2821V44.7021H4.83002L6.71002 42.2821H7.56003L5.49002 44.7821C5.85858 44.8326 6.20282 44.9948 6.47638 45.2469C6.74993 45.499 6.93966 45.8289 7.02002 46.1921C7.23002 46.7521 7.38003 47.2521 7.62003 47.7421H6.86002C6.6651 47.3248 6.50133 46.8936 6.37003 46.4521C6.30702 46.0982 6.11658 45.7797 5.83466 45.5568C5.55274 45.3338 5.19888 45.2219 4.84003 45.2421H4.62003V47.7921H3.92004V42.3321L4.62003 42.2821Z"
      fill="black"
    />
    <path
      d="M8.66995 42.282L9.74994 44.602L10.1499 45.602C10.2499 45.322 10.3599 45.002 10.4899 44.602L11.3499 42.302H12.0899L10.95 45.102C10.6932 45.8297 10.3579 46.5271 9.94995 47.182C9.80768 47.3663 9.62619 47.5167 9.41864 47.6222C9.21109 47.7277 8.98267 47.7857 8.74994 47.792C8.61482 47.7963 8.47987 47.7794 8.34995 47.742L8.41995 47.172H8.67993C9.24993 47.172 9.57994 46.622 9.74994 46.232C9.7789 46.1819 9.79416 46.125 9.79416 46.067C9.79416 46.0091 9.7789 45.9522 9.74994 45.902L7.92993 42.222L8.66995 42.282Z"
      fill="black"
    />
    <path
      d="M13.39 42.2821V44.5621H16.0299V42.2821H16.7399V47.7921H16.0299V45.2321H13.39V47.7921H12.6799V42.3321L13.39 42.2821Z"
      fill="black"
    />
    <path
      d="M18.67 42.2821V47.1521H21.17V42.2821H21.88V47.1621H22.36L22.31 49.0321H21.75V47.7221H18V42.2621L18.67 42.2821Z"
      fill="black"
    />
    <path
      d="M26.07 45.182H23.95V47.182H26.31V47.792H23.24V42.332H26.19V42.922H23.95V44.652H26.07V45.182Z"
      fill="black"
    />
    <path
      d="M27.23 42.352C27.6559 42.2771 28.0875 42.2403 28.52 42.242C29.0485 42.192 29.5775 42.334 30.01 42.642C30.1663 42.755 30.2907 42.9064 30.3713 43.0816C30.4519 43.2568 30.4859 43.4498 30.47 43.642C30.4617 43.9147 30.3673 44.1777 30.2002 44.3933C30.0331 44.6089 29.802 44.766 29.54 44.842C29.856 44.9041 30.141 45.0733 30.3468 45.321C30.5527 45.5688 30.6668 45.8799 30.67 46.202C30.672 46.407 30.6322 46.6102 30.5531 46.7993C30.474 46.9884 30.3573 47.1595 30.21 47.302C29.6643 47.6989 28.9913 47.8805 28.32 47.812C27.9554 47.8178 27.5909 47.7944 27.23 47.742V42.352ZM27.93 44.592H28.57C29.32 44.592 29.75 44.202 29.75 43.672C29.75 43.142 29.27 42.782 28.56 42.782C28.3489 42.7792 28.138 42.796 27.93 42.832V44.592ZM27.93 47.212C28.1264 47.225 28.3235 47.225 28.52 47.212C29.25 47.212 29.92 46.942 29.92 46.152C29.92 45.362 29.29 45.092 28.52 45.092H27.93V47.212Z"
      fill="black"
    />
    <path
      d="M36.22 44.952C36.2593 45.3093 36.2229 45.6708 36.1133 46.0131C36.0036 46.3554 35.8231 46.6707 35.5835 46.9386C35.3439 47.2065 35.0506 47.421 34.7226 47.568C34.3946 47.715 34.0394 47.7914 33.68 47.792C33.3319 47.7871 32.9887 47.7095 32.6723 47.5643C32.3559 47.419 32.0734 47.2093 31.8427 46.9486C31.6121 46.6878 31.4384 46.3817 31.3329 46.05C31.2274 45.7182 31.1924 45.3681 31.23 45.022C31.197 44.6681 31.2371 44.3112 31.348 43.9734C31.4589 43.6357 31.6381 43.3244 31.8745 43.059C32.1109 42.7935 32.3994 42.5795 32.722 42.4304C33.0447 42.2812 33.3946 42.2001 33.75 42.192C34.0993 42.1928 34.4446 42.267 34.7633 42.4099C35.0821 42.5528 35.3672 42.7612 35.6001 43.0215C35.8331 43.2818 36.0087 43.5882 36.1155 43.9208C36.2222 44.2534 36.2579 44.6048 36.22 44.952ZM31.98 45.042C31.98 46.212 32.61 47.252 33.72 47.252C34.83 47.252 35.47 46.252 35.47 44.992C35.47 43.732 34.91 42.792 33.75 42.792C32.59 42.792 31.98 43.842 31.98 45.042Z"
      fill="black"
    />
    <path
      d="M42.7501 42.2821V47.7921H42.05V42.9221H39.5101V47.7921H38.8101V42.3321L42.7501 42.2821Z"
      fill="black"
    />
    <path
      d="M47.64 42.2821V47.7921H46.92V42.9221H45.12V44.5721C45.12 45.7621 45.07 47.0821 44.3 47.5721C44.0481 47.7306 43.7576 47.817 43.46 47.8221L43.38 47.2621C43.5913 47.2148 43.7874 47.1151 43.95 46.9721C44.3638 46.2554 44.5323 45.4233 44.43 44.6021V42.3021L47.64 42.2821Z"
      fill="black"
    />
    <path
      d="M49.75 46.0221L49.18 47.7421H48.46L50.31 42.2821H51.16L53.03 47.7421H52.27L51.69 46.0221H49.75ZM51.54 45.4721L51.01 43.9021C50.89 43.5421 50.81 43.2221 50.72 42.9021C50.64 43.2321 50.55 43.5621 50.44 43.9021L49.91 45.4821L51.54 45.4721Z"
      fill="black"
    />
    <path
      d="M53.69 42.602C54.1197 42.3276 54.6203 42.1851 55.13 42.192C56.08 42.192 56.73 42.682 56.73 43.532C56.7242 43.8485 56.6097 44.1534 56.4058 44.3956C56.2019 44.6377 55.9209 44.8024 55.61 44.862C55.9654 44.8725 56.3027 45.0217 56.5494 45.2778C56.7961 45.5339 56.9328 45.8764 56.93 46.232C56.93 47.372 55.93 47.822 54.93 47.822C54.4297 47.8324 53.9358 47.7081 53.5 47.462L53.69 46.912C54.0641 47.1188 54.4827 47.232 54.91 47.242C55.72 47.242 56.17 46.812 56.17 46.242C56.17 45.422 55.38 45.182 54.68 45.182H54.36V44.652H54.68C54.9823 44.6888 55.2868 44.6041 55.5267 44.4166C55.7667 44.2292 55.9225 43.9542 55.96 43.652C55.96 43.102 55.58 42.802 54.96 42.802C54.5591 42.8172 54.1693 42.938 53.83 43.152L53.69 42.602Z"
      fill="black"
    />
    <path
      d="M58.75 46.0221L58.19 47.7421H57.46L59.31 42.2821H60.16L62.03 47.7421H61.27L60.69 46.0221H58.75ZM60.54 45.4721L60.01 43.9021C59.89 43.5421 59.81 43.2221 59.73 42.9021C59.65 43.2321 59.56 43.5621 59.45 43.9021L58.92 45.4821L60.54 45.4721Z"
      fill="black"
    />
    <path
      d="M62.41 44.212H62.98L64.06 45.702L62.98 47.192H62.42L63.49 45.702L62.41 44.212ZM63.73 44.212H64.3L65.38 45.702L64.3 47.192H63.75L64.82 45.702L63.73 44.212Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconTrcKuncovo",
};
</script>

<style scoped lang="scss"></style>
