var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "43",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.51 12.3198C39.2387 12.3198 40.64 10.9184 40.64 9.18979C40.64 7.46114 39.2387 6.05981 37.51 6.05981C35.7814 6.05981 34.38 7.46114 34.38 9.18979C34.38 10.9184 35.7814 12.3198 37.51 12.3198Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.31 20.5199C49.8067 20.5199 51.02 19.3066 51.02 17.8099C51.02 16.3132 49.8067 15.0999 48.31 15.0999C46.8133 15.0999 45.6 16.3132 45.6 17.8099C45.6 19.3066 46.8133 20.5199 48.31 20.5199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.8 9.18991C44.9101 9.18991 45.81 8.29002 45.81 7.17993C45.81 6.06984 44.9101 5.16992 43.8 5.16992C42.6899 5.16992 41.79 6.06984 41.79 7.17993C41.79 8.29002 42.6899 9.18991 43.8 9.18991Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.7 19.4799C40.0421 19.4799 41.13 18.3919 41.13 17.0499C41.13 15.7078 40.0421 14.6199 38.7 14.6199C37.358 14.6199 36.27 15.7078 36.27 17.0499C36.27 18.3919 37.358 19.4799 38.7 19.4799Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.77 12.1999C49.742 12.1999 50.53 11.4119 50.53 10.4399C50.53 9.46789 49.742 8.67993 48.77 8.67993C47.798 8.67993 47.01 9.46789 47.01 10.4399C47.01 11.4119 47.798 12.1999 48.77 12.1999Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.25 7.59985H43.25V26.9199H44.25V7.59985Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.79 16.1798L37.16 9.54984L37.87 8.83984L43.79 14.7698L48.42 10.1398L49.12 10.8498L43.79 16.1798Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.75 23.0199L38.35 17.6099L39.05 16.8999L43.75 21.5999L47.95 17.4099L48.66 18.1099L43.75 23.0199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.67 36.78V40.47H13.2V37.18H11.48V40.47H11V36.78H13.67Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.51 36.82C14.8108 36.7707 15.1152 36.7473 15.42 36.75C15.7778 36.733 16.1306 36.8389 16.42 37.05C16.5269 37.1489 16.6109 37.2699 16.6662 37.4046C16.7215 37.5394 16.7466 37.6845 16.74 37.83C16.7518 38.1214 16.6475 38.4055 16.45 38.62C16.2383 38.7841 15.9945 38.9019 15.7343 38.9656C15.4742 39.0294 15.2035 39.0377 14.94 38.99V40.47H14.47L14.51 36.82ZM14.98 38.6C15.1127 38.6174 15.2472 38.6174 15.38 38.6C15.95 38.6 16.3 38.32 16.3 37.81C16.3 37.3 15.96 37.09 15.43 37.09C15.2803 37.0751 15.1296 37.0751 14.98 37.09V38.6Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.87 36.78V38.32C17.87 38.91 17.87 39.39 17.87 39.88C18.0706 39.4593 18.2977 39.0519 18.55 38.66L19.75 36.76H20.22V40.45H19.77V38.88C19.77 38.28 19.77 37.88 19.82 37.37C19.6183 37.7966 19.3913 38.2107 19.14 38.61L17.98 40.45H17.41V36.76L17.87 36.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.03 36.82C21.3308 36.7707 21.6352 36.7473 21.94 36.75C22.2978 36.733 22.6507 36.8389 22.94 37.05C23.0469 37.1489 23.1309 37.2699 23.1862 37.4046C23.2415 37.5394 23.2667 37.6845 23.26 37.83C23.2719 38.1214 23.1676 38.4055 22.97 38.62C22.7583 38.7841 22.5145 38.9019 22.2544 38.9656C21.9942 39.0294 21.7236 39.0377 21.46 38.99V40.47H20.99L21.03 36.82ZM21.5 38.6C21.6328 38.6174 21.7672 38.6174 21.9 38.6C22.47 38.6 22.82 38.32 22.82 37.81C22.82 37.3 22.48 37.09 21.95 37.09C21.8004 37.0751 21.6496 37.0751 21.5 37.09V38.6Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.05 38.58C27.0812 38.8233 27.0603 39.0704 26.9887 39.305C26.9171 39.5396 26.7965 39.7562 26.6347 39.9406C26.473 40.125 26.274 40.273 26.0508 40.3746C25.8275 40.4762 25.5852 40.5292 25.34 40.53C25.1036 40.5273 24.8705 40.4751 24.6556 40.3767C24.4407 40.2783 24.2489 40.1359 24.0924 39.9587C23.936 39.7815 23.8185 39.5735 23.7474 39.3481C23.6764 39.1227 23.6534 38.8848 23.68 38.65C23.6549 38.4072 23.6808 38.1618 23.7562 37.9297C23.8316 37.6975 23.9547 37.4837 24.1176 37.3019C24.2805 37.1201 24.4797 36.9745 24.7023 36.8743C24.9248 36.7741 25.1659 36.7215 25.41 36.72C25.6432 36.7242 25.873 36.7769 26.0848 36.8748C26.2965 36.9728 26.4855 37.1137 26.6398 37.2887C26.7941 37.4636 26.9102 37.6688 26.9808 37.8911C27.0515 38.1135 27.075 38.3481 27.05 38.58ZM24.19 38.64C24.19 39.43 24.62 40.14 25.37 40.14C26.12 40.14 26.55 39.44 26.55 38.61C26.55 37.78 26.17 37.1 25.37 37.1C24.57 37.1 24.19 37.83 24.19 38.64Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.08 36.78V40.08H30.41V41.35H30.04V40.46H27.71V41.35H27.33V40.08H27.61C27.758 39.8233 27.8786 39.5519 27.97 39.27C28.1064 38.7617 28.1671 38.236 28.15 37.71V36.78H30.08ZM28.46 37.87C28.4763 38.3762 28.4156 38.882 28.28 39.37C28.2026 39.614 28.0986 39.8487 27.97 40.07H29.61V37.17H28.46V37.87Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.49 36.78V38.32H33.27V36.78H33.75V40.47H33.27V38.74H31.49V40.47H31.01V36.78H31.49Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.06 36.78V38.16C35.2065 38.1497 35.3535 38.1497 35.5 38.16C36.26 38.16 36.92 38.49 36.92 39.31C36.9294 39.5992 36.8294 39.8813 36.64 40.1C36.2736 40.4111 35.7994 40.5656 35.32 40.53C35.0736 40.545 34.8265 40.545 34.58 40.53V36.85L35.06 36.78ZM35.06 40.1C35.1864 40.1124 35.3136 40.1124 35.44 40.1C35.97 40.1 36.44 39.83 36.44 39.27C36.44 38.71 35.95 38.48 35.44 38.48H35.06V40.1ZM37.81 36.78V40.47H37.33V36.78H37.81Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.12 36.78V38.32C39.12 38.91 39.12 39.39 39.12 39.88C39.3206 39.4593 39.5477 39.0519 39.8 38.66L41 36.76H41.41V40.45H40.96V38.88C40.96 38.28 40.96 37.88 41.01 37.37C40.8083 37.7966 40.5813 38.2107 40.33 38.61L39.17 40.45H38.66V36.76L39.12 36.78ZM39.72 36C39.72 36.17 39.8 36.34 40.06 36.34C40.32 36.34 40.37 36.18 40.39 36H40.76C40.76 36.36 40.51 36.59 40.04 36.59C39.9551 36.6013 39.8688 36.5945 39.7867 36.5702C39.7045 36.5459 39.6284 36.5046 39.5634 36.4489C39.4983 36.3933 39.4456 36.3245 39.4089 36.2472C39.3721 36.1698 39.352 36.0856 39.35 36H39.72Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.29 36.99C43.5904 36.8056 43.9376 36.7118 44.29 36.72C44.93 36.72 45.37 37.05 45.37 37.62C45.3666 37.8347 45.2892 38.0416 45.1506 38.2057C45.0121 38.3697 44.8211 38.4808 44.61 38.52C44.7295 38.5213 44.8476 38.5461 44.9575 38.5931C45.0674 38.64 45.1669 38.7082 45.2505 38.7936C45.3341 38.879 45.4 38.9801 45.4445 39.091C45.4891 39.2019 45.5113 39.3205 45.51 39.44C45.51 40.21 44.85 40.52 44.17 40.52C43.8208 40.5345 43.4745 40.4514 43.17 40.28L43.3 39.9C43.549 40.0458 43.8314 40.125 44.12 40.13C44.67 40.13 44.97 39.83 44.97 39.43C44.97 38.87 44.44 38.72 43.97 38.72H43.75V38.32H43.97C44.0718 38.3326 44.1751 38.325 44.2739 38.2976C44.3727 38.2702 44.4652 38.2236 44.546 38.1605C44.6268 38.0973 44.6944 38.0188 44.7448 37.9295C44.7953 37.8402 44.8276 37.7418 44.84 37.64C44.84 37.27 44.57 37.06 44.17 37.06C43.898 37.0693 43.6344 37.1561 43.41 37.31L43.29 36.99Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46.73 39.32L46.35 40.48H45.85L47.11 36.79H47.68L48.94 40.48H48.41L48.02 39.32H46.73ZM47.94 38.94L47.58 37.88C47.5 37.64 47.44 37.42 47.39 37.21C47.33 37.43 47.27 37.65 47.2 37.88L46.84 38.94H47.94Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.15 36.78V40.47H51.68V37.18H49.96V40.47H49.49V36.78H52.15Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.14 38.58C56.1712 38.8233 56.1503 39.0704 56.0787 39.305C56.0071 39.5396 55.8865 39.7562 55.7248 39.9406C55.5631 40.125 55.364 40.273 55.1408 40.3746C54.9176 40.4762 54.6753 40.5292 54.43 40.53C54.1937 40.5273 53.9605 40.4751 53.7456 40.3767C53.5308 40.2783 53.3389 40.1359 53.1824 39.9587C53.026 39.7815 52.9085 39.5735 52.8374 39.3481C52.7664 39.1227 52.7434 38.8848 52.77 38.65C52.745 38.4089 52.7704 38.1652 52.8446 37.9344C52.9188 37.7035 53.0401 37.4907 53.2009 37.3092C53.3616 37.1277 53.5583 36.9817 53.7785 36.8802C53.9987 36.7788 54.2376 36.7243 54.48 36.72C54.715 36.7214 54.947 36.7721 55.1611 36.8688C55.3752 36.9655 55.5667 37.1061 55.7231 37.2813C55.8795 37.4566 55.9975 37.6628 56.0693 37.8864C56.1411 38.1101 56.1652 38.3464 56.14 38.58ZM53.28 38.64C53.28 39.43 53.7 40.14 54.46 40.14C55.22 40.14 55.64 39.44 55.64 38.61C55.64 37.78 55.26 37.1 54.46 37.1C53.66 37.1 53.28 37.83 53.28 38.64Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.76 36.82C57.0475 36.7718 57.3385 36.7484 57.63 36.75C57.9847 36.7201 58.3386 36.8157 58.63 37.02C58.7289 37.096 58.8079 37.1948 58.8601 37.308C58.9124 37.4212 58.9364 37.5455 58.93 37.67C58.9251 37.8531 58.8625 38.03 58.7512 38.1754C58.6398 38.3208 58.4855 38.4274 58.31 38.48C58.5203 38.5318 58.7081 38.6505 58.8452 38.8182C58.9822 38.9859 59.0611 39.1936 59.07 39.41C59.0709 39.5464 59.0438 39.6815 58.9905 39.8071C58.9372 39.9326 58.8587 40.0459 58.76 40.14C58.3922 40.4127 57.9354 40.5376 57.48 40.49C57.2336 40.505 56.9864 40.505 56.74 40.49L56.76 36.82ZM57.24 38.34H57.67C58.18 38.34 58.47 38.07 58.47 37.72C58.47 37.37 58.14 37.11 57.66 37.11C57.5205 37.0925 57.3794 37.0925 57.24 37.11V38.34ZM57.24 40.11H57.64C58.13 40.11 58.59 39.93 58.59 39.39C58.59 38.85 58.15 38.68 57.59 38.68H57.2L57.24 40.11Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.64 38.74H60.21V40.07H61.81V40.47H59.73V36.78H61.73V37.18H60.21V38.32H61.64V38.74Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.06 36.78V40.08H65.41V41.35H65.04V40.46H62.71V41.35H62.33V40.08H62.61C62.762 39.8254 62.8829 39.5535 62.97 39.27C63.1064 38.7617 63.1671 38.236 63.15 37.71V36.78H65.06ZM63.41 37.87C63.4263 38.3762 63.3656 38.882 63.23 39.37C63.1526 39.614 63.0486 39.8487 62.92 40.07H64.56V37.17H63.41V37.87Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M66.47 36.78V38.32H68.25V36.78H68.73V40.47H68.25V38.74H66.47V40.47H65.99V36.78H66.47Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M70.01 36.78V38.32C70.01 38.91 70.01 39.39 70.01 39.88C70.2145 39.4613 70.4415 39.0541 70.69 38.66L71.89 36.76H72.36V40.45H71.92V38.88C71.92 38.28 71.92 37.88 71.92 37.37C71.7184 37.7966 71.4913 38.2107 71.24 38.61L70.08 40.45H69.57V36.76L70.01 36.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M73.64 36.78V38.41H73.79L75.05 36.78H75.63L74.23 38.47C74.84 38.55 75.07 38.92 75.23 39.47C75.3436 39.8109 75.4772 40.1448 75.63 40.47H75.12C74.987 40.1881 74.8734 39.8974 74.78 39.6C74.7374 39.367 74.6135 39.1568 74.4304 39.0066C74.2472 38.8564 74.0168 38.7761 73.78 38.78H73.63V40.47H73.16V36.78H73.64Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.52002 47.45L1.61002 48.9399H1.07001L0 47.45L1.07001 45.96H1.64001L0.52002 47.45ZM1.85001 47.45L2.94 48.9399H2.36002L1.29001 47.45L2.36002 45.96H2.94L1.85001 47.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.47 44.03V48.92H7.95001L7.89999 50.8H7.35001V49.48H3.91L3.86002 50.8H3.31L3.26001 48.92H3.67999C3.89475 48.5379 4.07227 48.136 4.21002 47.72C4.40773 46.97 4.49529 46.1952 4.47 45.42V44.03H7.47ZM5.07001 45.64C5.09316 46.3934 4.99869 47.1457 4.79001 47.87C4.67852 48.2177 4.53105 48.5528 4.35001 48.87H6.76001V44.57H5.07001V45.64Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.44 46.71C13.4839 47.0695 13.451 47.4343 13.3434 47.7802C13.2358 48.1262 13.056 48.4453 12.8159 48.7166C12.5758 48.9879 12.2809 49.2051 11.9506 49.3539C11.6204 49.5027 11.2623 49.5798 10.9 49.58C10.5519 49.575 10.2087 49.4974 9.89233 49.3522C9.57595 49.207 9.29341 48.9972 9.06277 48.7365C8.83213 48.4757 8.65848 48.1697 8.55297 47.8379C8.44746 47.5062 8.4124 47.156 8.45004 46.8099C8.41106 46.452 8.44713 46.09 8.55593 45.7468C8.66473 45.4036 8.84387 45.0869 9.08193 44.8169C9.32 44.5468 9.61175 44.3293 9.93856 44.1783C10.2654 44.0274 10.6201 43.9462 10.98 43.9399C11.3295 43.9419 11.6745 44.0175 11.9929 44.1616C12.3112 44.3057 12.5956 44.5152 12.8276 44.7765C13.0597 45.0378 13.2341 45.3449 13.3396 45.678C13.4451 46.0112 13.4793 46.3628 13.44 46.71ZM9.20004 46.7899C9.20004 47.9599 9.83003 49.0099 10.94 49.0099C12.05 49.0099 12.69 48.01 12.69 46.74C12.69 45.47 12.12 44.52 10.95 44.52C9.78004 44.52 9.20004 45.5999 9.20004 46.7899Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.91 44.03V49.49H17.19V44.62H15.39V46.32C15.39 47.5 15.34 48.82 14.57 49.32C14.3189 49.4815 14.0285 49.5714 13.73 49.58L13.64 49.01C13.8552 48.9651 14.055 48.8652 14.22 48.72C14.6338 48.0033 14.8023 47.1712 14.7 46.35V44.05L17.91 44.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.79 44.0299V46.3199C19.79 47.1899 19.79 47.8999 19.73 48.6299C20.01 48.0599 20.35 47.4699 20.73 46.8199L22.49 44.0099H23.2V49.4699H22.53V47.1499C22.53 46.2599 22.53 45.6199 22.6 44.9299C22.3072 45.5644 21.9732 46.1791 21.6 46.77L19.89 49.4799H19.14V44.02L19.79 44.0299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.16 44.03V46.32H27.8V44.03H28.52V49.49H27.8V46.93H25.16V49.49H24.46V44.03H25.16Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.62 47.78L30.05 49.49H29.32L31.18 44.03H32.07L33.93 49.49H33.18L32.6 47.78H30.62ZM32.41 47.22L31.88 45.65C31.7654 45.3221 31.6686 44.9883 31.59 44.65C31.51 44.97 31.42 45.3 31.31 45.65L30.78 47.23L32.41 47.22Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.41 44.1C36.856 44.0241 37.3077 43.9873 37.76 43.99C38.3098 43.9452 38.8562 44.1094 39.29 44.45C39.4497 44.5924 39.5757 44.7686 39.6587 44.9658C39.7417 45.163 39.7798 45.3762 39.7701 45.5899C39.782 45.8016 39.7509 46.0136 39.6787 46.2129C39.6066 46.4123 39.4947 46.595 39.35 46.7499C39.1269 46.9579 38.8638 47.1184 38.5767 47.2215C38.2896 47.3247 37.9845 47.3684 37.68 47.35C37.4906 47.3713 37.2994 47.3713 37.11 47.35V49.5299H36.41V44.1ZM37.11 46.73C37.3035 46.774 37.5017 46.7941 37.7 46.79C38.55 46.79 39.07 46.3699 39.07 45.6199C39.07 44.8699 38.56 44.55 37.78 44.55C37.555 44.541 37.3299 44.5611 37.11 44.61V46.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.55 46.9299H41.42V48.9299H43.79V49.52H40.72V44.0599H43.72V44.6499H41.47V46.3199H43.6L43.55 46.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M45.4 44.03V46.45H45.62L47.49 44.03H48.34L46.28 46.54C47.17 46.66 47.52 47.21 47.81 47.95C47.9762 48.4758 48.1766 48.9903 48.41 49.49H47.64C47.4494 49.0709 47.2857 48.64 47.15 48.2C47.0872 47.8479 46.8982 47.5308 46.6184 47.3081C46.3386 47.0853 45.9872 46.9723 45.63 46.99H45.4V49.49H44.7V44.03H45.4Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.76 44.0299V46.3199C49.76 47.1899 49.76 47.8999 49.69 48.6299C49.9905 48.0091 50.3244 47.4049 50.69 46.8199L52.46 44.0099H53.16V49.4699H52.5V47.1499C52.5 46.2599 52.5 45.6199 52.56 44.9299C52.2632 45.5624 51.9293 46.1769 51.56 46.77L49.85 49.4799H49.07V44.02L49.76 44.0299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.9501 49.32C59.4944 49.5095 59.0032 49.5982 58.51 49.58C58.1448 49.603 57.7788 49.5469 57.4372 49.4153C57.0957 49.2838 56.7865 49.08 56.5311 48.8179C56.2756 48.5558 56.0798 48.2415 55.9571 47.8967C55.8344 47.5518 55.7876 47.1846 55.82 46.82C55.797 46.4388 55.8547 46.057 55.9895 45.6997C56.1243 45.3424 56.333 45.0175 56.6021 44.7466C56.8713 44.4756 57.1946 44.2645 57.5509 44.1272C57.9073 43.99 58.2887 43.9296 58.6701 43.95C59.1138 43.9298 59.556 44.0155 59.9601 44.2L59.79 44.77C59.4456 44.6108 59.0694 44.5322 58.69 44.54C58.3933 44.518 58.0954 44.5625 57.8181 44.6703C57.5408 44.778 57.291 44.9463 57.0869 45.1628C56.8828 45.3793 56.7296 45.6386 56.6385 45.9218C56.5474 46.2051 56.5205 46.5051 56.56 46.8C56.5322 47.0868 56.5667 47.3762 56.6612 47.6484C56.7556 47.9206 56.9077 48.1692 57.1072 48.3772C57.3066 48.5852 57.5486 48.7476 57.8166 48.8534C58.0846 48.9591 58.3723 49.0057 58.66 48.99C59.0542 48.9945 59.4453 48.9197 59.81 48.77L59.9501 49.32Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M63.66 46.9299H61.54V48.9299H63.91V49.52H60.84V44.0599H63.84V44.6499H61.59V46.3199H63.7101L63.66 46.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.9 44.63H64.2401V44.03H68.2401V44.63H66.57V49.49H65.86L65.9 44.63Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M69.41 44.03L70.5 46.36C70.64 46.68 70.77 47 70.9 47.36C71 47.08 71.1 46.76 71.24 46.36L72.09 44.06H72.84L71.7 46.87C71.4413 47.5936 71.1061 48.2876 70.7 48.94C70.5616 49.1423 70.3768 49.3086 70.1612 49.4252C69.9455 49.5417 69.7051 49.6051 69.46 49.61C69.3247 49.6206 69.1886 49.6036 69.06 49.56L69.13 48.99H69.39C69.96 48.99 70.29 48.44 70.46 48.05C70.489 47.9998 70.5042 47.9429 70.5042 47.885C70.5042 47.8271 70.489 47.7701 70.46 47.72L68.64 44.04L69.41 44.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M74.13 44.03V46.32H76.78V44.03H77.49V49.49H76.78V46.93H74.13V49.49H73.43V44.03H74.13Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M79.41 44.03V46.03C79.58 46.03 79.9 46.03 80.09 46.03C81.21 46.03 82.19 46.53 82.19 47.74C82.2063 48.1666 82.0557 48.5827 81.7701 48.9C81.4966 49.1353 81.1786 49.3132 80.8351 49.4232C80.4915 49.5331 80.1293 49.5729 79.7701 49.54C79.4025 49.5425 79.0351 49.5224 78.67 49.48V44.03H79.41ZM79.41 48.95C79.6063 48.9674 79.8037 48.9674 80 48.95C80.78 48.95 81.4601 48.57 81.4601 47.74C81.4601 46.91 80.7701 46.55 79.9901 46.55C79.7968 46.5401 79.6032 46.5401 79.41 46.55V48.95Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M82.69 45.96H83.27L84.35 47.45L83.27 48.9399H82.7L83.78 47.45L82.69 45.96ZM84.01 45.96H84.59L85.67 47.45L84.59 48.9399H84.07L85.15 47.45L84.01 45.96Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }