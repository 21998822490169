<template>
  <g>
    <path
      d="M25.8851 46.0399C37.1683 46.0399 46.3151 36.8931 46.3151 25.6099C46.3151 14.3267 37.1683 5.17993 25.8851 5.17993C14.602 5.17993 5.45508 14.3267 5.45508 25.6099C5.45508 36.8931 14.602 46.0399 25.8851 46.0399Z"
      fill="#E53D19"
    />
    <path
      d="M30.4649 35.4501H29.4649V11.3101H22.2949V35.4501H21.2949V10.3101H30.4649V35.4501Z"
      fill="white"
    />
    <path
      d="M17.1949 34.8H5.71484V33.8H16.1949V15.67H22.2949V34.3H21.2949V16.67H17.1949V34.8Z"
      fill="white"
    />
    <path d="M19.665 17.96H18.385V20.09H19.665V17.96Z" fill="white" />
    <path d="M19.665 21.79H18.385V23.92H19.665V21.79Z" fill="white" />
    <path d="M19.665 25.61H18.385V27.74H19.665V25.61Z" fill="white" />
    <path d="M19.665 29.4399H18.385V31.5699H19.665V29.4399Z" fill="white" />
    <path d="M25.0349 13.64H23.7549V15.77H25.0349V13.64Z" fill="white" />
    <path d="M25.0349 17.47H23.7549V19.6H25.0349V17.47Z" fill="white" />
    <path d="M25.0349 21.3H23.7549V23.4301H25.0349V21.3Z" fill="white" />
    <path d="M25.0349 25.12H23.7549V27.25H25.0349V25.12Z" fill="white" />
    <path d="M28.0148 13.64H26.7349V15.77H28.0148V13.64Z" fill="white" />
    <path d="M28.0148 17.47H26.7349V19.6H28.0148V17.47Z" fill="white" />
    <path d="M28.0148 21.3H26.7349V23.4301H28.0148V21.3Z" fill="white" />
    <path d="M28.0148 25.12H26.7349V27.25H28.0148V25.12Z" fill="white" />
    <path
      d="M46.0549 34.39H34.5748V16.26H30.4648V33.89H29.4648V15.26H35.5748V33.39H46.0549V34.39Z"
      fill="white"
    />
    <path d="M32.105 19.6799H33.385V17.5499H32.105V19.6799Z" fill="white" />
    <path d="M32.105 23.5H33.385V21.37H32.105V23.5Z" fill="white" />
    <path d="M32.105 27.34H33.385V25.21H32.105V27.34Z" fill="white" />
    <path d="M32.105 31.17H33.385V29.04H32.105V31.17Z" fill="white" />
    <path d="M28.0148 28.79H23.7549V35.45H28.0148V28.79Z" fill="white" />
    <path d="M37.5651 37.8101H14.2051V39.8101H37.5651V37.8101Z" fill="white" />
    <path
      d="M25.885 50.67C20.9305 50.67 16.0874 49.2008 11.9679 46.4483C7.84848 43.6957 4.63776 39.7835 2.74178 35.2062C0.845805 30.6289 0.349744 25.5922 1.3163 20.7329C2.28286 15.8737 4.66864 11.4102 8.17194 7.90693C11.6752 4.40363 16.1387 2.01785 20.998 1.05129C25.8572 0.0847279 30.8939 0.580796 35.4712 2.47677C40.0485 4.37275 43.9607 7.58347 46.7133 11.7029C49.4658 15.8224 50.935 20.6655 50.935 25.62C50.927 32.2612 48.2853 38.6281 43.5892 43.3242C38.8932 48.0203 32.5262 50.662 25.885 50.67ZM25.885 1.56996C21.1404 1.57984 16.5051 2.99582 12.5649 5.63897C8.62473 8.28213 5.55639 12.0339 3.74755 16.4201C1.93871 20.8064 1.47056 25.6305 2.40224 30.2827C3.33393 34.9349 5.62363 39.2067 8.98208 42.5581C12.3405 45.9096 16.617 48.1904 21.2712 49.1124C25.9253 50.0344 30.7484 49.5562 35.1309 47.7382C39.5134 45.9203 43.2587 42.8441 45.8937 38.8984C48.5286 34.9527 49.935 30.3146 49.935 25.57C49.9138 19.2026 47.3716 13.1028 42.8645 8.6051C38.3574 4.10737 32.2523 1.57785 25.885 1.56996Z"
      fill="#E53D19"
    />
    <path
      d="M3.37483 55.28L4.52483 56.92H4.66483V55.28H5.10483V56.93H5.24483L6.38483 55.29H6.94482L5.63483 56.99C6.21483 57.05 6.45483 57.41 6.63483 57.94C6.76483 58.29 6.84482 58.65 7.00482 58.99H6.51483C6.39668 58.7055 6.29321 58.4151 6.20483 58.12C6.03483 57.62 5.85483 57.29 5.26483 57.29H5.10483V58.99H4.63483V57.29H4.47482C3.88482 57.29 3.69482 57.62 3.47482 58.12C3.39776 58.4162 3.30088 58.7068 3.18483 58.99H2.69482C2.85134 58.6447 2.98823 58.2908 3.10483 57.93C3.28483 57.41 3.52483 57.05 4.10483 56.98L2.80482 55.28H3.37483Z"
      fill="black"
    />
    <path
      d="M7.95514 55.28V56.84C7.95514 57.43 7.95514 57.91 7.95514 58.4C8.14514 58.01 8.37513 57.61 8.64513 57.17L9.83513 55.27H10.3151V58.97H9.86513V57.39C9.86513 56.8 9.86513 56.39 9.86513 55.89C9.65994 56.3167 9.42954 56.7307 9.17514 57.13L8.02513 58.97H7.51514V55.27L7.95514 55.28Z"
      fill="black"
    />
    <path
      d="M13.5551 55.28V58.98H13.1051V55.68H11.8851V56.8C11.8851 57.6 11.8851 58.49 11.3351 58.8C11.1653 58.9115 10.968 58.9738 10.7651 58.98L10.7051 58.6C10.8496 58.5665 10.9835 58.4978 11.0951 58.4C11.3776 57.9173 11.4935 57.3551 11.4251 56.8V55.24L13.5551 55.28Z"
      fill="black"
    />
    <path
      d="M17.5449 57.09C17.584 57.3379 17.5688 57.5914 17.5002 57.8328C17.4317 58.0742 17.3114 58.2978 17.1477 58.4881C16.9841 58.6784 16.781 58.8308 16.5526 58.9347C16.3241 59.0387 16.0758 59.0917 15.8249 59.09C15.589 59.0858 15.3566 59.0325 15.1425 58.9336C14.9283 58.8347 14.7371 58.6923 14.581 58.5155C14.4248 58.3387 14.3072 58.1313 14.2356 57.9065C14.1639 57.6818 14.1398 57.4446 14.1649 57.21C14.1399 56.9676 14.1656 56.7226 14.2402 56.4905C14.3149 56.2585 14.437 56.0445 14.5987 55.8622C14.7604 55.6798 14.9582 55.533 15.1796 55.431C15.401 55.3291 15.6412 55.2743 15.8849 55.27C16.1161 55.272 16.3444 55.3216 16.5556 55.4159C16.7667 55.5102 16.9561 55.647 17.112 55.8178C17.2678 55.9887 17.3867 56.1899 17.4612 56.4088C17.5356 56.6277 17.5641 56.8596 17.5449 57.09ZM14.6749 57.15C14.6749 57.94 15.1049 58.65 15.8549 58.65C16.6049 58.65 17.0349 57.95 17.0349 57.11C17.0349 56.27 16.6549 55.61 15.8649 55.61C15.0749 55.61 14.6749 56.34 14.6749 57.15Z"
      fill="black"
    />
    <path
      d="M18.615 55.28V56.84C18.615 57.43 18.615 57.91 18.615 58.4C18.805 58.01 19.035 57.61 19.305 57.17L20.495 55.27H20.975V58.97H20.525V57.39C20.525 56.8 20.525 56.39 20.525 55.89C20.3237 56.3187 20.0932 56.733 19.835 57.13L18.685 58.97H18.175V55.27L18.615 55.28ZM19.215 54.51C19.215 54.68 19.295 54.85 19.545 54.85C19.795 54.85 19.865 54.69 19.885 54.51H20.245C20.245 54.87 20.005 55.1 19.535 55.1C19.4499 55.1129 19.3629 55.1074 19.2801 55.0837C19.1973 55.06 19.1206 55.0188 19.0551 54.9628C18.9896 54.9068 18.937 54.8374 18.9007 54.7593C18.8645 54.6812 18.8455 54.5961 18.845 54.51H19.215Z"
      fill="black"
    />
    <path
      d="M23.405 55.28V56.93H23.545L24.815 55.29H25.385L23.995 56.99C24.605 57.07 24.835 57.44 24.995 57.94C25.1101 58.2981 25.247 58.6487 25.405 58.99H24.885C24.7571 58.7037 24.6469 58.4099 24.555 58.11C24.5124 57.8771 24.3886 57.6668 24.2054 57.5166C24.0223 57.3665 23.7918 57.2862 23.555 57.29H23.405V58.99H22.925V55.29L23.405 55.28Z"
      fill="black"
    />
    <path
      d="M28.945 57.09C28.984 57.3371 28.9691 57.5897 28.901 57.8304C28.833 58.0711 28.7137 58.2942 28.5511 58.4843C28.3886 58.6744 28.1867 58.827 27.9595 58.9315C27.7323 59.0361 27.4851 59.0902 27.235 59.09C26.9992 59.0858 26.7668 59.0325 26.5526 58.9336C26.3385 58.8347 26.1472 58.6923 25.9911 58.5155C25.835 58.3387 25.7173 58.1313 25.6457 57.9065C25.5741 57.6818 25.55 57.4446 25.575 57.21C25.55 56.9676 25.5757 56.7226 25.6504 56.4905C25.7251 56.2585 25.8471 56.0445 26.0088 55.8622C26.1705 55.6798 26.3683 55.533 26.5897 55.431C26.8111 55.3291 27.0513 55.2743 27.295 55.27C27.5254 55.2733 27.7526 55.324 27.9626 55.4189C28.1726 55.5137 28.3608 55.6508 28.5155 55.8215C28.6703 55.9922 28.7883 56.1929 28.8621 56.4111C28.936 56.6293 28.9642 56.8604 28.945 57.09ZM26.085 57.15C26.085 57.94 26.515 58.65 27.265 58.65C28.015 58.65 28.445 57.95 28.445 57.11C28.445 56.27 28.065 55.61 27.265 55.61C26.465 55.61 26.105 56.34 26.105 57.15H26.085Z"
      fill="black"
    />
    <path
      d="M32.6651 57.35C32.6651 56.84 32.6051 56.22 32.6151 55.76C32.4851 56.19 32.3351 56.65 32.1451 57.16L31.4951 58.95H31.1051L30.5051 57.2C30.3351 56.68 30.1851 56.2 30.0851 55.76C30.0851 56.22 30.0851 56.84 30.0051 57.39L29.9151 58.98H29.4551L29.7151 55.28H30.3251L30.9551 57.07C31.1051 57.52 31.2351 57.93 31.3251 58.31C31.4151 57.94 31.5451 57.54 31.7051 57.07L32.3651 55.28H32.9751L33.2051 58.98H32.7351L32.6651 57.35Z"
      fill="black"
    />
    <path
      d="M36.6251 55.28V58.98H36.1551V55.68H34.4351V58.98H33.9551V55.28H36.6251Z"
      fill="black"
    />
    <path
      d="M39.9049 55.28V58.98H39.4249V55.68H38.2049V56.8C38.2049 57.6 38.2049 58.49 37.6449 58.8C37.4789 58.9116 37.2848 58.974 37.0849 58.98L37.0249 58.6C37.1694 58.5665 37.3034 58.4978 37.4149 58.4C37.6892 57.9143 37.8013 57.3538 37.7349 56.8V55.24L39.9049 55.28Z"
      fill="black"
    />
    <path
      d="M42.6449 57.24H41.2049V58.58H42.8049V58.98H40.7349V55.28H42.7349V55.68H41.2049V56.85H42.6449V57.24Z"
      fill="black"
    />
    <path
      d="M43.895 55.28V56.93H44.045L45.315 55.29H45.885L44.495 56.99C44.7376 57.0303 44.9625 57.1423 45.1407 57.3117C45.319 57.481 45.4424 57.6999 45.495 57.94C45.6101 58.2981 45.747 58.6487 45.905 58.99H45.385C45.2571 58.7037 45.1469 58.4099 45.055 58.11C45.0124 57.8771 44.8886 57.6668 44.7054 57.5166C44.5223 57.3665 44.2918 57.2862 44.055 57.29H43.895V58.99H43.425V55.29L43.895 55.28Z"
      fill="black"
    />
    <path
      d="M48.875 58.86C48.5562 58.9826 48.2163 59.0404 47.875 59.0301C47.6276 59.0454 47.3799 59.0072 47.1487 58.918C46.9175 58.8287 46.7083 58.6906 46.5355 58.513C46.3627 58.3354 46.2302 58.1226 46.1473 57.8891C46.0644 57.6555 46.0329 57.4069 46.0549 57.16C46.0652 56.8333 46.1553 56.5141 46.3175 56.2303C46.4797 55.9465 46.709 55.7068 46.9853 55.5321C47.2616 55.3574 47.5765 55.2532 47.9024 55.2284C48.2284 55.2036 48.5554 55.2591 48.8549 55.3901L48.7449 55.7801C48.5103 55.6706 48.2538 55.6159 47.9949 55.6201C47.7954 55.6102 47.5961 55.6437 47.4108 55.7184C47.2255 55.7931 47.0586 55.9071 46.9217 56.0525C46.7847 56.198 46.681 56.3715 46.6177 56.561C46.5544 56.7505 46.533 56.9515 46.5549 57.1501C46.5341 57.3444 46.556 57.5409 46.6192 57.7258C46.6823 57.9107 46.7851 58.0796 46.9204 58.2206C47.0557 58.3616 47.2202 58.4714 47.4023 58.5421C47.5845 58.6128 47.7799 58.6428 47.9749 58.63C48.2424 58.6327 48.5076 58.5817 48.7549 58.4801L48.875 58.86Z"
      fill="black"
    />
    <path
      d="M9.66504 65.6C9.66504 64.84 9.58504 63.93 9.58504 63.25C9.39504 63.89 9.17503 64.57 8.89503 65.32L7.89503 67.97H7.35504L6.47504 65.37C6.21504 64.6 5.99505 63.89 5.84505 63.25C5.84505 63.93 5.78505 64.84 5.73505 65.66L5.59505 68H4.92505L5.30504 62.54H6.20505L7.13504 65.18C7.36504 65.85 7.54504 66.45 7.68504 67.02C7.82504 66.47 8.02505 65.87 8.26505 65.18L9.26505 62.54H10.165L10.505 68H9.81505L9.66504 65.6Z"
      fill="black"
    />
    <path
      d="M12.385 66.28L11.815 68H11.105L12.955 62.54H13.815L15.675 68H14.915L14.335 66.28H12.385ZM14.175 65.73L13.635 64.16C13.515 63.8 13.435 63.48 13.355 63.16C13.275 63.4934 13.185 63.8267 13.085 64.16L12.545 65.74L14.175 65.73Z"
      fill="black"
    />
    <path d="M17.1551 62.54V68H16.4551V62.54H17.1551Z" fill="black" />
    <path
      d="M18.395 68V62.54H19.155L20.905 65.3C21.2784 65.87 21.6125 66.4647 21.905 67.08C21.845 66.35 21.825 65.69 21.825 64.84V62.55H22.485V68.01H21.775L20.045 65.24C19.6746 64.6545 19.3406 64.0467 19.045 63.42C19.045 64.1 19.105 64.76 19.105 65.67V68H18.395Z"
      fill="black"
    />
    <path
      d="M23.6249 67.1401C24.0034 67.3746 24.4397 67.4993 24.8849 67.5001C25.6049 67.5001 26.0249 67.12 26.0249 66.57C26.0249 66.02 25.7349 65.76 25.0249 65.48C24.3149 65.2 23.5849 64.7 23.5849 63.93C23.5955 63.7136 23.6509 63.5016 23.7474 63.3076C23.844 63.1136 23.9797 62.9416 24.1459 62.8026C24.3122 62.6635 24.5055 62.5604 24.7135 62.4998C24.9216 62.4391 25.14 62.4222 25.3549 62.45C25.7746 62.4362 26.191 62.529 26.5649 62.72L26.3649 63.29C26.0561 63.1273 25.7139 63.0383 25.3649 63.0301C24.6249 63.0301 24.3649 63.48 24.3649 63.85C24.3649 64.22 24.6949 64.61 25.4449 64.91C26.3649 65.26 26.8349 65.71 26.8349 66.51C26.8349 67.31 26.2149 68.08 24.9249 68.08C24.4389 68.0878 23.9593 67.9671 23.5349 67.73L23.6249 67.1401Z"
      fill="black"
    />
    <path
      d="M28.745 63.14H27.105V62.54H31.105V63.14H29.435V68H28.715L28.745 63.14Z"
      fill="black"
    />
    <path
      d="M31.7349 62.6101C32.1807 62.5337 32.6325 62.4969 33.0849 62.5001C33.6498 62.4415 34.2156 62.6026 34.6649 62.9501C34.803 63.077 34.9127 63.2317 34.9868 63.4041C35.061 63.5764 35.0978 63.7625 35.0949 63.9501C35.1011 64.26 35.0071 64.5636 34.8269 64.8158C34.6468 65.0681 34.39 65.2554 34.0949 65.3501C34.3155 65.4448 34.5069 65.5967 34.6493 65.7901C34.7916 65.9834 34.8799 66.2113 34.9049 66.4501C35.0028 66.9609 35.1432 67.4627 35.3249 67.9501H34.5949C34.4309 67.5294 34.307 67.0941 34.2249 66.6501C34.0649 65.8901 33.7749 65.6501 33.1349 65.5901H32.4749V67.9501H31.7649L31.7349 62.6101ZM32.4449 65.1001H33.1649C33.9149 65.1001 34.3949 64.6901 34.3949 64.1001C34.3949 63.5101 33.8849 63.1001 33.1349 63.1001C32.9029 63.0952 32.6711 63.1187 32.4449 63.1701V65.1001Z"
      fill="black"
    />
    <path
      d="M38.925 65.4401H36.795V67.4401H39.165V68.0301H36.105V62.5701H39.055V63.1601H36.805V64.8901H38.935L38.925 65.4401Z"
      fill="black"
    />
    <path
      d="M42.905 65.4401H40.785V67.4401H43.105V68.0301H40.105V62.5701H43.055V63.1601H40.815V64.8901H42.935L42.905 65.4401Z"
      fill="black"
    />
    <path
      d="M45.2349 63.14H43.5649V62.54H47.5649V63.14H45.8949V68H45.1849L45.2349 63.14Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconMainStreet",
};
</script>

<style scoped lang="scss"></style>
