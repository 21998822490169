var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "30",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.5 20.565H30.77C29.3617 20.565 28.011 20.0055 27.0152 19.0097C26.0194 18.0139 25.46 16.6633 25.46 15.255V10.375H27.19C28.5983 10.375 29.9489 10.9344 30.9447 11.9303C31.9405 12.9261 32.5 14.2767 32.5 15.685V20.565ZM26.5 11.375V15.255C26.5 16.3981 26.954 17.4944 27.7623 18.3026C28.5706 19.1109 29.6669 19.565 30.81 19.565H31.54V15.685C31.54 14.5419 31.0859 13.4456 30.2776 12.6374C29.4693 11.8291 28.373 11.375 27.23 11.375H26.5Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.57 27.065H36.57V12.535C36.57 10.6692 35.8288 8.87982 34.5095 7.5605C33.1902 6.24118 31.4008 5.5 29.535 5.5C27.6692 5.5 25.8798 6.24118 24.5605 7.5605C23.2412 8.87982 22.5 10.6692 22.5 12.535V14.315C22.5026 15.7066 23.0566 17.0404 24.0406 18.0244C25.0246 19.0084 26.3584 19.5624 27.75 19.565H32.5V27.065H31.5V20.565H27.75C26.0924 20.565 24.5027 19.9065 23.3306 18.7344C22.1585 17.5623 21.5 15.9726 21.5 14.315V12.535C21.5 10.404 22.3465 8.36025 23.8534 6.85339C25.3602 5.34654 27.404 4.5 29.535 4.5C31.666 4.5 33.7097 5.34654 35.2166 6.85339C36.7235 8.36025 37.57 10.404 37.57 12.535V27.065Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.16 20.0551H33.16V27.0551H34.16V20.0551Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.99 20.0551H34.99V27.0551H35.99V20.0551Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.5 27.0351H20.5V26.5351C20.5026 24.9234 21.1441 23.3784 22.2837 22.2388C23.4234 21.0992 24.9683 20.4577 26.58 20.4551H32.48L32.5 27.0351ZM21.5 26.0351H31.5V21.4551H26.6C25.3402 21.4564 24.1258 21.9258 23.1926 22.7721C22.2594 23.6185 21.674 24.7814 21.55 26.0351H21.5Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.4399 27.035H36.5699V22.415H37.0699C37.6977 22.4177 38.299 22.6682 38.7429 23.1121C39.1868 23.556 39.4373 24.1573 39.4399 24.785V27.035ZM37.5699 26.035H38.4399V24.785C38.4395 24.5092 38.3563 24.2398 38.2013 24.0116C38.0462 23.7834 37.8263 23.607 37.5699 23.505V26.035Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.42 41.563L0 36.103H0.739998L1.39 38.863C1.55 39.543 1.7 40.223 1.8 40.753C1.89 40.203 2.06 39.563 2.25 38.853L2.98 36.103H3.72L4.38 38.873C4.53 39.513 4.68 40.163 4.76 40.743C4.87 40.143 5.03 39.533 5.2 38.853L5.92 36.103H6.64L5.09 41.563H4.35L3.66 38.713C3.50842 38.1271 3.39154 37.5327 3.31 36.933C3.21 37.473 3.09 38.013 2.89 38.713L2.11 41.563H1.42Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.05 38.7731C12.05 40.6531 10.91 41.6531 9.52 41.6531C9.1706 41.6481 8.82608 41.5703 8.5085 41.4245C8.19091 41.2788 7.90725 41.0683 7.67569 40.8067C7.44412 40.545 7.26975 40.2378 7.16373 39.9049C7.05771 39.5719 7.02238 39.2205 7.06 38.8731C7.02245 38.5151 7.05991 38.1533 7.16999 37.8106C7.28006 37.468 7.46034 37.152 7.69933 36.8829C7.93832 36.6139 8.23076 36.3975 8.55801 36.2477C8.88526 36.098 9.24014 36.018 9.6 36.0131C9.94811 36.015 10.2919 36.0903 10.609 36.2339C10.9261 36.3775 11.2095 36.5863 11.4406 36.8466C11.6717 37.107 11.8454 37.4131 11.9504 37.745C12.0554 38.0769 12.0893 38.4272 12.05 38.7731ZM7.81 38.8631C7.81 40.0331 8.45 41.0731 9.56 41.0731C10.67 41.0731 11.31 40.0731 11.31 38.8031C11.31 37.7231 10.74 36.5831 9.56 36.5831C8.38 36.5831 7.81 37.6631 7.81 38.8631Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13 36.1731C13.4454 36.0921 13.8973 36.0519 14.35 36.0531C14.9147 35.9966 15.4798 36.1575 15.93 36.5031C16.0657 36.6319 16.1738 36.787 16.2478 36.9589C16.3217 37.1308 16.3599 37.316 16.36 37.5031C16.3656 37.8117 16.2713 38.1138 16.091 38.3643C15.9108 38.6149 15.6544 38.8003 15.36 38.8931C15.5788 38.9892 15.7681 39.1417 15.9087 39.335C16.0492 39.5282 16.1359 39.7553 16.16 39.9931C16.2579 40.5039 16.3984 41.0057 16.58 41.4931H15.85C15.6883 41.069 15.5678 40.6303 15.49 40.1831C15.33 39.4331 15.03 39.1831 14.39 39.1231H13.73V41.4931H13V36.1731ZM13.7 38.6631H14.42C15.17 38.6631 15.65 38.2431 15.65 37.6631C15.65 37.0831 15.14 36.6631 14.4 36.6631C14.1652 36.6568 13.9304 36.677 13.7 36.7231V38.6631Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.32 36.1731C17.816 36.0931 18.3176 36.0529 18.82 36.0531C19.6183 35.988 20.4106 36.2354 21.03 36.7431C21.2997 36.9977 21.5089 37.3095 21.6422 37.6556C21.7755 38.0017 21.8294 38.3734 21.8 38.7431C21.8196 39.1308 21.7606 39.5184 21.6265 39.8827C21.4925 40.2471 21.2862 40.5805 21.02 40.8631C20.3471 41.4317 19.4788 41.7152 18.6 41.6531C18.1724 41.6537 17.745 41.6303 17.32 41.5831V36.1731ZM18.03 41.0031C18.2663 41.021 18.5037 41.021 18.74 41.0031C19.054 41.0397 19.3721 41.0049 19.6708 40.9014C19.9694 40.7979 20.2408 40.6283 20.4648 40.4053C20.6888 40.1823 20.8596 39.9116 20.9643 39.6134C21.0691 39.3152 21.1052 38.9972 21.07 38.6831C21.0879 38.3918 21.0418 38.1001 20.935 37.8285C20.8281 37.5569 20.6632 37.3119 20.4516 37.1108C20.2401 36.9098 19.9871 36.7574 19.7104 36.6645C19.4337 36.5716 19.14 36.5404 18.85 36.5731C18.5746 36.5704 18.2997 36.5972 18.03 36.6531V41.0031Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.2501 41.3831C27.7937 41.5703 27.303 41.6589 26.8101 41.6431C26.4439 41.6645 26.0774 41.6069 25.7354 41.4742C25.3934 41.3416 25.0839 41.137 24.8279 40.8743C24.5718 40.6117 24.3752 40.297 24.2514 39.9518C24.1275 39.6065 24.0794 39.2386 24.1101 38.8731C24.0884 38.4927 24.1473 38.1121 24.2827 37.7559C24.4181 37.3998 24.6271 37.0763 24.8961 36.8064C25.165 36.5365 25.4878 36.3264 25.8435 36.1897C26.1991 36.053 26.5796 35.9928 26.9601 36.0131C27.406 35.9934 27.8506 36.0755 28.2601 36.2531L28.0901 36.8231C27.7392 36.6739 27.3613 36.599 26.9801 36.6031C26.6843 36.5814 26.3874 36.6258 26.1109 36.7329C25.8344 36.8401 25.5852 37.0074 25.3813 37.2228C25.1774 37.4382 25.024 37.6962 24.9321 37.9782C24.8402 38.2601 24.8122 38.559 24.8501 38.8531C24.8207 39.1407 24.8541 39.4312 24.9478 39.7047C25.0415 39.9781 25.1934 40.228 25.393 40.4371C25.5926 40.6462 25.8352 40.8096 26.104 40.9159C26.3728 41.0223 26.6614 41.0691 26.9501 41.0531C27.3454 41.0594 27.7375 40.981 28.1001 40.8231L28.2501 41.3831Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.13 36.103H29.83V40.963H32.17V41.563H29.17L29.13 36.103Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.84 39.843L33.27 41.563H32.54L34.39 36.103H35.25L37.11 41.563H36.35L35.77 39.843H33.84ZM35.63 39.293L35.09 37.723C34.97 37.363 34.89 37.033 34.81 36.723C34.73 37.043 34.64 37.383 34.54 37.723L34 39.323L35.63 39.293Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.81 40.703C38.1926 40.9268 38.6268 41.0474 39.07 41.053C39.8 41.053 40.22 40.673 40.22 40.123C40.22 39.573 39.93 39.323 39.22 39.043C38.51 38.763 37.78 38.263 37.78 37.493C37.7908 37.2775 37.8458 37.0665 37.9418 36.8732C38.0377 36.6799 38.1725 36.5085 38.3376 36.3696C38.5028 36.2307 38.6948 36.1274 38.9017 36.066C39.1086 36.0047 39.3259 35.9866 39.54 36.013C39.9585 36.0006 40.3736 36.0898 40.75 36.273L40.55 36.853C40.2437 36.684 39.8999 36.5946 39.55 36.593C38.8 36.593 38.55 37.033 38.55 37.413C38.55 37.793 38.88 38.173 39.64 38.413C40.56 38.773 41.03 39.213 41.03 40.023C41.03 40.833 40.4 41.593 39.12 41.593C38.6312 41.597 38.1494 41.4766 37.72 41.243L37.81 40.703Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.8 40.703C42.1857 40.9281 42.6234 41.0487 43.07 41.053C43.79 41.053 44.21 40.673 44.21 40.123C44.21 39.573 43.92 39.323 43.21 39.043C42.32 38.723 41.77 38.263 41.77 37.493C41.7806 37.2766 41.8359 37.0646 41.9325 36.8706C42.029 36.6765 42.1647 36.5046 42.331 36.3656C42.4973 36.2265 42.6905 36.1234 42.8986 36.0628C43.1067 36.0021 43.325 35.9852 43.54 36.013C43.9551 36.0015 44.3669 36.0907 44.74 36.273L44.55 36.853C44.2429 36.686 43.8996 36.5967 43.55 36.593C42.81 36.593 42.55 37.033 42.55 37.413C42.55 37.793 42.88 38.173 43.64 38.413C44.56 38.773 45.03 39.213 45.03 40.023C45.03 40.833 44.41 41.593 43.12 41.593C42.6343 41.5971 42.1558 41.4766 41.73 41.243L41.8 40.703Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.5101 40.703C47.8958 40.928 48.3335 41.0486 48.7801 41.053C49.5001 41.053 49.9201 40.673 49.9201 40.123C49.9201 39.573 49.6301 39.323 48.9201 39.043C48.2101 38.763 47.4801 38.263 47.4801 37.493C47.4908 37.2775 47.5459 37.0665 47.6418 36.8732C47.7378 36.6799 47.8725 36.5085 48.0377 36.3696C48.2028 36.2307 48.3948 36.1274 48.6017 36.066C48.8086 36.0047 49.0259 35.9866 49.2401 36.013C49.6585 36.0006 50.0737 36.0898 50.4501 36.273L50.2601 36.853C49.953 36.686 49.6096 36.5967 49.2601 36.593C48.5101 36.593 48.2601 37.033 48.2601 37.413C48.2601 37.793 48.5901 38.173 49.3501 38.413C50.2701 38.773 50.7401 39.213 50.7401 40.023C50.7401 40.833 50.1201 41.593 48.8301 41.593C48.3414 41.5957 47.8599 41.4754 47.4301 41.243L47.5101 40.703Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.6 36.163C52.0493 36.0868 52.5044 36.05 52.96 36.053C53.5089 36.0159 54.0526 36.1794 54.49 36.513C54.6486 36.6563 54.7738 36.8327 54.8567 37.0297C54.9397 37.2267 54.9783 37.4395 54.97 37.653C54.9803 37.8669 54.9475 38.0807 54.8737 38.2816C54.7998 38.4826 54.6863 38.6667 54.54 38.823C54.3171 39.0284 54.0555 39.1872 53.7704 39.2902C53.4854 39.3932 53.1827 39.4384 52.88 39.423C52.6907 39.4455 52.4994 39.4455 52.31 39.423V41.613H51.6V36.163ZM52.31 38.793C52.5001 38.837 52.695 38.8572 52.89 38.853C53.74 38.853 54.26 38.443 54.26 37.683C54.26 36.923 53.75 36.613 52.97 36.613C52.7485 36.6086 52.5271 36.6287 52.31 36.673V38.793Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.1101 39.843L55.5501 41.563H54.8201L56.6701 36.103H57.5201L59.3901 41.563H58.6301L58.0501 39.843H56.1101ZM57.9101 39.293L57.3701 37.723C57.2501 37.363 57.1701 37.033 57.0901 36.723C57.0101 37.043 56.9201 37.383 56.8201 37.723L56.2801 39.303L57.9101 39.293Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }