var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.13 35.09V40.55H4.42999V35.68H1.89001V40.55H1.19V35.09H5.13Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.03 37.76C11.0754 38.1204 11.0436 38.4863 10.9367 38.8334C10.8298 39.1805 10.6503 39.5009 10.4101 39.7733C10.1698 40.0457 9.87434 40.2639 9.5433 40.4133C9.21225 40.5627 8.85319 40.64 8.48998 40.64C8.14189 40.6351 7.79867 40.5575 7.48229 40.4123C7.16591 40.267 6.88337 40.0573 6.65273 39.7965C6.4221 39.5358 6.24845 39.2297 6.14294 38.898C6.03743 38.5662 6.00237 38.2161 6.04 37.87C6.00103 37.5121 6.0371 37.15 6.1459 36.8069C6.2547 36.4637 6.43384 36.147 6.6719 35.8769C6.90996 35.6069 7.20171 35.3894 7.52853 35.2384C7.85534 35.0874 8.21005 35.0062 8.57 35C8.91848 35.0021 9.26261 35.0774 9.58016 35.2209C9.89772 35.3645 10.1816 35.573 10.4135 35.8332C10.6454 36.0933 10.8201 36.3993 10.9263 36.7312C11.0325 37.0631 11.0678 37.4136 11.03 37.76ZM6.74999 37.83C6.74999 39 7.37998 40.04 8.48998 40.04C9.59998 40.04 10.24 39.04 10.24 37.78C10.24 36.69 9.67999 35.56 8.49999 35.56C7.31999 35.56 6.74999 36.65 6.74999 37.83Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.5 35.0901V40.5501H14.75V35.6801H12.94V37.3301C12.94 38.5201 12.89 39.8401 12.12 40.3301C11.872 40.4903 11.5851 40.5802 11.29 40.59L11.2 40.0201C11.4123 39.9753 11.6089 39.8753 11.77 39.73C12.1838 39.0134 12.3523 38.1812 12.25 37.3601V35.0601L15.5 35.0901Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.38 35.09V37.39C17.38 38.26 17.38 38.97 17.32 39.7C17.6206 39.0791 17.9544 38.4749 18.32 37.89L20.09 35.08H20.79V40.54H20.13V38.2299C20.13 37.34 20.13 36.69 20.19 36C19.8968 36.6343 19.5628 37.2489 19.19 37.84L17.48 40.55H16.75V35.09H17.38Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.75 35.09V37.51H22.96L24.84 35.09H25.69L23.62 37.59C23.9886 37.6405 24.3328 37.8027 24.6064 38.0548C24.8799 38.3069 25.0696 38.6367 25.15 39C25.36 39.56 25.51 40.06 25.75 40.55H24.99C24.7951 40.1326 24.6313 39.7015 24.5 39.26C24.437 38.9061 24.2465 38.5876 23.9646 38.3646C23.6827 38.1417 23.3289 38.0297 22.97 38.05H22.75V40.55H22.05V35.09H22.75Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.32 35.0901V40.5501H29.61V35.6801H27.8V37.3301C27.8 38.5201 27.75 39.8401 26.98 40.3301C26.732 40.4903 26.4451 40.5802 26.15 40.59L26.06 40.0201C26.2722 39.9753 26.4689 39.8753 26.63 39.73C27.0438 39.0134 27.2123 38.1812 27.11 37.3601V35.0601L30.32 35.0901Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.21 35.09V37.39C32.21 38.26 32.21 38.97 32.14 39.7C32.4406 39.0791 32.7744 38.4749 33.14 37.89L34.91 35.08H35.61V40.54H34.95V38.2299C34.95 37.34 34.95 36.69 35.01 36C34.7168 36.6343 34.3828 37.2489 34.01 37.84L32.3 40.55H31.55V35.09H32.21Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.57 35.09V37.37H40.22V35.09H40.93V40.55H40.22V37.99H37.57V40.55H36.87V35.09H37.57Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.81 35.09V37.39C42.81 38.26 42.81 38.97 42.75 39.7C43.0506 39.0791 43.3844 38.4749 43.75 37.89L45.51 35.08H46.22V40.54H45.55V38.2299C45.55 37.34 45.55 36.69 45.62 36C45.3268 36.6343 44.9928 37.2489 44.62 37.84L42.91 40.55H42.16V35.09H42.81Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.18 35.09V37.51H48.39L50.27 35.09H51.12L49.05 37.59C49.4185 37.6405 49.7628 37.8027 50.0363 38.0548C50.3099 38.3069 50.4996 38.6367 50.58 39C50.79 39.56 50.94 40.06 51.18 40.55H50.42C50.2251 40.1326 50.0613 39.7015 49.93 39.26C49.865 38.9071 49.674 38.5898 49.3925 38.3672C49.1111 38.1447 48.7583 38.0319 48.4 38.05H48.18V40.55H47.48V35.09H48.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.75 38.83L52.18 40.55H51.46L53.31 35.09H54.16L56.03 40.55H55.27L54.75 38.83H52.75ZM54.54 38.28L54.01 36.71C53.89 36.35 53.81 36.03 53.72 35.71C53.64 36.04 53.55 36.37 53.44 36.71L52.91 38.29L54.54 38.28Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.54 50.27V45H15.32L16.7 47.6C17.01 48.23 17.3 48.81 17.52 49.36C17.46 48.63 17.45 48.01 17.45 47.18V45.01H18.08V50.28H17.36L15.97 47.66C15.67 47.07 15.38 46.47 15.14 45.87C15.14 46.55 15.14 47.16 15.14 48.09V50.27H14.54ZM21.11 46.51C21.1327 46.6915 21.1166 46.8758 21.0627 47.0506C21.0088 47.2254 20.9183 47.3868 20.7972 47.5239C20.6762 47.6611 20.5273 47.7709 20.3606 47.8462C20.1938 47.9214 20.013 47.9603 19.83 47.9603C19.6471 47.9603 19.4663 47.9214 19.2995 47.8462C19.1327 47.7709 18.9839 47.6611 18.8629 47.5239C18.7418 47.3868 18.6513 47.2254 18.5974 47.0506C18.5434 46.8758 18.5273 46.6915 18.55 46.51C18.5318 46.33 18.5516 46.1482 18.6082 45.9763C18.6649 45.8045 18.757 45.6465 18.8786 45.5126C19.0003 45.3787 19.1488 45.2718 19.3144 45.199C19.48 45.1262 19.6591 45.0891 19.84 45.09C20.0217 45.0837 20.2025 45.1173 20.3697 45.1886C20.5369 45.2599 20.6864 45.3671 20.8076 45.5026C20.9287 45.6381 21.0186 45.7986 21.0709 45.9726C21.1232 46.1467 21.1365 46.3302 21.11 46.51ZM18.75 48.7V48.28H20.88V48.7H18.75ZM19.12 46.54C19.12 47.11 19.43 47.54 19.81 47.54C20.19 47.54 20.51 47.13 20.51 46.54C20.51 45.95 20.31 45.54 19.81 45.54C19.31 45.54 19.14 46.06 19.14 46.54H19.12Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.75 45.5C24.5738 45.482 24.3962 45.482 24.22 45.5C23.7504 45.5637 23.3155 45.7822 22.984 46.1209C22.6526 46.4596 22.4436 46.8992 22.39 47.37C22.6003 47.0993 22.8919 46.9032 23.222 46.8107C23.5521 46.7182 23.9032 46.7342 24.2235 46.8563C24.5438 46.9784 24.8165 47.2001 25.0013 47.4888C25.1861 47.7776 25.2733 48.118 25.25 48.46C25.2636 48.6986 25.2284 48.9374 25.1467 49.1619C25.065 49.3865 24.9384 49.592 24.7747 49.7661C24.6109 49.9401 24.4135 50.079 24.1943 50.1742C23.9752 50.2694 23.7389 50.319 23.5 50.32C22.37 50.32 21.64 49.44 21.64 48.07C21.6109 47.1933 21.931 46.3409 22.53 45.7C22.9871 45.2596 23.5786 44.985 24.21 44.92C24.3831 44.908 24.5569 44.908 24.73 44.92L24.75 45.5ZM24.55 48.5C24.5687 48.3473 24.5543 48.1923 24.5077 48.0457C24.4611 47.899 24.3835 47.7641 24.28 47.6502C24.1765 47.5363 24.0497 47.4461 23.9082 47.3856C23.7667 47.3252 23.6138 47.296 23.46 47.3C23.2514 47.3078 23.0486 47.3712 22.8728 47.4837C22.697 47.5963 22.5544 47.7538 22.46 47.94C22.4054 48.0377 22.3778 48.1481 22.38 48.26C22.38 49.13 22.79 49.77 23.54 49.77C24.29 49.77 24.53 49.3 24.53 48.54L24.55 48.5Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.58 44.83V49.71H32.06L32.01 51.58H31.46V50.27H28.02L27.97 51.58H27.42L27.37 49.71H27.78C28.0021 49.3338 28.1831 48.9348 28.32 48.52C28.5176 47.7665 28.6051 46.9885 28.58 46.21V44.83H31.58ZM29.19 46.44C29.2074 47.193 29.113 47.9445 28.91 48.67C28.7976 49.019 28.6466 49.3544 28.46 49.67H30.88V45.3699H29.19V46.44Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.75 45.1301C33.1797 44.8557 33.6803 44.7132 34.19 44.7201C35.14 44.7201 35.79 45.2101 35.79 46.0601C35.7857 46.3759 35.6715 46.6804 35.4671 46.9213C35.2628 47.1622 34.9809 47.3244 34.67 47.3801C35.0272 47.388 35.367 47.5361 35.6158 47.7924C35.8647 48.0488 36.0027 48.3928 36 48.7501C36 49.8901 35 50.3401 34 50.3401C33.5 50.3481 33.0067 50.2239 32.57 49.9801L32.76 49.4301C33.1341 49.6369 33.5527 49.7501 33.98 49.7601C34.79 49.7601 35.24 49.3301 35.24 48.7601C35.24 47.9401 34.45 47.7001 33.76 47.7001H33.43V47.1701H33.76C34.0618 47.2041 34.3649 47.1185 34.6042 46.9315C34.8436 46.7445 35 46.4712 35.04 46.1701C35.04 45.6201 34.65 45.3101 34.04 45.3101C33.6341 45.3226 33.2395 45.4472 32.9 45.6701L32.75 45.1301Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.58 47.83C41.58 47.07 41.5 46.15 41.5 45.47C41.31 46.11 41.09 46.79 40.81 47.55L39.81 50.2H39.27L38.39 47.6C38.13 46.83 37.91 46.12 37.76 45.47C37.76 46.15 37.7 47.07 37.66 47.89L37.51 50.23H36.84L37.22 44.77H38.12L39.05 47.41C39.28 48.08 39.46 48.68 39.6 49.25C39.74 48.7 39.94 48.1 40.18 47.41L41.18 44.77H42.08L42.42 50.23H41.75L41.58 47.83Z",
          fill: "black",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "28",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: { d: "M30.22 18.03H25.6V19.03H30.22V18.03Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: {
          d: "M28.41 16.2201H27.41V20.8401H28.41V16.2201Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.62 24.7901H17.2V10.1301H38.62V24.7901ZM18.2 23.7901H37.62V11.1301H18.2V23.7901Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.56 13.3201H17.67V14.3201H38.56V13.3201Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.77 15.4701H19.19V11.9801H23.77V15.4701ZM20.19 14.4701H22.77V12.9801H20.19V14.4701Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.63 15.5701H32.05V12.0801H36.63V15.5701ZM33.05 14.5701H35.63V13.0801H33.05V14.5701Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.57 11.13H24.25V9.73C24.2487 9.37196 24.3181 9.01719 24.4542 8.68603C24.5903 8.35487 24.7904 8.05384 25.0431 7.8002C25.2958 7.54657 25.5961 7.34532 25.9268 7.208C26.2575 7.07068 26.612 7 26.97 7H28.85C29.2081 7 29.5626 7.07068 29.8932 7.208C30.2239 7.34532 30.5242 7.54657 30.7769 7.8002C31.0296 8.05384 31.2297 8.35487 31.3658 8.68603C31.502 9.01719 31.5713 9.37196 31.57 9.73V11.13ZM25.25 10.13H30.57V9.73C30.57 9.2729 30.3891 8.83438 30.0669 8.51023C29.7446 8.18608 29.3071 8.00264 28.85 8H26.97C26.5129 8.00264 26.0755 8.18608 25.7532 8.51023C25.4309 8.83438 25.25 9.2729 25.25 9.73V10.13Z",
          fill: "#E73C14",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }