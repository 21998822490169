<template>
  <g @click="$emit('click')">
    <circle
      cx="21"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M20.9001 23.1131C19.4246 23.1131 17.9823 22.6756 16.7555 21.8559C15.5287 21.0362 14.5725 19.8711 14.0079 18.5079C13.4433 17.1448 13.2956 15.6448 13.5834 14.1977C13.8713 12.7506 14.5817 11.4214 15.625 10.3781C16.6683 9.3348 17.9976 8.62431 19.4447 8.33646C20.8918 8.04862 22.3918 8.19635 23.7549 8.76098C25.118 9.32561 26.2831 10.2818 27.1028 11.5086C27.9225 12.7354 28.3601 14.1777 28.3601 15.6531C28.3574 17.6308 27.5706 19.5268 26.1722 20.9252C24.7737 22.3237 22.8778 23.1105 20.9001 23.1131ZM20.9001 8.90311C19.565 8.90311 18.26 9.299 17.15 10.0407C16.0399 10.7824 15.1748 11.8366 14.6639 13.07C14.153 14.3034 14.0193 15.6606 14.2798 16.97C14.5402 18.2793 15.1831 19.4821 16.1271 20.4261C17.0711 21.3701 18.2738 22.013 19.5832 22.2734C20.8926 22.5339 22.2498 22.4002 23.4832 21.8893C24.7166 21.3784 25.7708 20.5133 26.5125 19.4032C27.2542 18.2932 27.6501 16.9881 27.6501 15.6531C27.6474 13.8637 26.9354 12.1484 25.6701 10.8831C24.4048 9.61776 22.6895 8.90576 20.9001 8.90311Z"
      fill="#E73C14"
    />
    <path
      d="M20.9 21.0232C19.8384 21.0212 18.8011 20.7046 17.9194 20.1133C17.0376 19.5221 16.3508 18.6827 15.9459 17.7013C15.541 16.7199 15.4361 15.6405 15.6445 14.5995C15.8528 13.5584 16.365 12.6025 17.1164 11.8525C17.8678 11.1025 18.8247 10.5921 19.8661 10.3857C20.9075 10.1793 21.9867 10.2862 22.9674 10.6929C23.948 11.0997 24.7861 11.788 25.3757 12.6708C25.9653 13.5537 26.28 14.5915 26.28 15.6532C26.2774 17.0783 25.7094 18.4442 24.7007 19.451C23.6921 20.4578 22.3252 21.0232 20.9 21.0232ZM20.9 10.9932C19.9788 10.9952 19.0788 11.3002 18.3138 11.8134C17.5488 12.3267 16.953 13.0551 16.6019 13.9068C16.2507 14.7585 16.1599 15.6951 16.3408 16.5984C16.5217 17.5017 16.9663 18.3311 17.6184 18.9818C18.2706 19.6325 19.1009 20.0754 20.0046 20.2544C20.9083 20.4334 21.8447 20.3405 22.6956 19.9875C23.5465 19.6345 24.2738 19.0372 24.7854 18.2711C25.297 17.505 25.57 16.6044 25.57 15.6832C25.574 15.0679 25.4561 14.4579 25.223 13.8884C24.99 13.3189 24.6465 12.8012 24.2123 12.3651C23.7781 11.9291 23.2619 11.5833 22.6934 11.3479C22.1249 11.1124 21.5154 10.9919 20.9 10.9932Z"
      fill="#E73C14"
    />
    <path
      d="M11.34 23.7631C11.118 23.7605 10.9059 23.6712 10.7489 23.5142C10.592 23.3573 10.5026 23.1451 10.5 22.9231V8.35313C10.4995 8.30602 10.509 8.25935 10.5279 8.21623C10.5469 8.1731 10.5749 8.13453 10.61 8.10313C10.6451 8.06505 10.6889 8.03606 10.7377 8.01864C10.7865 8.00122 10.8387 7.9959 10.89 8.00314C11.3631 8.02125 11.8108 8.22201 12.139 8.56326C12.4672 8.9045 12.6504 9.35968 12.65 9.83314V15.8331C12.65 15.926 12.6131 16.015 12.5475 16.0806C12.4819 16.1463 12.3928 16.1831 12.3 16.1831H11.98L12.18 22.8931C12.1827 23.0056 12.163 23.1175 12.1221 23.2223C12.0812 23.327 12.0199 23.4227 11.9417 23.5036C11.8636 23.5845 11.7702 23.6491 11.6669 23.6937C11.5636 23.7382 11.4525 23.7618 11.34 23.7631ZM11.21 8.76313V22.9031C11.21 23.0431 11.47 23.0431 11.47 22.9031L11.26 15.8431C11.256 15.7951 11.2629 15.7467 11.2802 15.7017C11.2976 15.6567 11.3248 15.6161 11.36 15.5831C11.4257 15.5151 11.5155 15.4755 11.61 15.4731H11.94V9.81313C11.936 9.58796 11.8642 9.36922 11.734 9.18548C11.6037 9.00174 11.4211 8.86152 11.21 8.78314V8.76313Z"
      fill="#E73C14"
    />
    <path
      d="M30.4601 23.6632C30.2214 23.6632 29.9925 23.5684 29.8237 23.3996C29.6549 23.2308 29.5601 23.0019 29.5601 22.7632L29.8 13.6732C29.8025 13.6232 29.8153 13.5743 29.8376 13.5295C29.86 13.4848 29.8915 13.4452 29.9301 13.4132C29.9694 13.3792 30.0158 13.3543 30.066 13.3405C30.1162 13.3266 30.1688 13.3242 30.2201 13.3332C30.3787 13.3633 30.5415 13.3633 30.7 13.3332C30.7497 13.3262 30.8003 13.3296 30.8485 13.3433C30.8968 13.357 30.9416 13.3806 30.9802 13.4126C31.0188 13.4446 31.0503 13.4843 31.0727 13.5292C31.095 13.5741 31.1078 13.6231 31.11 13.6732L31.36 22.7532C31.3614 22.8723 31.3391 22.9904 31.2944 23.1007C31.2498 23.2111 31.1837 23.3115 31.1 23.3961C31.0163 23.4807 30.9166 23.5479 30.8068 23.5938C30.6969 23.6396 30.5791 23.6632 30.4601 23.6632ZM30.4601 15.7732L30.2701 22.7732C30.27 22.7977 30.275 22.822 30.2846 22.8445C30.2943 22.867 30.3085 22.8873 30.3263 22.9042C30.3441 22.921 30.3651 22.9341 30.3881 22.9425C30.4111 22.9509 30.4356 22.9546 30.4601 22.9532C30.5104 22.9532 30.5588 22.9332 30.5944 22.8976C30.63 22.8619 30.6501 22.8136 30.6501 22.7632L30.4601 15.7732Z"
      fill="#E73C14"
    />
    <path
      d="M30.46 14.0632H30.1001C29.6195 13.9793 29.181 13.7366 28.8547 13.374C28.5283 13.0114 28.333 12.5499 28.3 12.0632V11.9132C28.2901 11.8235 28.2901 11.7329 28.3 11.6432C28.3432 10.5688 28.6899 9.52861 29.3 8.64319C29.4338 8.46577 29.6037 8.31876 29.7985 8.21188C29.9933 8.105 30.2085 8.04069 30.43 8.02319C30.6493 8.03826 30.8626 8.10166 31.0545 8.20885C31.2464 8.31604 31.4122 8.46437 31.5401 8.64319C32.1444 9.5315 32.4905 10.57 32.5401 11.6432C32.545 11.7264 32.545 11.8099 32.5401 11.8932V12.1032C32.4956 12.576 32.2959 13.0207 31.9721 13.3681C31.6482 13.7154 31.2186 13.9458 30.75 14.0232L30.46 14.0632ZM30.46 8.74318C30.3398 8.75588 30.2239 8.79469 30.1203 8.85687C30.0166 8.91905 29.9278 9.00311 29.86 9.10318C29.3352 9.87578 29.0373 10.7799 29 11.7132C28.9953 11.7764 28.9953 11.8399 29 11.9032V12.0032C29.0244 12.3281 29.1568 12.6355 29.376 12.8766C29.5953 13.1176 29.8889 13.2783 30.21 13.3332C30.3686 13.3632 30.5315 13.3632 30.69 13.3332C31.0117 13.2775 31.3052 13.1149 31.5229 12.8717C31.7407 12.6286 31.87 12.319 31.89 11.9932C31.896 11.92 31.896 11.8464 31.89 11.7732C31.8476 10.8347 31.5503 9.92543 31.03 9.14319C30.9745 9.03932 30.8962 8.94936 30.8009 8.88008C30.7057 8.8108 30.596 8.764 30.48 8.74318H30.46Z"
      fill="#E73C14"
    />
    <path
      d="M9.03003 35.1063C9.33475 35.0621 9.64211 35.0387 9.95001 35.0363C10.3101 35.0134 10.666 35.1237 10.95 35.3463C11.0574 35.4431 11.1426 35.5621 11.1995 35.6951C11.2565 35.828 11.284 35.9717 11.28 36.1163C11.2876 36.2607 11.2658 36.4052 11.2159 36.541C11.1661 36.6768 11.0892 36.801 10.99 36.9063C10.8394 37.0479 10.6616 37.1572 10.4673 37.2277C10.273 37.2982 10.0663 37.3284 9.85999 37.3163C9.73384 37.3326 9.60612 37.3326 9.47998 37.3163V38.7963H9L9.03003 35.1063ZM9.51001 36.8863C9.64278 36.9037 9.77727 36.9037 9.91003 36.8863C10.48 36.8863 10.83 36.6063 10.83 36.0963C10.83 35.5863 10.49 35.3763 9.96002 35.3763C9.81047 35.3599 9.65956 35.3599 9.51001 35.3763V36.8863Z"
      fill="black"
    />
    <path
      d="M13.86 37.0263H12.4301V38.3563H14.0301V38.7563H11.9501V35.0663H13.9501V35.4663H12.4401V36.5963H13.8701L13.86 37.0263Z"
      fill="black"
    />
    <path
      d="M17.22 38.5963C16.9047 38.7314 16.5627 38.793 16.22 38.7763C15.9727 38.7917 15.725 38.7535 15.4938 38.6642C15.2626 38.575 15.0534 38.4368 14.8806 38.2592C14.7077 38.0817 14.5753 37.8688 14.4924 37.6353C14.4094 37.4018 14.3779 37.1531 14.4 36.9063C14.3892 36.647 14.4336 36.3884 14.5304 36.1476C14.6272 35.9067 14.7741 35.6893 14.9614 35.5096C15.1486 35.3299 15.3719 35.1921 15.6165 35.1053C15.8611 35.0185 16.1214 34.9848 16.38 35.0063C16.6826 34.9931 16.9841 35.0514 17.26 35.1763L17.15 35.5563C16.9127 35.4562 16.6576 35.4052 16.4 35.4063C16.201 35.3949 16.0018 35.427 15.8165 35.5005C15.6311 35.574 15.4641 35.6871 15.327 35.8318C15.1899 35.9766 15.086 36.1495 15.0226 36.3385C14.9592 36.5276 14.9379 36.7282 14.96 36.9263C14.9376 37.1214 14.9583 37.319 15.0208 37.5052C15.0832 37.6914 15.1858 37.8615 15.3212 38.0037C15.4567 38.1458 15.6217 38.2565 15.8047 38.3278C15.9877 38.3991 16.1841 38.4293 16.38 38.4163C16.6474 38.4189 16.9127 38.3679 17.16 38.2663L17.22 38.5963Z"
      fill="black"
    />
    <path
      d="M18.7201 35.4763H17.5901V35.0663H20.3801V35.4763H19.2501V38.7563H18.7701L18.7201 35.4763Z"
      fill="black"
    />
    <path
      d="M23.7 36.8762C23.7313 37.119 23.7105 37.3657 23.6388 37.5998C23.5671 37.8338 23.4463 38.0499 23.2844 38.2336C23.1226 38.4172 22.9234 38.5642 22.7001 38.6647C22.4769 38.7651 22.2348 38.8168 21.99 38.8162C21.7589 38.8066 21.5322 38.7497 21.324 38.6489C21.1158 38.5481 20.9305 38.4056 20.7795 38.2303C20.6286 38.055 20.5153 37.8506 20.4465 37.6297C20.3778 37.4089 20.3552 37.1762 20.38 36.9462C20.3551 36.7046 20.3805 36.4604 20.4545 36.2291C20.5286 35.9977 20.6498 35.7842 20.8104 35.602C20.9711 35.4197 21.1677 35.2727 21.3879 35.1702C21.6081 35.0677 21.8472 35.0118 22.09 35.0062C22.3216 35.0144 22.5491 35.0704 22.758 35.1707C22.9669 35.271 23.1529 35.4134 23.3041 35.5891C23.4553 35.7647 23.5685 35.9697 23.6367 36.1912C23.7048 36.4127 23.7264 36.646 23.7 36.8762ZM20.84 36.9362C20.84 37.7262 21.26 38.4262 22.01 38.4262C22.76 38.4262 23.2 37.7362 23.2 36.8962C23.2 36.0562 22.81 35.3962 22.02 35.3962C21.23 35.3962 20.84 36.1262 20.84 36.9362Z"
      fill="black"
    />
    <path
      d="M24.3201 35.1062C24.6247 35.0616 24.9321 35.0382 25.2401 35.0362C25.5999 35.0152 25.9552 35.1253 26.2401 35.3462C26.3457 35.444 26.4289 35.5634 26.4841 35.6963C26.5394 35.8292 26.5653 35.9724 26.5601 36.1162C26.5695 36.26 26.5496 36.4043 26.5015 36.5401C26.4533 36.6759 26.378 36.8005 26.2801 36.9062C26.1295 37.0478 25.9516 37.1572 25.7573 37.2277C25.563 37.2982 25.3564 37.3283 25.1501 37.3162C25.0239 37.3326 24.8962 37.3326 24.7701 37.3162V38.7962H24.29L24.3201 35.1062ZM24.8 36.8862C24.9294 36.9043 25.0607 36.9043 25.1901 36.8862C25.7701 36.8862 26.1201 36.6062 26.1201 36.0962C26.1201 35.5862 25.7701 35.3762 25.2501 35.3762C25.1005 35.3599 24.9496 35.3599 24.8 35.3762V36.8862Z"
      fill="black"
    />
    <path
      d="M27.38 37.5963L27 38.7563H26.51L27.76 35.0663H28.38L29.64 38.7563H29.13L28.68 37.5963H27.38ZM28.59 37.2263L28.23 36.1663C28.15 35.9263 28.1 35.7063 28.04 35.4863C27.9906 35.7133 27.9271 35.9371 27.85 36.1563L27.49 37.2263H28.59Z"
      fill="black"
    />
    <path
      d="M30.61 35.0663V36.5963H32.38V35.0463H32.87V38.7363H32.38V37.0063H30.6V38.7363H30.12V35.0463L30.61 35.0663Z"
      fill="black"
    />
    <path
      d="M0.559998 45.7462L1.65002 47.2362H1.07001L0 45.7462L1.07001 44.2562H1.65002L0.559998 45.7462ZM1.89001 45.7462L2.97998 47.2362H2.40002L1.33002 45.7462L2.40002 44.2562H2.97998L1.89001 45.7462Z"
      fill="black"
    />
    <path
      d="M4.65997 46.0663L4.09003 47.7863H3.35999L5.21997 42.3263H6.07001L7.92999 47.7863H7.17999L6.56 46.0663H4.65997ZM6.45001 45.5163L5.91998 43.9463C5.80565 43.6183 5.70887 43.2845 5.63 42.9463C5.54 43.2663 5.45998 43.6063 5.34998 43.9463L4.82001 45.5263L6.45001 45.5163Z"
      fill="black"
    />
    <path
      d="M8.63995 46.9263C9.02047 47.1562 9.45543 47.2805 9.89996 47.2863C10.62 47.2863 11.0399 46.8963 11.0399 46.3463C11.0399 45.7963 10.7499 45.5463 10.0399 45.2663C9.14992 44.9463 8.59991 44.4863 8.59991 43.7163C8.61054 43.4998 8.6659 43.2879 8.76245 43.0939C8.859 42.8998 8.99468 42.7279 9.16095 42.5888C9.32722 42.4498 9.52051 42.3467 9.72858 42.286C9.93665 42.2254 10.155 42.2084 10.3699 42.2363C10.7852 42.2235 11.1972 42.3128 11.5699 42.4963L11.3799 43.0763C11.0729 42.9093 10.7295 42.82 10.3799 42.8163C9.63994 42.8163 9.37994 43.2663 9.37994 43.6363C9.37994 44.0063 9.71991 44.3963 10.4699 44.6863C11.3899 45.0463 11.8599 45.4863 11.8599 46.2963C11.8599 47.1063 11.24 47.8663 9.94995 47.8663C9.46431 47.8704 8.98572 47.7499 8.55994 47.5163L8.63995 46.9263Z"
      fill="black"
    />
    <path d="M13.43 42.3263V47.7863H12.73V42.3263H13.43Z" fill="black" />
    <path
      d="M15.56 46.0663L15 47.7863H14.27L16.12 42.3263H16.97L18.84 47.7863H18.08L17.5 46.0663H15.56ZM17.36 45.5163L16.82 43.9463C16.7 43.5863 16.62 43.2663 16.54 42.9463C16.46 43.2663 16.37 43.6063 16.27 43.9463L15.73 45.5263L17.36 45.5163Z"
      fill="black"
    />
    <path
      d="M22.0399 42.3263V44.5963H24.6799V42.3163H25.39V47.7763H24.6799V45.2163H22.0399V47.7763H21.33V42.3163L22.0399 42.3263Z"
      fill="black"
    />
    <path
      d="M27.4999 46.0663L26.9299 47.7863H26.2L28.0599 42.3263H28.9099L30.77 47.7863H30.02L29.4299 46.0663H27.4999ZM29.2899 45.5163L28.7499 43.9463C28.6299 43.5863 28.5499 43.2663 28.4699 42.9463C28.3899 43.2663 28.3 43.6063 28.2 43.9463L27.6599 45.5263L29.2899 45.5163Z"
      fill="black"
    />
    <path
      d="M31.5599 42.3263H32.27V47.1963H34.5999V47.7863H31.5999L31.5599 42.3263Z"
      fill="black"
    />
    <path
      d="M35.39 42.3263H36.1V47.1963H38.43V47.7863H35.43L35.39 42.3263Z"
      fill="black"
    />
    <path
      d="M38.7899 44.2562H39.3599L40.4399 45.7462L39.3599 47.2362H38.7899L39.8699 45.7462L38.7899 44.2562ZM40.1099 44.2562H40.6799L41.7599 45.7462L40.6799 47.2362H40.1199L41.1899 45.7462L40.1099 44.2562Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconRestoraunt",
};
</script>

<style scoped lang="scss"></style>
