<template>
  <map-layout style="width: 100%; height: 100%; max-height: 800px">
    <map-icon-main-street transform="translate(641,307)" />

    <svg-arrow-hint
      ref="hint"
      hint-path-clone="hintPathCloneDevelopment"
      hint-arrow="hintArrowDevelopment"
      hint-path="hintPathDevelopment"
    />

    <MapIconMetroKuncovo
      class="map-icon__comfort"
      transform="translate(546,228)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 322],
            [589, 293],
            [575, 266],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovo
      class="map-icon__comfort"
      transform="translate(490,268)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 302],
            [589, 293],
            [530, 279],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoMcd
      class="map-icon__comfort"
      transform="translate(619,378)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 330],
            [770, 342],
            [710, 473],
            [648, 420],
          ],
          hint: '6 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoBkl
      class="map-icon__comfort"
      transform="translate(529,298)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [585, 282],
            [566, 288],
            [560, 295],
          ],
          hint: '7 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroScolkovo
      class="map-icon__comfort"
      transform="translate(126,505)"
      @click="
        $refs.hint.animate({
          points: [
            [645, 340],
            [595, 392],
            [490, 543],
            [174, 515],
          ],
          hint: '10 минут',
          reverse: true,
          dy: -5,
          electric: true,
        })
      "
    />

    <MapIconSber
      class="map-icon__comfort"
      transform="translate(457,405)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 352],
            [576, 368],
            [495, 405],
          ],
          hint: '6 минут',
          reverse: true,
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconProductVkuss
      class="map-icon__comfort"
      transform="translate(779,286)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 329],
            [798, 306],
            [799, 306],
          ],
          hint: '5 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconPolyclinic
      class="map-icon__comfort"
      transform="translate(860,259)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 289],
            [798, 276],
            [857, 283],
          ],
          hint: '9 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconTrcOkeaniya
      class="map-icon__comfort"
      transform="translate(999,314)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 340],
            [750, 349],
            [915, 340],
            [997, 335],
          ],
          hint: '13 минут',
          car: true,
        })
      "
    />

    <MapIconLdcKutuzov
      class="map-icon__comfort"
      transform="translate(1029,339)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 340],
            [750, 339],
            [915, 330],
            [1037, 365],
          ],
          hint: '13 минут',
          car: true,
        })
      "
    />

    <MapIconTrcVremena
      class="map-icon__comfort"
      transform="translate(1179,150)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [855, 232],
            [1066, 180],
            [1180, 170],
          ],
          hint: '20 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconProductGlobus
      class="map-icon__comfort"
      transform="translate(1109,180)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [855, 232],
            [1066, 180],
            [1120, 199],
          ],
          hint: '18 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconPerekrestok
      class="map-icon__comfort"
      transform="translate(660,444)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 320],
            [750, 342],
            [719, 423],
            [700, 440],
          ],
          hint: '7 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconPlanetHealth
      class="map-icon__comfort"
      transform="translate(503,171)"
      @click="
        $refs.hint.animate({
          points: [
            [660, 315],
            [664, 302],
            [610, 233],
            [572, 200],
          ],
          hint: '6 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconVtb
      class="map-icon__comfort"
      transform="translate(417,120)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [605, 309],
            [576, 308],
            [445, 180],
          ],
          hint: '7 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconLdc
      class="map-icon__comfort"
      transform="translate(336,215)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 382],
            [436, 400],
            [390, 265],
          ],
          hint: '9 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconTrcKuncovo
      class="map-icon__comfort"
      transform="translate(180,145)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 392],
            [436, 430],
            [220, 199],
          ],
          hint: '10 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconVetHelp
      class="map-icon__comfort"
      transform="translate(306,438)"
      @click="
        $refs.hint.animate({
          points: [
            [645, 340],
            [635, 342],
            [500, 543],
            [390, 455],
          ],
          hint: '16 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconProductVkuss
      class="map-icon__comfort"
      transform="translate(367,375)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 352],
            [576, 368],
            [435, 385],
          ],
          hint: '7 минут',
          car: true,
          reverse: true,
        })
      "
    />
  </map-layout>
</template>

<script>
import MapLayout from "@/components/elements/map/MapLayout";
import MapIconMainStreet from "@/components/elements/map/icons/MapIconMainStreet";
import SvgArrowHint from "@/components/elements/map/SVGArrowHint";
import MapIconMetroKuncovo from "@/components/elements/map/icons/MapIconMetroKuncovo";
import MapIconMetroKuncovoBkl from "@/components/elements/map/icons/MapIconMetroKuncovoBkl";
import MapIconMetroKuncovoMcd from "@/components/elements/map/icons/MapIconMetroKuncovoMcd";
import MapIconMetroScolkovo from "@/components/elements/map/icons/MapIconMetroScolkovo";
import MapIconSber from "@/components/elements/map/icons/MapIconSber";
import MapIconPolyclinic from "@/components/elements/map/icons/MapIconPolyclinic";
import MapIconTrcOkeaniya from "@/components/elements/map/icons/MapIconTrcOkeaniya";
import MapIconTrcVremena from "@/components/elements/map/icons/MapIconTrcVremena";
import MapIconPerekrestok from "@/components/elements/map/icons/MapIconPerekrestok";
import MapIconPlanetHealth from "@/components/elements/map/icons/MapIconPlanetHealth";
import MapIconVtb from "@/components/elements/map/icons/MapIconVtb";
import MapIconLdc from "@/components/elements/map/icons/MapIconLdc";
import MapIconTrcKuncovo from "@/components/elements/map/icons/MapIconTrcKuncovo";
import MapIconVetHelp from "@/components/elements/map/icons/MapIconVetHelp";
import MapIconProductVkuss from "@/components/elements/map/icons/MapIconProductVkuss";
import MapIconLdcKutuzov from "@/components/elements/map/icons/MapIconLdcKutuzov";
import MapIconProductGlobus from "@/components/elements/map/icons/MapIconProductGlobus";

export default {
  name: "PointDevelopment",
  components: {
    SvgArrowHint,
    MapIconMainStreet,
    MapLayout,
    MapIconMetroKuncovo,
    MapIconMetroKuncovoBkl,
    MapIconMetroKuncovoMcd,
    MapIconMetroScolkovo,
    MapIconSber,
    MapIconPolyclinic,
    MapIconTrcOkeaniya,
    MapIconTrcVremena,
    MapIconPerekrestok,
    MapIconPlanetHealth,
    MapIconVtb,
    MapIconLdc,
    MapIconTrcKuncovo,
    MapIconVetHelp,
    MapIconProductVkuss,
    MapIconLdcKutuzov,
    MapIconProductGlobus,
  },
  data: () => ({
    animateStartComfort: false,
    points: [
      {
        points: [
          [646, 330],
          [599, 322],
          [589, 293],
          [575, 266],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [599, 302],
          [589, 293],
          [530, 279],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [686, 330],
          [770, 342],
          [710, 473],
          [648, 420],
        ],
        hint: "6 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [585, 282],
          [566, 288],
          [560, 295],
        ],
        hint: "7 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [645, 340],
          [595, 392],
          [490, 543],
          [174, 515],
        ],
        hint: "10 минут",
        reverse: true,
        dy: -5,
        electric: true,
      },
      {
        points: [
          [646, 330],
          [625, 352],
          [576, 368],
          [495, 405],
        ],
        hint: "6 минут",
        reverse: true,
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [750, 329],
          [798, 306],
          [799, 306],
        ],
        hint: "5 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [750, 289],
          [798, 276],
          [857, 283],
        ],
        hint: "9 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 340],
          [750, 349],
          [915, 340],
          [997, 335],
        ],
        hint: "13 минут",
        car: true,
      },
      {
        points: [
          [690, 340],
          [750, 339],
          [915, 330],
          [1037, 365],
        ],
        hint: "13 минут",
        car: true,
      },
      {
        points: [
          [690, 330],
          [855, 232],
          [1066, 180],
          [1180, 170],
        ],
        hint: "20 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [855, 232],
          [1066, 180],
          [1120, 199],
        ],
        hint: "18 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [686, 320],
          [750, 342],
          [719, 423],
          [700, 440],
        ],
        hint: "7 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [660, 315],
          [664, 302],
          [610, 233],
          [572, 200],
        ],
        hint: "6 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [605, 309],
          [576, 308],
          [445, 180],
        ],
        hint: "7 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 382],
          [436, 400],
          [390, 265],
        ],
        hint: "9 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 392],
          [436, 430],
          [220, 199],
        ],
        hint: "10 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [645, 340],
          [635, 342],
          [500, 543],
          [390, 455],
        ],
        hint: "16 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 352],
          [576, 368],
          [435, 385],
        ],
        hint: "7 минут",
        car: true,
        reverse: true,
      },
    ],
  }),
  mounted() {
    this.animateComfort(this.points);
  },
  methods: {
    animateComfort(points = []) {
      const pointsToAnimate = [...points];
      const _this = this;
      const intervalID = setInterval(() => {
        const point = pointsToAnimate.pop();
        if (point === undefined) {
          clearInterval(intervalID);
          _this.animateComfort(_this.points);
        } else if (_this.animateStartComfort === true) {
          clearInterval(intervalID);
          setTimeout(() => {
            _this.animateStartComfort = false;
            _this.animateComfort(_this.points);
          }, 2000);
        } else {
          _this.$refs.hint.animate(point);
        }
      }, 4000);

      var elem = document.querySelectorAll(".map-icon__comfort");

      elem.forEach(function (item, i, arr) {
        item.addEventListener(
          "click",
          () => {
            _this.animateStartComfort = true;
          },
          { once: true }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
