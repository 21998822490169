var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "map-layout",
    { staticStyle: { width: "100%", height: "100%", "max-height": "800px" } },
    [
      _c("map-icon-main-street", {
        attrs: { transform: "translate(641,307)" },
      }),
      _c("svg-arrow-hint", {
        ref: "hint",
        attrs: {
          "hint-path-clone": "hintPathCloneFamily",
          "hint-arrow": "hintArrowFamily",
          "hint-path": "hintPathFamily",
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(546,228)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 322],
                [589, 293],
                [575, 266],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(490,268)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 302],
                [589, 293],
                [530, 279],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoMcd", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(619,378)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 330],
                [770, 342],
                [710, 473],
                [648, 420],
              ],
              hint: "6 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoBkl", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(529,298)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [585, 282],
                [566, 288],
                [560, 295],
              ],
              hint: "7 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroScolkovo", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(126,505)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [645, 340],
                [595, 392],
                [490, 543],
                [174, 515],
              ],
              hint: "10 минут",
              reverse: true,
              dy: -5,
              electric: true,
            })
          },
        },
      }),
      _c("MapIconClinic", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(549,378)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [684, 330],
                [750, 432],
                [668, 473],
                [596, 405],
              ],
              hint: "10 минут",
              manLeft: true,
              reverse: true,
            })
          },
        },
      }),
      _c("MapIconKindergartenSmile", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(709,444)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 350],
                [700, 359],
                [715, 390],
                [725, 435],
              ],
              hint: "12 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconSchoolKolmogorova", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(631,490)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [699, 379],
                [699, 480],
                [689, 485],
              ],
              hint: "13 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconFenceClub", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(407,330)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 362],
                [576, 398],
                [470, 345],
              ],
              hint: "6 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMuseumWin", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(1217,229)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 329],
                [915, 330],
                [1220, 246],
              ],
              hint: "20 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconFamalyRestor", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(1110,172)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [855, 222],
                [1066, 180],
                [1110, 188],
              ],
              hint: "20 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconMusSchool", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(1159,133)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [825, 232],
                [1066, 180],
                [1180, 150],
              ],
              hint: "24 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconOlimp", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(615,158)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 316],
                [664, 232],
                [663, 243],
                [663, 220],
              ],
              hint: "15 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconShcoolWater", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(735,149)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 310],
                [714, 262],
                [703, 273],
                [760, 210],
              ],
              hint: "5 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconSchool", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(820,195)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 310],
                [714, 289],
                [723, 283],
                [823, 240],
              ],
              hint: "10 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMusScool", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(815,105)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [744, 330],
                [943, 283],
                [867, 153],
              ],
              hint: "10 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconFootballScholl", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(417,270)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 362],
                [556, 408],
                [470, 325],
              ],
              hint: "6 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconSunScholl", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(416,215)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [645, 299],
                [466, 240],
                [465, 240],
              ],
              hint: "8 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconZoo", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(304,240)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [615, 332],
                [476, 508],
                [335, 295],
              ],
              hint: "12 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconEnglishExem", {
        staticClass: "map-icon__family",
        attrs: { transform: "translate(394,90)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [656, 310],
                [625, 242],
                [536, 168],
                [450, 115],
              ],
              hint: "14 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }