var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "47",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.51 12.3198C43.2387 12.3198 44.64 10.9184 44.64 9.18979C44.64 7.46114 43.2387 6.05981 41.51 6.05981C39.7814 6.05981 38.38 7.46114 38.38 9.18979C38.38 10.9184 39.7814 12.3198 41.51 12.3198Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.31 20.5199C53.8067 20.5199 55.02 19.3066 55.02 17.8099C55.02 16.3132 53.8067 15.0999 52.31 15.0999C50.8133 15.0999 49.6 16.3132 49.6 17.8099C49.6 19.3066 50.8133 20.5199 52.31 20.5199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.8 9.18991C48.9101 9.18991 49.81 8.29002 49.81 7.17993C49.81 6.06984 48.9101 5.16992 47.8 5.16992C46.6899 5.16992 45.79 6.06984 45.79 7.17993C45.79 8.29002 46.6899 9.18991 47.8 9.18991Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.7 19.4799C44.0421 19.4799 45.13 18.3919 45.13 17.0499C45.13 15.7078 44.0421 14.6199 42.7 14.6199C41.358 14.6199 40.27 15.7078 40.27 17.0499C40.27 18.3919 41.358 19.4799 42.7 19.4799Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.77 12.1999C53.742 12.1999 54.53 11.4119 54.53 10.4399C54.53 9.46789 53.742 8.67993 52.77 8.67993C51.798 8.67993 51.01 9.46789 51.01 10.4399C51.01 11.4119 51.798 12.1999 52.77 12.1999Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.25 7.59985H47.25V26.9199H48.25V7.59985Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.79 16.1798L41.16 9.54984L41.87 8.83984L47.79 14.7698L52.42 10.1398L53.12 10.8498L47.79 16.1798Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.75 23.0199L42.35 17.6099L43.05 16.8999L47.75 21.5999L51.95 17.4099L52.66 18.1099L47.75 23.0199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.14908 41.44C4.69276 41.6272 4.20203 41.7158 3.70908 41.7C3.34339 41.7229 2.97706 41.6666 2.63505 41.5352C2.29305 41.4037 1.9834 41.2001 1.72717 40.9382C1.47095 40.6762 1.27417 40.3622 1.15025 40.0174C1.02633 39.6726 0.97819 39.3051 1.00908 38.94C0.987578 38.5592 1.04641 38.1781 1.18176 37.8215C1.31711 37.4649 1.52594 37.1407 1.79471 36.8701C2.06347 36.5994 2.38616 36.3883 2.74181 36.2505C3.09745 36.1127 3.47811 36.0512 3.85907 36.07C4.30591 36.0516 4.75092 36.1372 5.15907 36.32L4.98907 36.89C4.6406 36.7323 4.26152 36.6538 3.87907 36.66C3.58235 36.638 3.28448 36.6825 3.00714 36.7903C2.7298 36.898 2.47999 37.0663 2.27592 37.2828C2.07186 37.4993 1.91867 37.7587 1.82753 38.0419C1.73639 38.3251 1.70958 38.6251 1.74907 38.92C1.72126 39.2068 1.75576 39.4962 1.85019 39.7684C1.94462 40.0407 2.09675 40.2893 2.29617 40.4972C2.4956 40.7052 2.73761 40.8676 3.00563 40.9734C3.27365 41.0792 3.56137 41.1258 3.84907 41.11C4.24443 41.1163 4.63654 41.0379 4.99907 40.88L5.14908 41.44Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.529 38.82C10.5728 39.1787 10.5401 39.5427 10.4331 39.8879C10.3261 40.2331 10.1472 40.5518 9.90821 40.8229C9.66921 41.094 9.37551 41.3114 9.04645 41.4609C8.71738 41.6103 8.3604 41.6884 7.99899 41.69C7.65053 41.6849 7.30695 41.6074 6.99011 41.4622C6.67328 41.3171 6.39014 41.1075 6.15872 40.847C5.92731 40.5864 5.75269 40.2805 5.646 39.9487C5.5393 39.617 5.50287 39.2666 5.539 38.92C5.50152 38.5616 5.53896 38.1993 5.64894 37.8561C5.75893 37.5129 5.93907 37.1963 6.1779 36.9265C6.41674 36.6566 6.70908 36.4393 7.03635 36.2884C7.36362 36.1375 7.71867 36.0563 8.079 36.05C8.42805 36.0518 8.77279 36.1273 9.09067 36.2715C9.40855 36.4158 9.6924 36.6255 9.92366 36.8869C10.1549 37.1484 10.3284 37.4557 10.4327 37.7888C10.537 38.1219 10.5698 38.4733 10.529 38.82ZM6.299 38.9C6.299 40.07 6.929 41.12 8.039 41.12C9.149 41.12 9.789 40.12 9.789 38.85C9.789 37.76 9.219 36.63 8.049 36.63C6.879 36.63 6.299 37.7 6.299 38.9Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.999 36.14V41.6H14.289V36.73H12.479V38.44C12.479 39.62 12.479 40.94 11.669 41.44C11.4173 41.6001 11.1272 41.6899 10.829 41.7L10.739 41.13C10.9542 41.0852 11.154 40.9852 11.319 40.84C11.7328 40.1233 11.9013 39.2912 11.799 38.47V36.14H14.999Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.959 36.14V41.03H20.439V42.9H19.889V41.59H16.449L16.399 42.9H15.849V41.03H16.259C16.477 40.6482 16.6579 40.2464 16.799 39.83C16.9923 39.0792 17.0798 38.305 17.059 37.53V36.14H19.959ZM17.559 37.75C17.58 38.5026 17.489 39.2541 17.289 39.98C17.1703 40.3266 17.0197 40.6613 16.839 40.98H19.239V36.68H17.539L17.559 37.75Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.239 39.88L21.679 41.6H20.949L22.799 36.14H23.649L25.519 41.6H24.759L24.179 39.88H22.239ZM24.039 39.33L23.499 37.76C23.379 37.4 23.299 37.08 23.219 36.76C23.139 37.08 23.049 37.41 22.949 37.76L22.409 39.34L24.039 39.33Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.989 36.74H25.329V36.14H29.329V36.74H27.659V41.6H26.9491L26.989 36.74Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.7991 39.04H30.6791V41.04H33.0491V41.63H29.9791V36.14H32.9291V36.73H30.6791V38.44H32.7991V39.04Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.669 36.14V38.44H37.3091V36.14H38.019V41.6H37.3091V39.04H34.669V41.6H33.959V36.14H34.669Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.939 36.14V38.56H40.159L42.029 36.14H42.879L40.809 38.65C41.1768 38.6985 41.5207 38.8587 41.7944 39.1091C42.0681 39.3596 42.2582 39.688 42.339 40.05C42.5123 40.5766 42.7126 41.0939 42.939 41.6H42.179C41.9884 41.1809 41.8247 40.75 41.689 40.31C41.6262 39.9579 41.4372 39.6408 41.1574 39.4181C40.8776 39.1954 40.5262 39.0823 40.169 39.1H39.939V41.6H39.239V36.14H39.939Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.139 38.82C48.1828 39.1787 48.1501 39.5427 48.0431 39.8879C47.9361 40.2331 47.7572 40.5518 47.5182 40.8229C47.2793 41.094 46.9856 41.3114 46.6565 41.4609C46.3274 41.6103 45.9705 41.6884 45.609 41.69C45.2606 41.6849 44.917 41.6074 44.6002 41.4622C44.2833 41.3171 44.0002 41.1075 43.7688 40.847C43.5374 40.5864 43.3627 40.2805 43.256 39.9487C43.1493 39.617 43.1129 39.2666 43.149 38.92C43.1116 38.5616 43.149 38.1993 43.259 37.8561C43.369 37.5129 43.5491 37.1963 43.7879 36.9265C44.0268 36.6566 44.3191 36.4393 44.6464 36.2884C44.9736 36.1375 45.3287 36.0563 45.689 36.05C46.0381 36.0518 46.3828 36.1273 46.7007 36.2715C47.0186 36.4158 47.3024 36.6255 47.5337 36.8869C47.765 37.1484 47.9384 37.4557 48.0427 37.7888C48.1471 38.1219 48.1799 38.4733 48.139 38.82ZM43.909 38.9C43.909 40.07 44.539 41.12 45.649 41.12C46.759 41.12 47.399 40.12 47.399 38.85C47.399 37.76 46.829 36.63 45.659 36.63C44.489 36.63 43.909 37.7 43.909 38.9Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.059 36.21C49.4849 36.1351 49.9166 36.0983 50.349 36.1C50.8776 36.05 51.4065 36.192 51.839 36.5C51.9953 36.613 52.1197 36.7644 52.2003 36.9396C52.2809 37.1148 52.3149 37.3078 52.299 37.5C52.2908 37.7727 52.1963 38.0357 52.0292 38.2513C51.8621 38.4669 51.631 38.624 51.369 38.7C51.6859 38.7642 51.9711 38.9353 52.1768 39.1847C52.3825 39.4342 52.4963 39.7467 52.499 40.07C52.5008 40.2735 52.4609 40.4751 52.3818 40.6626C52.3027 40.8501 52.1861 41.0193 52.039 41.16C51.4933 41.5569 50.8203 41.7385 50.149 41.67C49.7848 41.6725 49.4208 41.6524 49.059 41.61V36.21ZM49.759 38.45H50.399C51.149 38.45 51.589 38.06 51.589 37.53C51.589 37 51.099 36.64 50.389 36.64C50.1779 36.6372 49.967 36.654 49.759 36.69V38.45ZM49.759 41.07C49.9555 41.083 50.1526 41.083 50.349 41.07C51.079 41.07 51.759 40.8 51.759 40.01C51.759 39.22 51.119 38.95 50.349 38.95H49.759V41.07Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.239 41.44C56.7827 41.6272 56.292 41.7158 55.799 41.7C55.4333 41.7229 55.067 41.6666 54.725 41.5352C54.383 41.4037 54.0734 41.2001 53.8171 40.9382C53.5609 40.6762 53.3641 40.3622 53.2402 40.0174C53.1163 39.6726 53.0682 39.3051 53.099 38.94C53.0775 38.5592 53.1364 38.1781 53.2717 37.8215C53.4071 37.4649 53.6159 37.1407 53.8847 36.8701C54.1534 36.5994 54.4761 36.3883 54.8318 36.2505C55.1874 36.1127 55.5681 36.0512 55.949 36.07C56.3959 36.0516 56.8409 36.1372 57.249 36.32L57.079 36.89C56.7306 36.7323 56.3515 36.6538 55.969 36.66C55.6723 36.638 55.3744 36.6825 55.0971 36.7903C54.8198 36.898 54.57 37.0663 54.3659 37.2828C54.1618 37.4993 54.0086 37.7587 53.9175 38.0419C53.8263 38.3251 53.7996 38.6251 53.839 38.92C53.8112 39.2068 53.8457 39.4962 53.9402 39.7684C54.0346 40.0407 54.1867 40.2893 54.3861 40.4972C54.5856 40.7052 54.8276 40.8676 55.0956 40.9734C55.3636 41.0792 55.6513 41.1258 55.939 41.11C56.3344 41.1163 56.7265 41.0379 57.089 40.88L57.239 41.44Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.779 36.14V38.56H58.999L60.869 36.14H61.719L59.649 38.65C60.0168 38.6985 60.3608 38.8587 60.6344 39.1091C60.9081 39.3596 61.0982 39.688 61.179 40.05C61.3524 40.5766 61.5526 41.0939 61.779 41.6H61.019C60.8241 41.1827 60.6603 40.7515 60.529 40.31C60.466 39.9561 60.2756 39.6376 59.9937 39.4147C59.7117 39.1917 59.3579 39.0798 58.999 39.1H58.779V41.6H58.079V36.14H58.779Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M63.129 36.14V38.44C63.129 39.31 63.129 40.02 63.069 40.75C63.3696 40.1291 63.7034 39.5249 64.069 38.94L65.829 36.13H66.539V41.59H65.869V39.28C65.869 38.39 65.869 37.75 65.939 37.06C65.6419 37.6923 65.308 38.3067 64.939 38.9L63.239 41.6H62.489V36.14H63.129Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M68.4591 36.14V38.44C68.4591 39.31 68.4591 40.02 68.3991 40.75C68.6791 40.18 69.0191 39.59 69.3991 38.94L71.2391 36.14H71.9491V41.6H71.2391V39.28C71.2391 38.39 71.2391 37.75 71.3091 37.06C71.0163 37.6945 70.6822 38.3092 70.3091 38.9L68.5991 41.61H67.8491V36.14H68.4591ZM69.349 35C69.349 35.25 69.469 35.5 69.849 35.5C70.229 35.5 70.3091 35.27 70.3391 35H70.8891C70.8891 35.53 70.5191 35.87 69.8291 35.87C69.705 35.8855 69.579 35.8746 69.4594 35.8381C69.3397 35.8017 69.2291 35.7404 69.1348 35.6583C69.0404 35.5762 68.9645 35.4752 68.9118 35.3617C68.8591 35.2483 68.8309 35.125 68.8291 35H69.349Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M78.789 36.14V41.6H78.079V36.73H75.549V41.6H74.8391V36.14H78.789Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M80.899 39.88L80.339 41.6H79.609L81.459 36.14H82.309L84.179 41.6H83.419L82.839 39.88H80.899ZM82.689 39.33L82.159 37.76C82.039 37.4 81.959 37.08 81.879 36.76C81.799 37.08 81.709 37.41 81.599 37.76L81.069 39.34L82.689 39.33Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.979 36.21C85.4249 36.1337 85.8767 36.0969 86.329 36.1C86.877 36.0572 87.4215 36.2174 87.859 36.55C88.0179 36.6953 88.1431 36.8734 88.226 37.072C88.3089 37.2706 88.3475 37.4849 88.3391 37.7C88.3487 37.9115 88.3166 38.1228 88.2446 38.3218C88.1725 38.5209 88.0619 38.7038 87.919 38.86C87.6927 39.0652 87.4272 39.2224 87.1385 39.3221C86.8497 39.4218 86.5437 39.4619 86.239 39.44C86.0497 39.462 85.8584 39.462 85.669 39.44V41.63H84.9691L84.979 36.21ZM85.679 38.84C85.8691 38.884 86.064 38.9042 86.259 38.9C87.109 38.9 87.6291 38.48 87.6291 37.73C87.6291 36.98 87.119 36.66 86.3391 36.66C86.1174 36.6509 85.8954 36.671 85.679 36.72V38.84Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M89.979 36.14V38.56H90.239L92.109 36.14H92.959L90.899 38.65C91.799 38.77 92.139 39.32 92.429 40.05C92.5936 40.5797 92.794 41.0976 93.029 41.6H92.239C92.0484 41.1809 91.8847 40.75 91.749 40.31C91.6864 39.9597 91.4988 39.644 91.2212 39.4215C90.9435 39.199 90.5945 39.0848 90.239 39.1H90.009V41.6H89.239V36.14H89.979Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }