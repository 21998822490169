<template>
  <g @click="$emit('click')">
    <circle
      cx="39"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.156 21.4069L30.2959 22.267C29.9784 22.5857 29.8 23.0172 29.8 23.467C29.8 23.9169 29.9784 24.3484 30.2959 24.667C30.6167 24.9806 31.0473 25.1564 31.4959 25.157C31.941 25.1539 32.3676 24.9782 32.6859 24.667L33.545 23.808C33.2834 23.7805 33.0292 23.6665 32.8287 23.466L32.6628 23.3001L32.0059 23.957C31.9422 24.0261 31.8649 24.0812 31.7789 24.119C31.6928 24.1567 31.5998 24.1761 31.5059 24.1761C31.4119 24.1761 31.319 24.1567 31.2329 24.119C31.1469 24.0812 31.0696 24.0261 31.0059 23.957C30.8871 23.8294 30.8211 23.6614 30.8211 23.487C30.8211 23.3126 30.8871 23.1447 31.0059 23.017L31.6777 22.3149L31.4994 22.1366C31.2954 21.9327 31.181 21.6732 31.156 21.4069Z"
      fill="#E73C14"
    />
    <path
      d="M33.6559 24.317C33.4343 24.3168 33.2149 24.2725 33.0106 24.1866C32.8063 24.1008 32.6211 23.9751 32.4659 23.817L31.1359 22.497C30.9792 22.3391 30.8553 22.1518 30.7712 21.9459C30.6872 21.7399 30.6445 21.5195 30.6459 21.297C30.6442 21.076 30.6867 20.857 30.7709 20.6526C30.855 20.4483 30.9791 20.2628 31.1359 20.107C31.4528 19.7901 31.8827 19.6121 32.3309 19.6121C32.7791 19.6121 33.2089 19.7901 33.5259 20.107L34.8559 21.427C35.0125 21.585 35.1364 21.7723 35.2205 21.9782C35.3046 22.1841 35.3472 22.4046 35.3459 22.627C35.3475 22.848 35.305 23.0671 35.2209 23.2715C35.1367 23.4758 35.0126 23.6613 34.8559 23.817C34.5379 24.1361 34.1063 24.3159 33.6559 24.317ZM32.3359 20.607C32.2449 20.6057 32.1545 20.6226 32.0702 20.6568C31.9859 20.691 31.9093 20.7418 31.845 20.8061C31.7806 20.8705 31.7298 20.9471 31.6956 21.0314C31.6615 21.1157 31.6445 21.206 31.6459 21.297C31.6448 21.3879 31.6619 21.4781 31.6962 21.5623C31.7306 21.6464 31.7815 21.7229 31.8459 21.787L33.1659 23.117C33.2988 23.2489 33.4786 23.3229 33.6659 23.3229C33.8532 23.3229 34.0329 23.2489 34.1659 23.117C34.2932 22.9856 34.3648 22.81 34.3659 22.627C34.367 22.5361 34.3498 22.4459 34.3155 22.3618C34.2811 22.2776 34.2303 22.2012 34.1659 22.137L32.8559 20.807C32.7158 20.6742 32.5288 20.6023 32.3359 20.607Z"
      fill="#E73C14"
    />
    <path
      d="M34.0159 21.4371C33.8862 21.434 33.7619 21.3843 33.6658 21.2971C33.619 21.2506 33.5818 21.1953 33.5564 21.1344C33.531 21.0734 33.5179 21.0081 33.5179 20.9421C33.5179 20.8761 33.531 20.8107 33.5564 20.7498C33.5818 20.6889 33.619 20.6336 33.6658 20.5871L47.1059 7.14709C47.1993 7.05548 47.325 7.00415 47.4559 7.00415C47.5867 7.00415 47.7124 7.05548 47.8058 7.14709C47.8527 7.19358 47.8899 7.24888 47.9153 7.30981C47.9407 7.37074 47.9538 7.43607 47.9538 7.50208C47.9538 7.56808 47.9407 7.63344 47.9153 7.69437C47.8899 7.7553 47.8527 7.8106 47.8058 7.85709L34.3759 21.2971C34.2772 21.3865 34.149 21.4364 34.0159 21.4371Z"
      fill="#E73C14"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.5276 23.7988L45.3958 24.6671C45.7141 24.9782 46.1407 25.1539 46.5858 25.1571C47.0344 25.1565 47.465 24.9806 47.7858 24.6671C48.1034 24.3484 48.2817 23.9169 48.2817 23.4671C48.2817 23.0172 48.1034 22.5857 47.7858 22.2671L46.9361 21.4237C46.9059 21.6801 46.7925 21.9286 46.5957 22.1253L46.4149 22.3061L47.0958 22.9871C47.1615 23.0527 47.2136 23.1307 47.2491 23.2165C47.2847 23.3023 47.3029 23.3942 47.3029 23.4871C47.3029 23.5799 47.2847 23.6719 47.2491 23.7577C47.2136 23.8435 47.1615 23.9214 47.0958 23.9871C47.0302 24.0527 46.9522 24.1048 46.8664 24.1404C46.7806 24.1759 46.6887 24.1942 46.5958 24.1942C46.503 24.1942 46.411 24.1759 46.3252 24.1404C46.2395 24.1048 46.1615 24.0527 46.0958 23.9871L45.4175 23.3036L45.2664 23.4547C45.0602 23.6609 44.7971 23.7756 44.5276 23.7988Z"
      fill="#E73C14"
    />
    <path
      d="M44.4259 24.3172C44.0923 24.3144 43.7669 24.2136 43.4902 24.0273C43.2135 23.8409 42.9978 23.5773 42.8698 23.2692C42.7419 22.9611 42.7075 22.6222 42.7708 22.2946C42.8341 21.9671 42.9923 21.6654 43.2259 21.4272L44.5459 20.1072C44.8645 19.7896 45.296 19.6113 45.7459 19.6113C46.1957 19.6113 46.6272 19.7896 46.9459 20.1072C47.1026 20.2629 47.2268 20.4484 47.3109 20.6528C47.395 20.8571 47.4375 21.0762 47.4359 21.2972C47.4372 21.5196 47.3946 21.7401 47.3105 21.946C47.2264 22.1519 47.1025 22.3393 46.9459 22.4972L45.6159 23.8172C45.4606 23.9753 45.2754 24.101 45.0711 24.1868C44.8668 24.2726 44.6475 24.317 44.4259 24.3172ZM45.7459 20.6072C45.5629 20.6082 45.3873 20.6799 45.2559 20.8072L43.9359 22.1372C43.8715 22.2013 43.8206 22.2778 43.7863 22.3619C43.7519 22.4461 43.7348 22.5363 43.7359 22.6272C43.7369 22.8102 43.8086 22.9857 43.9359 23.1172C44.0011 23.1837 44.0788 23.2365 44.1647 23.2726C44.2506 23.3087 44.3428 23.3273 44.4359 23.3273C44.529 23.3273 44.6212 23.3087 44.7071 23.2726C44.7929 23.2365 44.8707 23.1837 44.9359 23.1172L46.2659 21.7872C46.3303 21.723 46.3812 21.6466 46.4155 21.5624C46.4499 21.4783 46.467 21.3881 46.4659 21.2972C46.4659 21.114 46.3941 20.9381 46.2659 20.8072C46.1972 20.7405 46.1157 20.6884 46.0263 20.6541C45.937 20.6197 45.8416 20.6037 45.7459 20.6072Z"
      fill="#E73C14"
    />
    <path
      d="M44.0658 21.4371C43.9327 21.4364 43.8045 21.3865 43.7058 21.2971L30.2658 7.85706C30.1717 7.7629 30.1188 7.6352 30.1188 7.50204C30.1188 7.36889 30.1717 7.24122 30.2658 7.14706C30.36 7.05291 30.4877 7 30.6208 7C30.754 7 30.8817 7.05291 30.9758 7.14706L44.4158 20.5871C44.5081 20.6822 44.5597 20.8095 44.5597 20.942C44.5597 21.0746 44.5081 21.2019 44.4158 21.2971C44.3198 21.3843 44.1955 21.434 44.0658 21.4371Z"
      fill="#E73C14"
    />
    <path
      d="M2.98659 35.92V36.41C3.63637 36.41 4.25953 36.6682 4.71899 37.1276C5.17846 37.5871 5.43658 38.2103 5.43658 38.86C5.43658 39.5098 5.17846 40.133 4.71899 40.5925C4.25953 41.0519 3.63637 41.31 2.98659 41.31V41.82H2.30658V41.31C1.99303 41.3208 1.68074 41.2653 1.39011 41.1472C1.09947 41.029 0.83704 40.8508 0.619964 40.6243C0.402887 40.3978 0.23605 40.128 0.130355 39.8326C0.0246596 39.5372 -0.0175115 39.2228 0.00659076 38.91C-0.0152195 38.5921 0.0281446 38.273 0.134047 37.9724C0.23995 37.6718 0.406154 37.396 0.622451 37.1619C0.838748 36.9278 1.10059 36.7404 1.39191 36.6111C1.68323 36.4818 1.99789 36.4134 2.31659 36.41V35.92H2.98659ZM2.30658 36.92C2.07196 36.937 1.84332 37.0019 1.63478 37.1107C1.42624 37.2195 1.24223 37.37 1.09415 37.5528C0.946059 37.7355 0.837039 37.9467 0.773818 38.1733C0.710597 38.3999 0.694525 38.637 0.726592 38.8701C0.698915 39.1019 0.718165 39.3369 0.783202 39.5611C0.84824 39.7853 0.957722 39.9941 1.10515 40.1751C1.25257 40.3562 1.43492 40.5056 1.64133 40.6147C1.84773 40.7238 2.07397 40.7902 2.30658 40.81V36.92ZM2.98659 40.81C3.22077 40.7944 3.44922 40.7307 3.65773 40.623C3.86624 40.5152 4.05033 40.3657 4.19855 40.1837C4.34676 40.0017 4.45592 39.7912 4.51924 39.5652C4.58256 39.3392 4.59868 39.1026 4.56659 38.8701C4.56659 37.4601 3.76659 36.96 2.98659 36.92V40.81Z"
      fill="black"
    />
    <path
      d="M9.02649 39.04H6.8965V41.04H9.26649V41.63H6.15649V36.17H9.15649V36.73H6.90649V38.4601H9.0365L9.02649 39.04Z"
      fill="black"
    />
    <path
      d="M13.1566 41.6L12.4566 40.4C12.1666 39.94 11.9966 39.64 11.8266 39.32C11.6488 39.69 11.4519 40.0505 11.2366 40.4L10.5766 41.6H9.7666L11.4366 38.84L9.83659 36.14H10.6566L11.3766 37.42C11.5766 37.78 11.7266 38.06 11.8766 38.35C12.0366 38.02 12.1666 37.77 12.3766 37.42L13.1166 36.14H13.9266L12.2666 38.8L13.9666 41.6H13.1566Z"
      fill="black"
    />
    <path
      d="M15.9764 36.73H14.3164V36.13H18.3664V36.73H16.6964V41.59H15.9764V36.73Z"
      fill="black"
    />
    <path
      d="M23.3565 38.82C23.4004 39.1796 23.3675 39.5444 23.2599 39.8904C23.1523 40.2363 22.9725 40.5554 22.7324 40.8267C22.4923 41.0979 22.1974 41.3152 21.8672 41.464C21.5369 41.6128 21.1788 41.6899 20.8165 41.69C20.4684 41.6851 20.1252 41.6076 19.8088 41.4623C19.4925 41.3171 19.2099 41.1074 18.9793 40.8466C18.7486 40.5858 18.575 40.2797 18.4695 39.948C18.364 39.6162 18.3289 39.2661 18.3665 38.92C18.3276 38.5622 18.3636 38.2001 18.4724 37.8569C18.5812 37.5137 18.7604 37.197 18.9984 36.9269C19.2365 36.6569 19.5283 36.4394 19.8551 36.2885C20.1819 36.1375 20.5366 36.0563 20.8965 36.05C21.2455 36.0536 21.5898 36.1301 21.9074 36.2748C22.2249 36.4194 22.5087 36.6289 22.7404 36.8898C22.9722 37.1507 23.1467 37.4573 23.2528 37.7897C23.3589 38.1222 23.3942 38.4731 23.3565 38.82ZM19.1165 38.91C19.1165 40.07 19.7465 41.1201 20.8565 41.1201C21.9665 41.1201 22.6065 40.12 22.6065 38.85C22.6065 37.58 22.0365 36.63 20.8665 36.63C19.6965 36.63 19.1565 37.73 19.1565 38.91H19.1165Z"
      fill="black"
    />
    <path
      d="M24.2666 36.2201C24.6914 36.1362 25.1236 36.096 25.5566 36.1001C26.0883 36.0503 26.6203 36.1922 27.0566 36.5001C27.2096 36.6155 27.3311 36.7676 27.4097 36.9424C27.4884 37.1172 27.5217 37.309 27.5066 37.5001C27.4975 37.771 27.404 38.0324 27.239 38.2476C27.074 38.4628 26.8459 38.621 26.5866 38.7001C26.9016 38.7663 27.1845 38.9383 27.3883 39.1875C27.592 39.4367 27.7044 39.7482 27.7066 40.0701C27.7109 40.2739 27.6721 40.4763 27.5928 40.6641C27.5136 40.8519 27.3956 41.0209 27.2466 41.1601C26.7009 41.557 26.0279 41.7386 25.3566 41.6701C24.9924 41.6726 24.6284 41.6525 24.2666 41.6101V36.2201ZM24.9666 38.4501H25.6066C26.3566 38.4501 26.7966 38.0601 26.7966 37.5401C26.7966 37.0201 26.3066 36.6501 25.5966 36.6501C25.387 36.6325 25.1762 36.6325 24.9666 36.6501V38.4501ZM24.9666 41.0701C25.163 41.0831 25.3601 41.0831 25.5566 41.0701C26.2866 41.0701 26.9666 40.8001 26.9666 40.0101C26.9666 39.2201 26.3266 38.9601 25.5566 38.9601H24.9666V41.0701Z"
      fill="black"
    />
    <path
      d="M29.5366 39.89L28.9666 41.6H28.2366L30.0966 36.14H30.9466L32.8066 41.6H32.0566L31.4766 39.89H29.5366ZM31.3266 39.34L30.7966 37.76C30.6766 37.41 30.5966 37.08 30.5066 36.76C30.4266 37.08 30.3366 37.41 30.2266 37.76L29.6966 39.34H31.3266Z"
      fill="black"
    />
    <path
      d="M37.4766 36.1401V41.6001H36.7666V36.7301H34.9566V38.3901C34.9566 39.5701 34.9066 40.8901 34.1366 41.3901C33.8885 41.5504 33.6017 41.6402 33.3065 41.6501L33.2166 41.0801C33.4288 41.0354 33.6255 40.9353 33.7866 40.7901C34.2004 40.0734 34.3689 39.2413 34.2666 38.4201V36.1201L37.4766 36.1401Z"
      fill="black"
    />
    <path
      d="M39.4065 36.14V38.14C39.6295 38.124 39.8534 38.124 40.0765 38.14C41.1965 38.14 42.1765 38.64 42.1765 39.85C42.195 40.2769 42.044 40.6939 41.7565 41.01C41.4831 41.2453 41.1651 41.4233 40.8215 41.5332C40.4779 41.6432 40.1157 41.6829 39.7565 41.65C39.389 41.6525 39.0216 41.6324 38.6565 41.59V36.14H39.4065ZM39.4065 41.06C39.5994 41.0778 39.7936 41.0778 39.9865 41.06C40.7765 41.06 41.4465 40.68 41.4465 39.85C41.4465 39.02 40.7565 38.66 39.9765 38.66C39.7866 38.6497 39.5964 38.6497 39.4065 38.66V41.06Z"
      fill="black"
    />
    <path
      d="M43.7967 36.14V38.43H46.4367V36.14H47.1567V41.6H46.4467V39.04H43.8067V41.6H43.0967V36.14H43.7967Z"
      fill="black"
    />
    <path
      d="M49.0764 36.14V38.14C49.2895 38.1248 49.5034 38.1248 49.7164 38.14C50.8464 38.14 51.8264 38.64 51.8264 39.85C51.8449 40.2769 51.694 40.6939 51.4064 41.01C50.8686 41.4692 50.172 41.699 49.4664 41.65C49.0989 41.6525 48.7316 41.6324 48.3665 41.59V36.14H49.0764ZM49.0764 41.06C49.2626 41.0788 49.4502 41.0788 49.6364 41.06C50.4164 41.06 51.0864 40.66 51.0864 39.84C51.0864 39.02 50.3564 38.66 49.6364 38.66C49.45 38.6484 49.2629 38.6484 49.0764 38.66V41.06ZM53.1465 36.14V41.6H52.4364V36.14H53.1465Z"
      fill="black"
    />
    <path
      d="M55.0765 36.14V38.44C55.0765 39.31 55.0765 40.02 55.0065 40.75C55.307 40.1291 55.6408 39.5249 56.0065 38.94L57.7765 36.13H58.4765V41.59H57.8165V39.27C57.8165 38.39 57.8165 37.74 57.8765 37.05C57.5797 37.6825 57.2458 38.2969 56.8765 38.89L55.1565 41.6H54.3965V36.14H55.0765ZM55.9665 35C55.9665 35.25 56.0765 35.5 56.4565 35.5C56.8365 35.5 56.9165 35.27 56.9565 35H57.4965C57.4965 35.54 57.1265 35.87 56.4465 35.87C55.7665 35.87 55.4465 35.54 55.4465 35H55.9665Z"
      fill="black"
    />
    <path
      d="M62.1566 36.14V38.57H62.3766L64.2566 36.14H65.1066L63.0366 38.65C63.4056 38.6991 63.7506 38.8609 64.0244 39.1132C64.2982 39.3655 64.4875 39.6961 64.5666 40.06C64.7355 40.5849 64.9358 41.0992 65.1665 41.6H64.4066C64.2082 41.1864 64.0411 40.7585 63.9066 40.32C63.6566 39.59 63.3166 39.1 62.3866 39.1H62.1566V41.6H61.4666V36.14H62.1566Z"
      fill="black"
    />
    <path
      d="M69.7266 36.1401V41.6001H69.0166V36.7301H67.2066V38.3901C67.2066 39.5701 67.1565 40.8901 66.3866 41.3901C66.1385 41.5504 65.8517 41.6402 65.5565 41.6501L65.4666 41.0801C65.6788 41.0354 65.8755 40.9353 66.0366 40.7901C66.4504 40.0734 66.6189 39.2413 66.5166 38.4201V36.1201L69.7266 36.1401Z"
      fill="black"
    />
    <path
      d="M71.1567 36.14L72.2467 38.47C72.3867 38.79 72.5167 39.11 72.6467 39.47C72.7467 39.19 72.8467 38.87 72.9867 38.47L73.8367 36.17H74.5867L73.4467 38.98C73.19 39.7045 72.8547 40.3987 72.4467 41.05C72.3039 41.2605 72.1116 41.4326 71.8866 41.5512C71.6617 41.6698 71.411 41.7312 71.1567 41.73C71.0279 41.7556 70.8954 41.7556 70.7667 41.73L70.8367 41.16C70.9229 41.1726 71.0105 41.1726 71.0967 41.16C71.6667 41.16 71.9967 40.61 72.1667 40.22C72.1956 40.1698 72.2109 40.113 72.2109 40.055C72.2109 39.9971 72.1956 39.9402 72.1667 39.89L70.3467 36.21L71.1567 36.14Z"
      fill="black"
    />
    <path
      d="M78.2265 36.14V36.73H75.8665V38.2C76.0365 38.2 76.3665 38.2 76.5465 38.2C77.0411 38.1845 77.5299 38.3093 77.9565 38.56C78.1788 38.7033 78.3596 38.9026 78.4807 39.1378C78.6017 39.3729 78.659 39.6358 78.6465 39.9C78.654 40.1183 78.6181 40.3359 78.5408 40.5401C78.4636 40.7444 78.3465 40.9313 78.1965 41.09C77.6498 41.5224 76.962 41.7363 76.2665 41.69C75.899 41.6927 75.5316 41.6727 75.1665 41.63V36.18L78.2265 36.14ZM75.8665 41.06C76.0661 41.0777 76.2669 41.0777 76.4665 41.06C76.8899 41.0747 77.3023 40.9241 77.6165 40.64C77.8164 40.4223 77.924 40.1355 77.9165 39.84C77.9259 39.6652 77.8893 39.4911 77.8104 39.3349C77.7314 39.1787 77.6128 39.046 77.4665 38.95C77.1684 38.7594 76.8203 38.6619 76.4665 38.67H75.9065L75.8665 41.06Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconFenceClub",
};
</script>

<style scoped lang="scss"></style>
