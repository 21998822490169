<template>
  <map-layout style="width: 100%; height: 100%; max-height: 800px">
    <map-icon-main-street transform="translate(641,307)" />

    <svg-arrow-hint
      ref="hint"
      hint-path-clone="hintPathCloneFamily"
      hint-arrow="hintArrowFamily"
      hint-path="hintPathFamily"
    />

    <MapIconMetroKuncovo
      class="map-icon__family"
      transform="translate(546,228)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 322],
            [589, 293],
            [575, 266],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovo
      class="map-icon__family"
      transform="translate(490,268)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 302],
            [589, 293],
            [530, 279],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoMcd
      class="map-icon__family"
      transform="translate(619,378)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 330],
            [770, 342],
            [710, 473],
            [648, 420],
          ],
          hint: '6 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoBkl
      class="map-icon__family"
      transform="translate(529,298)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [585, 282],
            [566, 288],
            [560, 295],
          ],
          hint: '7 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroScolkovo
      class="map-icon__family"
      transform="translate(126,505)"
      @click="
        $refs.hint.animate({
          points: [
            [645, 340],
            [595, 392],
            [490, 543],
            [174, 515],
          ],
          hint: '10 минут',
          reverse: true,
          dy: -5,
          electric: true,
        })
      "
    />

    <MapIconClinic
      class="map-icon__family"
      transform="translate(549,378)"
      @click="
        $refs.hint.animate({
          points: [
            [684, 330],
            [750, 432],
            [668, 473],
            [596, 405],
          ],
          hint: '10 минут',
          manLeft: true,
          reverse: true,
        })
      "
    />

    <MapIconKindergartenSmile
      class="map-icon__family"
      transform="translate(709,444)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 350],
            [700, 359],
            [715, 390],
            [725, 435],
          ],
          hint: '12 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconSchoolKolmogorova
      class="map-icon__family"
      transform="translate(631,490)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [699, 379],
            [699, 480],
            [689, 485],
          ],
          hint: '13 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconFenceClub
      class="map-icon__family"
      transform="translate(407,330)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 362],
            [576, 398],
            [470, 345],
          ],
          hint: '6 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconMuseumWin
      class="map-icon__family"
      transform="translate(1217,229)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 329],
            [915, 330],
            [1220, 246],
          ],
          hint: '20 минут',
          car: true,
        })
      "
    />

    <MapIconFamalyRestor
      class="map-icon__family"
      transform="translate(1110,172)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [855, 222],
            [1066, 180],
            [1110, 188],
          ],
          hint: '20 минут',
          car: true,
        })
      "
    />

    <MapIconMusSchool
      class="map-icon__family"
      transform="translate(1159,133)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [825, 232],
            [1066, 180],
            [1180, 150],
          ],
          hint: '24 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconOlimp
      class="map-icon__family"
      transform="translate(615,158)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 316],
            [664, 232],
            [663, 243],
            [663, 220],
          ],
          hint: '15 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconShcoolWater
      class="map-icon__family"
      transform="translate(735,149)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 310],
            [714, 262],
            [703, 273],
            [760, 210],
          ],
          hint: '5 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconSchool
      class="map-icon__family"
      transform="translate(820,195)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 310],
            [714, 289],
            [723, 283],
            [823, 240],
          ],
          hint: '10 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconMusScool
      class="map-icon__family"
      transform="translate(815,105)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [744, 330],
            [943, 283],
            [867, 153],
          ],
          hint: '10 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconFootballScholl
      class="map-icon__family"
      transform="translate(417,270)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 362],
            [556, 408],
            [470, 325],
          ],
          hint: '6 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconSunScholl
      class="map-icon__family"
      transform="translate(416,215)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [645, 299],
            [466, 240],
            [465, 240],
          ],
          hint: '8 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconZoo
      class="map-icon__family"
      transform="translate(304,240)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [615, 332],
            [476, 508],
            [335, 295],
          ],
          hint: '12 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconEnglishExem
      class="map-icon__family"
      transform="translate(394,90)"
      @click="
        $refs.hint.animate({
          points: [
            [656, 310],
            [625, 242],
            [536, 168],
            [450, 115],
          ],
          hint: '14 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />
  </map-layout>
</template>

<script>
import MapLayout from "@/components/elements/map/MapLayout";
import MapIconMainStreet from "@/components/elements/map/icons/MapIconMainStreet";
import SvgArrowHint from "@/components/elements/map/SVGArrowHint";
import MapIconMetroKuncovo from "@/components/elements/map/icons/MapIconMetroKuncovoFamily";
import MapIconMetroKuncovoMcd from "@/components/elements/map/icons/MapIconMetroKuncovoMcd";
import MapIconMetroScolkovo from "@/components/elements/map/icons/MapIconMetroScolkovo";
import MapIconClinic from "@/components/elements/map/icons/MapIconClinic";
import MapIconKindergartenSmile from "@/components/elements/map/icons/MapIconKindergartenSmile";
import MapIconSchoolKolmogorova from "@/components/elements/map/icons/MapIconSchoolKolmogorova";
import MapIconFenceClub from "@/components/elements/map/icons/MapIconFenceClub";
import MapIconMuseumWin from "@/components/elements/map/icons/MapIconMuseumWin";
import MapIconFamalyRestor from "@/components/elements/map/icons/MapIconFamalyRestor";
import MapIconMusSchool from "@/components/elements/map/icons/MapIconMusSchool";
import MapIconOlimp from "@/components/elements/map/icons/MapIconOlimp";
import MapIconShcoolWater from "@/components/elements/map/icons/MapIconShcoolWater";
import MapIconSchool from "@/components/elements/map/icons/MapIconSchool";
import MapIconMusScool from "@/components/elements/map/icons/MapIconMusScool";
import MapIconFootballScholl from "@/components/elements/map/icons/MapIconFootballScholl";
import MapIconSunScholl from "@/components/elements/map/icons/MapIconSunScholl";
import MapIconZoo from "@/components/elements/map/icons/MapIconZoo";
import MapIconEnglishExem from "@/components/elements/map/icons/MapIconEnglishExem";
import MapIconMetroKuncovoBkl from "@/components/elements/map/icons/MapIconMetroKuncovoBkl";

export default {
  name: "PointFamily",
  components: {
    SvgArrowHint,
    MapIconMainStreet,
    MapLayout,
    MapIconMetroKuncovo,
    MapIconMetroKuncovoMcd,
    MapIconMetroScolkovo,
    MapIconClinic,
    MapIconKindergartenSmile,
    MapIconSchoolKolmogorova,
    MapIconFenceClub,
    MapIconMuseumWin,
    MapIconFamalyRestor,
    MapIconMusSchool,
    MapIconOlimp,
    MapIconShcoolWater,
    MapIconSchool,
    MapIconMusScool,
    MapIconFootballScholl,
    MapIconSunScholl,
    MapIconZoo,
    MapIconEnglishExem,
    MapIconMetroKuncovoBkl,
  },
  data: () => ({
    animateStartFamily: false,
    points: [
      {
        points: [
          [646, 330],
          [599, 322],
          [589, 293],
          [575, 266],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [599, 302],
          [589, 293],
          [530, 279],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [686, 330],
          [770, 342],
          [710, 473],
          [648, 420],
        ],
        hint: "6 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [585, 282],
          [566, 288],
          [560, 295],
        ],
        hint: "7 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [645, 340],
          [595, 392],
          [490, 543],
          [174, 515],
        ],
        hint: "10 минут",
        reverse: true,
        dy: -5,
        electric: true,
      },
      {
        points: [
          [684, 330],
          [750, 432],
          [668, 473],
          [596, 405],
        ],
        hint: "10 минут",
        manLeft: true,
        reverse: true,
      },
      {
        points: [
          [690, 350],
          [700, 359],
          [715, 390],
          [725, 435],
        ],
        hint: "12 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [699, 379],
          [699, 480],
          [689, 485],
        ],
        hint: "13 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 362],
          [576, 398],
          [470, 345],
        ],
        hint: "6 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [750, 329],
          [915, 330],
          [1220, 246],
        ],
        hint: "20 минут",
        car: true,
      },
      {
        points: [
          [690, 330],
          [855, 222],
          [1066, 180],
          [1110, 188],
        ],
        hint: "20 минут",
        car: true,
      },
      {
        points: [
          [690, 330],
          [825, 232],
          [1066, 180],
          [1180, 150],
        ],
        hint: "24 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 316],
          [664, 232],
          [663, 243],
          [663, 220],
        ],
        hint: "15 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 310],
          [714, 262],
          [703, 273],
          [760, 210],
        ],
        hint: "5 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 310],
          [714, 289],
          [723, 283],
          [823, 240],
        ],
        hint: "10 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [744, 330],
          [943, 283],
          [867, 153],
        ],
        hint: "10 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 362],
          [556, 408],
          [470, 325],
        ],
        hint: "6 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [645, 299],
          [466, 240],
          [465, 240],
        ],
        hint: "8 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [615, 332],
          [476, 508],
          [335, 295],
        ],
        hint: "14 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [656, 310],
          [625, 242],
          [536, 168],
          [450, 115],
        ],
        hint: "14 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
    ],
  }),
  mounted() {
    this.animateFamily(this.points);
  },
  methods: {
    animateFamily(points = []) {
      const pointsToAnimate = [...points];
      const _this = this;
      const intervalID = setInterval(() => {
        const point = pointsToAnimate.pop();
        if (point === undefined) {
          clearInterval(intervalID);
          _this.animateFamily(_this.points);
        } else if (_this.animateStartFamily === true) {
          clearInterval(intervalID);
          setTimeout(() => {
            _this.animateStartFamily = false;
            _this.animateFamily(_this.points);
          }, 2000);
        } else {
          _this.$refs.hint.animate(point);
        }
      }, 4000);

      var elem = document.querySelectorAll(".map-icon__family");

      elem.forEach(function (item, i, arr) {
        item.addEventListener(
          "click",
          () => {
            _this.animateStartFamily = true;
          },
          { once: true }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
