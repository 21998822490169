<template>
  <g @click="$emit('click')">
    <g clip-path="url(#clip8)">
      <circle
        cx="28.63"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M36.36 22.36H33.5V13.44H36.36V22.36ZM34.25 21.61H35.61V14.19H34.25V21.61Z"
        fill="#E73C14"
      />
      <path
        d="M37.51 14.19H32.23V12.06H37.51V14.19ZM32.98 13.44H36.76V12.85H32.98V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M37.51 23.7H32.23V21.61H37.51V23.7ZM32.98 22.95H36.76V22.36H32.98V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M30.12 22.36H27.26V13.44H30.12V22.36ZM28.01 21.61H29.37V14.19H28.02L28.01 21.61Z"
        fill="#E73C14"
      />
      <path
        d="M31.28 14.19H25.99V12.06H31.28V14.19ZM26.74 13.44H30.53V12.85H26.74V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M31.28 23.7H25.99V21.61H31.28V23.7ZM26.74 22.95H30.53V22.36H26.74V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M23.89 22.36H21.0401V13.44H23.89V22.36ZM21.7901 21.61H23.12V14.19H21.77L21.7901 21.61Z"
        fill="#E73C14"
      />
      <path
        d="M25.04 14.19H19.76V12.06H25.04V14.19ZM20.51 13.44H24.29V12.85H20.51V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M25.04 23.7H19.76V21.61H25.04V23.7ZM20.51 22.95H24.29V22.36H20.51V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M38.04 25.26H19.23V22.95H38.04V25.26ZM19.98 24.51H37.29V23.7H19.98V24.51Z"
        fill="#E73C14"
      />
      <path
        d="M38.04 12.85H19.23V10.53H38.04V12.85ZM19.98 12.06H37.29V11.24H19.98V12.06Z"
        fill="#E73C14"
      />
      <path
        d="M37.61 11.28H19.74L28.83 6L37.61 11.28ZM22.53 10.53H34.91L28.82 6.88L22.53 10.53Z"
        fill="#E73C14"
      />
    </g>
    <path
      d="M5.73999 39.2C5.73999 38.44 5.64997 37.52 5.65997 36.84C5.46997 37.48 5.23997 38.16 4.96997 38.92L3.96997 41.57H3.44L2.56 38.97C2.3 38.2 2.07998 37.49 1.91998 36.84C1.91998 37.52 1.86 38.44 1.81 39.26L1.66998 41.6H0.98999L1.38 36.14H2.25L3.19 38.78C3.41 39.45 3.59999 40.05 3.73999 40.62C3.87999 40.07 4.07001 39.47 4.32001 38.78L5.32001 36.14H6.21997L6.56 41.6H5.87L5.73999 39.2Z"
      fill="black"
    />
    <path
      d="M7.82001 36.14L8.90997 38.46L9.29999 39.46C9.39999 39.17 9.50001 38.86 9.64001 38.46L10.49 36.16H11.25L10.1 38.96C9.84327 39.6876 9.50795 40.3851 9.09998 41.04C8.96153 41.2423 8.77683 41.4087 8.56116 41.5252C8.34548 41.6417 8.10508 41.7051 7.85999 41.71C7.72818 41.7137 7.59659 41.6968 7.46997 41.66L7.53998 41.08C7.62612 41.0935 7.71385 41.0935 7.79999 41.08C8.36999 41.08 8.7 40.53 8.87 40.14C8.90107 40.0906 8.91754 40.0334 8.91754 39.975C8.91754 39.9166 8.90107 39.8594 8.87 39.81L7.06 36.13L7.82001 36.14Z"
      fill="black"
    />
    <path
      d="M11.72 36.46C12.1496 36.1857 12.6502 36.0431 13.16 36.05C14.16 36.05 14.76 36.54 14.76 37.39C14.7557 37.7059 14.6415 38.0104 14.4371 38.2512C14.2328 38.4921 13.9509 38.6543 13.64 38.71C13.9972 38.7179 14.3369 38.866 14.5858 39.1224C14.8347 39.3787 14.9727 39.7228 14.97 40.08C14.97 41.21 13.97 41.67 12.97 41.67C12.4698 41.679 11.9763 41.5547 11.54 41.31L11.73 40.76C12.1041 40.9668 12.5227 41.08 12.95 41.09C13.76 41.09 14.21 40.66 14.21 40.09C14.21 39.27 13.42 39.03 12.73 39.03H12.4V38.5H12.73C13.0318 38.5341 13.3349 38.4484 13.5742 38.2615C13.8135 38.0745 13.97 37.8011 14.01 37.5C14.01 36.95 13.62 36.64 13.01 36.64C12.6041 36.6525 12.2095 36.7771 11.87 37L11.72 36.46Z"
      fill="black"
    />
    <path
      d="M18.74 39.04H16.62V41.04H18.98V41.63H15.91V36.17H18.91V36.76H16.67V38.49H18.79L18.74 39.04Z"
      fill="black"
    />
    <path
      d="M20.56 36.14V38.44C20.56 39.31 20.56 40.02 20.5 40.75C20.78 40.18 21.12 39.59 21.5 38.94L23.26 36.13H23.97V41.59H23.3V39.26C23.3 38.38 23.3 37.73 23.37 37.04C23.0772 37.6745 22.7432 38.2892 22.37 38.88L20.66 41.59H19.91V36.13L20.56 36.14ZM21.45 35C21.45 35.25 21.57 35.5 21.95 35.5C22.33 35.5 22.41 35.26 22.44 35H22.98C22.98 35.53 22.62 35.86 21.93 35.86C21.8067 35.8754 21.6816 35.8648 21.5626 35.8289C21.4437 35.793 21.3336 35.7327 21.2394 35.6516C21.1452 35.5706 21.069 35.4708 21.0157 35.3586C20.9624 35.2463 20.9332 35.1242 20.93 35H21.45Z"
      fill="black"
    />
    <path
      d="M30.89 36.14V41.6H30.18V36.73H27.65V41.6H26.94V36.14H30.89Z"
      fill="black"
    />
    <path
      d="M36.79 38.81C36.8337 39.1701 36.8008 39.5354 36.6933 39.8818C36.5858 40.2282 36.4062 40.548 36.1663 40.82C35.9263 41.092 35.6315 41.3102 35.3013 41.4602C34.971 41.6101 34.6127 41.6885 34.25 41.69C33.9015 41.685 33.5579 41.6074 33.2411 41.4623C32.9243 41.3171 32.6411 41.1076 32.4097 40.847C32.1783 40.5864 32.0037 40.2805 31.897 39.9488C31.7903 39.617 31.7538 39.2667 31.79 38.92C31.7509 38.5613 31.7873 38.1984 31.8967 37.8546C32.0061 37.5107 32.1862 37.1935 32.4253 36.9233C32.6644 36.6531 32.9574 36.4358 33.2854 36.2855C33.6134 36.1351 33.9692 36.0549 34.33 36.05C34.6785 36.0522 35.0227 36.1274 35.3402 36.271C35.6578 36.4145 35.9417 36.6231 36.1735 36.8832C36.4054 37.1434 36.5801 37.4493 36.6863 37.7812C36.7925 38.1131 36.8278 38.4636 36.79 38.81ZM32.55 38.9C32.55 40.07 33.18 41.11 34.29 41.11C35.4 41.11 36.04 40.11 36.04 38.84C36.04 37.76 35.47 36.62 34.3 36.62C33.13 36.62 32.55 37.7 32.55 38.9Z"
      fill="black"
    />
    <path
      d="M40.76 36.14V36.73H38.4V38.2C38.6264 38.1848 38.8536 38.1848 39.08 38.2C39.5744 38.1861 40.0628 38.3108 40.49 38.56C40.7123 38.7034 40.8931 38.9026 41.0142 39.1378C41.1353 39.3729 41.1924 39.6358 41.18 39.9C41.1884 40.1169 41.1528 40.3332 41.0755 40.536C40.9982 40.7388 40.8806 40.9238 40.73 41.08C40.1862 41.5187 39.4971 41.7364 38.8 41.69C38.4321 41.6956 38.0643 41.6722 37.7 41.62V36.18L40.76 36.14ZM38.4 41.06C38.5963 41.0778 38.7937 41.0778 38.99 41.06C39.4138 41.078 39.8275 40.9269 40.14 40.64C40.3424 40.4198 40.4501 40.1289 40.44 39.83C40.4505 39.6562 40.4154 39.4827 40.3383 39.3266C40.2611 39.1705 40.1445 39.0372 40 38.94C39.6985 38.7582 39.352 38.6646 39 38.67C38.8135 38.6606 38.6265 38.6606 38.44 38.67L38.4 41.06Z"
      fill="black"
    />
    <path
      d="M44.92 39.04H42.8V41.04H45.17V41.63H42.1V36.17H45.04V36.76H42.8V38.49H44.92V39.04Z"
      fill="black"
    />
    <path
      d="M49.98 36.1401V40.9801H50.46L50.41 42.8501H49.86L49.81 41.5401H46.36L46.31 42.8501H45.76V40.9801H46.18C46.3966 40.599 46.5742 40.1969 46.71 39.7801C46.9077 39.0301 46.9953 38.2553 46.97 37.4801V36.1001L49.98 36.1401ZM47.58 37.7501C47.6025 38.5034 47.508 39.2557 47.3 39.9801C47.1868 40.3272 47.0394 40.6622 46.86 40.9801H49.25V36.6801H47.56L47.58 37.7501Z"
      fill="black"
    />
    <path
      d="M52.05 36.14V38.14C52.21 38.14 52.52 38.14 52.7 38.14C53.82 38.14 54.8 38.63 54.8 39.85C54.8142 40.2763 54.6638 40.6917 54.38 41.01C53.8432 41.4711 53.1458 41.7012 52.44 41.65C52.0721 41.6554 51.7043 41.632 51.34 41.58V36.14H52.05ZM52.05 41.06C52.2395 41.0791 52.4305 41.0791 52.62 41.06C53.39 41.06 54.07 40.66 54.07 39.83C54.07 39 53.34 38.66 52.61 38.66C52.4237 38.6432 52.2363 38.6432 52.05 38.66V41.06ZM56.12 36.14V41.6H55.41V36.14H56.12Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip8">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(12.63)"
        />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconMuseumWin",
};
</script>

<style scoped lang="scss"></style>
