var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "49",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.9199 11.31H40.0699L49.1599 6L57.9199 11.31ZM42.8399 10.56H55.2199L49.1299 6.9L42.8399 10.56Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.3399 24.6199H39.3999V24.2499C39.3999 23.2704 39.7883 22.3308 40.4801 21.6372C41.1718 20.9436 42.1103 20.5526 43.0899 20.5499H45.6399C46.6212 20.5499 47.5623 20.9397 48.2562 21.6336C48.9501 22.3275 49.3399 23.2686 49.3399 24.2499V24.6199ZM40.1699 23.8699H48.5599C48.4698 23.1594 48.1233 22.5062 47.5857 22.033C47.048 21.5598 46.3561 21.2991 45.6399 21.2999H43.0899C42.3772 21.3116 41.692 21.5766 41.1569 22.0475C40.6219 22.5185 40.272 23.1645 40.1699 23.8699Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.3399 24.2499L48.5899 24.2499V15.4999C48.5745 14.7233 48.2584 13.9829 47.7082 13.4346C47.158 12.8863 46.4166 12.5727 45.6399 12.5599H43.0899C42.3102 12.5599 41.5624 12.8697 41.011 13.421C40.4597 13.9724 40.1499 14.7202 40.1499 15.4999V24.2499L39.3999 24.2499L39.3999 15.4999C39.3999 14.5213 39.7887 13.5827 40.4807 12.8907C41.1727 12.1987 42.1113 11.8099 43.0899 11.8099H45.6399C46.6195 11.8099 47.5591 12.1984 48.2527 12.8901C48.9463 13.5818 49.3373 14.5204 49.3399 15.4999V24.2499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.5299 24.6199H48.5899V24.2499C48.5899 23.2704 48.9784 22.3308 49.6701 21.6372C50.3618 20.9436 51.3003 20.5526 52.2799 20.5499H54.8399C55.8195 20.5526 56.758 20.9436 57.4497 21.6372C58.1415 22.3308 58.5299 23.2704 58.5299 24.2499V24.6199ZM49.3599 23.8699H57.7599C57.6578 23.1645 57.3079 22.5185 56.7729 22.0475C56.2378 21.5766 55.5526 21.3116 54.8399 21.2999H52.2799C51.5672 21.3116 50.882 21.5766 50.3469 22.0475C49.8119 22.5185 49.462 23.1645 49.3599 23.8699Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.5299 24.2499L57.7799 24.2499V15.4999C57.7799 14.7202 57.4702 13.9724 56.9188 13.421C56.3675 12.8697 55.6196 12.5599 54.8399 12.5599H52.2799C51.5002 12.5599 50.7524 12.8697 50.201 13.421C49.6497 13.9724 49.3399 14.7202 49.3399 15.4999V24.2499L48.5899 24.2499V15.4999C48.5899 14.5213 48.9787 13.5827 49.6707 12.8907C50.3627 12.1987 51.3013 11.8099 52.2799 11.8099H54.8399C55.8186 11.8099 56.7571 12.1987 57.4491 12.8907C58.1411 13.5827 58.5299 14.5213 58.5299 15.4999V24.2499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.770004 37.14L1.84999 39.46C1.98999 39.79 2.12 40.1 2.25 40.46C2.34 40.17 2.45 39.86 2.59 39.46L3.44 37.16H4.17999L3.06 39.94C2.80329 40.6676 2.46797 41.3651 2.06 42.02C1.92039 42.2212 1.73539 42.3866 1.51997 42.503C1.30455 42.6194 1.06476 42.6835 0.819992 42.69C0.687457 42.7107 0.552533 42.7107 0.419998 42.69L0.48999 42.11C0.576132 42.1235 0.663859 42.1235 0.75 42.11C1.32 42.11 1.64999 41.56 1.81999 41.17C1.84896 41.1198 1.86421 41.0629 1.86421 41.005C1.86421 40.9471 1.84896 40.8902 1.81999 40.84L0 37.16L0.770004 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.44975 37.14V38.87C5.41961 39.0293 5.42867 39.1936 5.47613 39.3486C5.52359 39.5036 5.60804 39.6448 5.7222 39.7599C5.83635 39.8751 5.97679 39.9607 6.13141 40.0095C6.28603 40.0583 6.45019 40.0688 6.60975 40.04C6.98782 40.0349 7.35749 39.9278 7.67975 39.73V37.14H8.39975V42.57H7.67975V40.28C7.28004 40.5053 6.82854 40.6224 6.36975 40.62C6.14975 40.6424 5.92755 40.6151 5.71946 40.5403C5.51138 40.4655 5.32271 40.345 5.16731 40.1877C5.01191 40.0303 4.89374 39.8402 4.82148 39.6312C4.74922 39.4222 4.72472 39.1997 4.74975 38.98V37.14H5.44975Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.2799 37.14V39.44C10.2799 40.31 10.2799 41.02 10.2199 41.75C10.4999 41.18 10.8399 40.59 11.2199 39.94L13.0599 37.14H13.7699V42.57H13.0599V40.27C13.0599 39.39 13.0599 38.74 13.1299 38.05C12.8371 38.6845 12.503 39.2991 12.1299 39.89L10.3699 42.57H9.61987V37.11L10.2799 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.5598 37.14V42.57H17.8498V37.7H16.0598V39.35C16.0598 40.54 16.0598 41.86 15.2398 42.35C14.9918 42.5102 14.7049 42.6001 14.4098 42.61L14.3198 42.05C14.5321 42.0052 14.7287 41.9052 14.8898 41.76C15.3022 41.0428 15.4707 40.2111 15.3698 39.39V37.09L18.5598 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.4498 37.14V39.44C20.4498 40.31 20.4498 41.02 20.3798 41.75C20.6803 41.1291 21.0142 40.5249 21.3798 39.94L23.1498 37.13H23.8498V42.57H23.1898V40.27C23.1898 39.39 23.1898 38.74 23.2498 38.05C22.953 38.6825 22.6191 39.2969 22.2498 39.89L20.5398 42.57H19.7798V37.11L20.4498 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.8097 37.14V42.01H27.5797V37.14H28.2797V42.01H30.0597V37.14H30.7697V42.02H31.2497V43.89H30.6897V42.58H25.2197V37.12L25.8097 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.9399 40.04H32.8099V42.04H35.1799V42.57H32.1099V37.11H35.0599V37.7H32.8099V39.43H34.9399V40.04Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.4798 39.81C42.5235 40.1701 42.4906 40.5354 42.3831 40.8818C42.2756 41.2282 42.096 41.548 41.856 41.82C41.6161 42.092 41.3213 42.3102 40.9911 42.4602C40.6608 42.6101 40.3025 42.6885 39.9398 42.69C39.5917 42.6851 39.2485 42.6076 38.9321 42.4623C38.6157 42.3171 38.3332 42.1074 38.1025 41.8466C37.8719 41.5858 37.6982 41.2798 37.5927 40.948C37.4872 40.6163 37.4522 40.2661 37.4898 39.92C37.4505 39.5588 37.4877 39.1934 37.5989 38.8475C37.71 38.5016 37.8928 38.1829 38.1351 37.9123C38.3775 37.6416 38.6741 37.4249 39.0057 37.2764C39.3373 37.1278 39.6965 37.0507 40.0598 37.05C40.4049 37.0577 40.7446 37.137 41.0574 37.283C41.3702 37.4289 41.6492 37.6383 41.8768 37.8978C42.1043 38.1574 42.2755 38.4614 42.3793 38.7906C42.4831 39.1198 42.5173 39.4669 42.4798 39.81ZM38.2398 39.9C38.2398 41.07 38.8698 42.11 39.9798 42.11C41.0898 42.11 41.7298 41.11 41.7298 39.84C41.7298 38.76 41.1598 37.62 39.9898 37.62C38.8198 37.62 38.2398 38.7 38.2398 39.9Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46.9497 37.14V42.57H46.2297V37.7H44.4297V39.35C44.4297 40.54 44.3797 41.86 43.6097 42.35C43.3586 42.5115 43.0681 42.6014 42.7697 42.61L42.6797 42.05C42.8949 42.0051 43.0947 41.9052 43.2597 41.76C43.6721 41.0428 43.8405 40.2111 43.7397 39.39V37.09L46.9497 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.8299 37.14V39.44C48.8299 40.31 48.8299 41.02 48.7699 41.75C49.0499 41.18 49.3899 40.59 49.7699 39.94L51.5299 37.13H52.2399V42.57H51.5699V40.27C51.5699 39.39 51.5699 38.74 51.6399 38.05C51.3471 38.6845 51.0131 39.2991 50.6399 39.89L48.9199 42.57H48.1699V37.11L48.8299 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.0599 40.2C58.0599 39.44 57.9699 38.52 57.9799 37.84C57.7999 38.48 57.5699 39.16 57.2899 39.92L56.2899 42.57H55.7599L54.8699 39.97C54.6099 39.2 54.3899 38.49 54.2399 37.84C54.2399 38.52 54.1899 39.44 54.1399 40.26L54.0599 42.57H53.3899L53.7699 37.11H54.6699L55.5999 39.75C55.8153 40.3533 55.9989 40.9674 56.1499 41.59C56.2899 41.04 56.4799 40.44 56.7199 39.75L57.7199 37.11H58.6099L58.9199 42.57H58.2399L58.0599 40.2Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M63.9498 37.14V42.57H63.2498V37.7H60.7098V42.57H60.0598V37.11L63.9498 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.8497 37.14V39.44C65.8497 40.31 65.8497 41.02 65.7797 41.75C66.0802 41.1291 66.4141 40.5249 66.7797 39.94L68.5497 37.13H69.2497V42.57H68.5897V40.27C68.5897 39.39 68.5897 38.74 68.6497 38.05C68.3529 38.6825 68.019 39.2969 67.6497 39.89L65.9397 42.57H65.1797V37.11L65.8497 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.1797 37.14V39.44C71.1797 40.31 71.1797 41.02 71.1097 41.75C71.4103 41.1291 71.7441 40.5249 72.1097 39.94L73.8798 37.13H74.5797V42.57H73.9197V40.27C73.9197 39.39 73.9198 38.74 73.9798 38.05C73.683 38.6825 73.3491 39.2969 72.9798 39.89L71.2697 42.57H70.5098V37.11L71.1797 37.14ZM72.0698 36C72.0698 36.25 72.1798 36.5 72.5598 36.5C72.9398 36.5 73.0198 36.26 73.0598 36H73.5998C73.5998 36.53 73.2297 36.86 72.5497 36.86C71.8697 36.86 71.5497 36.53 71.5497 36H72.0698Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M79.6598 42.4201C79.2035 42.6072 78.7128 42.6958 78.2198 42.6801C77.8541 42.7029 77.4878 42.6467 77.1458 42.5152C76.8038 42.3838 76.4942 42.1802 76.2379 41.9182C75.9817 41.6563 75.7849 41.3423 75.661 40.9975C75.5371 40.6526 75.4889 40.2852 75.5198 39.9201C75.4983 39.5393 75.5572 39.1582 75.6925 38.8016C75.8279 38.445 76.0367 38.1208 76.3055 37.8502C76.5742 37.5795 76.8969 37.3684 77.2525 37.2306C77.6082 37.0927 77.9889 37.0312 78.3698 37.0501C78.8158 37.0303 79.2603 37.1124 79.6698 37.2901L79.4998 37.8701C79.1514 37.7124 78.7723 37.6338 78.3898 37.6401C78.0941 37.6184 77.7972 37.6627 77.5206 37.7699C77.2441 37.8771 76.9949 38.0444 76.791 38.2598C76.5872 38.4751 76.4337 38.7331 76.3419 39.0151C76.25 39.2971 76.222 39.5959 76.2598 39.8901C76.2305 40.1777 76.2638 40.4682 76.3576 40.7416C76.4513 41.0151 76.6032 41.265 76.8028 41.4741C77.0024 41.6832 77.2449 41.8465 77.5137 41.9529C77.7825 42.0592 78.0712 42.106 78.3598 42.0901C78.7552 42.0964 79.1473 42.0179 79.5098 41.8601L79.6598 42.4201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M81.2398 37.14V39.57H81.4498L83.3298 37.15H84.1798L82.1098 39.65C82.4789 39.6991 82.8238 39.8608 83.0976 40.1132C83.3714 40.3655 83.5607 40.6961 83.6398 41.06C83.8104 41.5875 84.0107 42.105 84.2398 42.61H83.4798C83.2849 42.1927 83.1211 41.7615 82.9898 41.32C82.9248 40.9671 82.7338 40.6498 82.4523 40.4273C82.1709 40.2047 81.8181 40.0919 81.4598 40.11H81.2398V42.57H80.5398V37.11L81.2398 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M89.4398 39.81C89.4398 41.69 88.2998 42.69 86.9098 42.69C86.5608 42.6865 86.2165 42.61 85.8989 42.4653C85.5814 42.3207 85.2976 42.1112 85.0659 41.8502C84.8341 41.5893 84.6596 41.2828 84.5535 40.9504C84.4474 40.6179 84.4121 40.267 84.4498 39.92C84.4107 39.5613 84.4471 39.1984 84.5565 38.8546C84.6659 38.5107 84.8459 38.1935 85.085 37.9233C85.3242 37.6531 85.6171 37.4358 85.9451 37.2855C86.2731 37.1351 86.629 37.0549 86.9898 37.05C87.3379 37.052 87.6817 37.1273 87.9988 37.2709C88.3159 37.4145 88.5992 37.6233 88.8303 37.8836C89.0614 38.144 89.2351 38.4501 89.3401 38.782C89.4451 39.1139 89.4791 39.4642 89.4398 39.81ZM85.1998 39.9C85.1998 41.07 85.8298 42.11 86.9498 42.11C88.0698 42.11 88.6998 41.11 88.6998 39.84C88.6998 38.76 88.1298 37.62 86.9498 37.62C85.7698 37.62 85.1998 38.7 85.1998 39.9Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M93.2599 37.14V37.73H91.0598V42.57H90.3499V37.11L93.2599 37.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M98.2498 39.81C98.2936 40.1701 98.2606 40.5354 98.1531 40.8818C98.0456 41.2282 97.866 41.548 97.6261 41.82C97.3862 42.092 97.0914 42.3102 96.7611 42.4602C96.4308 42.6101 96.0725 42.6885 95.7098 42.69C95.3614 42.685 95.0178 42.6074 94.7009 42.4623C94.3841 42.3171 94.101 42.1076 93.8695 41.847C93.6381 41.5865 93.4635 41.2805 93.3568 40.9488C93.2501 40.617 93.2137 40.2667 93.2498 39.92C93.2108 39.5613 93.2471 39.1984 93.3565 38.8546C93.4659 38.5107 93.646 38.1935 93.8851 37.9233C94.1243 37.6531 94.4172 37.4358 94.7452 37.2855C95.0732 37.1351 95.429 37.0549 95.7898 37.05C96.1383 37.0522 96.4824 37.1275 96.8 37.271C97.1176 37.4145 97.4015 37.6231 97.6333 37.8832C97.8652 38.1434 98.0399 38.4493 98.1461 38.7812C98.2523 39.1131 98.2876 39.4636 98.2498 39.81ZM94.0598 39.9C94.0598 41.07 94.6898 42.11 95.7998 42.11C96.9098 42.11 97.5498 41.11 97.5498 39.84C97.5498 38.76 96.9798 37.62 95.8098 37.62C94.6398 37.62 94.0598 38.7 94.0598 39.9Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.0598 46.92C29.5061 46.8483 29.9578 46.8148 30.4098 46.82C30.9578 46.7773 31.5023 46.9374 31.9398 47.27C32.0979 47.4138 32.2227 47.5902 32.3056 47.7871C32.3885 47.984 32.4274 48.1965 32.4198 48.41C32.4393 48.6176 32.4175 48.8269 32.3557 49.026C32.2939 49.2251 32.1934 49.41 32.0598 49.57C31.8367 49.778 31.5736 49.9385 31.2865 50.0416C30.9994 50.1448 30.6943 50.1885 30.3898 50.17C30.1985 50.1751 30.0073 50.1583 29.8198 50.12V52.32H29.0598V46.92ZM29.7598 49.56C29.9538 49.6001 30.1519 49.6169 30.3498 49.61C31.1998 49.61 31.7198 49.2 31.7198 48.44C31.7198 47.68 31.2098 47.38 30.4298 47.38C30.2054 47.3742 29.9809 47.391 29.7598 47.43V49.56Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.2199 49.76H34.0599V51.76H36.4299V52.35H33.3599V46.86H36.3099V47.45H34.0599V49.17H36.1899L36.2199 49.76Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.2398 47.18C37.6695 46.9057 38.1701 46.7631 38.6798 46.77C39.6198 46.77 40.2798 47.26 40.2798 48.11C40.2755 48.4259 40.1613 48.7304 39.957 48.9712C39.7526 49.2121 39.4708 49.3743 39.1598 49.43C39.5153 49.4405 39.8525 49.5897 40.0992 49.8458C40.346 50.1019 40.4826 50.4444 40.4798 50.8C40.4798 51.93 39.4798 52.39 38.4798 52.39C37.9847 52.4047 37.4946 52.2874 37.0598 52.05L37.2498 51.5C37.6239 51.7068 38.0425 51.82 38.4698 51.83C39.2798 51.83 39.7298 51.39 39.7298 50.83C39.7298 50.02 38.9398 49.78 38.2398 49.78H37.9198V49.21H38.2398C38.5421 49.2468 38.8466 49.1621 39.0866 48.9746C39.3265 48.7872 39.4823 48.5122 39.5198 48.21C39.5198 47.66 39.1398 47.35 38.5198 47.35C38.1172 47.3638 37.7262 47.4884 37.3898 47.71L37.2398 47.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.2597 49.76H42.1297V51.76H44.4997V52.35H41.4297V46.86H44.3797V47.45H42.1297V49.17H44.2597V49.76Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M45.4099 46.92C45.8596 46.848 46.3146 46.8146 46.7699 46.82C47.3176 46.78 47.8611 46.9399 48.2999 47.27C48.4579 47.4137 48.5828 47.5902 48.6656 47.7871C48.7485 47.9839 48.7875 48.1965 48.7799 48.41C48.7984 48.8369 48.6474 49.2539 48.3599 49.57C48.1358 49.7766 47.8725 49.9361 47.5857 50.0391C47.2988 50.1422 46.9942 50.1867 46.6899 50.17C46.4986 50.1755 46.3073 50.1587 46.1199 50.12V52.32H45.4099V46.92ZM46.1199 49.57C46.3105 49.6101 46.5053 49.6269 46.6999 49.62C47.5499 49.57 48.0599 49.2 48.0599 48.44C48.0599 47.68 47.5499 47.38 46.7699 47.38C46.5488 47.374 46.3276 47.3908 46.1099 47.43L46.1199 49.57Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.7197 46.93C50.1614 46.8473 50.6105 46.8104 51.0597 46.82C51.5899 46.7709 52.1206 46.9089 52.5597 47.21C52.7114 47.3267 52.8318 47.479 52.9104 47.6535C52.9889 47.828 53.023 48.0192 53.0097 48.21C52.994 48.48 52.8938 48.7382 52.7234 48.9482C52.5529 49.1582 52.3207 49.3092 52.0597 49.38C52.3766 49.4442 52.6618 49.6154 52.8675 49.8648C53.0732 50.1142 53.187 50.4267 53.1897 50.75C53.1917 50.955 53.152 51.1582 53.0729 51.3474C52.9938 51.5365 52.877 51.7075 52.7297 51.85C52.1812 52.2411 51.5105 52.4221 50.8397 52.36C50.4719 52.3603 50.1045 52.3369 49.7397 52.29L49.7197 46.93ZM50.4297 49.17H51.0597C51.7997 49.17 52.2397 48.78 52.2397 48.25C52.2397 47.72 51.7497 47.36 51.0397 47.36C50.8318 47.3518 50.6236 47.3686 50.4197 47.41L50.4297 49.17ZM50.4297 51.78C50.6259 51.799 50.8235 51.799 51.0197 51.78C51.7497 51.78 52.4197 51.51 52.4197 50.71C52.4197 49.91 51.7797 49.66 51.0097 49.66H50.4297V51.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.9997 50.57L54.4297 52.29H53.6997L55.5497 46.83H56.4097L58.2697 52.29H57.5197L56.9297 50.57H54.9997ZM56.7897 50.02L56.2497 48.45C56.1297 48.09 56.0497 47.77 55.9697 47.45C55.8897 47.7833 55.7997 48.1166 55.6997 48.45L55.1597 50.03L56.7897 50.02Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.7698 52.32V47.05H61.5498L62.9198 49.65C63.2398 50.28 63.5198 50.86 63.7498 51.41C63.6898 50.67 63.6798 50.06 63.6798 49.22V47.05H64.3098V52.32H63.5898L62.1998 49.7C61.8926 49.118 61.6155 48.5206 61.3698 47.91C61.3698 48.59 61.3698 49.2 61.3698 50.13V52.32H60.7698ZM67.3398 48.57C67.3613 48.7494 67.3441 48.9312 67.2892 49.1033C67.2343 49.2753 67.1431 49.4336 67.0217 49.5673C66.9003 49.701 66.7516 49.8071 66.5856 49.8783C66.4197 49.9495 66.2403 49.9842 66.0598 49.98C65.8797 49.9826 65.7011 49.9468 65.536 49.875C65.3708 49.8032 65.2228 49.6971 65.1017 49.5638C64.9806 49.4305 64.8893 49.2729 64.8338 49.1016C64.7783 48.9302 64.7599 48.749 64.7798 48.57C64.7646 48.3925 64.7863 48.2137 64.8437 48.0449C64.9011 47.8762 64.9928 47.7211 65.113 47.5896C65.2333 47.458 65.3795 47.3529 65.5424 47.2806C65.7054 47.2084 65.8815 47.1708 66.0598 47.17C66.2398 47.1644 66.419 47.1978 66.585 47.2678C66.751 47.3379 66.8998 47.443 67.0214 47.576C67.143 47.7089 67.2344 47.8666 67.2893 48.0382C67.3443 48.2098 67.3615 48.3912 67.3398 48.57ZM64.9998 50.76V50.32H67.1298V50.75L64.9998 50.76ZM65.3598 48.57C65.3598 49.14 65.6798 49.57 66.0598 49.57C66.4398 49.57 66.7598 49.16 66.7598 48.57C66.7598 47.98 66.5598 47.57 66.0598 47.57C65.5598 47.57 65.3598 48.11 65.3598 48.57Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M67.9497 52.32V51.88L68.5097 51.34C69.8597 50.06 70.4597 49.34 70.5097 48.58C70.5185 48.4464 70.4987 48.3124 70.4516 48.1871C70.4045 48.0618 70.3311 47.9479 70.2364 47.8533C70.1417 47.7586 70.0279 47.6852 69.9026 47.6381C69.7773 47.591 69.6433 47.5712 69.5097 47.58C69.0957 47.5966 68.7001 47.7556 68.3897 48.03L68.1597 47.53C68.578 47.1814 69.1052 46.9904 69.6497 46.99C69.8594 46.9715 70.0707 46.9977 70.2696 47.0668C70.4684 47.136 70.6504 47.2464 70.8035 47.391C70.9565 47.5356 71.0772 47.7109 71.1576 47.9055C71.238 48.1001 71.2761 48.3096 71.2697 48.52C71.2697 49.52 70.5597 50.28 69.4497 51.35L69.0297 51.74H71.3997V52.33L67.9497 52.32Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }