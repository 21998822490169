var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "28",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.62 25.2199C27.2715 25.2219 26.9303 25.1204 26.6395 24.9282C26.3488 24.736 26.1216 24.4618 25.9869 24.1404C25.8521 23.819 25.8159 23.4648 25.8826 23.1227C25.9494 22.7807 26.1162 22.4661 26.362 22.219C26.6077 21.9719 26.9213 21.8032 27.263 21.7345C27.6046 21.6658 27.959 21.7 28.2812 21.833C28.6034 21.9659 28.8789 22.1914 29.0727 22.4811C29.2665 22.7707 29.37 23.1114 29.37 23.4599C29.37 23.925 29.186 24.3711 28.8581 24.7009C28.5302 25.0307 28.0851 25.2173 27.62 25.2199ZM27.62 22.6999C27.4693 22.6979 27.3214 22.7408 27.1951 22.8231C27.0688 22.9054 26.9699 23.0234 26.9108 23.1621C26.8517 23.3008 26.8352 23.4539 26.8634 23.602C26.8916 23.7501 26.9632 23.8864 27.0691 23.9937C27.175 24.101 27.3103 24.1744 27.458 24.2045C27.6057 24.2347 27.7591 24.2202 27.8985 24.163C28.038 24.1058 28.1573 24.0084 28.2412 23.8832C28.3252 23.758 28.37 23.6107 28.37 23.4599C28.37 23.2601 28.2914 23.0683 28.151 22.926C28.0106 22.7838 27.8199 22.7026 27.62 22.6999Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.9701 25.2199C31.622 25.2199 31.2817 25.1167 30.9923 24.9233C30.7029 24.73 30.4773 24.4551 30.3441 24.1335C30.2108 23.8119 30.176 23.458 30.2439 23.1166C30.3118 22.7752 30.4794 22.4616 30.7256 22.2154C30.9717 21.9693 31.2853 21.8017 31.6267 21.7338C31.9681 21.6659 32.322 21.7007 32.6436 21.8339C32.9652 21.9671 33.2401 22.1927 33.4335 22.4822C33.6269 22.7716 33.7301 23.1119 33.7301 23.4599C33.7314 23.6914 33.6868 23.9209 33.5988 24.135C33.5108 24.3492 33.3812 24.5437 33.2175 24.7074C33.0539 24.8711 32.8593 25.0007 32.6452 25.0887C32.431 25.1767 32.2016 25.2213 31.9701 25.2199ZM31.9701 22.7C31.8198 22.7 31.6728 22.7445 31.5478 22.828C31.4229 22.9115 31.3255 23.0302 31.2679 23.1691C31.2104 23.308 31.1954 23.4608 31.2247 23.6082C31.254 23.7556 31.3264 23.8911 31.4327 23.9973C31.539 24.1036 31.6744 24.176 31.8218 24.2053C31.9692 24.2347 32.122 24.2196 32.2609 24.1621C32.3998 24.1046 32.5185 24.0072 32.602 23.8822C32.6855 23.7572 32.7301 23.6103 32.7301 23.4599C32.7301 23.2584 32.65 23.0651 32.5075 22.9226C32.365 22.78 32.1716 22.7 31.9701 22.7Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.0401 22.16H25.7501L22.3001 9.15998C22.2059 8.81563 22.0013 8.51173 21.7176 8.29494C21.434 8.07815 21.087 7.96047 20.7301 7.95998C20.3484 7.94987 19.9748 8.07167 19.6725 8.30483C19.3701 8.53799 19.1573 8.86825 19.0701 9.23998L18.0701 9.01998C18.2044 8.44315 18.5266 7.92734 18.9862 7.55373C19.4457 7.18011 20.0165 6.96991 20.6086 6.95616C21.2006 6.94242 21.7805 7.12593 22.2569 7.47782C22.7333 7.82971 23.0791 8.33 23.2401 8.89998L26.5001 21.12H33.0501L33.0401 22.16Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.05 19.9399H25.5201V18.9399H32.3801L34.5301 13.6799H23.5801V12.6799H36.0201L33.05 19.9399Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.98 35.0325V35.4325H11.49V38.7225H11.02V35.0325H12.98Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.84 35.0325V36.5825C13.84 37.1725 13.84 37.6525 13.79 38.1425C13.9949 37.724 14.2219 37.3168 14.47 36.9225L15.66 35.0225H16.14V38.7125H15.75V37.1425C15.75 36.5425 15.75 36.1425 15.75 35.6425C15.5509 36.0722 15.3203 36.4867 15.06 36.8825L13.91 38.7225H13.4V35.0325H13.84Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.66 35.0325V38.7225H19.19V35.4325H17.47V38.7225H16.99V35.0325H19.66Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.41 36.9925H20.97V38.3225H22.57V38.7225H20.5V35.0325H22.5V35.4325H20.98V36.5925H22.42L22.41 36.9925Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.19 35.0725C23.4941 35.0229 23.8019 34.9995 24.11 35.0025C24.4699 34.9815 24.8252 35.0916 25.11 35.3125C25.2156 35.4103 25.2989 35.5297 25.3541 35.6626C25.4093 35.7955 25.4352 35.9387 25.43 36.0825C25.4383 36.2262 25.4179 36.3702 25.3698 36.5058C25.3217 36.6415 25.247 36.7662 25.15 36.8726C24.9383 37.0366 24.6945 37.1544 24.4344 37.2181C24.1742 37.2819 23.9036 37.2902 23.64 37.2425V38.7225H23.16L23.19 35.0725ZM23.67 36.8525C23.7994 36.8706 23.9307 36.8706 24.06 36.8525C24.64 36.8525 24.99 36.5725 24.99 36.0625C24.99 35.5525 24.64 35.3426 24.12 35.3426C23.9708 35.3211 23.8193 35.3211 23.67 35.3426V36.8525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.1199 37.1025C29.1199 36.5825 29.0599 35.9625 29.0599 35.5025C28.9299 35.9425 28.7799 36.4025 28.5899 36.9125L27.9399 38.7025H27.5799L26.9799 36.9425C26.8099 36.4225 26.6599 35.9425 26.5499 35.5025C26.5499 35.9625 26.5499 36.5825 26.4799 37.1425L26.3799 38.7225H25.9299L26.1899 35.0325H26.7899L27.4199 36.8125C27.5799 37.2725 27.6999 37.6725 27.7999 38.0625C27.8999 37.6825 28.0299 37.2825 28.1899 36.8125L28.8499 35.0325H29.4599L29.6899 38.7225H29.2199L29.1199 37.1025Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.97 37.5625L30.58 38.7225H30.09L31.34 35.0325H31.92L33.18 38.7225H32.67L32.27 37.5625H30.97ZM32.18 37.1925L31.81 36.1225C31.73 35.8825 31.68 35.6625 31.62 35.4525C31.57 35.6725 31.51 35.8925 31.44 36.1225L31.07 37.1925H32.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.75 35.0725C34.0541 35.0229 34.3619 34.9995 34.67 35.0025C35.0299 34.9815 35.3851 35.0916 35.67 35.3125C35.7774 35.4094 35.8626 35.5284 35.9195 35.6613C35.9765 35.7943 36.0039 35.938 36 36.0825C36.007 36.2269 35.9849 36.3712 35.9351 36.5069C35.8853 36.6427 35.8088 36.767 35.71 36.8726C35.4983 37.0366 35.2545 37.1544 34.9944 37.2181C34.7342 37.2819 34.4636 37.2902 34.2 37.2425V38.7225H33.75V35.0725ZM34.23 36.8525C34.3594 36.87 34.4906 36.87 34.62 36.8525C35.2 36.8525 35.55 36.5725 35.55 36.0625C35.55 35.5525 35.2 35.3426 34.68 35.3426C34.5308 35.3211 34.3792 35.3211 34.23 35.3426V36.8525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.11 35.0325V36.6625H37.26L38.52 35.0325H39.1L37.7 36.7225C38.31 36.8025 38.54 37.1725 38.7 37.7225C38.8136 38.0633 38.9472 38.3973 39.1 38.7225H38.59C38.4527 38.4424 38.339 38.1514 38.25 37.8525C38.2074 37.6195 38.0835 37.4092 37.9004 37.2591C37.7173 37.1089 37.4868 37.0286 37.25 37.0325H37.1V38.7225H36.63V35.0325H37.11Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.52 36.9925H40.08V38.3225H41.68V38.7225H39.61V35.0325H41.61V35.4325H40.09V36.5925H41.53L41.52 36.9925Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.03 35.4325H41.91V35.0325H44.64V35.4325H43.51V38.7225H43.03V35.4325Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.610046 45.7025L1.69005 47.1925H1.12004L0.0500488 45.7025L1.12004 44.2125H1.69005L0.610046 45.7025ZM1.94005 45.7025L3.02005 47.1925H2.45004L1.38005 45.7025L2.45004 44.2125H3.02005L1.94005 45.7025Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.75005 42.2725V47.7325H7.04004V42.8725H4.51004V47.7425H3.80005V42.2725H7.75005Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.83 45.1825H9.75005V47.1825H12.12V47.7725H9.05005V42.2725H12V42.8625H9.75005V44.5925H11.8801L11.83 45.1825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.98 42.3525C13.4292 42.2763 13.8843 42.2395 14.34 42.2425C14.8894 42.2006 15.4347 42.3646 15.87 42.7025C16.0286 42.8458 16.1537 43.0222 16.2367 43.2192C16.3196 43.4161 16.3583 43.6289 16.35 43.8425C16.3591 44.0563 16.3258 44.2697 16.252 44.4705C16.1782 44.6713 16.0654 44.8555 15.92 45.0125C15.6966 45.2165 15.4346 45.3738 15.1496 45.4751C14.8645 45.5764 14.562 45.6197 14.26 45.6025C14.0706 45.6246 13.8793 45.6246 13.69 45.6025V47.7825H12.98V42.3525ZM13.69 44.9825C13.8801 45.0265 14.0749 45.0467 14.27 45.0425C15.12 45.0425 15.64 44.6225 15.64 43.8725C15.64 43.1225 15.13 42.8025 14.35 42.8025C14.1285 42.7981 13.9071 42.8182 13.69 42.8625V44.9825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.12 45.1825H18V47.1825H20.36V47.7725H17.29V42.2725H20.29V42.8625H18.05V44.5925H20.17L20.12 45.1825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.97 42.2725V44.7025H22.19L24.06 42.2725H24.91L22.85 44.7825C23.2191 44.8316 23.5641 44.9933 23.8379 45.2457C24.1117 45.498 24.301 45.8286 24.38 46.1925C24.5489 46.7174 24.7493 47.2316 24.98 47.7325H24.22C24.0217 47.3188 23.8546 46.891 23.72 46.4525C23.6594 46.0986 23.4713 45.7791 23.1913 45.5543C22.9113 45.3296 22.5587 45.2151 22.2 45.2325H21.97V47.7325H21.28V42.2725H21.97Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.6699 42.3526C26.1158 42.2763 26.5676 42.2395 27.0199 42.2426C27.5697 42.1978 28.116 42.3621 28.5499 42.7026C28.7085 42.8459 28.8337 43.0223 28.9166 43.2192C28.9996 43.4162 29.0382 43.629 29.0299 43.8426C29.0467 44.2723 28.8962 44.6917 28.6099 45.0126C28.3844 45.2164 28.1206 45.3735 27.834 45.4748C27.5474 45.5761 27.2435 45.6195 26.9399 45.6026C26.7505 45.6242 26.5593 45.6242 26.3699 45.6026V47.7826H25.6699V42.3526ZM26.3699 44.9826C26.56 45.0266 26.7548 45.0468 26.9499 45.0426C27.8099 45.0426 28.3199 44.6226 28.3199 43.8726C28.3199 43.1226 27.8099 42.8026 27.0399 42.8026C26.8151 42.7981 26.5904 42.8183 26.3699 42.8626V44.9826Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.8 45.1825H30.68V47.1825H33.05V47.7725H29.98V42.2725H32.92V42.8625H30.68V44.5925H32.8V45.1825ZM30.27 41.4925C30.27 41.3838 30.3132 41.2795 30.3901 41.2026C30.467 41.1257 30.5712 41.0825 30.68 41.0825C30.7887 41.0825 30.893 41.1257 30.9699 41.2026C31.0468 41.2795 31.09 41.3838 31.09 41.4925C31.09 41.6013 31.0468 41.7056 30.9699 41.7824C30.893 41.8593 30.7887 41.9025 30.68 41.9025C30.6258 41.9039 30.5718 41.8942 30.5215 41.8741C30.4711 41.854 30.4254 41.8238 30.387 41.7855C30.3487 41.7471 30.3185 41.7014 30.2984 41.651C30.2783 41.6007 30.2686 41.5467 30.27 41.4925ZM31.78 41.4925C31.7786 41.4392 31.788 41.3861 31.8075 41.3364C31.827 41.2867 31.8563 41.2414 31.8935 41.2032C31.9308 41.165 31.9754 41.1346 32.0246 41.1139C32.0738 41.0932 32.1266 41.0825 32.18 41.0825C32.2334 41.0825 32.2862 41.0932 32.3354 41.1139C32.3846 41.1346 32.4291 41.165 32.4664 41.2032C32.5037 41.2414 32.533 41.2867 32.5525 41.3364C32.572 41.3861 32.5813 41.4392 32.58 41.4925C32.58 41.5986 32.5378 41.7003 32.4628 41.7754C32.3878 41.8504 32.2861 41.8925 32.18 41.8925C32.0739 41.8925 31.9722 41.8504 31.8971 41.7754C31.8221 41.7003 31.78 41.5986 31.78 41.4925Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.75 47.5626C37.2944 47.7521 36.8031 47.8408 36.31 47.8226C35.9447 47.8457 35.5787 47.7895 35.2372 47.6579C34.8956 47.5264 34.5865 47.3226 34.331 47.0605C34.0755 46.7984 33.8798 46.4841 33.757 46.1393C33.6343 45.7945 33.5875 45.4272 33.62 45.0626C33.5969 44.6814 33.6546 44.2996 33.7894 43.9423C33.9242 43.585 34.133 43.2602 34.4021 42.9892C34.6712 42.7182 34.9945 42.5071 35.3509 42.3699C35.7073 42.2326 36.0886 42.1722 36.47 42.1926C36.9137 42.1724 37.3559 42.2581 37.76 42.4426L37.59 43.0126C37.2455 42.8535 36.8694 42.7748 36.49 42.7826C36.1933 42.7606 35.8954 42.8051 35.618 42.9129C35.3407 43.0206 35.0909 43.1889 34.8868 43.4054C34.6828 43.6219 34.5296 43.8813 34.4384 44.1645C34.3473 44.4477 34.3205 44.7477 34.36 45.0426C34.3322 45.3294 34.3667 45.6188 34.4611 45.891C34.5555 46.1632 34.7077 46.4119 34.9071 46.6198C35.1065 46.8278 35.3485 46.9902 35.6165 47.096C35.8846 47.2018 36.1723 47.2484 36.46 47.2326C36.8542 47.2371 37.2453 47.1623 37.61 47.0126L37.75 47.5626Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.9801 42.8825H38.3201V42.2725H42.3201V42.8725H40.6501V47.7325H39.9401L39.9801 42.8825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.35 44.9625C47.3955 45.3224 47.3637 45.6878 47.2568 46.0345C47.1498 46.3811 46.9701 46.7009 46.7297 46.9725C46.4893 47.2442 46.1937 47.4614 45.8627 47.6097C45.5316 47.758 45.1727 47.8339 44.81 47.8325C44.4619 47.8276 44.1187 47.75 43.8023 47.6048C43.4859 47.4595 43.2034 47.2498 42.9727 46.989C42.7421 46.7283 42.5684 46.4222 42.4629 46.0905C42.3574 45.7587 42.3224 45.4086 42.36 45.0625C42.3226 44.7049 42.3597 44.3434 42.4691 44.0009C42.5785 43.6584 42.7577 43.3423 42.9955 43.0726C43.2333 42.8029 43.5244 42.5854 43.8505 42.4339C44.1766 42.2824 44.5305 42.2002 44.89 42.1925C45.2389 42.196 45.5833 42.2726 45.9008 42.4172C46.2184 42.5619 46.5022 42.7714 46.7339 43.0323C46.9656 43.2932 47.1401 43.5998 47.2463 43.9322C47.3524 44.2646 47.3877 44.6156 47.35 44.9625ZM43.11 45.0525C43.11 46.2125 43.74 47.2625 44.85 47.2625C45.96 47.2625 46.6 46.2625 46.6 44.9925C46.6 43.9025 46.04 42.7725 44.86 42.7725C43.68 42.7725 43.11 43.8525 43.11 45.0525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.96 42.2725V44.7025H49.18L51.05 42.2725H51.9L49.83 44.7825C50.1991 44.8316 50.5441 44.9933 50.8179 45.2457C51.0917 45.498 51.281 45.8286 51.36 46.1925C51.5334 46.7158 51.7336 47.2298 51.96 47.7325H51.2C51.0051 47.3186 50.8413 46.8907 50.71 46.4525C50.6494 46.0986 50.4613 45.7791 50.1813 45.5543C49.9013 45.3296 49.5487 45.2151 49.19 45.2325H48.96V47.7325H48.26V42.2725H48.96Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.22 44.2125H52.8L53.87 45.7025L52.8 47.1925H52.23L53.31 45.7025L52.22 44.2125ZM53.54 44.2125H54.12L55.2 45.7025L54.12 47.1925H53.55L54.63 45.7025L53.54 44.2125Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }