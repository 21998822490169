var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("defs", [
      _c(
        "marker",
        {
          attrs: {
            id: _vm.hintArrow,
            orient: "auto",
            markerWidth: "2",
            markerHeight: "4",
            refX: "0.1",
            refY: "2",
          },
        },
        [_c("path", { attrs: { d: "M0,0 V4 L2,2 Z", fill: "#686868" } })]
      ),
      _c("path", { ref: "pathClone", attrs: { id: _vm.hintPathClone, d: "" } }),
    ]),
    _c(
      "text",
      {
        class: { "arrow-hint__text": true, "arrow-hint__text--show": _vm.end },
        attrs: { fill: "#000000", "text-anchor": "middle", dy: _vm.dy },
      },
      [
        _c(
          "textPath",
          {
            attrs: {
              startOffset: "50%",
              href:
                _vm.reverse || !_vm.reverse
                  ? `#${_vm.hintPathClone}`
                  : `#${_vm.hintPath}`,
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.hint) + " "),
            _vm.man
              ? _c(
                  "tspan",
                  {
                    staticStyle: { "font-family": "fontello" },
                    attrs: {
                      dx: "0",
                      dy: "0",
                      fill: "#000",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("  ")]
                )
              : _vm._e(),
            _vm.manLeft
              ? _c(
                  "tspan",
                  {
                    staticStyle: { "font-family": "fontello" },
                    attrs: {
                      dx: "0",
                      dy: "0",
                      fill: "#000",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("  ")]
                )
              : _vm._e(),
            _vm.car
              ? _c(
                  "tspan",
                  {
                    staticStyle: { "font-family": "fontello" },
                    attrs: {
                      dx: "0",
                      dy: "0",
                      fill: "#000",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("  ")]
                )
              : _vm._e(),
            _vm.electric
              ? _c(
                  "tspan",
                  {
                    staticStyle: { "font-family": "fontello" },
                    attrs: {
                      dx: "0",
                      dy: "0",
                      fill: "#000",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("  ")]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
    _c("path", {
      ref: "path",
      attrs: {
        id: _vm.hintPath,
        d: "",
        "marker-end": `url(#${_vm.hintArrow})`,
        "stroke-width": "3",
        "stroke-dasharray": "3 3",
        fill: "none",
        stroke: "#686868",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }