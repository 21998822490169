<template>
  <map-layout style="width: 100%; height: 100%; max-height: 800px">
    <map-icon-main-street transform="translate(641,307)" />

    <svg-arrow-hint
      ref="hint"
      hint-path-clone="hintPathCloneComfort"
      hint-arrow="hintArrowComfort"
      hint-path="hintPathComfort"
    />

    <MapIconMetroKuncovo
      class="map-icon__harmony"
      transform="translate(546,228)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 322],
            [589, 293],
            [575, 266],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovo
      class="map-icon__harmony"
      transform="translate(490,268)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [599, 302],
            [589, 293],
            [530, 279],
          ],
          hint: '8 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoMcd
      class="map-icon__harmony"
      transform="translate(619,378)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 330],
            [770, 342],
            [710, 473],
            [648, 420],
          ],
          hint: '6 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroKuncovoBkl
      class="map-icon__harmony"
      transform="translate(529,298)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [585, 282],
            [566, 288],
            [560, 295],
          ],
          hint: '7 минут',
          man: true,
          reverse: true,
          manLeft: true,
          dy: -5,
        })
      "
    />

    <MapIconMetroScolkovo
      class="map-icon__harmony"
      transform="translate(126,505)"
      @click="
        $refs.hint.animate({
          points: [
            [645, 340],
            [595, 392],
            [490, 543],
            [174, 515],
          ],
          hint: '10 минут',
          reverse: true,
          dy: -5,
          electric: true,
        })
      "
    />

    <MapIconParkSnowboard
      class="map-icon__harmony"
      transform="translate(481,46)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [494, 322],
            [471, 113],
            [493, 94],
          ],
          hint: '9 минут',
          reverse: true,
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconParkFili
      class="map-icon__harmony"
      transform="translate(881,88)"
      @click="
        $refs.hint.animate({
          points: [
            [686, 326],
            [764, 302],
            [791, 313],
            [887, 138],
          ],
          hint: '11 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconParkVorosilovsk
      class="map-icon__harmony"
      transform="translate(741,158)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 306],
            [754, 272],
            [761, 243],
            [777, 209],
          ],
          hint: '7 минут',
          car: true,
        })
      "
    />

    <MapIconRestoranManor
      class="map-icon__harmony"
      transform="translate(691,205)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 316],
            [664, 272],
            [663, 243],
            [690, 230],
          ],
          hint: '6 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconParkSold
      class="map-icon__harmony"
      transform="translate(611,165)"
      @click="
        $refs.hint.animate({
          points: [
            [666, 316],
            [664, 232],
            [663, 243],
            [663, 220],
          ],
          hint: '5 минут',
          car: true,
        })
      "
    />

    <MapIconParkSuvorov
      class="map-icon__harmony"
      transform="translate(523,151)"
      @click="
        $refs.hint.animate({
          points: [
            [660, 315],
            [644, 302],
            [583, 233],
            [572, 200],
          ],
          hint: '6 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconSport
      class="map-icon__harmony"
      transform="translate(493,350)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 372],
            [576, 368],
            [545, 370],
          ],
          hint: '11 минут',
          manLeft: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconMojPark
      class="map-icon__harmony"
      transform="translate(1010,190)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [855, 202],
            [966, 180],
            [1040, 195],
          ],
          hint: '9 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconRestoraunt
      class="map-icon__harmony"
      transform="translate(1225,156)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [825, 182],
            [1066, 120],
            [1220, 165],
          ],
          hint: '17 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconRestorauntSpett
      class="map-icon__harmony"
      transform="translate(1169,175)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [855, 202],
            [1066, 140],
            [1170, 177],
          ],
          hint: '15 минут',
          car: true,
          dy: -5,
        })
      "
    />

    <MapIconParkDolina
      class="map-icon__harmony"
      transform="translate(679,508)"
      @click="
        $refs.hint.animate({
          points: [
            [690, 330],
            [750, 379],
            [765, 450],
            [730, 505],
          ],
          hint: '19 минут',
          car: true,
        })
      "
    />

    <MapIconSpaClub
      class="map-icon__harmony"
      transform="translate(437,225)"
      @click="
        $refs.hint.animate({
          points: [
            [646, 330],
            [625, 292],
            [526, 258],
            [485, 248],
          ],
          hint: '8 минут',
          car: true,
          reverse: true,
          dy: -5,
        })
      "
    />

    <MapIconStretching
      class="map-icon__harmony"
      transform="translate(501,485)"
      @click="
        $refs.hint.animate({
          points: [
            [688, 330],
            [690, 322],
            [728, 443],
            [566, 493],
          ],
          hint: '13 минут',
          car: true,
          reverse: true,
        })
      "
    />

    <MapIconKozlovForest
      class="map-icon__harmony"
      transform="translate(551,530)"
      @click="
        $refs.hint.animate({
          points: [
            [688, 330],
            [690, 322],
            [698, 443],
            [606, 523],
          ],
          hint: '10 минут',
          car: true,
          reverse: true,
        })
      "
    />
  </map-layout>
</template>

<script>
import MapLayout from "@/components/elements/map/MapLayout";
import SvgArrowHint from "@/components/elements/map/SVGArrowHint";
import MapIconMainStreet from "@/components/elements/map/icons/MapIconMainStreet";
import MapIconParkFili from "@/components/elements/map/icons/MapIconParkFili";
import MapIconParkVorosilovsk from "@/components/elements/map/icons/MapIconParkVorosilovsk";
import MapIconParkSold from "@/components/elements/map/icons/MapIconParkSold";
import MapIconParkSuvorov from "@/components/elements/map/icons/MapIconParkSuvorov";
import MapIconParkSnowboard from "@/components/elements/map/icons/MapIconParkSnowboard";
import MapIconParkDolina from "@/components/elements/map/icons/MapIconParkDolina";
import MapIconMetroKuncovo from "@/components/elements/map/icons/MapIconMetroKuncovoHormony";
import MapIconMetroKuncovoBkl from "@/components/elements/map/icons/MapIconMetroKuncovoBkl";
import MapIconMetroKuncovoMcd from "@/components/elements/map/icons/MapIconMetroKuncovoMcd";
import MapIconMetroScolkovo from "@/components/elements/map/icons/MapIconMetroScolkovo";
import MapIconSport from "@/components/elements/map/icons/MapIconSport";
import MapIconRestoraunt from "@/components/elements/map/icons/MapIconRestoraunt";
import MapIconRestorauntSpett from "@/components/elements/map/icons/MapIconRestorauntSpett";
import MapIconRestoranManor from "@/components/elements/map/icons/MapIconRestoranManor";
import MapIconSpaClub from "@/components/elements/map/icons/MapIconSpaClub";
import MapIconMojPark from "@/components/elements/map/icons/MapIconMojPark";
import MapIconStretching from "@/components/elements/map/icons/MapIconStretching";
import MapIconKozlovForest from "@/components/elements/map/icons/MapIconKozlovForest";

export default {
  name: "PointComfort",
  components: {
    MapLayout,
    SvgArrowHint,
    MapIconParkFili,
    MapIconMainStreet,
    MapIconParkVorosilovsk,
    MapIconParkSold,
    MapIconParkSuvorov,
    MapIconMetroKuncovo,
    MapIconParkSnowboard,
    MapIconParkDolina,
    MapIconMetroKuncovoBkl,
    MapIconMetroKuncovoMcd,
    MapIconMetroScolkovo,
    MapIconSport,
    MapIconRestoraunt,
    MapIconRestorauntSpett,
    MapIconRestoranManor,
    MapIconSpaClub,
    MapIconMojPark,
    MapIconStretching,
    MapIconKozlovForest,
  },
  data: () => ({
    animateStartHarmony: false,
    points: [
      {
        points: [
          [646, 330],
          [599, 322],
          [589, 293],
          [575, 266],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [599, 302],
          [589, 293],
          [530, 279],
        ],
        hint: "8 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [686, 330],
          [770, 342],
          [710, 473],
          [648, 420],
        ],
        hint: "6 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [585, 282],
          [566, 288],
          [560, 295],
        ],
        hint: "7 минут",
        reverse: true,
        manLeft: true,
        dy: -5,
      },
      {
        points: [
          [645, 340],
          [595, 392],
          [490, 543],
          [174, 515],
        ],
        hint: "10 минут",
        reverse: true,
        dy: -5,
        electric: true,
      },
      {
        points: [
          [646, 330],
          [494, 322],
          [471, 113],
          [493, 94],
        ],
        hint: "9 минут",
        reverse: true,
        car: true,
        dy: -5,
      },
      {
        points: [
          [686, 326],
          [764, 302],
          [791, 313],
          [887, 138],
        ],
        hint: "11 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 306],
          [754, 272],
          [761, 243],
          [777, 209],
        ],
        hint: "7 минут",
        car: true,
      },
      {
        points: [
          [666, 316],
          [664, 272],
          [663, 243],
          [690, 230],
        ],
        hint: "6 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [666, 316],
          [664, 232],
          [663, 243],
          [663, 220],
        ],
        hint: "5 минут",
        car: true,
      },
      {
        points: [
          [660, 315],
          [644, 302],
          [583, 233],
          [572, 200],
        ],
        hint: "6 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [646, 330],
          [625, 372],
          [576, 368],
          [545, 370],
        ],
        hint: "11 минут",
        manLeft: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [855, 202],
          [966, 180],
          [1040, 195],
        ],
        hint: "9 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [825, 182],
          [1066, 120],
          [1220, 165],
        ],
        hint: "17 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [855, 202],
          [1066, 140],
          [1170, 177],
        ],
        hint: "10 минут",
        car: true,
        dy: -5,
      },
      {
        points: [
          [690, 330],
          [750, 379],
          [765, 450],
          [730, 505],
        ],
        hint: "19 минут",
        car: true,
      },
      {
        points: [
          [646, 330],
          [625, 292],
          [526, 258],
          [485, 248],
        ],
        hint: "8 минут",
        car: true,
        reverse: true,
        dy: -5,
      },
      {
        points: [
          [688, 330],
          [690, 322],
          [728, 443],
          [566, 493],
        ],
        hint: "13 минут",
        car: true,
        reverse: true,
      },
      {
        points: [
          [688, 330],
          [690, 322],
          [698, 443],
          [606, 523],
        ],
        hint: "10 минут",
        car: true,
        reverse: true,
      },
    ],
  }),
  mounted() {
    this.animateHarmony(this.points);
  },
  methods: {
    animateHarmony(points = []) {
      const pointsToAnimate = [...points];
      const _this = this;
      const intervalID = setInterval(() => {
        const point = pointsToAnimate.pop();
        if (point === undefined) {
          clearInterval(intervalID);
          _this.animateHarmony(_this.points);
        } else if (_this.animateStartHarmony === true) {
          clearInterval(intervalID);
          setTimeout(() => {
            _this.animateStartHarmony = false;
            _this.animateHarmony(_this.points);
          }, 2000);
        } else {
          _this.$refs.hint.animate(point);
        }
      }, 4000);

      var elem = document.querySelectorAll(".map-icon__harmony");

      elem.forEach(function (item, i, arr) {
        item.addEventListener(
          "click",
          () => {
            _this.animateStartHarmony = true;
          },
          { once: true }
        );
      });
    },
  },
};
</script>
