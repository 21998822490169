var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "47",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.7799 25.5201H37.3999V25.1401C37.4079 20.2078 39.3734 15.4805 42.8648 11.9967C46.3561 8.5128 51.0877 6.5574 56.0199 6.56006H56.3899V6.93006C56.3886 9.37265 55.9062 11.7911 54.9702 14.0472C54.0343 16.3034 52.6631 18.3531 50.935 20.0793C49.2069 21.8056 47.1557 23.1745 44.8986 24.108C42.6414 25.0416 40.2225 25.5214 37.7799 25.5201ZM55.6099 7.31006C51.0146 7.41439 46.6366 9.28636 43.3864 12.5365C40.1362 15.7867 38.2642 20.1648 38.1599 24.7601C42.7559 24.6582 47.1355 22.787 50.3861 19.5363C53.6368 16.2856 55.508 11.9061 55.6099 7.31006Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.0199 21.6601C42.7868 21.6594 42.5561 21.6122 42.3414 21.5212C42.1268 21.4302 41.9325 21.2972 41.7699 21.1301C41.5211 20.8874 41.3502 20.5761 41.279 20.236C41.2077 19.8958 41.2394 19.5422 41.3699 19.2201C42.1718 17.2777 43.3484 15.5123 44.8325 14.0247C46.3167 12.5371 48.0794 11.3564 50.0199 10.5501C50.3513 10.3969 50.7222 10.3512 51.0809 10.4193C51.4395 10.4874 51.7678 10.666 52.0199 10.9301C52.2682 11.1731 52.438 11.4849 52.5075 11.8253C52.577 12.1657 52.543 12.5191 52.4099 12.8401C51.611 14.7874 50.4332 16.5566 48.9448 18.045C47.4565 19.5333 45.6873 20.7112 43.7399 21.5101C43.5138 21.6123 43.268 21.6635 43.0199 21.6601ZM42.0699 19.5001C41.9936 19.6853 41.9748 19.8893 42.0158 20.0854C42.0568 20.2816 42.1558 20.4608 42.2999 20.6001C42.4391 20.7442 42.6184 20.8432 42.8146 20.8842C43.0107 20.9252 43.2146 20.9063 43.3999 20.8301C45.2539 20.0695 46.9386 18.9488 48.3565 17.5326C49.7743 16.1165 50.8972 14.4331 51.6599 12.5801C51.735 12.3938 51.7522 12.1893 51.7094 11.9931C51.6666 11.7969 51.5657 11.6181 51.4199 11.4801C51.2827 11.3362 51.1057 11.2366 50.9115 11.1938C50.7174 11.1511 50.5149 11.1672 50.3299 11.2401C48.4747 12.0012 46.7892 13.1234 45.3712 14.5413C43.9532 15.9593 42.8311 17.6448 42.0699 19.5001Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.602 14.8172L44.0717 15.3475L47.5295 18.8053L48.0598 18.275L44.602 14.8172Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46.3491 13.1218L45.8188 13.6521L49.2624 17.0957L49.7927 16.5654L46.3491 13.1218Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.3299 11.7701H40.8799L38.4799 9.37006C38.1569 9.04419 37.9756 8.60391 37.9756 8.14506C37.9756 7.68621 38.1569 7.24593 38.4799 6.92006C38.8058 6.59702 39.2461 6.41577 39.7049 6.41577C40.1638 6.41577 40.6041 6.59702 40.9299 6.92006L43.3299 9.32006V11.7701ZM41.1899 11.0201H42.5799V9.63006L40.3999 7.45006C40.2135 7.26982 39.9643 7.16907 39.7049 7.16907C39.4456 7.16907 39.1964 7.26982 39.0099 7.45006C38.8297 7.63653 38.7289 7.88572 38.7289 8.14506C38.7289 8.4044 38.8297 8.65359 39.0099 8.84006L41.1899 11.0201Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.3099 25.09C54.0813 25.091 53.8548 25.0464 53.6436 24.9588C53.4324 24.8713 53.2408 24.7425 53.0799 24.58L50.6799 22.18V19.73H53.1299L55.5299 22.13C55.6924 22.2908 55.8212 22.4824 55.9088 22.6936C55.9964 22.9048 56.0409 23.1314 56.0399 23.36C56.0402 23.587 55.9952 23.8118 55.9076 24.0213C55.8201 24.2307 55.6917 24.4207 55.5299 24.58C55.3706 24.7417 55.1807 24.8701 54.9712 24.9577C54.7618 25.0452 54.537 25.0902 54.3099 25.09ZM51.4299 21.87L53.6099 24.05C53.7964 24.2302 54.0456 24.331 54.3049 24.331C54.5643 24.331 54.8135 24.2302 54.9999 24.05C55.093 23.961 55.1667 23.8538 55.2166 23.7351C55.2664 23.6164 55.2914 23.4887 55.2899 23.36C55.2888 23.0976 55.1847 22.8462 54.9999 22.66L52.8199 20.48H51.4299V21.87Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.49 35.0599V38.3999H27.68V35.0999H28.16V38.3999H29.35V35.0999H29.82V38.7999H26V35.0999L26.49 35.0599Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.1302 35.0599V36.6999H31.2702L32.5402 35.0599H33.1102L31.7202 36.7599C31.9695 36.8035 32.1993 36.9229 32.3782 37.1018C32.5572 37.2808 32.6766 37.5106 32.7202 37.7599C32.8371 38.101 32.974 38.4349 33.1302 38.7599H32.6102C32.4822 38.4771 32.372 38.1865 32.2802 37.8899C32.2397 37.6551 32.1167 37.4425 31.9334 37.2903C31.75 37.1382 31.5184 37.0565 31.2802 37.0599H31.1302V38.7599H30.6501V35.0599H31.1302Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.6801 36.87C36.7097 37.1138 36.6874 37.3612 36.6146 37.5957C36.5418 37.8303 36.4202 38.0468 36.2577 38.231C36.0953 38.4152 35.8956 38.5629 35.672 38.6644C35.4483 38.766 35.2057 38.819 34.9601 38.82C34.7243 38.8158 34.4919 38.7625 34.2777 38.6636C34.0636 38.5647 33.8723 38.4223 33.7162 38.2455C33.5601 38.0687 33.4424 37.8613 33.3708 37.6365C33.2992 37.4118 33.2751 37.1746 33.3001 36.94C33.2751 36.6975 33.3008 36.4525 33.3755 36.2205C33.4502 35.9885 33.5722 35.7745 33.7339 35.5921C33.8956 35.4098 34.0934 35.2629 34.3148 35.161C34.5362 35.0591 34.7764 35.0043 35.0201 35C35.2555 35.0028 35.4877 35.0548 35.7018 35.1526C35.916 35.2504 36.1073 35.3919 36.2636 35.5679C36.4199 35.744 36.5377 35.9508 36.6094 36.175C36.6811 36.3992 36.7052 36.6359 36.6801 36.87ZM33.8101 36.93C33.8101 37.72 34.2401 38.43 34.9901 38.43C35.7401 38.43 36.1701 37.73 36.1701 36.89C36.1701 36.05 35.8201 35.4 34.9901 35.4C34.1601 35.4 33.8201 36.12 33.8201 36.93H33.8101Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.7001 35.06V38.76H39.2201V35.46H38.0001V36.58C38.0001 37.38 38.0001 38.27 37.4401 38.63C37.271 38.7336 37.0782 38.7922 36.8801 38.8L36.8201 38.4C36.9646 38.3665 37.0986 38.2978 37.2101 38.2C37.4843 37.7144 37.5964 37.1538 37.5301 36.6V35.04L39.7001 35.06Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.13 37.5999L40.74 38.7599H40.25L41.5 35.0599H42.08L43.34 38.7599H42.82L42.42 37.5999H41.13ZM42.34 37.2199L41.97 36.1599C41.89 35.9199 41.84 35.6999 41.78 35.4899C41.73 35.7099 41.67 35.9299 41.6 36.1599L41.23 37.2199H42.34Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M45.51 35.0599V36.6999H45.66L46.93 35.0599H47.5L46.11 36.7599C46.3594 36.8035 46.5892 36.9229 46.7681 37.1018C46.9471 37.2808 47.0665 37.5106 47.11 37.7599C47.227 38.101 47.3639 38.4349 47.5201 38.7599H47C46.8721 38.4771 46.7619 38.1865 46.67 37.8899C46.6296 37.6551 46.5066 37.4425 46.3232 37.2903C46.1399 37.1382 45.9083 37.0565 45.67 37.0599H45.51V38.7599H45.04V35.0599H45.51Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.64 37.5999L48.25 38.7599H47.76L49.02 35.0599H49.59L50.85 38.7599H50.34L49.95 37.5999H48.64ZM49.85 37.2199L49.49 36.1599C49.4 35.9199 49.35 35.6999 49.29 35.4899C49.29 35.7099 49.18 35.9299 49.11 36.1599L48.75 37.2199H49.85Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.0601 38.76C51.1101 38.66 51.1601 38.54 51.2101 38.42C51.3118 37.9733 51.5022 37.5517 51.7701 37.18C51.8535 37.0959 51.9566 37.034 52.0701 37C51.8456 36.9775 51.6377 36.8714 51.4877 36.7029C51.3377 36.5344 51.2564 36.3156 51.2601 36.09C51.2561 35.9474 51.2847 35.8057 51.3436 35.6758C51.4025 35.5459 51.4902 35.431 51.6001 35.34C51.9103 35.135 52.2789 35.0367 52.6501 35.06C52.9483 35.0556 53.2462 35.0791 53.5401 35.13V38.78H53.0601V37.18H52.7101C52.6141 37.1719 52.5175 37.1838 52.4263 37.2147C52.3351 37.2457 52.2513 37.2952 52.1801 37.36C51.9468 37.7097 51.7807 38.0996 51.6901 38.51C51.6901 38.6 51.6201 38.68 51.5701 38.78L51.0601 38.76ZM53.0601 35.45C52.9126 35.4156 52.7615 35.3988 52.6101 35.4C52.1401 35.4 51.7401 35.59 51.7401 36.09C51.7401 36.59 52.1301 36.8 52.6501 36.8H53.0601V35.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.8201 35.0599V36.6999H54.9701L56.2301 35.0599H56.8201L55.4201 36.7599C55.6694 36.8035 55.8992 36.9229 56.0782 37.1018C56.2571 37.2808 56.3766 37.5106 56.4201 37.7599C56.5338 38.1008 56.6673 38.4347 56.8201 38.7599H56.3101C56.1788 38.4773 56.0652 38.1867 55.9701 37.8899C55.8001 37.3999 55.5701 37.0599 54.9701 37.0599H54.8201V38.7599H54.3501V35.0599H54.8201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.8201 35.06V36.62C57.8201 37.21 57.8201 37.69 57.7701 38.18C57.9707 37.7594 58.1978 37.3519 58.4501 36.96L59.6401 35.05H60.1201V38.75H59.6701V37.17C59.6701 36.58 59.6701 36.17 59.7201 35.67C59.5188 36.0987 59.2882 36.513 59.0301 36.91L57.8201 38.76H57.3101V35.06H57.8201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.4201 35.0599V36.6099H63.2101V35.0599H63.6901V38.7599H63.2101V37.0299H61.4201V38.7599H60.9401V35.0599H61.4201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M66.48 35.0599V35.4599H64.99V38.7599H64.52V35.0599H66.48Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M67.0402 37.5999L66.6602 38.7599H66.1702L67.4202 35.0599H68.0002L69.2602 38.7599H68.7502L68.3502 37.5999H67.0402ZM68.2502 37.2199L67.8902 36.1599C67.8102 35.9199 67.7502 35.6999 67.7002 35.4899C67.6402 35.7099 67.5802 35.9299 67.5102 36.1599L67.1501 37.2199H68.2502Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.559998 45.74L1.64999 47.23H1.06998L0 45.74L1.06998 44.25H1.64999L0.559998 45.74ZM1.88998 45.74L2.97 47.23H2.39999L1.32999 45.74L2.39999 44.25H2.97L1.88998 45.74Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.66 46.0599L4.09 47.7799H3.35999L5.22 42.3199H6.06998L7.92999 47.7799H7.17999L6.59 46.0599H4.66ZM6.44998 45.5099L5.91 43.9399C5.79 43.5799 5.71 43.2599 5.63 42.9399C5.55 43.2733 5.45999 43.6066 5.35999 43.9399L4.81998 45.5199L6.44998 45.5099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.42999 42.3199V44.7399H9.64999L11.52 42.3199H12.37L10.3 44.8199C10.6675 44.8731 11.0103 45.0363 11.2834 45.2879C11.5565 45.5396 11.747 45.868 11.83 46.2299C12.0033 46.7565 12.2036 47.2739 12.43 47.7799H11.67C11.4751 47.3626 11.3113 46.9314 11.18 46.4899C11.117 46.1361 10.9265 45.8176 10.6446 45.5946C10.3627 45.3717 10.0089 45.2597 9.64999 45.2799H9.42999V47.7799H8.73999V42.3199H9.42999Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.04 46.0599L13.48 47.7799H12.74L14.59 42.3199H15.44L17.31 47.7799H16.55L15.97 46.0599H14.04ZM15.83 45.5099L15.3 43.9399C15.18 43.5799 15.1 43.2599 15.02 42.9399C14.94 43.2699 14.85 43.5999 14.74 43.9399L14.21 45.5199L15.83 45.5099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.06 42.3199V47.2099H22.54L22.49 49.0799H21.94L21.89 47.7699H18.45V49.0799H17.9L17.85 47.2099H18.26C18.4821 46.8303 18.6631 46.428 18.8 46.0099C18.9977 45.2599 19.0853 44.4852 19.06 43.7099V42.3199H22.06ZM19.66 43.9299C19.681 44.6825 19.5899 45.4341 19.39 46.1599C19.2793 46.5096 19.1283 46.8452 18.94 47.1599H21.36V42.8999H19.66V43.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.2599 45.22H24.1299V47.22H26.4999V47.81H23.4299V42.35H26.3799V42.94H24.1299V44.67H26.2599V45.22Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.0201 45.4C32.0201 44.64 31.9301 43.73 31.9401 43.05C31.7601 43.69 31.5301 44.37 31.2501 45.12L30.2501 47.77H29.7401L28.86 45.17C28.6 44.4 28.38 43.69 28.23 43.05C28.23 43.73 28.1801 44.64 28.1301 45.46L27.98 47.8H27.3101L27.6901 42.34H28.5901L29.5201 44.98C29.75 45.65 29.93 46.25 30.07 46.82C30.2 46.27 30.4 45.67 30.64 44.98L31.64 42.34H32.54L32.8801 47.8H32.1901L32.0201 45.4Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.59 42.3199V44.6199C34.59 45.4899 34.59 46.1999 34.53 46.9299C34.81 46.3599 35.15 45.7699 35.53 45.1199L37.3 42.3099H38.0099V47.7699H37.34V45.4599C37.34 44.5699 37.34 43.9299 37.41 43.2399C37.1132 43.8724 36.7793 44.4868 36.41 45.0799L34.7 47.7899H33.95V42.3299L34.59 42.3199Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.74 47.78C38.8249 47.6221 38.8984 47.4584 38.96 47.29C39.1064 46.6289 39.3891 46.0057 39.79 45.46C39.9157 45.3323 40.0699 45.2363 40.24 45.18C39.9076 45.1431 39.6005 44.9851 39.3773 44.736C39.154 44.487 39.0304 44.1644 39.03 43.83C39.0241 43.6183 39.067 43.408 39.1555 43.2155C39.2439 43.023 39.3755 42.8535 39.54 42.72C39.9992 42.4112 40.5478 42.2635 41.1 42.3C41.5358 42.2971 41.9709 42.3339 42.4 42.41V47.8H41.74V45.4H41.22C41.0776 45.3884 40.9344 45.4055 40.7987 45.4501C40.663 45.4948 40.5377 45.5661 40.43 45.66C40.1026 46.1965 39.8693 46.785 39.74 47.4C39.6969 47.5403 39.6364 47.6747 39.56 47.8L38.74 47.78ZM41.74 42.89C41.5202 42.8404 41.2953 42.8169 41.07 42.82C40.38 42.82 39.79 43.09 39.79 43.82C39.79 44.55 40.37 44.82 41.14 44.82H41.74V42.89Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.43 42.3199V42.9099H46.07V44.3999C46.2964 44.3847 46.5236 44.3847 46.75 44.3999C47.2447 44.3833 47.7339 44.5082 48.16 44.7599C48.3823 44.9033 48.563 45.1025 48.6841 45.3377C48.8052 45.5729 48.8624 45.8357 48.85 46.0999C48.8584 46.3168 48.8228 46.5331 48.7455 46.7359C48.6682 46.9387 48.5507 47.1237 48.4 47.2799C47.8566 47.7195 47.1673 47.9374 46.47 47.8899C46.1024 47.8927 45.7351 47.8726 45.37 47.8299V42.3799L48.43 42.3199ZM46.07 47.2399C46.2696 47.2577 46.4704 47.2577 46.67 47.2399C47.0933 47.2547 47.5058 47.1041 47.82 46.8199C48.019 46.6017 48.1264 46.3152 48.12 46.0199C48.1312 45.8435 48.0955 45.6673 48.0164 45.5092C47.9374 45.3511 47.8178 45.2168 47.67 45.1199C47.3689 44.9371 47.0222 44.8434 46.67 44.8499H46.11L46.07 47.2399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.59 45.22H50.47V47.22H52.84V47.81H49.74V42.35H52.74V42.94H50.49V44.67H52.61L52.59 45.22Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.55 42.32V47.78H56.8401V42.91H55.0301V44.57C55.0301 45.75 54.9801 47.07 54.2101 47.57C53.9627 47.7317 53.6754 47.8217 53.3801 47.83L53.29 47.26C53.5052 47.2152 53.7051 47.1153 53.8701 46.97C54.2756 46.2505 54.4403 45.42 54.3401 44.6V42.3L57.55 42.32Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M63.44 44.99C63.4854 45.3503 63.4536 45.7162 63.3467 46.0634C63.2398 46.4105 63.0602 46.7309 62.82 47.0033C62.5798 47.2757 62.2843 47.4939 61.9533 47.6433C61.6222 47.7927 61.2632 47.87 60.9 47.87C60.5519 47.8651 60.2086 47.7875 59.8923 47.6422C59.5759 47.497 59.2933 47.2873 59.0627 47.0265C58.832 46.7658 58.6584 46.4597 58.5529 46.1279C58.4474 45.7962 58.4123 45.4461 58.4499 45.1C58.4125 44.7416 58.4499 44.3793 58.5599 44.0361C58.6699 43.6929 58.85 43.3763 59.0889 43.1064C59.3277 42.8366 59.62 42.6193 59.9473 42.4684C60.2746 42.3175 60.6296 42.2363 60.99 42.23C61.3381 42.232 61.6818 42.3072 61.999 42.4508C62.3161 42.5945 62.5994 42.8032 62.8305 43.0636C63.0616 43.3239 63.2353 43.63 63.3403 43.9619C63.4453 44.2938 63.4793 44.6441 63.44 44.99ZM59.1999 45.08C59.1999 46.25 59.83 47.3 60.94 47.3C62.05 47.3 62.69 46.3 62.69 45.03C62.69 43.76 62.1299 42.81 60.9499 42.81C59.7699 42.81 59.1999 43.88 59.1999 45.08Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.0201 42.3199V44.6199C65.0201 45.4899 65.0201 46.1999 64.9501 46.9299C65.2506 46.3091 65.5845 45.7048 65.9501 45.1199L67.7401 42.3199H68.4401V47.7799H67.7801V45.4599C67.7801 44.5699 67.7801 43.9299 67.8401 43.2399C67.5433 43.8724 67.2094 44.4868 66.8401 45.0799L65.1301 47.7899H64.3701V42.3299L65.0201 42.3199ZM65.9101 41.1799C65.9101 41.4299 66.0201 41.6799 66.4001 41.6799C66.7801 41.6799 66.8701 41.4499 66.9001 41.1799H67.4401C67.4401 41.7099 67.0701 42.0499 66.3901 42.0499C66.266 42.0654 66.14 42.0545 66.0204 42.0181C65.9008 41.9816 65.7902 41.9203 65.6959 41.8382C65.6015 41.7561 65.5255 41.6551 65.4728 41.5417C65.4202 41.4282 65.392 41.305 65.3901 41.1799H65.9101Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.3999 42.4C71.8258 42.3252 72.2575 42.2883 72.6899 42.29C73.2216 42.2403 73.7536 42.3821 74.1899 42.69C74.3429 42.8054 74.4644 42.9576 74.543 43.1323C74.6217 43.3071 74.655 43.4989 74.6399 43.69C74.6308 43.961 74.5373 44.2224 74.3723 44.4375C74.2074 44.6527 73.9792 44.8109 73.7199 44.89C74.0349 44.9562 74.3178 45.1282 74.5216 45.3774C74.7253 45.6267 74.8377 45.9381 74.8399 46.26C74.8442 46.4638 74.8054 46.6662 74.7261 46.8541C74.6469 47.0419 74.5289 47.2109 74.3799 47.35C73.8342 47.7469 73.1612 47.9285 72.4899 47.86C72.1257 47.8625 71.7617 47.8424 71.3999 47.8V42.4ZM72.0999 44.64H72.7399C73.4899 44.64 73.9299 44.25 73.9299 43.72C73.9299 43.19 73.4399 42.83 72.7299 42.83C72.5188 42.8273 72.3079 42.844 72.0999 42.88V44.64ZM72.0999 47.26C72.2997 47.2732 72.5001 47.2732 72.6999 47.26C73.4199 47.26 74.0999 46.99 74.0999 46.2C74.0999 45.41 73.4599 45.14 72.6899 45.14H72.0999V47.26Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M80.3899 44.99C80.4353 45.3503 80.4035 45.7162 80.2966 46.0634C80.1897 46.4105 80.0102 46.7309 79.77 47.0033C79.5297 47.2757 79.2342 47.4939 78.9032 47.6433C78.5722 47.7927 78.2131 47.87 77.8499 47.87C77.5018 47.8651 77.1586 47.7875 76.8422 47.6422C76.5258 47.497 76.2433 47.2873 76.0126 47.0265C75.782 46.7658 75.6084 46.4597 75.5028 46.1279C75.3973 45.7962 75.3623 45.4461 75.3999 45.1C75.3624 44.7416 75.3999 44.3793 75.5099 44.0361C75.6198 43.6929 75.7999 43.3763 76.0388 43.1064C76.2776 42.8366 76.57 42.6193 76.8972 42.4684C77.2245 42.3175 77.5796 42.2363 77.9399 42.23C78.288 42.232 78.6318 42.3072 78.9489 42.4508C79.266 42.5945 79.5494 42.8032 79.7805 43.0636C80.0116 43.3239 80.1852 43.63 80.2903 43.9619C80.3953 44.2938 80.4292 44.6441 80.3899 44.99ZM76.1499 45.08C76.1499 46.25 76.7799 47.3 77.8899 47.3C78.9999 47.3 79.6399 46.3 79.6399 45.03C79.6399 43.76 79.0799 42.81 77.8999 42.81C76.7199 42.81 76.1499 43.88 76.1499 45.08Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.8699 42.3199V47.2099H85.3499L85.2999 49.0799H84.7399V47.7799H81.2899V49.0899H80.7399L80.6899 47.2199H81.0999C81.3147 46.8379 81.4922 46.436 81.6299 46.0199C81.8277 45.2699 81.9152 44.4952 81.8899 43.7199V42.3299L84.8699 42.3199ZM82.4699 43.9299C82.4931 44.6833 82.3986 45.4357 82.1899 46.1599C82.0785 46.5077 81.931 46.8428 81.7499 47.1599H84.1599V42.8999H82.4699V43.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M86.94 42.3199V44.3199C87.1 44.3199 87.41 44.3199 87.59 44.3199C88.71 44.3199 89.7 44.8199 89.7 46.0299C89.7125 46.4577 89.5583 46.8737 89.27 47.1899C88.7337 47.652 88.0359 47.8822 87.33 47.8299C86.9658 47.8328 86.6017 47.8127 86.24 47.7699V42.3199H86.94ZM86.94 47.2399C87.1295 47.2586 87.3204 47.2586 87.51 47.2399C88.29 47.2399 88.96 46.8399 88.96 46.0199C88.96 45.1999 88.23 44.8399 87.5 44.8399C87.3135 44.8284 87.1265 44.8284 86.94 44.8399V47.2399ZM91.02 42.3199V47.7799H90.3V42.3199H91.02Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M91.8501 44.25H92.4201L93.5001 45.74L92.4201 47.23H91.8601L92.9301 45.74L91.8501 44.25ZM93.1701 44.25H93.7401L94.8201 45.74L93.7401 47.23H93.1801L94.2501 45.74L93.1701 44.25Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }