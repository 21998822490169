<template>
  <g @click="$emit('click')">
    <circle
      cx="46"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M54.9199 11.31H37.0699L46.1599 6L54.9199 11.31ZM39.8399 10.56H52.2199L46.1299 6.9L39.8399 10.56Z"
      fill="#E73C14"
    />
    <path
      d="M46.3399 24.6199H36.3999V24.2499C36.3999 23.2704 36.7883 22.3308 37.4801 21.6372C38.1718 20.9436 39.1103 20.5526 40.0899 20.5499H42.6399C43.6212 20.5499 44.5623 20.9397 45.2562 21.6336C45.9501 22.3275 46.3399 23.2686 46.3399 24.2499V24.6199ZM37.1699 23.8699H45.5599C45.4698 23.1594 45.1233 22.5062 44.5857 22.033C44.048 21.5598 43.3561 21.2991 42.6399 21.2999H40.0899C39.3772 21.3116 38.692 21.5766 38.1569 22.0475C37.6219 22.5185 37.272 23.1645 37.1699 23.8699Z"
      fill="#E73C14"
    />
    <path
      d="M46.3399 24.2499L45.5899 24.2499V15.4999C45.5745 14.7233 45.2584 13.9829 44.7082 13.4346C44.158 12.8863 43.4166 12.5727 42.6399 12.5599H40.0899C39.3102 12.5599 38.5624 12.8697 38.011 13.421C37.4597 13.9724 37.1499 14.7202 37.1499 15.4999V24.2499L36.3999 24.2499L36.3999 15.4999C36.3999 14.5213 36.7887 13.5827 37.4807 12.8907C38.1727 12.1987 39.1113 11.8099 40.0899 11.8099H42.6399C43.6195 11.8099 44.5591 12.1984 45.2527 12.8901C45.9463 13.5818 46.3373 14.5204 46.3399 15.4999V24.2499Z"
      fill="#E73C14"
    />
    <path
      d="M55.5299 24.6199H45.5899V24.2499C45.5899 23.2704 45.9784 22.3308 46.6701 21.6372C47.3618 20.9436 48.3003 20.5526 49.2799 20.5499H51.8399C52.8195 20.5526 53.758 20.9436 54.4497 21.6372C55.1415 22.3308 55.5299 23.2704 55.5299 24.2499V24.6199ZM46.3599 23.8699H54.7599C54.6578 23.1645 54.3079 22.5185 53.7729 22.0475C53.2378 21.5766 52.5526 21.3116 51.8399 21.2999H49.2799C48.5672 21.3116 47.882 21.5766 47.3469 22.0475C46.8119 22.5185 46.462 23.1645 46.3599 23.8699Z"
      fill="#E73C14"
    />
    <path
      d="M55.5299 24.2499L54.7799 24.2499V15.4999C54.7799 14.7202 54.4702 13.9724 53.9188 13.421C53.3675 12.8697 52.6196 12.5599 51.8399 12.5599H49.2799C48.5002 12.5599 47.7524 12.8697 47.201 13.421C46.6497 13.9724 46.3399 14.7202 46.3399 15.4999V24.2499L45.5899 24.2499V15.4999C45.5899 14.5213 45.9787 13.5827 46.6707 12.8907C47.3627 12.1987 48.3013 11.8099 49.2799 11.8099H51.8399C52.8186 11.8099 53.7571 12.1987 54.4491 12.8907C55.1411 13.5827 55.5299 14.5213 55.5299 15.4999V24.2499Z"
      fill="#E73C14"
    />
    <path
      d="M32.2701 35.0599V38.3499H33.47V35.0599H33.94V38.3499H35.1301V35.0599H35.61V38.7899H31.8V35.0999L32.2701 35.0599Z"
      fill="black"
    />
    <path
      d="M36.9099 35.0599V36.7H37.0599L38.3199 35.0599H38.8999L37.4999 36.7899C37.7487 36.8348 37.9777 36.9547 38.1565 37.1334C38.3352 37.3121 38.455 37.5412 38.4999 37.7899C38.6399 38.1699 38.7499 38.4999 38.9099 38.7899H38.3999C38.2627 38.5099 38.1489 38.2189 38.0599 37.92C38.0173 37.687 37.8935 37.4767 37.7103 37.3265C37.5272 37.1764 37.2967 37.0961 37.0599 37.0999H36.9099V38.7899H36.4399V35.0999L36.9099 35.0599Z"
      fill="black"
    />
    <path
      d="M42.4599 36.87C42.4897 37.1133 42.4674 37.3602 42.3945 37.5943C42.3217 37.8284 42.1999 38.0443 42.0372 38.2277C41.8746 38.4111 41.6748 38.5579 41.4512 38.6583C41.2275 38.7587 40.9851 38.8104 40.7399 38.81C40.5045 38.8072 40.2723 38.7552 40.0582 38.6574C39.8441 38.5596 39.6528 38.4181 39.4965 38.2421C39.3402 38.066 39.2224 37.8593 39.1507 37.635C39.079 37.4108 39.0548 37.1741 39.0799 36.94C39.055 36.6975 39.0806 36.4525 39.1553 36.2205C39.23 35.9885 39.352 35.7745 39.5137 35.5921C39.6754 35.4098 39.8733 35.263 40.0947 35.161C40.3161 35.0591 40.5562 35.0043 40.7999 35C41.0358 35.0013 41.2688 35.0522 41.4837 35.1495C41.6985 35.2468 41.8905 35.3883 42.0471 35.5647C42.2037 35.7411 42.3214 35.9485 42.3925 36.1734C42.4637 36.3983 42.4866 36.6356 42.4599 36.87ZM39.5899 36.93C39.5899 37.72 40.0199 38.42 40.7699 38.42C41.5199 38.42 41.95 37.73 41.95 36.89C41.95 36.05 41.5699 35.39 40.7799 35.39C39.9899 35.39 39.5899 36.12 39.5899 36.93Z"
      fill="black"
    />
    <path
      d="M45.4801 35.0599V38.7899H45.0001V35.4999H43.7801V36.6199C43.7801 37.4199 43.7801 38.3099 43.2301 38.6199C43.0597 38.73 42.8628 38.7922 42.6601 38.8L42.6001 38.4099C42.7451 38.3837 42.8801 38.318 42.9901 38.2199C43.2691 37.736 43.3815 37.1739 43.3101 36.6199V35.0599H45.4801Z"
      fill="black"
    />
    <path
      d="M46.91 37.5899L46.52 38.7499H46L47.26 35.0599H47.83L49.09 38.7499H48.58L48.19 37.5899H46.91ZM48.12 37.2199L47.76 36.1599C47.67 35.9199 47.62 35.6999 47.56 35.4799C47.51 35.6999 47.45 35.9299 47.38 36.1499L47.02 37.2199H48.12Z"
      fill="black"
    />
    <path
      d="M53.94 37.1299C53.94 36.6199 53.88 35.9999 53.88 35.5399C53.75 35.9699 53.6 36.4299 53.41 36.9399L52.76 38.7299H52.4L51.8 36.9699C51.62 36.4499 51.48 35.9699 51.37 35.5399C51.37 35.9999 51.37 36.62 51.3 37.17L51.2 38.7499H50.75L51 35.0599H51.61L52.24 36.8499C52.3858 37.2562 52.5093 37.6702 52.61 38.0899C52.7 37.7199 52.84 37.3099 53 36.8499L53.66 35.0599H54.27L54.5 38.7499H54L53.94 37.1299Z"
      fill="black"
    />
    <path
      d="M57.19 35.0599V35.46H55.71V38.7899H55.23V35.0999L57.19 35.0599Z"
      fill="black"
    />
    <path
      d="M58 35.0599L58.73 36.6299C58.83 36.8499 58.91 37.0699 59 37.2899L59.23 36.6199L59.81 35.0599H60.31L59.54 36.96C59.3629 37.4509 59.1314 37.9205 58.85 38.36C58.7585 38.4989 58.6337 38.6129 58.487 38.6914C58.3404 38.77 58.1764 38.8107 58.01 38.8099C57.9205 38.8234 57.8295 38.8234 57.74 38.8099L57.79 38.42H57.97C58.35 38.42 58.58 38.0499 58.69 37.7799C58.7115 37.7473 58.7229 37.7091 58.7229 37.67C58.7229 37.6309 58.7115 37.5926 58.69 37.5599L57.47 35.0699L58 35.0599Z"
      fill="black"
    />
    <path
      d="M0.589996 45.7899L1.67999 47.2799H1.10001L0 45.7899L1.07001 44.2999H1.64999L0.589996 45.7899ZM1.92 45.7899L3.00999 47.2799H2.42999L1.36 45.7899L2.42999 44.2999H3L1.92 45.7899Z"
      fill="black"
    />
    <path
      d="M4.47006 42.3199V44.6199C4.47006 45.4899 4.47006 46.2 4.41006 46.93C4.69006 46.36 5.03006 45.7699 5.41006 45.1199L7.17006 42.3099H7.88007V47.7899H7.21007V45.46C7.21007 44.58 7.21006 43.93 7.28006 43.24C6.98931 43.8755 6.65519 44.4903 6.28006 45.08L4.56006 47.7899H3.81006V42.33L4.47006 42.3199Z"
      fill="black"
    />
    <path
      d="M13.74 45.38C13.74 44.62 13.65 43.7 13.66 43.02C13.48 43.66 13.25 44.34 12.98 45.1L12 47.79H11.47L10.58 45.19C10.32 44.41 10.1 43.71 9.95 43.06C9.95 43.74 9.89 44.66 9.84 45.48L9.69 47.82H9L9.38 42.36H10.28L11.21 45C11.4255 45.6032 11.609 46.2174 11.76 46.84C11.9 46.29 12.09 45.69 12.33 45L13.33 42.36H14.23L14.57 47.82H13.88L13.74 45.38Z"
      fill="black"
    />
    <path
      d="M18.25 46.0599L17.68 47.7799H17L18.86 42.3199H19.71L21.57 47.7799H20.82L20.23 46.0599H18.25ZM20.04 45.5099L19.5 43.9399C19.38 43.5799 19.3 43.2599 19.22 42.9399C19.14 43.2733 19.05 43.6066 18.95 43.9399L18.41 45.52L20.04 45.5099Z"
      fill="black"
    />
    <path
      d="M22.1301 47.36C22.126 47.2945 22.1354 47.2288 22.1576 47.1671C22.1799 47.1053 22.2146 47.0488 22.2596 47.001C22.3046 46.9532 22.3589 46.9151 22.4192 46.8892C22.4795 46.8632 22.5444 46.8498 22.6101 46.85C22.7256 46.8732 22.8295 46.9357 22.9042 47.0268C22.9788 47.118 23.0196 47.2322 23.0196 47.35C23.0196 47.4678 22.9788 47.582 22.9042 47.6731C22.8295 47.7643 22.7256 47.8268 22.6101 47.85C22.5462 47.85 22.4829 47.8372 22.424 47.8125C22.3652 47.7878 22.3118 47.7515 22.2671 47.7059C22.2224 47.6602 22.1872 47.6061 22.1637 47.5468C22.1402 47.4874 22.1288 47.4239 22.1301 47.36Z"
      fill="black"
    />
    <path
      d="M26.42 42.3199V44.5999H29.06V42.3199H29.77V47.7899H29.06V45.2299H26.42V47.7899H25.71V42.33L26.42 42.3199Z"
      fill="black"
    />
    <path
      d="M30.84 47.3599C30.844 47.2636 30.8763 47.1705 30.933 47.0924C30.9896 47.0143 31.068 46.9546 31.1584 46.9209C31.2488 46.8872 31.3471 46.8809 31.441 46.9028C31.535 46.9248 31.6203 46.974 31.6865 47.0442C31.7526 47.1144 31.7965 47.2026 31.8127 47.2977C31.8289 47.3928 31.8167 47.4906 31.7775 47.5788C31.7384 47.667 31.6741 47.7416 31.5927 47.7934C31.5114 47.8452 31.4165 47.8718 31.3201 47.8699C31.2544 47.87 31.1894 47.8567 31.1292 47.8307C31.0689 47.8048 31.0146 47.7667 30.9696 47.7189C30.9246 47.6711 30.8899 47.6146 30.8676 47.5528C30.8453 47.4911 30.8359 47.4254 30.84 47.3599Z"
      fill="black"
    />
    <path
      d="M35.1299 42.3199V44.7899H35.3399L37.2199 42.3699H38.0699L35.9999 44.7899C36.369 44.8391 36.714 45.0008 36.9878 45.2531C37.2616 45.5055 37.4509 45.8361 37.5299 46.2C37.7006 46.7275 37.9009 47.245 38.1299 47.7499H37.3699C37.1707 47.3345 37.0068 46.9029 36.8799 46.46C36.6199 45.74 36.2799 45.24 35.3499 45.24H35.1299V47.7899H34.4299V42.33L35.1299 42.3199Z"
      fill="black"
    />
    <path
      d="M43.3301 44.99C43.3301 46.87 42.1901 47.87 40.8001 47.87C40.4512 47.8664 40.1069 47.7899 39.7893 47.6453C39.4717 47.5006 39.188 47.2911 38.9562 47.0302C38.7245 46.7693 38.55 46.4627 38.4439 46.1303C38.3378 45.7978 38.3024 45.4469 38.3402 45.1C38.3011 44.7413 38.3375 44.3783 38.4469 44.0345C38.5563 43.6906 38.7363 43.3735 38.9754 43.1032C39.2146 42.833 39.5075 42.6158 39.8355 42.4654C40.1635 42.315 40.5194 42.2348 40.8802 42.23C41.2283 42.232 41.5721 42.3072 41.8892 42.4508C42.2063 42.5945 42.4896 42.8032 42.7207 43.0636C42.9518 43.3239 43.1255 43.63 43.2305 43.9619C43.3355 44.2938 43.3695 44.6441 43.3301 44.99ZM39.0902 45.08C39.0902 46.25 39.7302 47.29 40.8402 47.29C41.9502 47.29 42.5902 46.29 42.5902 45.02C42.5902 43.94 42.0202 42.8 40.8402 42.8C39.6602 42.8 39.0902 43.88 39.0902 45.08Z"
      fill="black"
    />
    <path
      d="M47.8 42.3199V47.7899H47.09V42.92H45.28V44.5699C45.28 45.7499 45.28 47.0799 44.46 47.5699C44.212 47.7302 43.9251 47.8201 43.63 47.83L43.54 47.27C43.7546 47.2258 43.9521 47.1217 44.11 46.9699C44.523 46.2566 44.6916 45.4279 44.59 44.61V42.3099L47.8 42.3199Z"
      fill="black"
    />
    <path
      d="M53.6301 45.3799C53.5801 44.6199 53.5401 43.7 53.5401 43.02C53.3501 43.66 53.1301 44.3399 52.8501 45.0999L51.8501 47.7499H51.3101L50.4301 45.1499C50.1701 44.3699 49.9501 43.67 49.8001 43.02C49.8001 43.7 49.7401 44.6199 49.7001 45.4399L49.5501 47.7799H48.8801L49.2601 42.3199H50.1601L51.0901 44.96C51.3201 45.63 51.5001 46.23 51.6401 46.8C51.7801 46.25 51.9801 45.65 52.2201 44.96L53.2201 42.3199H54.1201L54.4601 47.7799H53.7701L53.6301 45.3799Z"
      fill="black"
    />
    <path
      d="M60.2 44.99C60.2 46.87 59.06 47.87 57.67 47.87C57.3211 47.8664 56.9768 47.7899 56.6592 47.6453C56.3416 47.5006 56.0578 47.2911 55.8261 47.0302C55.5944 46.7693 55.4199 46.4627 55.3138 46.1303C55.2077 45.7978 55.1723 45.4469 55.21 45.1C55.171 44.7413 55.2073 44.3783 55.3167 44.0345C55.4261 43.6906 55.6062 43.3735 55.8453 43.1032C56.0844 42.833 56.3774 42.6158 56.7054 42.4654C57.0334 42.315 57.3892 42.2348 57.75 42.23C58.0981 42.232 58.4419 42.3072 58.759 42.4508C59.0761 42.5945 59.3595 42.8032 59.5906 43.0636C59.8217 43.3239 59.9954 43.63 60.1004 43.9619C60.2054 44.2938 60.2394 44.6441 60.2 44.99ZM55.96 45.08C55.96 46.25 56.59 47.29 57.71 47.29C58.83 47.29 59.46 46.29 59.46 45.02C59.46 43.94 58.89 42.8 57.71 42.8C56.53 42.8 56 43.88 56 45.08H55.96Z"
      fill="black"
    />
    <path
      d="M64.0001 42.3199V42.9099H61.8101V47.7899H61.1001V42.33L64.0001 42.3199Z"
      fill="black"
    />
    <path
      d="M69.0001 44.99C69.0001 46.87 67.8601 47.87 66.4701 47.87C66.1216 47.8649 65.778 47.7874 65.4612 47.6422C65.1444 47.4971 64.8612 47.2875 64.6298 47.0269C64.3984 46.7664 64.2238 46.4605 64.1171 46.1287C64.0104 45.7969 63.9739 45.4466 64.0101 45.1C63.971 44.7413 64.0074 44.3783 64.1168 44.0345C64.2262 43.6906 64.4062 43.3735 64.6454 43.1032C64.8845 42.833 65.1775 42.6158 65.5055 42.4654C65.8335 42.315 66.1893 42.2348 66.5501 42.23C66.8982 42.232 67.242 42.3072 67.5591 42.4508C67.8762 42.5945 68.1595 42.8032 68.3906 43.0636C68.6217 43.3239 68.7954 43.63 68.9004 43.9619C69.0054 44.2938 69.0394 44.6441 69.0001 44.99ZM64.7701 45.08C64.7701 46.25 65.4001 47.29 66.5101 47.29C67.6201 47.29 68.2601 46.29 68.2601 45.02C68.2601 43.94 67.6901 42.8 66.5201 42.8C65.3501 42.8 64.7701 43.88 64.7701 45.08Z"
      fill="black"
    />
    <path
      d="M69.9202 42.38C70.367 42.3128 70.8183 42.2794 71.2701 42.28C71.8181 42.2372 72.3626 42.3974 72.8001 42.73C72.9582 42.8737 73.083 43.0502 73.1659 43.2471C73.2488 43.4439 73.2878 43.6565 73.2802 43.87C73.2906 44.0831 73.2589 44.2961 73.1868 44.4969C73.1148 44.6977 73.0037 44.8822 72.8601 45.04C72.637 45.248 72.3739 45.4084 72.0868 45.5116C71.7997 45.6147 71.4947 45.6584 71.1902 45.64C70.9989 45.6455 70.8076 45.6287 70.6201 45.59V47.79H69.9202V42.38ZM70.6201 45.02C70.8161 45.0434 71.0142 45.0434 71.2101 45.02C72.0601 45.02 72.5801 44.61 72.5801 43.86C72.5801 43.11 72.0602 42.79 71.2902 42.79C71.0657 42.7837 70.8412 42.8004 70.6201 42.84V45.02Z"
      fill="black"
    />
    <path
      d="M78.8299 44.99C78.8753 45.3503 78.8435 45.7162 78.7367 46.0634C78.6298 46.4105 78.4502 46.7309 78.21 47.0033C77.9697 47.2757 77.6743 47.4939 77.3432 47.6433C77.0122 47.7927 76.6531 47.87 76.2899 47.87C75.9418 47.865 75.5986 47.7875 75.2822 47.6422C74.9659 47.497 74.6833 47.2873 74.4527 47.0265C74.222 46.7658 74.0484 46.4597 73.9429 46.128C73.8373 45.7962 73.8023 45.4461 73.8399 45.1C73.801 44.7421 73.837 44.38 73.9458 44.0368C74.0546 43.6937 74.2338 43.3769 74.4718 43.1069C74.7099 42.8368 75.0016 42.6194 75.3285 42.4684C75.6553 42.3174 76.01 42.2362 76.3699 42.23C76.7184 42.2321 77.0626 42.3074 77.3801 42.4509C77.6977 42.5944 77.9816 42.803 78.2134 43.0632C78.4453 43.3233 78.62 43.6292 78.7262 43.9612C78.8324 44.2931 78.8677 44.6436 78.8299 44.99ZM74.5899 45.08C74.5899 46.25 75.2199 47.29 76.3299 47.29C77.4399 47.29 78.0799 46.29 78.0799 45.02C78.0799 43.94 77.5199 42.8 76.3399 42.8C75.1599 42.8 74.5899 43.88 74.5899 45.08Z"
      fill="black"
    />
    <path
      d="M79.74 42.3901C80.1659 42.3157 80.5976 42.2788 81.03 42.28C81.5601 42.2326 82.0902 42.3704 82.53 42.6701C82.6817 42.7867 82.8021 42.939 82.8806 43.1135C82.9591 43.288 82.9933 43.4792 82.98 43.6701C82.9709 43.941 82.8774 44.2024 82.7124 44.4176C82.5474 44.6327 82.3193 44.7909 82.06 44.87C82.3759 44.9345 82.6598 45.1061 82.8639 45.3557C83.0679 45.6053 83.1796 45.9177 83.18 46.2401C83.1844 46.4454 83.1458 46.6493 83.0665 46.8388C82.9873 47.0282 82.8693 47.199 82.72 47.34C82.1751 47.7387 81.5014 47.9205 80.83 47.85C80.4655 47.85 80.1015 47.8266 79.74 47.78V42.3901ZM80.45 44.63H81.09C81.83 44.63 82.27 44.2401 82.27 43.7101C82.27 43.1801 81.78 42.82 81.07 42.82C80.8621 42.8118 80.6539 42.8286 80.45 42.87V44.63ZM80.45 47.2401C80.6462 47.2594 80.8438 47.2594 81.04 47.2401C81.77 47.2401 82.44 46.9701 82.44 46.1701C82.44 45.3701 81.8 45.12 81.03 45.12H80.45V47.2401Z"
      fill="black"
    />
    <path
      d="M85 46.0599L84.44 47.7799H83.71L85.56 42.3199H86.41L88.28 47.7799H87.52L86.94 46.0599H85ZM86.79 45.5099L86.26 43.9399C86.14 43.5799 86.06 43.2599 85.98 42.9399C85.9 43.2699 85.81 43.5999 85.7 43.9399L85.17 45.52L86.79 45.5099Z"
      fill="black"
    />
    <path
      d="M88.6599 44.25H89.2299L90.3099 45.74L89.2299 47.23H88.6699L89.7399 45.74L88.6599 44.25ZM89.9799 44.25H90.5499L91.6299 45.74L90.5499 47.23H89.9999L91.0699 45.74L89.9799 44.25Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconSchoolKolmogorova",
};
</script>

<style scoped lang="scss"></style>
