var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "26",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.9199 11.31H17.0699L26.1599 6L34.9199 11.31ZM19.8399 10.56H32.2199L26.1299 6.9L19.8399 10.56Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.3399 24.6199H16.3999V24.2499C16.3999 23.2704 16.7883 22.3308 17.4801 21.6372C18.1718 20.9436 19.1103 20.5526 20.0899 20.5499H22.6399C23.6212 20.5499 24.5623 20.9397 25.2562 21.6336C25.9501 22.3275 26.3399 23.2686 26.3399 24.2499V24.6199ZM17.1699 23.8699H25.5599C25.4698 23.1594 25.1233 22.5062 24.5857 22.033C24.048 21.5598 23.3561 21.2991 22.6399 21.2999H20.0899C19.3772 21.3116 18.692 21.5766 18.1569 22.0475C17.6219 22.5185 17.272 23.1645 17.1699 23.8699Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.3399 24.2499L25.5899 24.2499V15.4999C25.5745 14.7233 25.2584 13.9829 24.7082 13.4346C24.158 12.8863 23.4166 12.5727 22.6399 12.5599H20.0899C19.3102 12.5599 18.5624 12.8697 18.011 13.421C17.4597 13.9724 17.1499 14.7202 17.1499 15.4999V24.2499L16.3999 24.2499L16.3999 15.4999C16.3999 14.5213 16.7887 13.5827 17.4807 12.8907C18.1727 12.1987 19.1113 11.8099 20.0899 11.8099H22.6399C23.6195 11.8099 24.5591 12.1984 25.2527 12.8901C25.9463 13.5818 26.3373 14.5204 26.3399 15.4999V24.2499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.5299 24.6199H25.5899V24.2499C25.5899 23.2704 25.9784 22.3308 26.6701 21.6372C27.3618 20.9436 28.3003 20.5526 29.2799 20.5499H31.8399C32.8195 20.5526 33.758 20.9436 34.4497 21.6372C35.1415 22.3308 35.5299 23.2704 35.5299 24.2499V24.6199ZM26.3599 23.8699H34.7599C34.6578 23.1645 34.3079 22.5185 33.7729 22.0475C33.2378 21.5766 32.5526 21.3116 31.8399 21.2999H29.2799C28.5672 21.3116 27.882 21.5766 27.3469 22.0475C26.8119 22.5185 26.462 23.1645 26.3599 23.8699Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.5299 24.2499L34.7799 24.2499V15.4999C34.7799 14.7202 34.4702 13.9724 33.9188 13.421C33.3675 12.8697 32.6196 12.5599 31.8399 12.5599H29.2799C28.5002 12.5599 27.7524 12.8697 27.201 13.421C26.6497 13.9724 26.3399 14.7202 26.3399 15.4999V24.2499L25.5899 24.2499V15.4999C25.5899 14.5213 25.9787 13.5827 26.6707 12.8907C27.3627 12.1987 28.3013 11.8099 29.2799 11.8099H31.8399C32.8186 11.8099 33.7571 12.1987 34.4491 12.8907C35.1411 13.5827 35.5299 14.5213 35.5299 15.4999V24.2499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.37994 35.0801V39.9501H3.14993V35.0801H3.84995V39.9501H5.61993V35.0801H6.31995V40.5501H0.679932V35.0801H1.37994Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.24005 35.0801V37.5101H8.47006L10.3401 35.0801H11.1901L9.12006 37.5901C9.48914 37.6392 9.83411 37.8009 10.1079 38.0533C10.3817 38.3056 10.571 38.6362 10.6501 39.0001C10.8601 39.5601 11.0101 40.0601 11.2501 40.5501H10.4701C10.2768 40.1321 10.113 39.701 9.98007 39.2601C9.91764 38.9089 9.73048 38.5921 9.45303 38.3679C9.17559 38.1438 8.82652 38.0273 8.47006 38.0401H8.25006V40.5501H7.55005V35.0801H8.24005Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.47 37.76C16.5154 38.119 16.4838 38.4836 16.3775 38.8296C16.2711 39.1755 16.0923 39.4948 15.853 39.7663C15.6137 40.0377 15.3193 40.2552 14.9895 40.4041C14.6596 40.553 14.3019 40.63 13.94 40.63C13.5902 40.6278 13.2447 40.5523 12.926 40.4083C12.6072 40.2643 12.3222 40.055 12.0894 39.7939C11.8566 39.5328 11.6812 39.2258 11.5745 38.8927C11.4678 38.5595 11.4322 38.2077 11.47 37.86C11.4324 37.5021 11.4699 37.1402 11.5799 36.7976C11.69 36.4549 11.8703 36.139 12.1093 35.8699C12.3483 35.6008 12.6407 35.3845 12.968 35.2347C13.2952 35.0849 13.6501 35.005 14.01 35C14.3589 35.0006 14.7038 35.0748 15.0221 35.2178C15.3404 35.3608 15.625 35.5694 15.8572 35.8299C16.0893 36.0904 16.264 36.3969 16.3695 36.7295C16.4751 37.0621 16.5094 37.4133 16.47 37.76ZM12.2399 37.85C12.2399 39.01 12.87 40.06 13.98 40.06C15.09 40.06 15.73 39.06 15.73 37.79C15.73 36.52 15.1599 35.57 13.9899 35.57C12.8199 35.57 12.2099 36.7 12.2099 37.85H12.2399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.9099 35.0801V40.5501H20.1999V35.7001H18.3899V37.3501C18.3899 38.5301 18.3899 39.8501 17.5799 40.3501C17.3281 40.5102 17.0381 40.6 16.7399 40.6101L16.6499 40.0401C16.8651 39.9952 17.0649 39.8953 17.2299 39.7501C17.6382 39.035 17.8064 38.2078 17.7099 37.3901V35.0801H20.9099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.02 38.8301L22.47 40.5501H21.74L23.6 35.0801H24.47L26.33 40.5501H25.58L24.99 38.8301H23.02ZM24.81 38.2801L24.27 36.7001C24.15 36.3501 24.07 36.0201 23.99 35.7001C23.91 36.0201 23.82 36.3534 23.72 36.7001L23.18 38.2801H24.81Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.79 40.55V35.28H29.57L30.95 37.88C31.26 38.5 31.55 39.09 31.77 39.64C31.71 38.9 31.7 38.29 31.7 37.45V35.28H32.33V40.55H31.61L30.22 37.93C29.92 37.34 29.63 36.74 29.39 36.14C29.39 36.82 29.39 37.42 29.39 38.36V40.54L28.79 40.55ZM35.36 36.79C35.36 37.1295 35.2252 37.4551 34.9851 37.6951C34.7451 37.9352 34.4195 38.07 34.08 38.07C33.7405 38.07 33.415 37.9352 33.1749 37.6951C32.9349 37.4551 32.8 37.1295 32.8 36.79C32.7818 36.61 32.8016 36.4282 32.8582 36.2564C32.9149 36.0845 33.007 35.9265 33.1286 35.7926C33.2503 35.6587 33.3988 35.5519 33.5644 35.4791C33.73 35.4063 33.9091 35.3691 34.09 35.37C34.2717 35.3637 34.4525 35.3974 34.6197 35.4687C34.7869 35.54 34.9364 35.6472 35.0576 35.7826C35.1787 35.9181 35.2686 36.0786 35.3209 36.2527C35.3732 36.4268 35.3865 36.6102 35.36 36.79ZM33.02 38.97V38.55H35.15V38.97H33.02ZM33.39 36.82C33.39 37.39 33.7 37.82 34.08 37.82C34.46 37.82 34.78 37.41 34.78 36.82C34.78 36.23 34.59 35.82 34.08 35.82C33.57 35.82 33.39 36.33 33.39 36.82Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.97 40.5501V40.1101L36.53 39.5601C37.88 38.2801 38.53 37.5601 38.53 36.8101C38.5388 36.6765 38.519 36.5425 38.4719 36.4172C38.4247 36.2919 38.3514 36.178 38.2567 36.0834C38.162 35.9887 38.0482 35.9153 37.9229 35.8682C37.7976 35.8211 37.6636 35.8012 37.53 35.8101C37.1151 35.8225 36.7181 35.982 36.41 36.2601L36.18 35.7501C36.6048 35.4073 37.1341 35.2203 37.68 35.2201C37.8785 35.2134 38.0763 35.2467 38.2617 35.3181C38.4471 35.3894 38.6162 35.4974 38.759 35.6355C38.9017 35.7736 39.0152 35.939 39.0927 36.1219C39.1702 36.3048 39.2101 36.5014 39.21 36.7001C39.21 37.7001 38.5 38.4601 37.39 39.5301L36.97 39.9201H39.34V40.5201L35.97 40.5501Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.6799 35.94L40.7699 36.44L40.6299 35.89L41.7799 35.28H42.3899V40.55H41.6999L41.6799 35.94Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.7899 37.8499C47.7899 39.6499 47.1199 40.6299 45.9499 40.6299C44.7799 40.6299 44.2299 39.6299 44.2099 37.9299C44.1898 36.2299 44.9699 35.1899 46.0399 35.1899C47.1099 35.1899 47.7899 36.1799 47.7899 37.8499ZM44.9299 37.9399C44.9299 39.3099 45.3498 40.0799 45.9898 40.0799C46.6298 40.0799 47.0699 39.2299 47.0699 37.8899C47.0699 36.5499 46.7399 35.6999 45.9999 35.6999C45.2599 35.6999 44.9299 36.4999 44.9299 37.9399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.9899 35.94L49.0699 36.44L48.9399 35.89L50.0899 35.28H50.7V40.55H50.0099L49.9899 35.94Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }