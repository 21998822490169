<template>
  <g @click="$emit('click')">
    <g clip-path="url(#clip7)">
      <circle
        cx="17.5"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M26 20.09H25C25 19.6921 24.842 19.3106 24.5607 19.0293C24.2794 18.748 23.8978 18.59 23.5 18.59C23.1022 18.59 22.7207 18.748 22.4394 19.0293C22.1581 19.3106 22 19.6921 22 20.09H21C21 19.6921 20.842 19.3106 20.5607 19.0293C20.2794 18.748 19.8978 18.59 19.5 18.59C19.1022 18.59 18.7207 18.748 18.4394 19.0293C18.1581 19.3106 18 19.6921 18 20.09H17C17 19.6921 16.842 19.3106 16.5607 19.0293C16.2794 18.748 15.8978 18.59 15.5 18.59C15.1022 18.59 14.7207 18.748 14.4394 19.0293C14.1581 19.3106 14 19.6921 14 20.09H13C13 19.6921 12.842 19.3106 12.5607 19.0293C12.2794 18.748 11.8978 18.59 11.5 18.59C11.1022 18.59 10.7207 18.748 10.4394 19.0293C10.1581 19.3106 10 19.6921 10 20.09H9C9.00276 19.5678 9.16896 19.0596 9.47525 18.6367C9.78154 18.2138 10.2126 17.8974 10.7078 17.732C11.2031 17.5665 11.7377 17.5603 12.2367 17.7142C12.7357 17.8681 13.174 18.1743 13.49 18.59C13.7229 18.2795 14.0248 18.0275 14.372 17.8539C14.7191 17.6803 15.1019 17.59 15.49 17.59C15.8781 17.59 16.2609 17.6803 16.608 17.8539C16.9552 18.0275 17.2572 18.2795 17.49 18.59C17.7229 18.2795 18.0248 18.0275 18.372 17.8539C18.7191 17.6803 19.1019 17.59 19.49 17.59C19.8781 17.59 20.2609 17.6803 20.608 17.8539C20.9552 18.0275 21.2572 18.2795 21.49 18.59C21.8036 18.173 22.2403 17.865 22.7384 17.7096C23.2364 17.5542 23.7707 17.5592 24.2658 17.7239C24.7609 17.8885 25.1918 18.2046 25.4976 18.6273C25.8034 19.0501 25.9686 19.5582 25.97 20.08L26 20.09Z"
        fill="#E73C14"
      />
      <path
        d="M26 23.22H25C25 22.8221 24.842 22.4406 24.5607 22.1593C24.2794 21.878 23.8978 21.72 23.5 21.72C23.1022 21.72 22.7207 21.878 22.4394 22.1593C22.1581 22.4406 22 22.8221 22 23.22H21C21 22.8221 20.842 22.4406 20.5607 22.1593C20.2794 21.878 19.8978 21.72 19.5 21.72C19.1022 21.72 18.7207 21.878 18.4394 22.1593C18.1581 22.4406 18 22.8221 18 23.22H17C17 22.8221 16.842 22.4406 16.5607 22.1593C16.2794 21.878 15.8978 21.72 15.5 21.72C15.1022 21.72 14.7207 21.878 14.4394 22.1593C14.1581 22.4406 14 22.8221 14 23.22H13C13 22.8221 12.842 22.4406 12.5607 22.1593C12.2794 21.878 11.8978 21.72 11.5 21.72C11.1022 21.72 10.7207 21.878 10.4394 22.1593C10.1581 22.4406 10 22.8221 10 23.22H9C9.00276 22.6978 9.16896 22.1896 9.47525 21.7667C9.78154 21.3438 10.2126 21.0274 10.7078 20.862C11.2031 20.6965 11.7377 20.6903 12.2367 20.8442C12.7357 20.9981 13.174 21.3043 13.49 21.72C13.7229 21.4095 14.0248 21.1575 14.372 20.9839C14.7191 20.8103 15.1019 20.72 15.49 20.72C15.8781 20.72 16.2609 20.8103 16.608 20.9839C16.9552 21.1575 17.2572 21.4095 17.49 21.72C17.7241 21.4113 18.0265 21.1609 18.3734 20.9886C18.7204 20.8163 19.1026 20.7266 19.49 20.7266C19.8774 20.7266 20.2596 20.8163 20.6066 20.9886C20.9535 21.1609 21.2559 21.4113 21.49 21.72C21.8041 21.3023 22.2417 20.9941 22.7408 20.8389C23.2398 20.6837 23.775 20.6895 24.2706 20.8555C24.7661 21.0214 25.1969 21.339 25.502 21.7634C25.807 22.1878 25.9707 22.6974 25.97 23.22H26Z"
        fill="#E73C14"
      />
      <path
        d="M17.84 11.82L14.84 15.45C15.0574 15.3675 15.2875 15.3235 15.52 15.32C16.0504 15.32 16.5591 15.5307 16.9342 15.9058C17.3093 16.2809 17.52 16.7896 17.52 17.32C17.5215 16.9207 17.6425 16.531 17.8673 16.2011C18.0922 15.8711 18.4107 15.6161 18.7818 15.4687C19.1529 15.3213 19.5596 15.2884 19.9496 15.3742C20.3396 15.46 20.695 15.6605 20.97 15.95L21.23 15.59L21.69 15.02L17.84 11.82Z"
        fill="#E73C14"
      />
      <path
        d="M17.67 14.29L14.67 11.75C14.6176 11.7042 14.5606 11.664 14.4999 11.63L12.1 10.34C11.975 10.2743 11.8655 10.183 11.7784 10.072C11.6913 9.96092 11.6286 9.83271 11.5946 9.69576C11.5606 9.5588 11.556 9.41619 11.5811 9.27732C11.6061 9.13844 11.6603 9.00644 11.74 8.88999C11.879 8.68284 12.0905 8.53516 12.3329 8.47595C12.5753 8.41675 12.831 8.45029 13.05 8.56999L15.66 9.96999C15.7226 10.0007 15.78 10.0413 15.83 10.09L18.83 12.62L17.67 14.29Z"
        fill="#E73C14"
      />
      <path
        d="M22 12.06C23.1211 12.06 24.03 11.1511 24.03 10.03C24.03 8.90886 23.1211 8 22 8C20.8789 8 19.97 8.90886 19.97 10.03C19.97 11.1511 20.8789 12.06 22 12.06Z"
        fill="#E73C14"
      />
    </g>
    <path
      d="M8.78998 35.78V36.18H7.19V37.18C7.34317 37.1701 7.49685 37.1701 7.65002 37.18C8.0009 37.1611 8.34935 37.2482 8.65002 37.43C8.80158 37.5244 8.92504 37.6577 9.00769 37.8159C9.09034 37.9742 9.12913 38.1517 9.12 38.33C9.12703 38.4768 9.10407 38.6234 9.05249 38.761C9.00091 38.8985 8.92179 39.0241 8.82001 39.13C8.4511 39.4278 7.98286 39.5744 7.51001 39.54C7.26357 39.555 7.01646 39.555 6.77002 39.54V35.86L8.78998 35.78ZM7.19 39.1C7.32307 39.1119 7.4569 39.1119 7.58997 39.1C7.73318 39.1105 7.877 39.0901 8.0116 39.04C8.14619 38.99 8.26843 38.9115 8.37 38.81C8.4382 38.7383 8.49113 38.6534 8.52551 38.5606C8.55989 38.4678 8.57503 38.3689 8.57001 38.27C8.57517 38.1528 8.5503 38.0361 8.4978 37.9311C8.44531 37.8261 8.36693 37.7363 8.27002 37.67C8.06059 37.5389 7.81699 37.4728 7.57001 37.48H7.19V39.1Z"
      fill="black"
    />
    <path
      d="M10.27 38.31L9.88 39.47H9.39001L10.65 35.78H11.22L12.48 39.47H11.97L11.58 38.31H10.27ZM11.48 37.93L11.12 36.87C11.03 36.63 10.98 36.41 10.92 36.2C10.92 36.42 10.81 36.64 10.74 36.87L10.38 37.93H11.48Z"
      fill="black"
    />
    <path
      d="M15.52 39.35C15.2036 39.4807 14.8619 39.5388 14.52 39.52C14.2723 39.5352 14.0242 39.4969 13.7926 39.4077C13.5609 39.3186 13.3512 39.1806 13.1776 39.0032C13.004 38.8258 12.8707 38.6132 12.7865 38.3797C12.7024 38.1462 12.6695 37.8973 12.69 37.65C12.6771 37.3931 12.7182 37.1364 12.8106 36.8964C12.9031 36.6564 13.0448 36.4384 13.2266 36.2566C13.4085 36.0747 13.6264 35.933 13.8664 35.8406C14.1064 35.7481 14.3632 35.7071 14.62 35.72C14.9214 35.7104 15.2213 35.765 15.5 35.88L15.38 36.27C15.1477 36.1651 14.8948 36.1139 14.64 36.12C14.441 36.1085 14.2418 36.1407 14.0565 36.2142C13.8711 36.2877 13.7041 36.4008 13.567 36.5455C13.4299 36.6903 13.326 36.8632 13.2626 37.0522C13.1992 37.2412 13.1779 37.4418 13.2 37.64C13.1792 37.8343 13.2011 38.0308 13.2642 38.2157C13.3274 38.4007 13.4302 38.5695 13.5654 38.7106C13.7007 38.8516 13.8653 38.9613 14.0474 39.032C14.2296 39.1027 14.425 39.1327 14.62 39.12C14.8843 39.1235 15.1464 39.0724 15.39 38.97L15.52 39.35Z"
      fill="black"
    />
    <path
      d="M18.62 39.3499C18.3028 39.4781 17.9617 39.5361 17.62 39.5199C17.3719 39.5368 17.123 39.4997 16.8906 39.4112C16.6582 39.3226 16.4478 39.1847 16.2738 39.0069C16.0999 38.8292 15.9665 38.6158 15.883 38.3815C15.7995 38.1473 15.7677 37.8977 15.79 37.6499C15.7755 37.3927 15.8155 37.1353 15.9074 36.8946C15.9992 36.6538 16.1409 36.4352 16.3231 36.253C16.5053 36.0708 16.7239 35.9292 16.9646 35.8373C17.2053 35.7455 17.4627 35.7054 17.72 35.7199C18.0213 35.7104 18.3213 35.7649 18.6 35.8799L18.48 36.2699C18.2477 36.1651 17.9948 36.1138 17.74 36.1199C17.5401 36.107 17.3399 36.138 17.1533 36.2108C16.9668 36.2837 16.7984 36.3965 16.6602 36.5414C16.5219 36.6863 16.4171 36.8598 16.3532 37.0496C16.2892 37.2394 16.2676 37.4409 16.29 37.6399C16.2709 37.8339 16.294 38.0296 16.3578 38.2138C16.4216 38.398 16.5244 38.5661 16.6594 38.7068C16.7943 38.8474 16.9581 38.9571 17.1394 39.0284C17.3208 39.0998 17.5155 39.131 17.71 39.1199C17.9774 39.1226 18.2426 39.0716 18.49 38.9699L18.62 39.3499Z"
      fill="black"
    />
    <path
      d="M21.1 37.74H19.62V39.07H21.22V39.47H19.15V35.78H21.15V36.18H19.62V37.34H21.06L21.1 37.74Z"
      fill="black"
    />
    <path
      d="M22.33 35.78V37.33C22.33 37.92 22.33 38.4 22.33 38.89C22.52 38.51 22.75 38.1 23.02 37.67L24.21 35.77H24.62V39.46H24.17V37.94C24.17 37.34 24.17 36.94 24.17 36.44C23.9705 36.8677 23.7433 37.2819 23.49 37.68L22.33 39.52H21.82V35.83L22.33 35.78ZM22.94 35C22.94 35.17 23.01 35.34 23.27 35.34C23.53 35.34 23.58 35.18 23.6 35H23.97C23.97 35.37 23.72 35.59 23.26 35.59C23.1749 35.6029 23.0879 35.5974 23.0051 35.5737C22.9223 35.55 22.8455 35.5088 22.78 35.4528C22.7146 35.3968 22.662 35.3274 22.6257 35.2493C22.5895 35.1711 22.5705 35.0861 22.57 35H22.94Z"
      fill="black"
    />
    <path
      d="M25.97 35.78V37.32H27.75V35.78H28.23V39.47H27.75V37.74H25.97V39.47H25.49V35.78H25.97Z"
      fill="black"
    />
    <path
      d="M1.47998 46.4499L2.62 47.9399H2.03998L0.969971 46.4499L2.03998 44.9599H2.62L1.47998 46.4499ZM2.81 46.4499L3.90002 47.9399H3.32001L2.25 46.4499L3.32001 44.9399H3.90002L2.81 46.4499Z"
      fill="black"
    />
    <path
      d="M5.48999 48.49L4.09998 43.03H4.84998L5.5 45.79C5.66 46.48 5.81002 47.16 5.90002 47.68C5.99002 47.14 6.15998 46.49 6.34998 45.79L7.07001 43.03H7.81L8.47998 45.8C8.62998 46.45 8.77999 47.1 8.85999 47.67C8.97999 47.07 9.12999 46.47 9.29999 45.79L10.02 43.03H10.74L9.20001 48.49H8.46002L7.77002 45.65C7.60002 44.95 7.48997 44.42 7.40997 43.87C7.30997 44.41 7.18999 44.94 6.98999 45.65L6.21002 48.49H5.48999Z"
      fill="black"
    />
    <path
      d="M14.28 45.9401H12.16V47.9401H14.52V48.5301H11.45V43.0701H14.4V43.6601H12.16V45.3901H14.28V45.9401Z"
      fill="black"
    />
    <path
      d="M19.62 48.25C19.0806 48.4427 18.5128 48.5441 17.94 48.55C17.5667 48.5865 17.19 48.5405 16.8364 48.4152C16.4829 48.29 16.1612 48.0886 15.8941 47.8253C15.6269 47.562 15.421 47.2432 15.2907 46.8915C15.1604 46.5398 15.109 46.1638 15.14 45.79C15.1283 45.4014 15.1995 45.0147 15.3487 44.6557C15.498 44.2967 15.7219 43.9736 16.0056 43.7078C16.2894 43.442 16.6265 43.2396 16.9945 43.1142C17.3625 42.9887 17.753 42.9429 18.14 42.98C18.606 42.9661 19.0695 43.0513 19.5 43.23L19.33 43.8C18.9483 43.6361 18.5353 43.5576 18.12 43.57C17.8251 43.5404 17.5272 43.5766 17.2481 43.6762C16.9689 43.7758 16.7153 43.9362 16.5058 44.1458C16.2962 44.3554 16.1358 44.6089 16.0362 44.8881C15.9366 45.1673 15.9004 45.4651 15.93 45.76C15.8991 46.0484 15.9313 46.34 16.0243 46.6147C16.1173 46.8894 16.269 47.1406 16.4688 47.3508C16.6685 47.561 16.9116 47.7253 17.1812 47.8322C17.4508 47.9392 17.7404 47.9862 18.03 47.97C18.3504 47.9861 18.6705 47.935 18.97 47.82V46.19H17.86V45.62H19.62V48.25Z"
      fill="black"
    />
    <path
      d="M21.88 48.49V46.18L20.16 43.03H20.96L21.73 44.54C21.94 44.95 22.1 45.28 22.27 45.66C22.42 45.31 22.61 44.95 22.82 44.54L23.6 43.03H24.41L22.62 46.17V48.49H21.88Z"
      fill="black"
    />
    <path
      d="M29.62 46.09C29.62 45.33 29.53 44.42 29.54 43.74C29.36 44.38 29.13 45.06 28.86 45.81L27.86 48.46H27.33L26.44 45.86C26.18 45.09 25.96 44.38 25.81 43.74C25.81 44.42 25.75 45.33 25.7 46.15L25.55 48.49H24.88L25.26 43.03H26.16L27.09 45.67C27.3041 46.2737 27.4877 46.8879 27.64 47.51C27.78 46.96 27.97 46.36 28.21 45.67L29.21 43.03H30.11L30.45 48.49H29.76L29.62 46.09Z"
      fill="black"
    />
    <path
      d="M31.05 44.9399H31.62L32.7 46.4299L31.62 47.9399H31.06L32.14 46.4499L31.05 44.9399ZM32.37 44.9399H32.95L34.02 46.4299L32.95 47.9399H32.38L33.46 46.4499L32.37 44.9399Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip7">
        <rect width="32" height="32" fill="white" transform="translate(1.5)" />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconSportWegym",
};
</script>

<style scoped lang="scss"></style>
