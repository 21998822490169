var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "map-layout",
    { staticStyle: { width: "100%", height: "100%", "max-height": "800px" } },
    [
      _c("map-icon-main-street", {
        attrs: { transform: "translate(641,307)" },
      }),
      _c("svg-arrow-hint", {
        ref: "hint",
        attrs: {
          "hint-path-clone": "hintPathCloneComfort",
          "hint-arrow": "hintArrowComfort",
          "hint-path": "hintPathComfort",
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(546,228)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 322],
                [589, 293],
                [575, 266],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(490,268)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 302],
                [589, 293],
                [530, 279],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoMcd", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(619,378)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 330],
                [770, 342],
                [710, 473],
                [648, 420],
              ],
              hint: "6 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoBkl", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(529,298)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [585, 282],
                [566, 288],
                [560, 295],
              ],
              hint: "7 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroScolkovo", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(126,505)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [645, 340],
                [595, 392],
                [490, 543],
                [174, 515],
              ],
              hint: "10 минут",
              reverse: true,
              dy: -5,
              electric: true,
            })
          },
        },
      }),
      _c("MapIconParkSnowboard", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(481,46)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [494, 322],
                [471, 113],
                [493, 94],
              ],
              hint: "9 минут",
              reverse: true,
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconParkFili", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(881,88)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 326],
                [764, 302],
                [791, 313],
                [887, 138],
              ],
              hint: "11 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconParkVorosilovsk", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(741,158)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 306],
                [754, 272],
                [761, 243],
                [777, 209],
              ],
              hint: "7 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconRestoranManor", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(691,205)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 316],
                [664, 272],
                [663, 243],
                [690, 230],
              ],
              hint: "6 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconParkSold", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(611,165)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 316],
                [664, 232],
                [663, 243],
                [663, 220],
              ],
              hint: "5 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconParkSuvorov", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(523,151)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [660, 315],
                [644, 302],
                [583, 233],
                [572, 200],
              ],
              hint: "6 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconSport", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(493,350)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 372],
                [576, 368],
                [545, 370],
              ],
              hint: "11 минут",
              manLeft: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMojPark", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(1010,190)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [855, 202],
                [966, 180],
                [1040, 195],
              ],
              hint: "9 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconRestoraunt", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(1225,156)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [825, 182],
                [1066, 120],
                [1220, 165],
              ],
              hint: "17 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconRestorauntSpett", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(1169,175)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [855, 202],
                [1066, 140],
                [1170, 177],
              ],
              hint: "15 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconParkDolina", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(679,508)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 379],
                [765, 450],
                [730, 505],
              ],
              hint: "19 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconSpaClub", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(437,225)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 292],
                [526, 258],
                [485, 248],
              ],
              hint: "8 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconStretching", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(501,485)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [688, 330],
                [690, 322],
                [728, 443],
                [566, 493],
              ],
              hint: "13 минут",
              car: true,
              reverse: true,
            })
          },
        },
      }),
      _c("MapIconKozlovForest", {
        staticClass: "map-icon__harmony",
        attrs: { transform: "translate(551,530)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [688, 330],
                [690, 322],
                [698, 443],
                [606, 523],
              ],
              hint: "10 минут",
              car: true,
              reverse: true,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }