<template>
  <div class="location">
    <content-wrapper-block>
      <page-title>
        {{ title }}
      </page-title>
      <div class="location-description">
        <slot name="description" />
      </div>
      <ui-tab-bar v-model="activeTab">
        <ui-tab-bar-tab value="Удобство"> Удобство </ui-tab-bar-tab>
        <ui-tab-bar-tab value="Гармония"> Гармония </ui-tab-bar-tab>
        <ui-tab-bar-tab value="Развитие"> Развитие </ui-tab-bar-tab>
        <ui-tab-bar-tab value="Семья"> Семья </ui-tab-bar-tab>
      </ui-tab-bar>
      <router-link :to="{ name: 'PanoramaPage' }">
        <ui-button light> Панорама </ui-button>
      </router-link>
    </content-wrapper-block>
    <content-wrapper-block
      no-padding
      :class="{ location__box: true, 'location__box--width': width }"
    >
      <div ref="map" class="map-animation">
        <PointComfort v-show="activeTab == 'Удобство'" />
        <PointHarmony v-show="activeTab == 'Гармония'" />
        <PointDevelopment v-show="activeTab == 'Развитие'" />
        <PointFamily v-show="activeTab == 'Семья'" />
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import UiTabBar from "@/components/ui/UITabBar";
import UiTabBarTab from "@/components/ui/UITabBarTab";
import PointComfort from "@/components/elements/map/Points/PointComfort";
import PointHarmony from "@/components/elements/map/Points/PointHarmony";
import PointDevelopment from "@/components/elements/map/Points/PointDevelopment";
import PointFamily from "@/components/elements/map/Points/PointFamily";
import UiButton from "@/components/ui/UIButton";

export default {
  name: "LocationBlock",
  components: {
    ContentWrapperBlock,
    UiTabBarTab,
    UiTabBar,
    UiButton,
    PageTitle,
    PointComfort,
    PointHarmony,
    PointDevelopment,
    PointFamily,
  },
  props: {
    title: String,
    width: {
      type: Boolean,
      default: false,
    },
    initialTab: String,
  },
  data: () => ({
    activeTab: "",
  }),
  async mounted() {
    await this.$nextTick();
    setTimeout(() => {
      if (window.innerWidth < 640) {
        this.$refs.map.scrollLeft = 250;
      } else {
        this.$refs.map.scrollLeft = 50;
      }
    }, 0);
    this.activeTab = this.initialTab;
  },
};
</script>

<style lang="scss">
.map-icon__comfort {
  cursor: pointer;

  &:hover {
    circle {
      stroke: black;
    }
    path {
      fill: #000000;
    }
  }
}
.map-icon__harmony {
  cursor: pointer;

  &:hover {
    circle {
      stroke: black;
    }
    path {
      fill: #000000;
    }
  }
}
.map-icon__development {
  cursor: pointer;

  &:hover {
    circle {
      stroke: black;
    }
    path {
      fill: #000000;
    }
  }
}
.map-icon__family {
  cursor: pointer;

  &:hover {
    circle {
      stroke: black;
    }
    path {
      fill: #000000;
    }
  }
}
</style>

<style lang="scss" scoped>
$shadowColor: #18181b;
.map-animation {
  overflow-x: auto;
}
.location {
  &-description {
    font-family: Rubik;
    font-weight: 300;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: #2e2e30;
    margin-bottom: 55px;
  }
  &__box {
    filter: none;
    margin-bottom: 0;
    width: 100%;
    background-color: white;
    filter: drop-shadow(0px 0px 20px rgba($shadowColor, 0.08));

    @media screen and (min-width: $screen-md) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: $screen-lg) {
      width: $screen-lg;
    }
    @media screen and (min-width: $screen-xl) {
      width: 1440px;
      margin-bottom: 80px;
    }
    img {
      max-width: 100%;
    }
  }
  &__box--width {
    width: 100%;
    margin-bottom: 0;
    img {
      width: 100%;
    }
  }
  .ui-tab-bar {
    margin-right: 15px;
    margin-bottom: 10px;

    @media screen and (min-width: $screen-mini) {
      margin-bottom: 0;
    }
  }
}
</style>
