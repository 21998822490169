var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip9)" } }, [
        _c("circle", {
          attrs: {
            cx: "34.63",
            cy: "16",
            r: "15.5",
            fill: "white",
            "fill-opacity": "0.01",
            stroke: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M42.36 22.36H39.5V13.44H42.36V22.36ZM40.25 21.61H41.61V14.19H40.25V21.61Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M43.51 14.19H38.23V12.06H43.51V14.19ZM38.98 13.44H42.76V12.85H38.98V13.44Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M43.51 23.7H38.23V21.61H43.51V23.7ZM38.98 22.95H42.76V22.36H38.98V22.95Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M36.12 22.36H33.26V13.44H36.12V22.36ZM34.01 21.61H35.37V14.19H34.02L34.01 21.61Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M37.28 14.19H31.99V12.06H37.28V14.19ZM32.74 13.44H36.53V12.85H32.74V13.44Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M37.28 23.7H31.99V21.61H37.28V23.7ZM32.74 22.95H36.53V22.36H32.74V22.95Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M29.89 22.36H27.0401V13.44H29.89V22.36ZM27.7901 21.61H29.1201V14.19H27.7701L27.7901 21.61Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M31.04 14.19H25.76V12.06H31.04V14.19ZM26.51 13.44H30.29V12.85H26.51V13.44Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M31.04 23.7H25.76V21.61H31.04V23.7ZM26.51 22.95H30.29V22.36H26.51V22.95Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M44.04 25.26H25.23V22.95H44.04V25.26ZM25.98 24.51H43.29V23.7H25.98V24.51Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M44.04 12.85H25.23V10.53H44.04V12.85ZM25.98 12.06H43.29V11.24H25.98V12.06Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M43.61 11.28H25.74L34.83 6L43.61 11.28ZM28.53 10.53H40.91L34.82 6.88L28.53 10.53Z",
            fill: "#E73C14",
          },
        }),
      ]),
      _c("path", {
        attrs: {
          d: "M11.57 35.83C11.8562 35.7717 12.148 35.7448 12.44 35.75C12.7947 35.7193 13.1489 35.8149 13.44 36.02C13.5407 36.0949 13.6216 36.1932 13.6757 36.3065C13.7297 36.4198 13.7552 36.5446 13.75 36.67C13.7444 36.8544 13.6804 37.0322 13.5671 37.1778C13.4539 37.3234 13.2973 37.4292 13.12 37.48C13.3303 37.5318 13.5181 37.6505 13.6552 37.8182C13.7922 37.9859 13.8711 38.1936 13.88 38.41C13.8822 38.5481 13.8558 38.6851 13.8025 38.8125C13.7491 38.9398 13.67 39.0548 13.57 39.15C13.2022 39.4142 12.7506 39.5351 12.3 39.49C12.0535 39.505 11.8064 39.505 11.56 39.49L11.57 35.83ZM12.04 37.34H12.48C12.98 37.34 13.28 37.08 13.28 36.72C13.28 36.36 12.95 36.12 12.46 36.12C12.3204 36.1047 12.1796 36.1047 12.04 36.12V37.34ZM12.04 39.11C12.1731 39.1219 12.3069 39.1219 12.44 39.11C12.94 39.11 13.39 38.93 13.39 38.39C13.39 37.85 12.96 37.68 12.44 37.68H12.04V39.11Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.01 35.78V37.16C15.1565 37.1498 15.3035 37.1498 15.45 37.16C16.21 37.16 16.88 37.49 16.88 38.32C16.8857 38.4627 16.8629 38.6052 16.8131 38.7391C16.7634 38.873 16.6875 38.9957 16.59 39.1C16.4013 39.2452 16.1858 39.3517 15.9559 39.4135C15.7259 39.4753 15.4861 39.4911 15.25 39.46C15.0036 39.475 14.7564 39.475 14.51 39.46V35.78H15.01ZM15.01 39.1C15.1394 39.1178 15.2706 39.1178 15.4 39.1C15.92 39.1 16.4 38.83 16.4 38.27C16.4 37.71 15.9 37.48 15.4 37.48C15.2735 37.4703 15.1465 37.4703 15.02 37.48L15.01 39.1ZM17.77 35.78V39.46H17.25V35.77L17.77 35.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.25 39.35C20.9336 39.4808 20.5918 39.5388 20.25 39.52C20.0036 39.5352 19.7568 39.4972 19.5264 39.4086C19.2959 39.3199 19.0873 39.1827 18.9146 39.0062C18.742 38.8298 18.6093 38.6182 18.5257 38.3859C18.4421 38.1536 18.4094 37.9061 18.43 37.66C18.4142 37.4029 18.4528 37.1452 18.5435 36.904C18.6341 36.6628 18.7746 36.4435 18.9558 36.2604C19.1371 36.0772 19.355 35.9344 19.5952 35.8413C19.8355 35.7482 20.0927 35.7069 20.35 35.72C20.6514 35.7105 20.9513 35.765 21.23 35.88L21.11 36.27C20.878 36.1642 20.6249 36.1129 20.37 36.12C20.1709 36.1086 19.9718 36.1408 19.7865 36.2143C19.6011 36.2878 19.4341 36.4008 19.297 36.5456C19.1598 36.6903 19.056 36.8632 18.9926 37.0523C18.9292 37.2413 18.9078 37.4419 18.93 37.64C18.9094 37.8348 18.9313 38.0316 18.9944 38.217C19.0575 38.4024 19.1601 38.5719 19.2952 38.7136C19.4303 38.8554 19.5946 38.966 19.7768 39.0379C19.9589 39.1098 20.1545 39.1413 20.35 39.13C20.6181 39.1295 20.8834 39.0751 21.13 38.97L21.25 39.35Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.69 36.18H21.57V35.78H24.3V36.18H23.18V39.46H22.69V36.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.87 38.31L24.49 39.47H23.99L25.25 35.78H25.82L27.08 39.47H26.57L26.18 38.31H24.87ZM26.08 37.94L25.72 36.87C25.64 36.63 25.58 36.41 25.53 36.2C25.47 36.42 25.41 36.64 25.34 36.87L24.98 37.94H26.08Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.63 35.83C27.9162 35.7717 28.2079 35.7448 28.5 35.75C28.8547 35.7193 29.2089 35.8149 29.5 36.02C29.6007 36.0949 29.6816 36.1932 29.7357 36.3065C29.7897 36.4198 29.8152 36.5446 29.81 36.67C29.8044 36.8544 29.7404 37.0322 29.6271 37.1778C29.5139 37.3234 29.3573 37.4292 29.18 37.48C29.3903 37.5318 29.5781 37.6505 29.7152 37.8182C29.8522 37.9859 29.9311 38.1936 29.94 38.41C29.9422 38.5481 29.9158 38.6851 29.8625 38.8125C29.8091 38.9398 29.73 39.0548 29.63 39.15C29.2614 39.4125 28.8104 39.5332 28.36 39.49C28.1135 39.505 27.8664 39.505 27.62 39.49L27.63 35.83ZM28.1 37.34H28.54C29.04 37.34 29.33 37.08 29.33 36.72C29.33 36.36 29.01 36.12 28.52 36.12C28.3804 36.1047 28.2396 36.1047 28.1 36.12V37.34ZM28.1 39.11C28.2331 39.1219 28.3669 39.1219 28.5 39.11C29 39.11 29.45 38.93 29.45 38.39C29.45 37.85 29.02 37.68 28.5 37.68H28.1V39.11Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.71 37.59C33.7398 37.8333 33.7175 38.0802 33.6446 38.3142C33.5717 38.5483 33.4499 38.7642 33.2873 38.9477C33.1247 39.1311 32.9249 39.2779 32.7012 39.3783C32.4776 39.4787 32.2351 39.5304 31.99 39.53C31.7551 39.5256 31.5236 39.4726 31.3102 39.3743C31.0968 39.276 30.9061 39.1345 30.7501 38.9588C30.5941 38.7831 30.4763 38.577 30.404 38.3534C30.3317 38.1299 30.3065 37.8938 30.33 37.66C30.3035 37.4172 30.3281 37.1716 30.4021 36.9389C30.4762 36.7062 30.5982 36.4916 30.7602 36.3089C30.9222 36.1262 31.1206 35.9794 31.3428 35.8779C31.5649 35.7765 31.8058 35.7227 32.05 35.72C32.2859 35.7213 32.5188 35.7722 32.7337 35.8695C32.9486 35.9668 33.1406 36.1082 33.2972 36.2846C33.4538 36.461 33.5715 36.6684 33.6426 36.8933C33.7137 37.1182 33.7367 37.3556 33.71 37.59ZM30.84 37.65C30.84 38.43 31.27 39.14 32.02 39.14C32.77 39.14 33.25 38.46 33.25 37.61C33.25 36.76 32.87 36.11 32.08 36.11C31.29 36.11 30.84 36.84 30.84 37.65Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.74 35.78V36.95C34.7254 37.0563 34.7354 37.1645 34.7693 37.2664C34.8032 37.3682 34.86 37.4608 34.9354 37.5372C35.0108 37.6136 35.1027 37.6716 35.2041 37.7067C35.3054 37.7419 35.4135 37.7533 35.52 37.74C35.7777 37.7361 36.0297 37.6637 36.25 37.53V35.78H36.73V39.46H36.25V37.89C35.9784 38.0428 35.6716 38.1221 35.36 38.12C35.2116 38.1344 35.0618 38.1154 34.9216 38.0645C34.7814 38.0135 34.6544 37.9318 34.5499 37.8253C34.4454 37.7189 34.3661 37.5905 34.3176 37.4494C34.2692 37.3083 34.2529 37.1582 34.27 37.01V35.77L34.74 35.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.04 35.78V37.32H39.82V35.78H40.31V39.46H39.82V37.73H38.04V39.46H37.56V35.77L38.04 35.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.61 35.78V37.16C41.7565 37.1498 41.9035 37.1498 42.05 37.16C42.81 37.16 43.47 37.49 43.47 38.32C43.4798 38.6062 43.3796 38.8854 43.19 39.1C42.8241 39.4121 42.3495 39.5667 41.87 39.53C41.6236 39.545 41.3764 39.545 41.13 39.53V35.85L41.61 35.78ZM41.61 39.1C41.7361 39.1174 41.8639 39.1174 41.99 39.1C42.52 39.1 42.99 38.83 42.99 38.27C42.99 37.71 42.5 37.48 41.99 37.48C41.8635 37.4698 41.7364 37.4698 41.61 37.48V39.1ZM44.37 35.78V39.46H43.88V35.77L44.37 35.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M45.67 35.78V37.33C45.67 37.92 45.67 38.4 45.67 38.89C45.8749 38.4715 46.1019 38.0643 46.35 37.67L47.55 35.77H48.02V39.46H47.57V37.89C47.57 37.29 47.57 36.89 47.57 36.39C47.3705 36.8176 47.1434 37.2319 46.89 37.63L45.73 39.46H45.25V35.77L45.67 35.78ZM46.27 35C46.27 35.17 46.35 35.34 46.61 35.34C46.87 35.34 46.92 35.19 46.94 35H47.31C47.31 35.37 47.06 35.59 46.6 35.59C46.14 35.59 45.93 35.37 45.91 35H46.27Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.84 36.0001C50.1374 35.8077 50.486 35.7101 50.84 35.7201C51.48 35.7201 51.93 36.0501 51.93 36.6201C51.9263 36.8362 51.8474 37.0442 51.7068 37.2085C51.5663 37.3727 51.373 37.4829 51.16 37.52C51.4023 37.5253 51.6327 37.6259 51.8012 37.8C51.9697 37.9742 52.0627 38.2077 52.06 38.45C52.06 39.21 51.4 39.52 50.73 39.52C50.3809 39.534 50.0348 39.4509 49.73 39.2801L49.86 38.9101C50.1119 39.0477 50.3931 39.1232 50.68 39.1301C51.23 39.1301 51.53 38.84 51.53 38.43C51.53 38.02 51 37.7201 50.53 37.7201H50.31V37.36H50.53C50.7351 37.3828 50.941 37.3244 51.1036 37.1973C51.2662 37.0702 51.3725 36.8846 51.4 36.68C51.4 36.3 51.13 36.1001 50.73 36.1001C50.4591 36.1064 50.1955 36.1896 49.97 36.3401L49.84 36.0001Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.25 38.31L52.87 39.47H52.37L53.63 35.78H54.25L55.51 39.47H55L54.61 38.31H53.25ZM54.46 37.94L54.1 36.87C54.02 36.63 53.96 36.41 53.91 36.2C53.85 36.42 53.79 36.64 53.72 36.87L53.36 37.94H54.46Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.65 35.78V39.46H58.17V36.17H56.95V37.28C56.95 38.08 56.95 38.98 56.39 39.33C56.223 39.4381 56.0289 39.497 55.83 39.5L55.77 39.12C55.9145 39.0865 56.0485 39.0178 56.16 38.92C56.4343 38.4343 56.5464 37.8738 56.48 37.32V35.77L58.65 35.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.5 46.46L2.59 47.95H2.01999L0.950012 46.46L2.01999 44.97H2.59L1.5 46.46ZM2.82999 46.46L3.92001 47.95H3.34L2.25 46.46L3.32001 44.97H3.89999L2.82999 46.46Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.62003 43.03V43.63H5.42004V48.46H4.72003V43L7.62003 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.45004 46.78L7.89005 48.49H7.16003L9.01004 43.03H9.86002L11.73 48.49H10.97L10.39 46.78H8.45004ZM10.25 46.23L9.71002 44.65C9.59002 44.3 9.51002 43.97 9.43002 43.65C9.35002 43.97 9.26002 44.3 9.15002 44.65L8.62003 46.23H10.25Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.39 43.03V48.46H15.68V43.6H13.87V45.25C13.87 46.43 13.82 47.75 13.05 48.25C12.8026 48.4116 12.5154 48.5016 12.22 48.51L12.13 47.94C12.3452 47.8952 12.545 47.7952 12.71 47.65C13.1156 46.9304 13.2803 46.0999 13.18 45.28V42.98L16.39 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.44 45.93H18.32V47.93H20.68V48.46H17.61V43H20.56V43.6H18.32V45.32H20.44V45.93Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.6 43.1C22.0459 43.0237 22.4977 42.9869 22.95 42.99C23.4998 42.9452 24.0462 43.1095 24.48 43.45C24.6386 43.5933 24.7638 43.7697 24.8467 43.9667C24.9297 44.1637 24.9683 44.3765 24.96 44.59C24.9768 45.0197 24.8263 45.4391 24.54 45.76C24.3156 45.9661 24.0523 46.1253 23.7655 46.2284C23.4787 46.3314 23.1743 46.3761 22.87 46.36C22.6807 46.382 22.4894 46.382 22.3 46.36V48.46H21.6V43.1ZM22.3 45.73C22.4935 45.774 22.6917 45.7942 22.89 45.79C23.74 45.79 24.26 45.38 24.26 44.62C24.26 43.86 23.75 43.55 22.97 43.55C22.7452 43.5456 22.5205 43.5657 22.3 43.61V45.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.73 45.93H26.61V47.93H28.98V48.46H25.91V43H28.86V43.6H26.61V45.32H28.73V45.93Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.45 48.46C29.539 48.3042 29.6127 48.1401 29.67 47.97C29.8228 47.3111 30.1049 46.6891 30.5 46.14C30.6257 46.0123 30.7799 45.9163 30.95 45.86C30.6186 45.823 30.3125 45.6645 30.091 45.4153C29.8694 45.166 29.7479 44.8435 29.75 44.51C29.7539 44.3095 29.8005 44.1121 29.8867 43.931C29.9729 43.75 30.0968 43.5894 30.25 43.46C30.7092 43.1512 31.2578 43.0035 31.81 43.04C32.2496 43.0356 32.6885 43.0758 33.12 43.16V48.46H32.41V46.1H31.89C31.7483 46.0869 31.6054 46.1042 31.4709 46.1508C31.3363 46.1973 31.2133 46.2721 31.11 46.37C30.7665 46.8871 30.5224 47.4635 30.39 48.07C30.33 48.2 30.29 48.32 30.21 48.46H29.45ZM32.45 43.57C32.2322 43.5307 32.0113 43.5106 31.79 43.51C31.09 43.51 30.5 43.77 30.5 44.51C30.5 45.25 31.08 45.51 31.85 45.51H32.45V43.57Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.05 48.46L38.36 47.26C38.07 46.8 37.89 46.5 37.72 46.18C37.56 46.5 37.41 46.79 37.13 47.26L36.47 48.46H35.66L37.33 45.7L35.72 43H36.54L37.26 44.28C37.47 44.64 37.62 44.92 37.77 45.21C37.92 44.88 38.06 44.63 38.26 44.28L39.01 43H39.82L38.16 45.66L39.86 48.46H39.05Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.68 48.46L42.98 47.26C42.7 46.8 42.52 46.5 42.35 46.18C42.1722 46.55 41.9753 46.9105 41.76 47.26L41.1 48.46H40.25L41.92 45.7L40.32 43H41.14L41.86 44.28C42.06 44.64 42.21 44.92 42.36 45.21C42.52 44.88 42.66 44.63 42.86 44.28L43.6 43H44.41L42.75 45.66L44.45 48.46H43.68Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: { d: "M46.02 43.03V48.46H45.31V43L46.02 43.03Z", fill: "black" },
      }),
      _c("path", {
        attrs: {
          d: "M48.96 43.11C49.3849 43.0267 49.8171 42.9865 50.25 42.99C50.7818 42.9394 51.3141 43.0813 51.75 43.39C51.9031 43.5054 52.0245 43.6576 52.1032 43.8324C52.1818 44.0072 52.2151 44.1989 52.2 44.39C52.194 44.6618 52.1016 44.9246 51.9362 45.1404C51.7707 45.3561 51.5409 45.5136 51.28 45.59C51.5969 45.6542 51.8821 45.8254 52.0878 46.0748C52.2936 46.3242 52.4073 46.6367 52.41 46.96C52.4118 47.1635 52.372 47.3652 52.2928 47.5526C52.2137 47.7401 52.0971 47.9094 51.95 48.05C51.4035 48.4452 50.7312 48.6267 50.06 48.56C49.6925 48.5625 49.3251 48.5425 48.96 48.5V43.11ZM49.67 45.34H50.31C51.05 45.34 51.49 44.95 51.49 44.43C51.49 43.91 51 43.54 50.29 43.54C50.0838 43.5222 49.8763 43.5222 49.67 43.54V45.34ZM49.67 47.96C49.8665 47.973 50.0636 47.973 50.26 47.96C50.99 47.96 51.66 47.69 51.66 46.9C51.66 46.11 51.02 45.85 50.25 45.85H49.67V47.96Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.18 45.93H54.06V47.93H56.42V48.46H53.35V43H56.3V43.6H54.06V45.32H56.18V45.93Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.03 43.03V45.46H58.25L60.13 43.03H60.98L58.91 45.54C59.2791 45.5891 59.6241 45.7508 59.8979 46.0032C60.1717 46.2555 60.361 46.5861 60.44 46.95C60.6089 47.4749 60.8093 47.9892 61.04 48.49H60.25C60.0517 48.0764 59.8846 47.6485 59.75 47.21C59.6894 46.8561 59.5013 46.5366 59.2213 46.3119C58.9412 46.0871 58.5887 45.9726 58.23 45.99H58V48.46H57.31V43L58.03 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M62.65 46.78L62.08 48.49H61.35L63.21 43.03H64.06L65.92 48.49H65.17L64.59 46.78H62.65ZM64.44 46.23L63.91 44.65C63.7954 44.3222 63.6986 43.9883 63.62 43.65C63.54 43.97 63.45 44.3 63.34 44.65L62.81 46.23H64.44Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M66.25 44.96H66.83L67.95 46.46L66.87 47.95H66.3L67.38 46.46L66.25 44.96ZM67.57 44.96H68.15L69.25 46.46L68.17 47.95H67.6L68.68 46.46L67.57 44.96Z",
          fill: "black",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip9" } }, [
          _c("rect", {
            attrs: {
              width: "32",
              height: "32",
              fill: "white",
              transform: "translate(18.63)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }