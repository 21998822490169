<template>
  <g @click="$emit('click')">
    <g clip-path="url(#clip6)">
      <circle
        cx="53.94"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M58.52 23.88H44.94V12.67H58.52V23.88ZM45.94 22.88H57.52V13.67H45.94V22.88Z"
        fill="#E73C14"
      />
      <path
        d="M63.3 23.76L62.04 22.5C61.6861 22.1444 61.2654 21.8623 60.802 21.67C60.3386 21.4778 59.8417 21.3792 59.34 21.38L59.29 20.38C60.374 20.3665 61.4309 20.7188 62.29 21.38V15.85C61.442 16.5385 60.3822 16.9129 59.29 16.91V15.91C59.7917 15.9108 60.2886 15.8123 60.752 15.62C61.2154 15.4278 61.6361 15.1457 61.99 14.79L63.3 13.47V23.76Z"
        fill="#E73C14"
      />
      <path
        d="M49.26 13.67C48.3777 13.6674 47.5322 13.3157 46.9083 12.6918C46.2843 12.0678 45.9326 11.2224 45.93 10.34C45.93 9.45591 46.2805 8.60788 46.9047 7.9818C47.5289 7.35571 48.3759 7.00265 49.26 7C50.145 7.00264 50.993 7.35538 51.6188 7.98118C52.2446 8.60698 52.5974 9.45499 52.6 10.34C52.5974 11.2241 52.2443 12.0711 51.6182 12.6953C50.9921 13.3195 50.1441 13.67 49.26 13.67ZM49.26 8C48.6411 8.00264 48.0485 8.25035 47.6118 8.6889C47.1752 9.12744 46.93 9.72113 46.93 10.34C46.93 10.958 47.1755 11.5506 47.6125 11.9876C48.0494 12.4245 48.6421 12.67 49.26 12.67C49.5668 12.6713 49.8709 12.612 50.1547 12.4955C50.4386 12.379 50.6966 12.2076 50.914 11.9911C51.1315 11.7746 51.304 11.5173 51.4217 11.234C51.5394 10.9506 51.6 10.6468 51.6 10.34C51.6 9.7194 51.3535 9.12421 50.9146 8.68537C50.4758 8.24654 49.8806 8 49.26 8Z"
        fill="#E73C14"
      />
      <path
        d="M55.07 13.67C54.631 13.6726 54.1958 13.5885 53.7895 13.4223C53.3832 13.2561 53.0137 13.0112 52.7024 12.7018C52.391 12.3923 52.1439 12.0243 51.9753 11.619C51.8067 11.2136 51.72 10.779 51.72 10.34C51.72 9.45591 52.0705 8.60788 52.6947 7.9818C53.3189 7.35571 54.1659 7.00265 55.05 7C55.935 7.00264 56.783 7.35538 57.4088 7.98118C58.0346 8.60698 58.3873 9.45499 58.39 10.34C58.3874 11.2206 58.0371 12.0646 57.4153 12.6882C56.7935 13.3119 55.9506 13.6647 55.07 13.67ZM55.07 8C54.7618 7.99868 54.4565 8.05824 54.1714 8.17525C53.8864 8.29225 53.6272 8.4644 53.4089 8.68182C53.1905 8.89924 53.0173 9.15765 52.899 9.44221C52.7808 9.72676 52.72 10.0319 52.72 10.34C52.72 10.958 52.9654 11.5506 53.4024 11.9876C53.8394 12.4245 54.432 12.67 55.05 12.67C55.3568 12.6713 55.6609 12.612 55.9447 12.4955C56.2285 12.379 56.4866 12.2076 56.704 11.9911C56.9214 11.7746 57.094 11.5173 57.2117 11.234C57.3294 10.9506 57.39 10.6468 57.39 10.34C57.39 9.72285 57.1462 9.13067 56.7117 8.69241C56.2772 8.25414 55.6871 8.00527 55.07 8Z"
        fill="#E73C14"
      />
    </g>
    <path
      d="M40.8 35.0801V36.7201H40.95L42.21 35.0801H42.79L41.39 36.7701C41.6376 36.8176 41.8652 36.9383 42.0435 37.1165C42.2218 37.2948 42.3425 37.5225 42.39 37.7701C42.5032 38.1111 42.6368 38.445 42.79 38.7701H42.28C42.1427 38.49 42.029 38.199 41.94 37.9001C41.8974 37.6671 41.7735 37.4568 41.5904 37.3067C41.4073 37.1565 41.1768 37.0762 40.94 37.0801H40.79V38.7701H40.32V35.0801H40.8Z"
      fill="black"
    />
    <path
      d="M43.75 35.0801V36.6401C43.75 37.2201 43.75 37.7101 43.7 38.2001C43.89 37.8201 44.12 37.4201 44.39 36.9801L45.58 35.0801H46.06V38.7701H45.61V37.2001C45.61 36.6101 45.61 36.2001 45.61 35.7001C45.4048 36.1267 45.1744 36.5408 44.92 36.9401L43.77 38.7701H43.26V35.0801H43.75Z"
      fill="black"
    />
    <path
      d="M47.38 35.0801V36.6301H49.16V35.0801H49.6501V38.7701H49.16V37.0401H47.38V38.7701H46.9V35.0801H47.38Z"
      fill="black"
    />
    <path
      d="M53.63 36.94C53.6613 37.1828 53.6404 37.4295 53.5688 37.6635C53.4971 37.8976 53.3763 38.1137 53.2144 38.2973C53.0525 38.481 52.8533 38.628 52.6301 38.7284C52.4069 38.8289 52.1648 38.8806 51.92 38.88C51.6779 38.8784 51.439 38.8247 51.2194 38.7227C50.9998 38.6207 50.8047 38.4727 50.6473 38.2888C50.4899 38.1048 50.3739 37.8892 50.307 37.6565C50.2402 37.4238 50.2242 37.1794 50.26 36.94C50.2335 36.6981 50.2578 36.4533 50.3313 36.2213C50.4048 35.9893 50.5259 35.7752 50.6868 35.5926C50.8477 35.41 51.045 35.263 51.2659 35.161C51.4869 35.0589 51.7266 35.0041 51.97 35C52.2126 35 52.4524 35.0525 52.6728 35.1539C52.8932 35.2554 53.0889 35.4034 53.2467 35.5878C53.4044 35.7721 53.5204 35.9884 53.5865 36.2219C53.6527 36.4553 53.6675 36.7003 53.63 36.94ZM50.77 37C50.77 37.79 51.19 38.49 51.94 38.49C52.69 38.49 53.13 37.8 53.13 36.96C53.13 36.12 52.74 35.46 51.95 35.46C51.16 35.46 50.77 36.14 50.77 36.94V37Z"
      fill="black"
    />
    <path
      d="M54.91 35.4901H53.78V35.0801H56.56V35.4901H55.43V38.7701H54.95L54.91 35.4901Z"
      fill="black"
    />
    <path
      d="M58.84 37.0401H57.41V38.3701H59.01V38.7701H56.93V35.0801H58.93V35.4801H57.42V36.6501H58.85L58.84 37.0401Z"
      fill="black"
    />
    <path
      d="M60.22 37.6101L59.84 38.7701H59.35L60.6 35.0801H61.18L62.44 38.7701H61.93L61.53 37.6101H60.22ZM61.43 37.2401L61.07 36.1801C60.99 35.9401 60.93 35.7201 60.88 35.5001C60.82 35.7201 60.76 35.9501 60.69 36.1701L60.33 37.2401H61.43Z"
      fill="black"
    />
    <path
      d="M63.44 35.4901H62.31V35.0801H65.05V35.4901H63.92V38.7701H63.44V35.4901Z"
      fill="black"
    />
    <path
      d="M65.46 35.13C65.7637 35.0762 66.0716 35.0495 66.38 35.05C66.7399 35.029 67.0951 35.1391 67.38 35.36C67.4856 35.4578 67.5689 35.5772 67.6241 35.7101C67.6793 35.843 67.7052 35.9862 67.7 36.13C67.7095 36.2738 67.6896 36.418 67.6414 36.5539C67.5933 36.6897 67.5179 36.8143 67.42 36.92C67.2694 37.0616 67.0916 37.171 66.8973 37.2415C66.703 37.312 66.4963 37.3421 66.29 37.33C66.1638 37.3464 66.0361 37.3464 65.91 37.33V38.81H65.43L65.46 35.13ZM65.94 36.91C66.0697 36.9224 66.2003 36.9224 66.33 36.91C66.91 36.91 67.26 36.63 67.26 36.13C67.26 35.63 66.91 35.4 66.39 35.4C66.2405 35.3837 66.0896 35.3837 65.94 35.4V36.91Z"
      fill="black"
    />
    <path
      d="M0.559998 45.76L1.64999 47.25H1.07001L0 45.76L1.07001 44.27H1.64999L0.559998 45.76ZM1.88998 45.76L2.97 47.25H2.39999L1.32999 45.76L2.39999 44.27H2.97L1.88998 45.76Z"
      fill="black"
    />
    <path
      d="M6.43 42.12V42.61C7.07978 42.61 7.70296 42.8681 8.16242 43.3276C8.62189 43.7871 8.88001 44.4102 8.88001 45.06C8.88001 45.7098 8.62189 46.333 8.16242 46.7924C7.70296 47.2519 7.07978 47.51 6.43 47.51V48.02H5.75001V47.51C5.43597 47.5193 5.12347 47.4625 4.83283 47.3432C4.54219 47.2239 4.2799 47.0448 4.063 46.8175C3.84609 46.5902 3.67941 46.3198 3.5738 46.024C3.46819 45.7281 3.42604 45.4133 3.45002 45.1C3.42661 44.7813 3.46926 44.4613 3.57533 44.1599C3.68139 43.8585 3.84854 43.5823 4.06635 43.3485C4.28416 43.1147 4.54791 42.9284 4.84107 42.8014C5.13423 42.6743 5.45048 42.6091 5.77 42.61V42.12H6.43ZM5.75001 43.12C5.51485 43.1355 5.28545 43.1995 5.07618 43.3078C4.86691 43.4162 4.6823 43.5667 4.53394 43.7498C4.38558 43.9329 4.27665 44.1447 4.21403 44.3719C4.1514 44.5991 4.1364 44.8367 4.16999 45.07C4.14372 45.3016 4.164 45.5361 4.22959 45.7597C4.29518 45.9833 4.40477 46.1916 4.55195 46.3723C4.69912 46.5531 4.88091 46.7025 5.08665 46.812C5.29238 46.9215 5.51791 46.9889 5.75001 47.01V43.12ZM6.41999 47.01C6.65461 46.9931 6.88326 46.9281 7.0918 46.8193C7.30035 46.7105 7.48437 46.5601 7.63245 46.3773C7.78054 46.1945 7.88955 45.9833 7.95277 45.7567C8.01599 45.5302 8.03207 45.293 8.00001 45.06C8.00001 43.66 7.20999 43.16 6.41999 43.12V47.01Z"
      fill="black"
    />
    <path
      d="M14.3 45.01C14.3 46.89 13.16 47.89 11.77 47.89C11.4211 47.8865 11.0767 47.8099 10.7592 47.6653C10.4416 47.5206 10.1578 47.3111 9.9261 47.0502C9.69438 46.7893 9.51985 46.4827 9.41374 46.1503C9.30763 45.8179 9.2723 45.4669 9.31001 45.12C9.27097 44.7613 9.3073 44.3984 9.4167 44.0545C9.5261 43.7107 9.70615 43.3935 9.94529 43.1233C10.1844 42.8531 10.4774 42.6358 10.8054 42.4854C11.1334 42.335 11.4892 42.2548 11.85 42.25C12.1981 42.252 12.5419 42.3272 12.859 42.4709C13.1761 42.6145 13.4595 42.8232 13.6906 43.0836C13.9217 43.3439 14.0954 43.65 14.2004 43.9819C14.3054 44.3138 14.3393 44.6641 14.3 45.01ZM10.06 45.1C10.06 46.27 10.7 47.31 11.81 47.31C12.92 47.31 13.56 46.31 13.56 45.04C13.56 43.96 12.99 42.82 11.81 42.82C10.63 42.82 10.06 43.94 10.06 45.1Z"
      fill="black"
    />
    <path
      d="M15.22 42.4C15.6669 42.3328 16.1181 42.2994 16.57 42.3C17.1179 42.2572 17.6625 42.4174 18.1 42.75C18.2612 42.9011 18.3862 43.0865 18.4658 43.2925C18.5455 43.4985 18.5777 43.7198 18.56 43.94C18.5705 44.1531 18.5387 44.3661 18.4667 44.5669C18.3946 44.7677 18.2836 44.9522 18.14 45.11C17.9165 45.3175 17.6534 45.4777 17.3664 45.5808C17.0794 45.6839 16.7744 45.7279 16.47 45.71C16.2787 45.7155 16.0874 45.6987 15.9 45.66V47.85H15.2L15.22 42.4ZM15.92 45.04C16.1106 45.0802 16.3054 45.0969 16.5 45.09C17.35 45.09 17.87 44.68 17.87 43.93C17.87 43.18 17.36 42.86 16.59 42.86C16.3655 42.8537 16.141 42.8705 15.92 42.91V45.04Z"
      fill="black"
    />
    <path
      d="M23.98 45.4C23.98 44.64 23.89 43.72 23.9 43.04C23.71 43.68 23.48 44.36 23.21 45.12L22.21 47.77H21.68L20.8 45.17C20.54 44.4 20.32 43.69 20.16 43.04C20.16 43.72 20.1 44.64 20.05 45.46L19.91 47.8H19.23L19.61 42.34H20.51L21.44 44.98C21.67 45.65 21.86 46.25 22 46.82C22.14 46.27 22.33 45.67 22.58 44.98L23.58 42.34H24.48L24.82 47.8H24.13L23.98 45.4Z"
      fill="black"
    />
    <path
      d="M26.06 42.34L27.15 44.66L27.54 45.66C27.64 45.37 27.74 45.06 27.88 44.66L28.73 42.36H29.48L28.33 45.16C28.0733 45.8876 27.738 46.5851 27.33 47.24C27.1916 47.4423 27.0068 47.6086 26.7912 47.7252C26.5755 47.8417 26.3351 47.9051 26.09 47.91C25.9609 47.9316 25.8291 47.9316 25.7 47.91L25.77 47.33C25.8561 47.3443 25.944 47.3443 26.03 47.33C26.6 47.33 26.93 46.78 27.1 46.39C27.129 46.3398 27.1442 46.2829 27.1442 46.225C27.1442 46.167 27.129 46.1101 27.1 46.06L25.29 42.38L26.06 42.34Z"
      fill="black"
    />
    <path
      d="M33.56 42.3401V47.8001H32.84V42.9401H31.04V44.5901C31.04 45.7801 31.04 47.1001 30.22 47.5901C29.9659 47.7437 29.6767 47.8298 29.38 47.8401L29.3 47.2801C29.5113 47.2328 29.7073 47.133 29.87 46.9901C30.2824 46.2729 30.4509 45.4412 30.35 44.6201V42.3201L33.56 42.3401Z"
      fill="black"
    />
    <path
      d="M35.64 46.08L35.07 47.8H34.35L36.2 42.34H37.05L38.92 47.8H38.16L37.58 46.08H35.64ZM37.43 45.53L36.9 43.96C36.78 43.6 36.7 43.28 36.61 42.96C36.53 43.29 36.44 43.62 36.33 43.96L35.8 45.54L37.43 45.53Z"
      fill="black"
    />
    <path
      d="M42.13 42.34V44.76H42.35L44.22 42.34H45.07L43 44.84C43.3691 44.8891 43.7141 45.0508 43.9879 45.3032C44.2617 45.5555 44.451 45.8861 44.53 46.25C44.74 46.81 44.89 47.31 45.13 47.8H44.37C44.1751 47.3826 44.0113 46.9515 43.88 46.51C43.817 46.1561 43.6265 45.8376 43.3446 45.6146C43.0627 45.3917 42.7089 45.2797 42.35 45.3H42.13V47.8H41.43V42.34H42.13Z"
      fill="black"
    />
    <path
      d="M46.49 42.3401V44.6401C46.49 45.5101 46.49 46.2201 46.42 46.9501C46.7205 46.3292 47.0543 45.725 47.42 45.1401L49.19 42.3301H49.89V47.7901H49.23V45.4601C49.23 44.5801 49.23 43.9301 49.29 43.2401C48.9968 43.8744 48.6627 44.489 48.29 45.0801L46.56 47.8001H45.81V42.3401H46.49Z"
      fill="black"
    />
    <path
      d="M51.85 42.34V44.62H54.5V42.34H55.21V47.8H54.5V45.24H51.85V47.8H51.15V42.34H51.85Z"
      fill="black"
    />
    <path
      d="M61.1 45.0101C61.1532 45.3742 61.1273 45.7456 61.0239 46.0988C60.9206 46.452 60.7423 46.7788 60.5012 47.0569C60.2602 47.335 59.962 47.5579 59.627 47.7103C59.292 47.8627 58.9281 47.941 58.56 47.94C58.2119 47.9351 57.8687 47.8576 57.5523 47.7123C57.236 47.5671 56.9534 47.3574 56.7227 47.0966C56.4921 46.8358 56.3185 46.5298 56.213 46.198C56.1075 45.8663 56.0724 45.5161 56.11 45.17C56.071 44.8122 56.1071 44.4501 56.2159 44.1069C56.3247 43.7637 56.5038 43.447 56.7419 43.177C56.98 42.9069 57.2717 42.6894 57.5985 42.5385C57.9253 42.3875 58.2801 42.3063 58.64 42.3C58.9839 42.3028 59.3233 42.3768 59.6372 42.5173C59.951 42.6578 60.2324 42.8618 60.4635 43.1164C60.6946 43.371 60.8704 43.6707 60.98 43.9966C61.0896 44.3225 61.1304 44.6676 61.1 45.0101ZM56.86 45.1001C56.86 46.2701 57.49 47.31 58.6 47.31C59.71 47.31 60.35 46.3101 60.35 45.0401C60.35 43.9601 59.78 42.8201 58.61 42.8201C57.44 42.8201 56.86 43.9401 56.86 45.1001Z"
      fill="black"
    />
    <path d="M64.43 42.34V47.8H63.73V42.34H64.43Z" fill="black" />
    <path
      d="M70.27 45.4C70.27 44.64 70.18 43.72 70.18 43.04C69.99 43.68 69.77 44.36 69.49 45.12L68.49 47.77H67.95L67.07 45.17C66.81 44.4 66.59 43.69 66.44 43.04C66.44 43.72 66.38 44.64 66.34 45.46L66.19 47.8H65.56L65.94 42.34H66.84L67.77 44.98C68 45.65 68.18 46.25 68.32 46.82C68.46 46.27 68.66 45.67 68.9 44.98L69.9 42.34H70.8L71.14 47.8H70.45L70.27 45.4Z"
      fill="black"
    />
    <path
      d="M72.98 46.08L72.41 47.8H71.68L73.56 42.34H74.41L76.27 47.8H75.52L74.93 46.08H72.98ZM74.77 45.53L74.23 43.96C74.11 43.6 74.03 43.28 73.95 42.96C73.87 43.2866 73.78 43.62 73.68 43.96L73.14 45.54L74.77 45.53Z"
      fill="black"
    />
    <path
      d="M79.93 47.8L79.23 46.59C78.95 46.13 78.77 45.83 78.6 45.51C78.45 45.83 78.29 46.12 78.01 46.6L77.35 47.8H76.56L78.23 45.04L76.63 42.34H77.45L78.17 43.62C78.37 43.98 78.52 44.25 78.67 44.54C78.83 44.22 78.97 43.97 79.17 43.62L79.91 42.34H80.72L79.06 45L80.76 47.8H79.93Z"
      fill="black"
    />
    <path
      d="M86.87 42.3401V47.8001H86.15V42.9401H84.35V44.5901C84.35 45.7801 84.35 47.1001 83.53 47.5901C83.2759 47.7437 82.9867 47.8298 82.69 47.8401L82.6 47.2801C82.8151 47.2352 83.015 47.1353 83.18 46.9901C83.5924 46.2729 83.7608 45.4412 83.66 44.6201V42.3201L86.87 42.3401Z"
      fill="black"
    />
    <path
      d="M88.97 46.08L88.4 47.8H87.68L89.56 42.34H90.41L92.28 47.8H91.52L90.94 46.08H88.97ZM90.76 45.53L90.23 43.96C90.11 43.6 90.03 43.28 89.94 42.96C89.86 43.29 89.77 43.62 89.66 43.96L89.13 45.54L90.76 45.53Z"
      fill="black"
    />
    <path
      d="M92.92 42.66C93.3496 42.3856 93.8502 42.2431 94.36 42.25C95.36 42.25 95.96 42.74 95.96 43.59C95.9557 43.9058 95.8415 44.2103 95.6371 44.4512C95.4328 44.6921 95.151 44.8543 94.84 44.91C95.1955 44.9204 95.5327 45.0697 95.7794 45.3258C96.0262 45.5819 96.1627 45.9244 96.16 46.28C96.16 47.42 95.16 47.87 94.16 47.87C93.6596 47.8804 93.1657 47.7561 92.73 47.51L92.92 46.96C93.2941 47.1668 93.7127 47.28 94.14 47.29C94.95 47.29 95.4 46.86 95.4 46.29C95.4 45.47 94.61 45.23 93.91 45.23H93.59V44.7H93.91C94.2122 44.7367 94.5168 44.6521 94.7567 44.4646C94.9967 44.2772 95.1525 44.0022 95.19 43.7C95.19 43.15 94.81 42.84 94.19 42.84C93.7874 42.8538 93.3964 42.9783 93.06 43.2L92.92 42.66Z"
      fill="black"
    />
    <path
      d="M99.94 45.24H97.81V47.24H100.18V47.83H97.11V42.34H100.11V42.94H97.86V44.67H99.99L99.94 45.24Z"
      fill="black"
    />
    <path
      d="M101.1 42.4C101.547 42.3328 101.998 42.2994 102.45 42.3C102.998 42.26 103.541 42.4199 103.98 42.75C104.138 42.8937 104.263 43.0702 104.346 43.267C104.429 43.4639 104.468 43.6765 104.46 43.89C104.47 44.103 104.439 44.3161 104.367 44.5169C104.295 44.7176 104.184 44.9022 104.04 45.06C103.816 45.2675 103.553 45.4276 103.266 45.5307C102.979 45.6338 102.674 45.6778 102.37 45.66C102.179 45.6654 101.987 45.6487 101.8 45.61V47.8H101.1V42.4ZM101.8 45.04C101.991 45.0801 102.185 45.0969 102.38 45.09C103.23 45.09 103.75 44.68 103.75 43.93C103.75 43.18 103.24 42.86 102.46 42.86C102.239 42.854 102.018 42.8708 101.8 42.91V45.04Z"
      fill="black"
    />
    <path
      d="M104.91 44.27H105.48L106.56 45.76L105.48 47.25H104.92L106 45.76L104.91 44.27ZM106.23 44.27H106.81L107.88 45.76L106.81 47.25H106.24L107.32 45.76L106.23 44.27Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip6">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(37.94)"
        />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconCinemaImax",
};
</script>

<style scoped lang="scss"></style>
