var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip5)" } }, [
        _c("circle", {
          attrs: {
            cx: "25.94",
            cy: "16",
            r: "15.5",
            fill: "white",
            "fill-opacity": "0.01",
            stroke: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M30.52 23.88H16.94V12.67H30.52V23.88ZM17.94 22.88H29.52V13.67H17.94V22.88Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M35.3 23.76L34.04 22.5C33.6861 22.1444 33.2654 21.8623 32.802 21.67C32.3386 21.4778 31.8417 21.3792 31.34 21.38L31.29 20.38C32.374 20.3665 33.4309 20.7188 34.29 21.38V15.85C33.442 16.5385 32.3822 16.9129 31.29 16.91V15.91C31.7917 15.9108 32.2886 15.8123 32.752 15.62C33.2154 15.4278 33.6361 15.1457 33.99 14.79L35.3 13.47V23.76Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M21.26 13.67C20.3777 13.6674 19.5322 13.3157 18.9083 12.6918C18.2843 12.0678 17.9326 11.2224 17.93 10.34C17.93 9.45591 18.2805 8.60788 18.9047 7.9818C19.5289 7.35571 20.3759 7.00265 21.26 7C22.145 7.00264 22.993 7.35538 23.6188 7.98118C24.2446 8.60698 24.5974 9.45499 24.6 10.34C24.5974 11.2241 24.2443 12.0711 23.6182 12.6953C22.9921 13.3195 22.1441 13.67 21.26 13.67ZM21.26 8C20.6411 8.00264 20.0485 8.25035 19.6118 8.6889C19.1752 9.12744 18.93 9.72113 18.93 10.34C18.93 10.958 19.1755 11.5506 19.6125 11.9876C20.0494 12.4245 20.6421 12.67 21.26 12.67C21.5668 12.6713 21.8709 12.612 22.1547 12.4955C22.4386 12.379 22.6966 12.2076 22.914 11.9911C23.1315 11.7746 23.304 11.5173 23.4217 11.234C23.5394 10.9506 23.6 10.6468 23.6 10.34C23.6 9.7194 23.3535 9.12421 22.9146 8.68537C22.4758 8.24654 21.8806 8 21.26 8Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M27.07 13.67C26.631 13.6726 26.1958 13.5885 25.7895 13.4223C25.3832 13.2561 25.0137 13.0112 24.7024 12.7018C24.391 12.3923 24.1439 12.0243 23.9753 11.619C23.8067 11.2136 23.72 10.779 23.72 10.34C23.72 9.45591 24.0705 8.60788 24.6947 7.9818C25.3189 7.35571 26.1659 7.00265 27.05 7C27.935 7.00264 28.783 7.35538 29.4088 7.98118C30.0346 8.60698 30.3873 9.45499 30.39 10.34C30.3874 11.2206 30.0371 12.0646 29.4153 12.6882C28.7935 13.3119 27.9506 13.6647 27.07 13.67ZM27.07 8C26.7618 7.99868 26.4565 8.05824 26.1714 8.17525C25.8864 8.29225 25.6272 8.4644 25.4089 8.68182C25.1905 8.89924 25.0173 9.15765 24.899 9.44221C24.7808 9.72676 24.72 10.0319 24.72 10.34C24.72 10.958 24.9654 11.5506 25.4024 11.9876C25.8394 12.4245 26.432 12.67 27.05 12.67C27.3568 12.6713 27.6609 12.612 27.9447 12.4955C28.2285 12.379 28.4866 12.2076 28.704 11.9911C28.9214 11.7746 29.094 11.5173 29.2117 11.234C29.3294 10.9506 29.39 10.6468 29.39 10.34C29.39 9.72285 29.1462 9.13067 28.7117 8.69241C28.2772 8.25414 27.6871 8.00527 27.07 8Z",
            fill: "#E73C14",
          },
        }),
      ]),
      _c("path", {
        attrs: {
          d: "M4.17999 35.77V39.47H3.69998V36.18H2.47998V37.3C2.47998 38.1 2.47999 38.99 1.92999 39.3C1.76025 39.4115 1.56296 39.4738 1.35999 39.48L1.29999 39.1C1.44448 39.0665 1.57847 38.9978 1.69 38.9C1.96903 38.4161 2.08143 37.854 2.00998 37.3V35.74L4.17999 35.77Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.94003 37.74H5.51001V39.07H7.11002V39.47H5.03003V35.77H7.03003V36.17H5.51001V37.34H6.94003V37.74Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.46002 36.18H7.33002V35.77H10.07V36.18H8.94003V39.47H8.46002V36.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.95 35.77V37.32H12.74V35.77H13.18V39.47H12.7V37.74H10.91V39.47H10.44V35.77H10.95Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.5 35.77V37.33C14.5 37.92 14.5 38.4 14.5 38.89C14.701 38.4728 14.9281 38.0686 15.18 37.68L16.38 35.77H16.85V39.47H16.4V37.89C16.4 37.3 16.4 36.89 16.45 36.39C16.2484 36.8166 16.0213 37.2307 15.77 37.63L14.61 39.47H14.1V35.77H14.5Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.1 35.77V37.33C18.1 37.92 18.1 38.4 18.1 38.89C18.3081 38.4713 18.5384 38.0641 18.79 37.67L19.98 35.76H20.46V39.46H20.01V37.88C20.01 37.29 20.01 36.88 20.01 36.38C19.8048 36.8067 19.5744 37.2207 19.32 37.62L18.18 39.47H17.67V35.77H18.1ZM18.71 35C18.71 35.17 18.78 35.34 19.04 35.34C19.3 35.34 19.35 35.18 19.38 35H19.74C19.74 35.36 19.5 35.59 19.03 35.59C18.9448 35.6029 18.8579 35.5974 18.7751 35.5737C18.6923 35.55 18.6155 35.5088 18.55 35.4528C18.4846 35.3968 18.4319 35.3274 18.3957 35.2493C18.3594 35.1712 18.3405 35.0861 18.34 35H18.71Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.89 35.77V37.41H23.04L24.31 35.77H24.88L23.49 37.47C23.7325 37.5103 23.9574 37.6223 24.1357 37.7917C24.3139 37.961 24.4373 38.1799 24.49 38.42C24.605 38.7781 24.7419 39.1288 24.9 39.47H24.38C24.252 39.1872 24.1418 38.8966 24.05 38.6C24.0095 38.3652 23.8865 38.1526 23.7032 38.0004C23.5199 37.8483 23.2882 37.7666 23.05 37.77H22.89V39.47H22.42V35.77H22.89Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.84 35.77V37.33C25.84 37.92 25.84 38.4 25.84 38.89C26.0406 38.4693 26.2677 38.0619 26.52 37.67L27.72 35.76H28.18V39.46H27.74V37.88C27.74 37.29 27.74 36.88 27.74 36.38C27.5384 36.8066 27.3113 37.2207 27.06 37.62L25.9 39.46H25.39V35.76L25.84 35.77Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.47 35.77V37.32H31.26V35.77H31.74V39.47H31.26V37.74H29.47V39.47H29V35.77H29.47Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.73 37.58C35.7597 37.8238 35.7374 38.0711 35.6646 38.3057C35.5918 38.5403 35.4701 38.7568 35.3077 38.941C35.1452 39.1252 34.9456 39.2729 34.7219 39.3744C34.4982 39.4759 34.2556 39.5289 34.01 39.53C33.7742 39.5257 33.5418 39.4725 33.3276 39.3736C33.1135 39.2747 32.9223 39.1323 32.7661 38.9554C32.61 38.7786 32.4924 38.5712 32.4207 38.3465C32.3491 38.1217 32.325 37.8845 32.35 37.65C32.3251 37.4075 32.3507 37.1625 32.4254 36.9305C32.5001 36.6985 32.6222 36.4845 32.7838 36.3021C32.9455 36.1197 33.1433 35.9729 33.3647 35.871C33.5862 35.769 33.8263 35.7142 34.07 35.71C34.3054 35.7128 34.5376 35.7648 34.7518 35.8626C34.9659 35.9604 35.1572 36.1018 35.3135 36.2779C35.4698 36.454 35.5876 36.6607 35.6593 36.8849C35.731 37.1092 35.7551 37.3459 35.73 37.58ZM32.86 37.64C32.86 38.43 33.29 39.14 34.04 39.14C34.79 39.14 35.22 38.44 35.22 37.6C35.22 36.76 34.84 36.1 34.04 36.1C33.24 36.1 32.86 36.83 32.86 37.64Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37 36.18H35.88V35.77H38.61V36.18H37.49V39.47H37V36.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.94 37.74H39.5V39.07H41.1V39.47H39.02V35.77H41.02V36.17H39.5V37.34H40.94V37.74Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.32 38.31L41.93 39.47H41.44L42.7 35.77H43.27L44.53 39.47H44.02L43.63 38.31H42.32ZM43.53 37.93L43.18 36.87C43.1 36.63 43.04 36.41 42.99 36.2C42.93 36.42 42.87 36.64 42.8 36.87L42.44 37.93H43.53Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M45.53 36.18H44.41V35.77H47.18V36.18H46.06V39.47H45.57L45.53 36.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.56 35.8201C47.8608 35.7708 48.1653 35.7474 48.47 35.7501C48.8278 35.7331 49.1807 35.8389 49.47 36.0501C49.5769 36.1489 49.661 36.27 49.7162 36.4047C49.7715 36.5394 49.7967 36.6846 49.79 36.8301C49.8004 37.1194 49.7003 37.4018 49.51 37.6201C49.2 37.8957 48.7945 38.0393 48.38 38.0201C48.2506 38.0366 48.1195 38.0366 47.99 38.0201V39.5001H47.52L47.56 35.8201ZM48.03 37.6001C48.1628 37.6174 48.2973 37.6174 48.43 37.6001C49 37.6001 49.35 37.3201 49.35 36.8101C49.35 36.3001 49.01 36.0801 48.48 36.0801C48.3305 36.0637 48.1796 36.0637 48.03 36.0801V37.6001Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.56 46.45L2.63998 47.94H2.07001L1 46.45L2.07001 44.96H2.63998L1.56 46.45ZM2.88998 46.45L3.97 47.94H3.39999L2.32999 46.45L3.39999 44.96H3.97L2.88998 46.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.47 43.03V45.45H5.69L7.56 43.03H8.41L6.35001 45.53C6.71702 45.5845 7.05916 45.7481 7.332 45.9995C7.60484 46.251 7.79578 46.5787 7.88 46.94C8.04454 47.4697 8.24498 47.9876 8.47998 48.49H7.72C7.50713 48.0676 7.3266 47.6297 7.17999 47.18C7.11718 46.8279 6.92817 46.5108 6.64838 46.2881C6.36858 46.0654 6.01719 45.9523 5.66 45.97H5.42999V48.47H4.72998V43.01L5.47 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.09 46.77L9.51999 48.49H8.78998L10.65 43.03H11.5L13.36 48.49H12.61L12.02 46.77H10.09ZM11.88 46.18L11.34 44.61C11.22 44.25 11.14 43.93 11.06 43.61C10.98 43.9434 10.89 44.2767 10.79 44.61L10.23 46.18H11.88Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.18 43.1C14.6259 43.0241 15.0777 42.9873 15.53 42.99C16.0779 42.9472 16.6225 43.1073 17.06 43.44C17.2188 43.5852 17.344 43.7634 17.4269 43.962C17.5098 44.1606 17.5484 44.3749 17.54 44.59C17.5519 44.8017 17.5209 45.0136 17.4487 45.2129C17.3765 45.4123 17.2647 45.595 17.12 45.75C16.8968 45.958 16.6337 46.1184 16.3466 46.2215C16.0596 46.3247 15.7545 46.3684 15.45 46.35C15.2606 46.3715 15.0694 46.3715 14.88 46.35V48.54H14.18V43.1ZM14.88 45.73C15.0735 45.774 15.2716 45.7941 15.47 45.79C16.32 45.79 16.84 45.37 16.84 44.62C16.84 43.87 16.33 43.55 15.55 43.55C15.325 43.541 15.0998 43.5611 14.88 43.61V45.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.07 45.6999C23.07 47.5799 21.93 48.5799 20.54 48.5799C20.1911 48.5764 19.8467 48.4998 19.5292 48.3552C19.2116 48.2106 18.9278 48.0011 18.6961 47.7401C18.4644 47.4792 18.2899 47.1727 18.1838 46.8402C18.0777 46.5078 18.0423 46.1569 18.08 45.8099C18.0425 45.4515 18.08 45.0892 18.19 44.746C18.2999 44.4028 18.4801 44.0863 18.7189 43.8164C18.9578 43.5465 19.2501 43.3293 19.5773 43.1784C19.9046 43.0275 20.2597 42.9463 20.62 42.9399C20.9681 42.9419 21.3119 43.0172 21.629 43.1608C21.9461 43.3044 22.2295 43.5132 22.4606 43.7735C22.6917 44.0339 22.8653 44.3399 22.9704 44.6719C23.0754 45.0038 23.1093 45.354 23.07 45.6999ZM18.83 45.7899C18.83 46.9599 19.47 48.0099 20.58 48.0099C21.69 48.0099 22.33 47.0099 22.33 45.7399C22.33 44.4699 21.76 43.5199 20.58 43.5199C19.4 43.5199 18.83 44.5899 18.83 45.7899Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: { d: "M25.61 46.04V46.56H23.61V46.04H25.61Z", fill: "black" },
      }),
      _c("path", {
        attrs: {
          d: "M29.18 42.81V43.3C29.4973 43.2947 29.8122 43.356 30.1044 43.4799C30.3965 43.6038 30.6594 43.7876 30.8762 44.0194C31.0929 44.2512 31.2586 44.5259 31.3626 44.8257C31.4666 45.1255 31.5066 45.4438 31.48 45.76C31.5023 46.0734 31.4591 46.388 31.353 46.6837C31.2469 46.9794 31.0803 47.2497 30.8639 47.4774C30.6475 47.7051 30.3859 47.8852 30.096 48.0062C29.8061 48.1272 29.4941 48.1864 29.18 48.18V48.69H28.5V48.18C28.186 48.1894 27.8735 48.1325 27.5829 48.0132C27.2922 47.8939 27.0299 47.7148 26.813 47.4875C26.5961 47.2603 26.4294 46.9899 26.3238 46.694C26.2182 46.3981 26.176 46.0833 26.2 45.77C26.1767 45.4522 26.219 45.133 26.3245 44.8323C26.43 44.5316 26.5963 44.2558 26.813 44.0222C27.0297 43.7886 27.2923 43.6021 27.5842 43.4744C27.8762 43.3467 28.1913 43.2806 28.51 43.28V42.79L29.18 42.81ZM28.5 43.81C28.2594 43.8232 28.0242 43.8874 27.8102 43.9984C27.5963 44.1093 27.4084 44.2645 27.2589 44.4537C27.1095 44.6428 27.002 44.8616 26.9436 45.0954C26.8852 45.3293 26.8771 45.5728 26.92 45.81C26.8909 46.0421 26.9091 46.2776 26.9736 46.5024C27.0381 46.7272 27.1475 46.9366 27.2952 47.1179C27.4429 47.2992 27.6257 47.4488 27.8328 47.5574C28.0399 47.666 28.2669 47.7316 28.5 47.75V43.81ZM29.17 47.7C29.4096 47.6838 29.643 47.6176 29.8554 47.5057C30.0678 47.3937 30.2545 47.2386 30.4033 47.0502C30.5521 46.8618 30.6599 46.6444 30.7196 46.4118C30.7793 46.1793 30.7897 45.9368 30.75 45.7C30.75 44.3 29.95 43.8 29.17 43.76V47.7Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.01 43.03V45.33C33.01 46.2 33.01 46.91 32.94 47.64C33.2406 47.0191 33.5744 46.4149 33.94 45.83L35.71 43.02H36.41V48.48H35.75V46.18C35.75 45.29 35.75 44.65 35.81 43.96C35.5132 44.5925 35.1793 45.2069 34.81 45.8L33.1 48.51H32.34V43.05L33.01 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.28 43.03V48.49H40.57V43.62H38.76V45.27C38.76 46.46 38.76 47.78 37.95 48.27C37.6983 48.4301 37.4082 48.5199 37.11 48.53L37.02 47.96C37.2352 47.9152 37.4351 47.8152 37.6 47.67C38.0138 46.9533 38.1823 46.1212 38.08 45.3V43L41.28 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.18 43.03V45.33C43.18 46.2 43.18 46.91 43.12 47.64C43.4 47.07 43.74 46.48 44.12 45.83L45.89 43.02H46.6V48.48H45.93V46.18C45.93 45.29 45.93 44.65 46 43.96C45.7032 44.5925 45.3693 45.2069 45 45.8L43.29 48.51H42.54V43.05L43.18 43.03Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.41 44.96H47.98L49.06 46.45L47.98 47.94H47.41L48.49 46.45L47.41 44.96ZM48.73 44.96H49.3L50.38 46.45L49.3 47.94H48.73L49.81 46.45L48.73 44.96Z",
          fill: "black",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip5" } }, [
          _c("rect", {
            attrs: {
              width: "32",
              height: "32",
              fill: "white",
              transform: "translate(9.94)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }