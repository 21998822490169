<template>
  <g>
    <defs>
      <marker
        :id="hintArrow"
        orient="auto"
        markerWidth="2"
        markerHeight="4"
        refX="0.1"
        refY="2"
      >
        <path d="M0,0 V4 L2,2 Z" fill="#686868" />
      </marker>

      <path :id="hintPathClone" ref="pathClone" d="" />
    </defs>

    <text
      :class="{ 'arrow-hint__text': true, 'arrow-hint__text--show': end }"
      fill="#000000"
      text-anchor="middle"
      :dy="dy"
    >
      <textPath
        startOffset="50%"
        :href="reverse || !reverse ? `#${hintPathClone}` : `#${hintPath}`"
      >
        {{ hint }}
        <tspan
          v-if="man"
          style="font-family: fontello"
          dx="0"
          dy="0"
          fill="#000"
          font-size="12px"
        >
          &#xe800;
        </tspan>
        <tspan
          v-if="manLeft"
          style="font-family: fontello"
          dx="0"
          dy="0"
          fill="#000"
          font-size="12px"
        >
          &#xe804;
        </tspan>
        <tspan
          v-if="car"
          style="font-family: fontello"
          dx="0"
          dy="0"
          fill="#000"
          font-size="12px"
        >
          &#xe803;
        </tspan>
        <tspan
          v-if="electric"
          style="font-family: fontello"
          dx="0"
          dy="0"
          fill="#000"
          font-size="12px"
        >
          &#xe802;
        </tspan>
      </textPath>
    </text>
    <path
      :id="hintPath"
      ref="path"
      d=""
      :marker-end="`url(#${hintArrow})`"
      stroke-width="3"
      stroke-dasharray="3 3"
      fill="none"
      stroke="#686868"
    />
  </g>
</template>

<script>
import ease from "easy-ease";

export default {
  name: "SvgArrowHint",
  props: {
    hintPath: {
      type: String,
      default: "",
    },
    hintArrow: {
      type: String,
      default: "",
    },
    hintPathClone: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    /**
     * Array of 4 curve points path
     */
    points: [],
    hint: "",
    reverse: false,
    man: false,
    manLeft: false,
    car: false,
    electric: false,
    dy: 200,
    end: false,
  }),
  methods: {
    animate({
      points,
      hint,
      man = false,
      car = false,
      reverse = false,
      manLeft = false,
      dy = 13,
      electric = false,
    }) {
      this.points = points;
      this.hint = hint;
      this.end = false;
      this.reverse = reverse;
      this.manLeft = manLeft;
      this.car = car;
      this.electric = electric;
      this.dy = dy;

      if (reverse) {
        this.drawCBezier(
          this.getBezierPoints(1, [...this.points].reverse()),
          this.$refs.pathClone
        );
      } else {
        this.drawCBezier(this.points, this.$refs.pathClone);
      }

      ease({
        startValue: 0,
        endValue: 1,
        durationMs: 1000,
        onStep: (value) => {
          this.drawCBezier(
            this.getBezierPoints(value, this.points),
            this.$refs.path
          );
        },
        onComplete: () => {
          this.end = true;
        },
      });
    },
    getBezierPoints(t, points) {
      const helperPoints = [];

      for (let i = 1; i < 4; i++) {
        const p = this.lerp(points[i - 1], points[i], t);
        helperPoints.push(p);
      }

      helperPoints.push(this.lerp(helperPoints[0], helperPoints[1], t));
      helperPoints.push(this.lerp(helperPoints[1], helperPoints[2], t));
      helperPoints.push(this.lerp(helperPoints[3], helperPoints[4], t));
      return [points[0], helperPoints[0], helperPoints[3], helperPoints[5]];
    },
    lerp(A, B, t) {
      return [(B[0] - A[0]) * t + A[0], (B[1] - A[1]) * t + A[1]];
    },
    drawCBezier(points, path) {
      let d = `M${points[0][0]},${points[0][1]} C`;
      for (let i = 1; i < 4; i++) {
        d += ` ${points[i][0]},${points[i][1]}`;
      }
      path.setAttributeNS(null, "d", d);
    },
  },
};
</script>

<style scoped lang="scss">
.test {
  display: block;
}
.arrow-hint {
  &__text {
    opacity: 0;
    font-size: 15px;
    font-family: $font-family-rubik;

    &--show {
      transition: all 300ms ease-out;
      opacity: 1;
    }
  }
}
</style>
