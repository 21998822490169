var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "34",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.73 16.78H37.09V15.52C37.09 15.0183 37.1888 14.5216 37.3807 14.0581C37.5727 13.5946 37.8541 13.1735 38.2089 12.8188C38.5636 12.4641 38.9847 12.1827 39.4481 11.9907C39.9116 11.7988 40.4083 11.7 40.91 11.7C41.9223 11.7026 42.8924 12.1059 43.6082 12.8217C44.324 13.5375 44.7273 14.5076 44.73 15.52V16.78ZM38.09 15.78H43.73V15.52C43.73 14.772 43.4329 14.0548 42.9041 13.5259C42.3752 12.9971 41.6579 12.7 40.91 12.7C40.1621 12.7 39.4448 12.9971 38.916 13.5259C38.3871 14.0548 38.09 14.772 38.09 15.52V15.78Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: { d: "M41.41 10.98H40.41V12.19H41.41V10.98Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: {
          d: "M34.51 24.9499H25.97V23.9499H33.51V17.7799L36.36 20.7799C36.379 20.8068 36.4046 20.8284 36.4344 20.8424C36.4642 20.8564 36.4971 20.8625 36.53 20.8599C36.56 20.8598 36.5897 20.8535 36.6172 20.8415C36.6448 20.8294 36.6695 20.8119 36.69 20.7899L40.88 16.7899L41.57 17.5099L37.38 21.5099C37.2637 21.6235 37.1261 21.7129 36.975 21.773C36.824 21.8331 36.6625 21.8626 36.5 21.8599C36.1701 21.8489 35.8577 21.7089 35.63 21.4699L34.51 20.2999V24.9499Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.75 21.84C29.5938 21.8346 29.4434 21.7793 29.321 21.6821C29.1986 21.5849 29.1106 21.4509 29.07 21.3L27.5 14.74H25.5V13.74H28.25L29.73 19.92L31.37 13.74H33.51L36.43 17.14C36.4526 17.1653 36.4804 17.1855 36.5114 17.1993C36.5424 17.2131 36.576 17.2201 36.61 17.22C36.6425 17.22 36.6746 17.2129 36.704 17.199C36.7334 17.1852 36.7593 17.165 36.78 17.14L37.44 16.4L38.19 17.06L37.53 17.8C37.416 17.9322 37.2748 18.0382 37.116 18.1107C36.9572 18.1832 36.7846 18.2205 36.61 18.22C36.4337 18.2162 36.2601 18.1761 36.1001 18.1021C35.9401 18.0281 35.797 17.9219 35.68 17.79L33.06 14.74H32.17L30.43 21.31C30.3905 21.4607 30.3025 21.5942 30.1796 21.69C30.0567 21.7858 29.9058 21.8385 29.75 21.84Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.78 13.53C28.9162 13.5274 28.0885 13.1831 27.4777 12.5723C26.8669 11.9615 26.5226 11.1338 26.52 10.27C26.52 9.40447 26.8631 8.57425 27.4742 7.96129C28.0853 7.34833 28.9144 7.00265 29.78 7C30.6455 7.00265 31.4747 7.34833 32.0858 7.96129C32.6968 8.57425 33.04 9.40447 33.04 10.27C33.0373 11.1338 32.693 11.9615 32.0822 12.5723C31.4714 13.1831 30.6438 13.5274 29.78 13.53ZM29.78 8C29.1797 8.00264 28.6049 8.24297 28.1813 8.66839C27.7578 9.09381 27.52 9.66969 27.52 10.27C27.52 10.8694 27.7581 11.4442 28.1819 11.8681C28.6058 12.2919 29.1806 12.53 29.78 12.53C30.3794 12.53 30.9542 12.2919 31.3781 11.8681C31.8019 11.4442 32.04 10.8694 32.04 10.27C32.04 9.66969 31.8022 9.09381 31.3787 8.66839C30.9551 8.24297 30.3803 8.00264 29.78 8Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.80854 39.34C2.49279 39.4738 2.15112 39.5353 1.80854 39.52C1.5605 39.5312 1.31291 39.489 1.08265 39.3961C0.85239 39.3032 0.644832 39.1618 0.474068 38.9815C0.303304 38.8013 0.173357 38.5864 0.0930256 38.3514C0.0126943 38.1165 -0.0161021 37.867 0.00855298 37.62C-0.00137957 37.3656 0.0415442 37.112 0.134652 36.8751C0.227759 36.6382 0.368995 36.4232 0.549447 36.2437C0.729898 36.0642 0.945616 35.9241 1.18299 35.8322C1.42037 35.7403 1.67425 35.6987 1.92854 35.71C2.23115 35.6968 2.53261 35.755 2.80854 35.88L2.68855 36.27C2.45715 36.1613 2.20415 36.1066 1.94856 36.11C1.75088 36.1004 1.55345 36.1336 1.3697 36.2071C1.18595 36.2806 1.02014 36.3928 0.883553 36.536C0.74697 36.6792 0.64278 36.8501 0.578072 37.0372C0.513364 37.2242 0.489681 37.4229 0.508553 37.62C0.486356 37.8155 0.507168 38.0135 0.569527 38.2001C0.631886 38.3867 0.734243 38.5574 0.869515 38.7003C1.00479 38.8432 1.16968 38.9548 1.35261 39.0273C1.53554 39.0998 1.7321 39.1314 1.92854 39.12C2.19595 39.1226 2.46121 39.0716 2.70857 38.97L2.80854 39.34Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.31848 37.73H3.92847V39.07H5.52844V39.46H3.4585V35.77H5.4585V36.17H3.92847V37.34H5.36847L5.31848 37.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.21851 37.84C9.21851 37.33 9.15851 36.71 9.15851 36.25C9.03851 36.68 8.87855 37.14 8.69855 37.65L8.0285 39.44H7.66852L7.06854 37.68C6.88854 37.16 6.74855 36.68 6.63855 36.25C6.63855 36.71 6.63854 37.33 6.56854 37.88L6.46851 39.46H6.01855L6.26855 35.77H6.87854L7.50854 37.56C7.65855 38.01 7.78854 38.42 7.87854 38.8C7.98483 38.3796 8.11506 37.9656 8.26855 37.56L8.92853 35.77H9.5285L9.75854 39.46H9.29852L9.21851 37.84Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.4185 37.73H10.9885V39.07H12.5885V39.46H10.5085V35.77H12.5085V36.17H10.9985V37.34H12.4285L12.4185 37.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.6484 35.77V37.33C13.6484 37.91 13.6484 38.4 13.6484 38.89C13.8384 38.5 14.0684 38.1 14.3384 37.66L15.5284 35.76H16.0085V39.46H15.5585V37.89C15.5585 37.3 15.5585 36.89 15.5585 36.39C15.3491 36.8112 15.1188 37.2218 14.8685 37.62L13.7085 39.46H13.1985V35.77H13.6484ZM14.2585 35C14.2585 35.17 14.3284 35.34 14.5884 35.34C14.8484 35.34 14.8985 35.18 14.9285 35H15.2885C15.2885 35.36 15.0485 35.59 14.5785 35.59C14.4933 35.6029 14.4064 35.5974 14.3235 35.5737C14.2407 35.55 14.164 35.5088 14.0985 35.4528C14.033 35.3968 13.9804 35.3274 13.9442 35.2493C13.9079 35.1712 13.8889 35.0861 13.8885 35H14.2585Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.2886 35.77V37.32H19.0686V35.77H19.5586V39.46H19.0686V37.73H17.2886V39.46H16.8086V35.77H17.2886Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.8584 35.77V37.15H21.2984C22.0584 37.15 22.7184 37.48 22.7184 38.3C22.7258 38.5889 22.6261 38.8703 22.4384 39.09C22.0704 39.3983 21.5974 39.5524 21.1184 39.52C20.8708 39.5246 20.6232 39.5079 20.3784 39.47V35.77H20.8584ZM20.8584 39.1C20.9848 39.1117 21.112 39.1117 21.2384 39.1C21.7684 39.1 22.2384 38.84 22.2384 38.28C22.2384 37.72 21.7484 37.48 21.2384 37.48C21.1119 37.4698 20.9849 37.4698 20.8584 37.48V39.1ZM23.6084 35.77V39.46H23.1284V35.77H23.6084Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.9285 35.77V37.33C24.9285 37.91 24.9285 38.4 24.9285 38.89C25.131 38.4671 25.358 38.0564 25.6085 37.66L26.8085 35.76H27.2785V39.46H26.8286V37.89C26.8286 37.3 26.8286 36.89 26.8286 36.39C26.6233 36.8134 26.3929 37.2241 26.1385 37.62L24.9785 39.46H24.4685V35.77H24.9285ZM25.5285 35C25.5285 35.17 25.6085 35.34 25.8685 35.34C26.1285 35.34 26.1785 35.18 26.1985 35H26.5685C26.5653 35.087 26.5437 35.1724 26.5052 35.2505C26.4667 35.3287 26.4122 35.3978 26.3452 35.4535C26.2782 35.5091 26.2003 35.5501 26.1164 35.5736C26.0325 35.5971 25.9447 35.6027 25.8585 35.59C25.7729 35.6028 25.6855 35.5972 25.6022 35.5736C25.5188 35.55 25.4415 35.5089 25.3752 35.4531C25.309 35.3973 25.2554 35.328 25.2181 35.2499C25.1807 35.1718 25.1604 35.0866 25.1585 35H25.5285Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.7086 35.77V37.41H29.8586L31.1186 35.77H31.6986L30.2986 37.47C30.9086 37.55 31.1386 37.92 31.2986 38.47C31.4103 38.8116 31.5439 39.1456 31.6986 39.47H31.1886C31.0796 39.194 30.9927 38.9098 30.9286 38.62C30.886 38.3871 30.7621 38.1768 30.579 38.0266C30.3959 37.8765 30.1654 37.7962 29.9286 37.8H29.7786V39.46H29.3086V35.77H29.7086Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.8285 35.77V39.46H34.3484V36.17H33.1285V37.29C33.1285 38.09 33.1285 38.98 32.5685 39.29C32.4025 39.4016 32.2084 39.4641 32.0085 39.47L31.9285 39.12C32.0735 39.0938 32.2085 39.0281 32.3185 38.93C32.5927 38.4443 32.7048 37.8838 32.6385 37.33V35.77H34.8285Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.7985 35.77L36.5284 37.34L36.7985 38L37.0284 37.33L37.5984 35.77H38.1085L37.3384 37.67C37.155 38.1582 36.9239 38.6271 36.6484 39.07C36.5538 39.2067 36.4286 39.3194 36.2828 39.3992C36.137 39.4791 35.9746 39.5239 35.8085 39.53C35.719 39.5444 35.6279 39.5444 35.5385 39.53V39.14H35.7085C36.0985 39.14 36.3185 38.77 36.4385 38.5C36.4385 38.42 36.4385 38.37 36.4385 38.28L35.2085 35.79L35.7985 35.77Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.5886 35.77V36.17H38.9886V37.17H39.4486C39.7985 37.1481 40.1468 37.2317 40.4486 37.41C40.6017 37.5052 40.7262 37.6401 40.809 37.8003C40.8917 37.9605 40.9296 38.1401 40.9186 38.32C40.9255 38.4676 40.9015 38.615 40.8481 38.7528C40.7948 38.8906 40.7132 39.0156 40.6086 39.12C40.2409 39.4128 39.7777 39.5589 39.3086 39.53C39.0576 39.5345 38.8067 39.5178 38.5586 39.48V35.77H40.5886ZM38.9886 39.1C39.1217 39.1113 39.2555 39.1113 39.3886 39.1C39.5307 39.107 39.6727 39.0859 39.8065 39.0378C39.9404 38.9898 40.0634 38.9157 40.1686 38.82C40.3026 38.6727 40.3743 38.4791 40.3686 38.28C40.3757 38.1625 40.3517 38.0451 40.2991 37.9398C40.2464 37.8344 40.1669 37.7448 40.0686 37.68C39.8613 37.5441 39.6162 37.4776 39.3686 37.49H38.9886V39.1Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.6085 37.7999V38.1599H41.2485V37.7999H42.6085Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.1885 35.82C43.4889 35.7667 43.7934 35.7399 44.0984 35.74C44.458 35.7217 44.8123 35.8315 45.0984 36.05C45.2041 36.1478 45.2873 36.2672 45.3425 36.4001C45.3978 36.533 45.4237 36.6762 45.4185 36.82C45.433 37.11 45.3324 37.394 45.1385 37.61C44.9869 37.7502 44.8089 37.8586 44.6148 37.929C44.4208 37.9994 44.2146 38.0304 44.0085 38.02C43.8787 38.0315 43.7482 38.0315 43.6185 38.02V39.46H43.1484L43.1885 35.82ZM43.6584 37.62C43.7915 37.6319 43.9254 37.6319 44.0585 37.62C44.6285 37.62 44.9785 37.35 44.9785 36.84C44.9785 36.33 44.6385 36.11 44.1085 36.11C43.9589 36.0943 43.808 36.0943 43.6584 36.11V37.62Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.0087 37.73H46.5787V39.07H48.1786V39.46H46.0986V35.77H48.0986V36.17H46.5787V37.34H48.0087V37.73Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.3785 39.34C51.0623 39.4722 50.721 39.5336 50.3785 39.52C50.1301 39.5329 49.8817 39.4918 49.6506 39.3995C49.4196 39.3072 49.2113 39.1658 49.0402 38.9852C48.8691 38.8046 48.7392 38.589 48.6595 38.3533C48.5798 38.1176 48.5522 37.8674 48.5785 37.62C48.5642 37.3623 48.6042 37.1045 48.696 36.8633C48.7878 36.6221 48.9292 36.4029 49.1113 36.2199C49.2933 36.0369 49.5117 35.8943 49.7524 35.8013C49.9932 35.7083 50.2508 35.6669 50.5085 35.68C50.8081 35.6649 51.1067 35.7232 51.3785 35.85L51.2685 36.24C51.0339 36.1305 50.7774 36.0758 50.5185 36.08C50.3181 36.0698 50.1178 36.1036 49.9317 36.1788C49.7456 36.2541 49.5781 36.369 49.441 36.5157C49.3039 36.6623 49.2005 36.8371 49.1379 37.0278C49.0753 37.2185 49.055 37.4206 49.0785 37.62C49.0545 37.8158 49.074 38.0146 49.1357 38.202C49.1974 38.3894 49.2998 38.5609 49.4355 38.7042C49.5711 38.8475 49.7368 38.9591 49.9205 39.0309C50.1043 39.1028 50.3016 39.1332 50.4985 39.12C50.7659 39.1226 51.0311 39.0716 51.2785 38.97L51.3785 39.34Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M52.8685 36.18H51.7485V35.77H54.4785V36.18H53.3485V39.46H52.8685V36.18Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.8484 37.6199C57.8874 37.867 57.8724 38.1196 57.8044 38.3603C57.7364 38.601 57.617 38.8241 57.4545 39.0142C57.2919 39.2043 57.0901 39.3569 56.8629 39.4615C56.6357 39.566 56.3885 39.6201 56.1384 39.6199C55.8909 39.618 55.6467 39.562 55.4231 39.4559C55.1994 39.3498 55.0017 39.1962 54.8436 39.0057C54.6855 38.8152 54.5709 38.5926 54.5078 38.3532C54.4447 38.1138 54.4347 37.8636 54.4784 37.6199C54.4534 37.3775 54.4791 37.1325 54.5537 36.9005C54.6284 36.6684 54.7505 36.4545 54.9122 36.2721C55.0739 36.0897 55.2717 35.9429 55.4931 35.841C55.7145 35.739 55.9547 35.6842 56.1984 35.6799C56.4402 35.6813 56.6788 35.7349 56.898 35.837C57.1171 35.9391 57.3117 36.0873 57.4683 36.2715C57.625 36.4557 57.74 36.6715 57.8056 36.9042C57.8712 37.1369 57.8858 37.3811 57.8484 37.6199ZM54.9884 37.6199C54.9884 38.4099 55.4184 39.1199 56.1684 39.1199C56.9184 39.1199 57.3484 38.4199 57.3484 37.5799C57.3484 36.7399 56.9684 36.0799 56.1684 36.0799C55.3684 36.0799 54.9884 36.8299 54.9884 37.6199Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.4684 35.82C58.7723 35.7672 59.0801 35.7404 59.3885 35.74C59.748 35.7217 60.1024 35.8315 60.3885 36.05C60.4941 36.1478 60.5773 36.2672 60.6326 36.4001C60.6878 36.533 60.7137 36.6762 60.7085 36.82C60.7161 36.9645 60.6943 37.1089 60.6444 37.2447C60.5946 37.3805 60.5177 37.5048 60.4185 37.61C60.2694 37.7508 60.0932 37.8598 59.9006 37.9303C59.7081 38.0008 59.5032 38.0313 59.2985 38.02C59.1687 38.0315 59.0382 38.0315 58.9084 38.02V39.46H58.4285L58.4684 35.82ZM58.9285 37.62C59.0615 37.6319 59.1954 37.6319 59.3285 37.62C59.8985 37.62 60.2485 37.35 60.2485 36.84C60.2485 36.33 59.9085 36.11 59.3785 36.11C59.2289 36.0937 59.078 36.0937 58.9285 36.11V37.62Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.5284 38.3L61.1385 39.46H60.6484L61.9285 35.77H62.5085L63.7685 39.46H63.2585L62.8685 38.3H61.5284ZM62.7385 37.93L62.3785 36.87C62.2885 36.63 62.2385 36.41 62.1785 36.19C62.1785 36.41 62.0685 36.64 61.9985 36.86L61.6385 37.93H62.7385Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M64.7584 35.77V37.32H66.5483V35.77H67.0283V39.46H66.5483V37.73H64.7584V39.46H64.2783V35.77H64.7584Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.56 46.45L10.64 47.94H10.07L9 46.45L10.07 44.96H10.64L9.56 46.45ZM10.89 46.45L11.97 47.94H11.4L10.33 46.45L11.4 44.96H11.97L10.89 46.45Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.8101 43.1C13.2559 43.0236 13.7077 42.9868 14.16 42.99C14.7232 42.9336 15.2871 43.0907 15.7401 43.43C15.8767 43.5581 15.9855 43.713 16.0594 43.8851C16.1334 44.0572 16.1711 44.2427 16.17 44.43C16.1773 44.7389 16.0836 45.0418 15.9031 45.2927C15.7227 45.5435 15.4653 45.7287 15.17 45.82C15.3913 45.9139 15.5833 46.0655 15.7258 46.259C15.8683 46.4526 15.9561 46.6809 15.98 46.92C16.0779 47.4308 16.2185 47.9326 16.4001 48.42H15.67C15.5051 47.9962 15.3812 47.5575 15.3 47.11C15.14 46.36 14.8501 46.11 14.2101 46.05H13.5601V48.49H12.85L12.8101 43.1ZM13.5601 45.62H14.28C15.03 45.62 15.5101 45.21 15.5101 44.62C15.5101 44.03 15.0001 43.62 14.2501 43.62C14.0213 43.5869 13.7889 43.5869 13.5601 43.62V45.62Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.8702 43.0299V48.4899H17.1702V43.0299H17.8702Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.1001 43.1C19.526 43.0256 19.9577 42.9888 20.3901 42.99C20.9219 42.9394 21.4542 43.0813 21.8901 43.39C22.0464 43.503 22.1709 43.6544 22.2515 43.8296C22.3321 44.0048 22.366 44.1978 22.3501 44.39C22.3419 44.6627 22.2474 44.9257 22.0803 45.1413C21.9132 45.3569 21.6821 45.514 21.4201 45.59C21.7339 45.6511 22.0176 45.8169 22.2247 46.0604C22.4319 46.3039 22.5501 46.6105 22.5601 46.93C22.5621 47.135 22.5224 47.3382 22.4433 47.5273C22.3642 47.7165 22.2474 47.8875 22.1001 48.03C21.5544 48.427 20.8815 48.6086 20.2101 48.54C19.8422 48.5454 19.4744 48.522 19.1101 48.47L19.1001 43.1ZM19.8101 45.34H20.4501C21.1901 45.34 21.6301 44.95 21.6301 44.42C21.6301 43.89 21.1501 43.53 20.4301 43.53C20.2239 43.5105 20.0163 43.5105 19.8101 43.53V45.34ZM19.8101 47.95C20.0063 47.969 20.2039 47.969 20.4001 47.95C21.1301 47.95 21.8001 47.68 21.8001 46.89C21.8001 46.1 21.1601 45.83 20.3901 45.83H19.8101V47.95Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.3801 46.7699L23.8101 48.4899H23.0801L24.9401 43.0299H25.79L27.6501 48.4899H26.9001L26.3101 46.7699H24.3801ZM26.17 46.2199L25.6301 44.6199C25.5101 44.2599 25.43 43.9399 25.35 43.6199C25.27 43.9532 25.1801 44.2866 25.0801 44.6199L24.5601 46.2199H26.17Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.0201 46.0899C33.0201 45.3299 32.9301 44.4099 32.9401 43.7299C32.7601 44.3699 32.5301 45.0499 32.2601 45.8099L31.2601 48.4599H30.73L29.8401 45.8599C29.5801 45.0866 29.3701 44.3766 29.2101 43.7299C29.2101 44.4099 29.15 45.3299 29.1 46.1499L28.9501 48.4899H28.28L28.66 43.0299H29.5601L30.4901 45.6699C30.7055 46.2732 30.8891 46.8874 31.04 47.5099C31.18 46.9599 31.37 46.3599 31.61 45.6699L32.61 43.0299H33.5101L33.85 48.4899H33.16L33.0201 46.0899Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.9299 43.1C35.3558 43.0251 35.7875 42.9883 36.2199 42.99C36.7516 42.9402 37.2836 43.0821 37.7199 43.39C37.8729 43.5054 37.9944 43.6575 38.0731 43.8323C38.1517 44.0071 38.185 44.1989 38.1699 44.39C38.1609 44.6609 38.0673 44.9223 37.9023 45.1375C37.7374 45.3527 37.5093 45.5109 37.2499 45.59C37.565 45.6562 37.8479 45.8282 38.0516 46.0774C38.2554 46.3266 38.3677 46.6381 38.3699 46.96C38.3743 47.1653 38.3357 47.3692 38.2565 47.5587C38.1772 47.7482 38.0592 47.9189 37.9099 48.06C37.3642 48.4569 36.6913 48.6385 36.02 48.57C35.6553 48.5754 35.2909 48.552 34.9299 48.5V43.1ZM35.6299 45.34H36.27C37.02 45.34 37.46 44.95 37.46 44.42C37.46 43.89 36.9699 43.53 36.2599 43.53C36.0504 43.5106 35.8395 43.5106 35.6299 43.53V45.34ZM35.6299 47.95C35.8295 47.9692 36.0304 47.9692 36.2299 47.95C36.9499 47.95 37.6299 47.68 37.6299 46.89C37.6299 46.1 36.9899 45.83 36.2199 45.83H35.6299V47.95Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.1501 45.9299H40.0201V47.9299H42.3901V48.5199H39.3201V43.0299H42.3201V43.6199H40.0701V45.3499H42.2001L42.1501 45.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.3 43.0299H44.0101V47.8999H46.3401V48.4899H43.3401L43.3 43.0299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.1301 43.0299H47.8301V47.8999H50.1601V48.4899H47.1601L47.1301 43.0299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.7799 45.9299H51.65V47.9299H54.02V48.5199H50.95V43.0299H53.95V43.6199H51.7V45.3499H53.83L53.7799 45.9299Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.5002 44.96H55.0803L56.1602 46.45L55.0803 47.94H54.5602L55.5602 46.45L54.5002 44.96ZM55.8203 44.96H56.4003L57.4802 46.45L56.4003 47.94H55.8303L56.9102 46.45L55.8203 44.96Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }