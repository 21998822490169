var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "58",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.8399 26.1901C56.1672 26.1874 54.5638 25.5218 53.381 24.339C52.1982 23.1562 51.5326 21.5528 51.5299 19.8801C51.5326 18.2074 52.1982 16.6039 53.381 15.4211C54.5638 14.2384 56.1672 13.5727 57.8399 13.5701C59.5126 13.5727 61.1161 14.2384 62.2988 15.4211C63.4816 16.6039 64.1473 18.2074 64.1499 19.8801C64.1473 21.5528 63.4816 23.1562 62.2988 24.339C61.1161 25.5218 59.5126 26.1874 57.8399 26.1901ZM57.8399 14.5701C56.4316 14.5701 55.081 15.1295 54.0852 16.1253C53.0894 17.1212 52.5299 18.4718 52.5299 19.8801C52.5299 21.2884 53.0894 22.639 54.0852 23.6348C55.081 24.6306 56.4316 25.1901 57.8399 25.1901C59.2482 25.1901 60.5988 24.6306 61.5947 23.6348C62.5905 22.639 63.1499 21.2884 63.1499 19.8801C63.1499 18.4718 62.5905 17.1212 61.5947 16.1253C60.5988 15.1295 59.2482 14.5701 57.8399 14.5701Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M48.8699 17.12C48.1618 17.12 47.4827 16.8387 46.982 16.338C46.4813 15.8373 46.2 15.1582 46.2 14.45C46.2026 13.7427 46.4847 13.0651 46.9849 12.565C47.485 12.0648 48.1626 11.7827 48.8699 11.78C49.224 11.78 49.5745 11.8504 49.901 11.987C50.2276 12.1237 50.5238 12.324 50.7722 12.5762C51.0207 12.8283 51.2165 13.1274 51.3483 13.456C51.4801 13.7845 51.5453 14.1361 51.54 14.49C51.5321 15.192 51.2469 15.8624 50.7468 16.3551C50.2466 16.8478 49.572 17.1227 48.8699 17.12ZM48.8699 12.78C48.427 12.78 48.0023 12.956 47.6891 13.2692C47.3759 13.5824 47.2 14.0071 47.2 14.45C47.2 14.893 47.3759 15.3177 47.6891 15.6309C48.0023 15.9441 48.427 16.12 48.8699 16.12C49.306 16.1202 49.7249 15.9497 50.0369 15.6451C50.349 15.3405 50.5295 14.926 50.54 14.49C50.5453 14.2674 50.506 14.0459 50.4245 13.8387C50.343 13.6314 50.2208 13.4426 50.0652 13.2832C49.9096 13.1239 49.7237 12.9973 49.5184 12.9109C49.3132 12.8245 49.0927 12.78 48.8699 12.78Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.1099 11.34C53.4018 11.34 52.7227 11.0587 52.222 10.558C51.7212 10.0573 51.4399 9.37813 51.4399 8.67C51.4399 7.96187 51.7212 7.28275 52.222 6.78203C52.7227 6.28131 53.4018 6 54.1099 6C54.8173 6.00263 55.4949 6.28479 55.995 6.78494C56.4952 7.28509 56.7773 7.96268 56.7799 8.67C56.7773 9.37732 56.4952 10.0549 55.995 10.5551C55.4949 11.0552 54.8173 11.3374 54.1099 11.34ZM54.1099 7C53.667 7 53.2423 7.17595 52.9291 7.48914C52.6159 7.80232 52.4399 8.22709 52.4399 8.67C52.4399 9.11291 52.6159 9.53769 52.9291 9.85088C53.2423 10.1641 53.667 10.34 54.1099 10.34C54.5529 10.34 54.9776 10.1641 55.2908 9.85088C55.604 9.53769 55.7799 9.11291 55.7799 8.67C55.7799 8.22709 55.604 7.80232 55.2908 7.48914C54.9776 7.17595 54.5529 7 54.1099 7Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.38 11.34C60.6726 11.3374 59.995 11.0552 59.4949 10.5551C58.9947 10.0549 58.7126 9.37732 58.71 8.67C58.7126 7.96268 58.9947 7.28509 59.4949 6.78494C59.995 6.28479 60.6726 6.00263 61.38 6C62.0881 6 62.7672 6.28131 63.2679 6.78203C63.7687 7.28275 64.05 7.96187 64.05 8.67C64.05 9.37813 63.7687 10.0573 63.2679 10.558C62.7672 11.0587 62.0881 11.34 61.38 11.34ZM61.38 7C60.9371 7 60.5123 7.17595 60.1991 7.48914C59.8859 7.80232 59.71 8.22709 59.71 8.67C59.71 9.11291 59.8859 9.53769 60.1991 9.85088C60.5123 10.1641 60.9371 10.34 61.38 10.34C61.8229 10.34 62.2476 10.1641 62.5608 9.85088C62.874 9.53769 63.05 9.11291 63.05 8.67C63.05 8.22709 62.874 7.80232 62.5608 7.48914C62.2476 7.17595 61.8229 7 61.38 7Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M67 16.74C66.2926 16.7374 65.615 16.4552 65.1149 15.9551C64.6147 15.4549 64.3326 14.7773 64.33 14.07C64.3326 13.3627 64.6147 12.6851 65.1149 12.185C65.615 11.6848 66.2926 11.4027 67 11.4C67.7073 11.4027 68.3849 11.6848 68.885 12.185C69.3852 12.6851 69.6673 13.3627 69.67 14.07C69.6673 14.7773 69.3852 15.4549 68.885 15.9551C68.3849 16.4552 67.7073 16.7374 67 16.74ZM67 12.4C66.557 12.4 66.1323 12.576 65.8191 12.8891C65.5059 13.2023 65.33 13.6271 65.33 14.07C65.33 14.5129 65.5059 14.9377 65.8191 15.2509C66.1323 15.5641 66.557 15.74 67 15.74C67.4429 15.74 67.8676 15.5641 68.1808 15.2509C68.494 14.9377 68.67 14.5129 68.67 14.07C68.67 13.6271 68.494 13.2023 68.1808 12.8891C67.8676 12.576 67.4429 12.4 67 12.4Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.1499 19.3701H55.5299V20.3701H60.1499V19.3701Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.34 17.5701H57.34V22.1901H58.34V17.5701Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.690002 36.14V41.01H3.19V36.14H3.89999V41.03H4.38V42.9H3.82001V41.58H0V36.12L0.690002 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.08998 39.0399H5.99998V41.0399H8.35999V41.6299H5.28998V36.1699H8.28998V36.7699H5.99998V38.4899H8.11998L8.08998 39.0399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.99998 36.14V38.39H12.64V36.1H13.36V41.56H12.64V39H9.99998V41.56H9.29999V36.1L9.99998 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.57 36.7399H13.9V36.1399H17.9V36.7399H16.23V41.5999H15.52L15.57 36.7399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.55 36.2099C18.9993 36.1337 19.4543 36.0969 19.91 36.0999C20.4589 36.0628 21.0026 36.2263 21.44 36.5599C21.5986 36.7032 21.7237 36.8796 21.8067 37.0766C21.8896 37.2735 21.9283 37.4863 21.92 37.6999C21.9291 37.9137 21.8958 38.1271 21.822 38.3279C21.7482 38.5287 21.6354 38.713 21.49 38.8699C21.2671 39.0752 21.0054 39.234 20.7204 39.3371C20.4354 39.4401 20.1327 39.4852 19.83 39.4699C19.6387 39.4758 19.4473 39.4591 19.26 39.4199V41.5999H18.55V36.2099ZM19.26 38.8399C19.4501 38.8839 19.6449 38.9041 19.84 38.8999C20.69 38.8999 21.21 38.4899 21.21 37.7299C21.21 36.9699 20.7 36.6599 19.92 36.6599C19.6984 36.6555 19.4771 36.6756 19.26 36.7199V38.8399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M24.58 36.2199C25.0048 36.1361 25.437 36.0959 25.87 36.0999C26.4017 36.0502 26.9337 36.1921 27.37 36.4999C27.5217 36.6166 27.6421 36.7689 27.7206 36.9434C27.7992 37.1179 27.8333 37.3091 27.82 37.4999C27.811 37.7709 27.7174 38.0323 27.5524 38.2475C27.3875 38.4626 27.1593 38.6209 26.9 38.7C27.215 38.7662 27.4979 38.9382 27.7017 39.1874C27.9054 39.4366 28.0178 39.748 28.02 40.0699C28.0256 40.2739 27.9874 40.4768 27.908 40.6648C27.8287 40.8528 27.71 41.0216 27.56 41.1599C27.0143 41.5569 26.3413 41.7385 25.67 41.67C25.3058 41.6724 24.9418 41.6524 24.58 41.61V36.2199ZM25.28 38.45H25.92C26.67 38.45 27.11 38.06 27.11 37.54C27.11 37.02 26.62 36.65 25.91 36.65C25.7004 36.6323 25.4896 36.6323 25.28 36.65V38.45ZM25.28 41.0699C25.4798 41.0832 25.6802 41.0832 25.88 41.0699C26.6 41.0699 27.28 40.81 27.28 40.01C27.28 39.21 26.64 39.01 25.87 39.01H25.28V41.0699Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.8 39.0399H29.67V41.0399H32V41.6299H29V36.1699H31.95V36.7699H29.7V38.4899H31.83L31.8 39.0399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34 36.7399H32.34V36.1399H36.34V36.7399H34.67V41.5999H34V36.7399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.85 39.0399H37.72V41.0399H40.09V41.6299H37V36.1699H40V36.7699H37.75V38.4899H39.88L39.85 39.0399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41 36.2099C41.4493 36.1337 41.9043 36.0969 42.36 36.0999C42.9089 36.0628 43.4526 36.2263 43.89 36.5599C44.0486 36.7032 44.1738 36.8796 44.2567 37.0766C44.3396 37.2735 44.3783 37.4863 44.37 37.6999C44.3791 37.9137 44.3458 38.1271 44.272 38.3279C44.1982 38.5287 44.0854 38.713 43.94 38.8699C43.7156 39.076 43.4522 39.2352 43.1655 39.3382C42.8787 39.4413 42.5743 39.486 42.27 39.4699C42.082 39.4754 41.894 39.4586 41.71 39.4199V41.5999H41V36.2099ZM41.71 38.8399C41.9001 38.8839 42.0949 38.9041 42.29 38.8999C43.14 38.8999 43.66 38.4899 43.66 37.7299C43.66 36.9699 43.15 36.6599 42.37 36.6599C42.1485 36.6555 41.9271 36.6756 41.71 36.7199V38.8399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46 36.1399V38.4399C46 39.3099 46 40.0199 45.93 40.7499C46.2285 40.1312 46.5624 39.5302 46.93 38.9499L48.7 36.1299H49.4V41.5899H48.74V39.2699C48.74 38.3899 48.74 37.7399 48.8 37.0499C48.5068 37.6842 48.1727 38.2988 47.8 38.8899L46.09 41.5999H45.34V36.1399H46Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.35 36.14V38.39H54V36.1H54.71V41.56H54V39H51.36V41.56H50.65V36.1L51.35 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.8 39.8899L56.24 41.5999H55.51L57.36 36.1399H58.21L60.08 41.5999H59.32L58.74 39.8899H56.8ZM58.6 39.3399L58.06 37.7599C57.94 37.4099 57.86 37.0799 57.78 36.7599C57.7 37.0799 57.61 37.4099 57.5 37.7599L57 39.3899L58.6 39.3399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.88 36.2099C61.3259 36.1336 61.7777 36.0968 62.23 36.0999C62.7793 36.059 63.3243 36.2229 63.76 36.5599C63.9186 36.7032 64.0438 36.8796 64.1267 37.0766C64.2096 37.2735 64.2483 37.4863 64.24 37.6999C64.2568 38.1296 64.1063 38.549 63.82 38.8699C63.5956 39.076 63.3322 39.2352 63.0455 39.3382C62.7587 39.4413 62.4543 39.486 62.15 39.4699C61.9587 39.4754 61.7674 39.4586 61.58 39.4199V41.5999H60.88V36.2099ZM61.58 38.8399C61.7735 38.8839 61.9716 38.9041 62.17 38.8999C63.02 38.8999 63.53 38.4899 63.53 37.7299C63.53 36.9699 63.02 36.6599 62.25 36.6599C62.0252 36.6555 61.8005 36.6756 61.58 36.7199V38.8399Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.89 36.14V38.39H68.53V36.1H69.24V41.56H68.53V39H65.89V41.56H65.19V36.1L65.89 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M75.13 38.8199C75.1754 39.179 75.1439 39.5436 75.0375 39.8895C74.9311 40.2354 74.7523 40.5547 74.513 40.8262C74.2737 41.0977 73.9793 41.3151 73.6495 41.464C73.3197 41.6129 72.9619 41.6899 72.6 41.6899C72.2511 41.6864 71.9067 41.6098 71.5892 41.4652C71.2716 41.3206 70.9878 41.111 70.7561 40.8501C70.5244 40.5892 70.3499 40.2827 70.2437 39.9502C70.1376 39.6178 70.1023 39.2668 70.14 38.9199C70.1024 38.562 70.1399 38.2002 70.25 37.8575C70.3601 37.5149 70.5403 37.1989 70.7793 36.9298C71.0183 36.6607 71.3108 36.4444 71.638 36.2946C71.9653 36.1448 72.3201 36.0649 72.68 36.0599C73.0281 36.0619 73.3719 36.1372 73.689 36.2808C74.0061 36.4244 74.2894 36.6332 74.5205 36.8935C74.7516 37.1539 74.9254 37.4599 75.0304 37.7918C75.1354 38.1237 75.1693 38.474 75.13 38.8199ZM70.9 38.9099C70.9 40.0699 71.53 41.1199 72.64 41.1199C73.75 41.1199 74.39 40.1199 74.39 38.8499C74.39 37.7599 73.82 36.6299 72.65 36.6299C71.48 36.6299 70.9 37.7099 70.9 38.9099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M76.71 36.14V38.44C76.71 39.31 76.71 40.02 76.65 40.75C76.93 40.18 77.27 39.59 77.65 38.95L79.42 36.13H80.13V41.59H79.46V39.27C79.46 38.39 79.46 37.74 79.53 37.05C79.2332 37.6825 78.8993 38.2969 78.53 38.89L76.82 41.6H76.07V36.14H76.71ZM77.6 35C77.6 35.25 77.72 35.5 78.1 35.5C78.48 35.5 78.56 35.27 78.59 35H79.13C79.13 35.54 78.77 35.87 78.13 35.87C78.0059 35.8855 77.8799 35.8746 77.7603 35.8381C77.6407 35.8017 77.5301 35.7404 77.4357 35.6583C77.3414 35.5762 77.2654 35.4752 77.2127 35.3618C77.16 35.2483 77.1319 35.125 77.13 35H77.6Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M87 36.1399V41.5999H86.29V36.7399H83.8V41.5999H83.09V36.1399H87Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M92.94 38.8199C92.9839 39.1795 92.9509 39.5443 92.8433 39.8902C92.7357 40.2361 92.556 40.5553 92.3159 40.8265C92.0758 41.0978 91.7809 41.315 91.4506 41.4639C91.1203 41.6127 90.7622 41.6897 90.4 41.6899C90.0511 41.6864 89.7067 41.6098 89.3892 41.4652C89.0716 41.3206 88.7878 41.111 88.5561 40.8501C88.3244 40.5892 88.1498 40.2827 88.0437 39.9502C87.9376 39.6178 87.9023 39.2668 87.94 38.9199C87.9024 38.562 87.9399 38.2002 88.05 37.8575C88.16 37.5149 88.3403 37.1989 88.5793 36.9298C88.8183 36.6607 89.1107 36.4444 89.438 36.2946C89.7653 36.1448 90.1201 36.0649 90.48 36.0599C90.8285 36.0621 91.1726 36.1373 91.4902 36.2809C91.8077 36.4244 92.0916 36.6329 92.3235 36.8931C92.5554 37.1532 92.7301 37.4592 92.8363 37.7911C92.9424 38.123 92.9778 38.4735 92.94 38.8199ZM88.7 38.9099C88.7 40.0699 89.33 41.1199 90.44 41.1199C91.55 41.1199 92.19 40.1199 92.19 38.8499C92.19 37.7599 91.62 36.6299 90.45 36.6299C89.28 36.6299 88.7 37.7099 88.7 38.9099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M98.43 39.2099C98.43 38.4399 98.34 37.5299 98.34 36.8499C98.15 37.4899 97.93 38.1699 97.65 38.9199L96.65 41.5699H96.11L95.23 38.9699C94.97 38.1999 94.75 37.4999 94.6 36.8499C94.6 37.5299 94.54 38.4399 94.5 39.2599L94.35 41.5999H93.68L94.06 36.1399H94.96L95.89 38.7899C96.12 39.4599 96.3 40.0599 96.44 40.6199C96.58 40.0699 96.78 39.4699 97.02 38.7899L98.02 36.1399H98.92L99.26 41.5999H98.57L98.43 39.2099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M105 38.8199C105.045 39.179 105.014 39.5436 104.907 39.8895C104.801 40.2354 104.622 40.5547 104.383 40.8262C104.144 41.0977 103.849 41.3151 103.519 41.464C103.19 41.6129 102.832 41.6899 102.47 41.6899C102.12 41.6878 101.775 41.6123 101.456 41.4682C101.137 41.3242 100.852 41.1149 100.619 40.8538C100.387 40.5927 100.211 40.2857 100.105 39.9526C99.9978 39.6195 99.9622 39.2677 100 38.9199C99.9624 38.562 99.9999 38.2002 100.11 37.8575C100.22 37.5149 100.4 37.1989 100.639 36.9298C100.878 36.6607 101.171 36.4444 101.498 36.2946C101.825 36.1448 102.18 36.0649 102.54 36.0599C102.889 36.0605 103.234 36.1347 103.552 36.2777C103.87 36.4207 104.155 36.6293 104.387 36.8898C104.619 37.1503 104.794 37.4569 104.9 37.7895C105.005 38.1221 105.039 38.4732 105 38.8199ZM100.76 38.9099C100.76 40.0699 101.4 41.1199 102.51 41.1199C103.62 41.1199 104.26 40.1199 104.26 38.8499C104.26 37.7599 103.69 36.6299 102.51 36.6299C101.33 36.6299 100.76 37.7099 100.76 38.9099Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M106.61 36.14V41.01H108.39V36.14H109.08V41.01H110.85V36.14H111.55V41.03H112.03V42.9H111.47V41.58H106V36.12L106.61 36.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M113.58 36.1399V38.4399C113.58 39.3099 113.58 40.0199 113.51 40.7499C113.809 40.1312 114.142 39.5302 114.51 38.9499L116.28 36.1299H116.98V41.5899H116.32V39.2699C116.32 38.3899 116.32 37.7399 116.38 37.0499C116.083 37.6824 115.749 38.2968 115.38 38.8899L113.67 41.5999H112.91V36.1399H113.58Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }