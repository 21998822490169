var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "location" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("page-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c(
            "div",
            { staticClass: "location-description" },
            [_vm._t("description")],
            2
          ),
          _c(
            "ui-tab-bar",
            {
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("ui-tab-bar-tab", { attrs: { value: "Удобство" } }, [
                _vm._v(" Удобство "),
              ]),
              _c("ui-tab-bar-tab", { attrs: { value: "Гармония" } }, [
                _vm._v(" Гармония "),
              ]),
              _c("ui-tab-bar-tab", { attrs: { value: "Развитие" } }, [
                _vm._v(" Развитие "),
              ]),
              _c("ui-tab-bar-tab", { attrs: { value: "Семья" } }, [
                _vm._v(" Семья "),
              ]),
            ],
            1
          ),
          _c(
            "router-link",
            { attrs: { to: { name: "PanoramaPage" } } },
            [_c("ui-button", { attrs: { light: "" } }, [_vm._v(" Панорама ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "content-wrapper-block",
        {
          class: { location__box: true, "location__box--width": _vm.width },
          attrs: { "no-padding": "" },
        },
        [
          _c(
            "div",
            { ref: "map", staticClass: "map-animation" },
            [
              _c("PointComfort", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab == "Удобство",
                    expression: "activeTab == 'Удобство'",
                  },
                ],
              }),
              _c("PointHarmony", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab == "Гармония",
                    expression: "activeTab == 'Гармония'",
                  },
                ],
              }),
              _c("PointDevelopment", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab == "Развитие",
                    expression: "activeTab == 'Развитие'",
                  },
                ],
              }),
              _c("PointFamily", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab == "Семья",
                    expression: "activeTab == 'Семья'",
                  },
                ],
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }