var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("circle", {
          attrs: {
            cx: "30.89",
            cy: "16",
            r: "15.5",
            fill: "white",
            "fill-opacity": "0.01",
            stroke: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M33.17 25.34H28.34V19.34H33.17V25.34ZM29.34 24.34H32.17V20.34H29.34V24.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M40.62 25.34H20.89V15.4H22.17V16.4H21.89V24.34H39.62V16.4H39.49V15.4H40.62V25.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M39.38 25.34H22.13V11.91H25.04V12.91H23.13V24.34H38.38V12.91H36.62V11.91H39.38V25.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M37.42 25.34H24.08V11C24.08 9.4087 24.7121 7.88257 25.8373 6.75735C26.9625 5.63214 28.4887 5 30.08 5H31.47C33.0613 5 34.5874 5.63214 35.7126 6.75735C36.8378 7.88257 37.47 9.4087 37.47 11L37.42 25.34ZM25.08 24.34H36.42V11C36.42 9.67392 35.8932 8.40214 34.9555 7.46446C34.0178 6.52678 32.746 6 31.42 6H30.03C28.7039 6 27.4321 6.52678 26.4944 7.46446C25.5567 8.40214 25.03 9.67392 25.03 11L25.08 24.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M35.25 18.36H26.25V12.67C26.2526 11.4774 26.7276 10.3343 27.5709 9.49098C28.4143 8.64764 29.5573 8.17269 30.75 8.17004C31.9427 8.17269 33.0857 8.64764 33.929 9.49098C34.7724 10.3343 35.2474 11.4774 35.25 12.67V18.36ZM27.25 17.36H34.25V12.67C34.25 11.7418 33.8812 10.8515 33.2249 10.1952C32.5685 9.53879 31.6783 9.17004 30.75 9.17004C29.8217 9.17004 28.9315 9.53879 28.2751 10.1952C27.6187 10.8515 27.25 11.7418 27.25 12.67V17.36Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M35.0799 13.36H26.4399V14.36H35.0799V13.36Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M34.5799 11.0599H26.9399V12.0599H34.5799V11.0599Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M29.34 8.85999H28.34V17.86H29.34V8.85999Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M31.25 9.17993H30.25V17.8599H31.25V9.17993Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M33.17 8.85999H32.17V17.86H33.17V8.85999Z",
            fill: "#E73C14",
          },
        }),
      ]),
      _c("path", {
        attrs: {
          d: "M27 35.4325H25.88V35.0325H28.61V35.4325H27.48V38.7225H27V35.4325Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.02 35.0725C29.3241 35.0229 29.6319 34.9995 29.94 35.0025C30.2999 34.9815 30.6551 35.0916 30.94 35.3125C31.0474 35.4094 31.1325 35.5284 31.1895 35.6613C31.2465 35.7943 31.274 35.938 31.27 36.0825C31.277 36.2269 31.255 36.3712 31.2051 36.5069C31.1553 36.6426 31.0787 36.767 30.98 36.8725C30.8289 37.0128 30.6508 37.1207 30.4564 37.1895C30.2621 37.2583 30.0557 37.2865 29.85 37.2725C29.7238 37.2886 29.5961 37.2886 29.47 37.2725V38.7525H28.99L29.02 35.0725ZM29.5 36.8525C29.6294 36.87 29.7606 36.87 29.89 36.8525C30.47 36.8525 30.82 36.5725 30.82 36.0625C30.82 35.5525 30.48 35.3425 29.95 35.3425C29.8005 35.3262 29.6496 35.3262 29.5 35.3425V36.8525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.42 35.0325V38.3225H34.11V35.0325H34.59V38.3325H34.91V39.6025H34.53V38.7125H32V35.0325H32.42Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.960022 45.7025L2.04999 47.2025H1.46997L0.400024 45.7025L1.46997 44.2625H2.04999L0.960022 45.7025ZM2.28998 45.7025L3.38 47.2025H2.79999L1.75 45.7025L2.79999 44.2625H3.38L2.28998 45.7025Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.17993 42.3624C4.60541 42.2853 5.03763 42.2518 5.46991 42.2624C6.00116 42.2179 6.53138 42.3593 6.96991 42.6624C7.12157 42.7791 7.24204 42.9314 7.32056 43.1059C7.39907 43.2804 7.4332 43.4716 7.41992 43.6624C7.41029 43.9321 7.31634 44.1919 7.15131 44.4054C6.98627 44.6189 6.75848 44.7752 6.49994 44.8524C6.81497 44.9186 7.09789 45.0906 7.30164 45.3399C7.50538 45.5891 7.61768 45.9005 7.61993 46.2224C7.61846 46.418 7.57686 46.6112 7.49774 46.7901C7.41862 46.969 7.30364 47.1298 7.15991 47.2624C6.61421 47.6594 5.94127 47.841 5.26996 47.7724C4.90574 47.7749 4.54168 47.7549 4.17993 47.7124V42.3624ZM4.87994 44.5924H5.51996C6.26996 44.5924 6.70996 44.2024 6.70996 43.6824C6.70996 43.1624 6.21995 42.7924 5.50995 42.7924C5.29872 42.7844 5.08726 42.8012 4.87994 42.8424V44.5924ZM4.87994 47.2124C5.0762 47.2305 5.27366 47.2305 5.46991 47.2124C6.19991 47.2124 6.87994 46.9524 6.87994 46.1524C6.87994 45.3524 6.23991 45.1524 5.46991 45.1524H4.87994V47.2124Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.57007 42.3525C9.01655 42.2829 9.46827 42.2528 9.92004 42.2625C10.4694 42.2216 11.0144 42.3855 11.4501 42.7225C11.6086 42.8658 11.7338 43.0422 11.8168 43.2392C11.8997 43.4361 11.9384 43.6489 11.9301 43.8625C11.9469 44.2922 11.7963 44.7116 11.5101 45.0325C11.2856 45.2386 11.0223 45.3978 10.7355 45.5008C10.4488 45.6039 10.1444 45.6486 9.84009 45.6325C9.64879 45.638 9.4575 45.6212 9.27008 45.5825V47.7525H8.57007V42.3525ZM9.27008 44.9825C9.46356 45.0265 9.66167 45.0467 9.86005 45.0425C10.71 45.0425 11.23 44.6325 11.23 43.8725C11.23 43.1125 10.7101 42.8025 9.94006 42.8025C9.71521 42.7981 9.49057 42.8182 9.27008 42.8625V44.9825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.7499 45.1925H13.6299V47.1925H15.9999V47.7925H12.9299V42.2625H15.9299V42.8625H13.6799V44.6025H15.7499V45.1925Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.46 45.3525C21.46 44.5925 21.37 43.6725 21.38 42.9925C21.19 43.6325 20.96 44.3125 20.69 45.0625L19.69 47.7125H19.15L18.27 45.1125C18.01 44.3425 17.79 43.6425 17.64 42.9925C17.64 43.6725 17.58 44.5925 17.53 45.4025L17.39 47.7525H16.75L17.1 42.2625H18L18.93 44.9125C19.15 45.5825 19.34 46.1825 19.48 46.7525C19.6403 46.1292 19.8339 45.515 20.06 44.9125L21.06 42.2625H21.96L22.3 47.7325H21.61L21.46 45.3525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.2 45.1925H24.08V47.1925H26.44V47.7925H23.37V42.2625H26.37V42.8625H24.13V44.6025H26.25L26.2 45.1925Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.0599 42.2625V44.5725H30.7499V42.2625H31.4699V47.7525H30.7499V45.1925H28.1099V47.7525H27.4099V42.2625H28.0599Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.52 46.0325L32.95 47.7525H32.22L34.08 42.2625H34.93L36.75 47.7525H36L35.42 46.0325H33.52ZM35.31 45.4825L34.75 43.9125C34.62 43.5525 34.54 43.2225 34.46 42.9125C34.38 43.2325 34.29 43.5725 34.18 43.9125L33.65 45.4925L35.31 45.4825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M42.2101 42.2625V42.8625H40.0101V47.7525H39.3101V42.2625H42.2101Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.1999 44.9625C47.2454 45.3216 47.2138 45.6862 47.1074 46.0321C47.001 46.378 46.8223 46.6973 46.5829 46.9688C46.3436 47.2402 46.0493 47.4577 45.7194 47.6066C45.3896 47.7555 45.0318 47.8325 44.6699 47.8325C44.321 47.829 43.9767 47.7524 43.6591 47.6078C43.3415 47.4632 43.0578 47.2536 42.826 46.9927C42.5943 46.7318 42.4198 46.4253 42.3137 46.0928C42.2075 45.7604 42.1723 45.4094 42.21 45.0625C42.1724 44.7046 42.2099 44.3428 42.3199 44.0001C42.43 43.6574 42.6103 43.3415 42.8492 43.0724C43.0882 42.8033 43.3807 42.587 43.7079 42.4372C44.0352 42.2874 44.3901 42.2075 44.7499 42.2025C45.098 42.2045 45.4419 42.2797 45.759 42.4234C46.0761 42.567 46.3594 42.7758 46.5905 43.0361C46.8216 43.2964 46.9953 43.6025 47.1003 43.9344C47.2053 44.2663 47.2393 44.6166 47.1999 44.9625ZM42.96 45.0525C42.96 46.2625 43.5899 47.2625 44.7499 47.2625C45.9099 47.2625 46.4999 46.2625 46.4999 44.9925C46.4999 43.9125 45.9299 42.7725 44.7499 42.7725C43.5699 42.7725 42.96 43.8525 42.96 45.0525Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.68 42.2625V47.1725H52.16V49.0425H51.61V47.7325H48.17L48.12 49.0425H47.57L47.52 47.1725H47.93C48.1499 46.7916 48.3308 46.3896 48.47 45.9725C48.6632 45.2216 48.7508 44.4475 48.73 43.6725V42.2625H51.68ZM49.28 43.8825C49.3017 44.6358 49.2073 45.3879 49 46.1125C48.8892 46.4604 48.7417 46.7957 48.56 47.1125H50.97V42.8725H49.28V43.8825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.95 46.0325L53.39 47.7525H52.6599L54.5099 42.2625H55.3599L57.2299 47.7325H56.4699L55.89 46.0125L53.95 46.0325ZM55.7499 45.4825L55.2199 43.9125C55.0999 43.5525 55.0199 43.2225 54.9399 42.9125C54.8599 43.2325 54.7699 43.5725 54.6599 43.9125L54.1299 45.4925L55.7499 45.4825Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.6 44.2625H58.17L59.25 45.7525L58.17 47.2525H57.6L58.68 45.7525L57.6 44.2625ZM58.92 44.2625H59.49L60.57 45.7525L59.49 47.2525H58.93L60 45.7525L58.92 44.2625Z",
          fill: "black",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0" } }, [
          _c("rect", {
            attrs: {
              width: "32",
              height: "32",
              fill: "white",
              transform: "translate(14.89)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }