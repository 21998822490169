var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "32",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: { d: "M38.12 15.85H31.65V16.85H38.12V15.85Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: {
          d: "M41.09 16.35H40.09V14.35C40.09 13.3315 39.6854 12.3548 38.9653 11.6347C38.2452 10.9145 37.2684 10.51 36.25 10.51H29.25V16.39H28.25V9.46997H36.25C36.8856 9.46997 37.515 9.59516 38.1022 9.83839C38.6894 10.0816 39.223 10.4381 39.6724 10.8876C40.1218 11.337 40.4783 11.8706 40.7216 12.4578C40.9648 13.045 41.09 13.6744 41.09 14.31V16.35Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.09 22.9799H40.59C39.7466 22.9799 38.9378 22.6449 38.3414 22.0485C37.7451 21.4522 37.41 20.6433 37.41 19.7999V13.9299H38.41V19.7999C38.4082 20.2926 38.5733 20.7713 38.8784 21.1581C39.1834 21.5449 39.6105 21.8169 40.09 21.9299V13.9299H41.09V22.9799Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.9 23.81H31.4C30.5566 23.81 29.7477 23.475 29.1514 22.8786C28.555 22.2822 28.22 21.4734 28.22 20.63V14.75H29.22V20.63C29.2204 21.1209 29.3865 21.5973 29.6914 21.9821C29.9963 22.3668 30.4221 22.6374 30.9 22.75V14.75H31.9V23.81Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.41 22.2H37.91C37.0666 22.2 36.2577 21.8649 35.6614 21.2686C35.065 20.6722 34.73 19.8634 34.73 19.02V16.35H35.73V19.02C35.7304 19.5109 35.8965 19.9873 36.2014 20.372C36.5063 20.7568 36.9322 21.0274 37.41 21.14V16.35H38.41V22.2Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.22 22.14H28.72C28.2994 22.14 27.8829 22.0569 27.4946 21.8953C27.1062 21.7338 26.7537 21.497 26.4572 21.1987C26.1607 20.9003 25.9262 20.5463 25.767 20.1569C25.6079 19.7676 25.5273 19.3506 25.53 18.93V17.5H26.53V18.93C26.5322 19.4231 26.7001 19.9011 27.0065 20.2874C27.313 20.6736 27.7403 20.9457 28.22 21.06V16.29H29.22V22.14Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.01 15C25.2346 15 24.4909 14.6927 23.9417 14.1454C23.3925 13.5981 23.0826 12.8554 23.08 12.08C23.0826 11.3047 23.3925 10.562 23.9417 10.0147C24.4909 9.46735 25.2346 9.16003 26.01 9.16003C26.3934 9.16003 26.7731 9.23556 27.1274 9.38231C27.4817 9.52905 27.8036 9.74413 28.0747 10.0153C28.3459 10.2864 28.5609 10.6083 28.7077 10.9626C28.8544 11.3169 28.93 11.6966 28.93 12.08C28.93 12.4635 28.8544 12.8432 28.7077 13.1975C28.5609 13.5517 28.3459 13.8736 28.0747 14.1448C27.8036 14.4159 27.4817 14.631 27.1274 14.7778C26.7731 14.9245 26.3934 15 26.01 15ZM26.01 10.16C25.757 10.1587 25.5062 10.2074 25.2721 10.3033C25.038 10.3992 24.8252 10.5404 24.6458 10.7188C24.4665 10.8973 24.3242 11.1094 24.2271 11.343C24.1299 11.5766 24.08 11.8271 24.08 12.08C24.08 12.333 24.1299 12.5835 24.2271 12.8171C24.3242 13.0507 24.4665 13.2628 24.6458 13.4412C24.8252 13.6196 25.038 13.7609 25.2721 13.8568C25.5062 13.9527 25.757 14.0014 26.01 14C26.5192 14 27.0075 13.7977 27.3676 13.4377C27.7277 13.0776 27.93 12.5893 27.93 12.08C27.93 11.5708 27.7277 11.0825 27.3676 10.7224C27.0075 10.3623 26.5192 10.16 26.01 10.16Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.2101 16.74H24.42V17.74H28.2101V16.74Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: { d: "M38.02 16.74H32.25V17.74H38.02V16.74Z", fill: "#E73C14" },
      }),
      _c("path", {
        attrs: {
          d: "M20.2001 35.2813C20.4958 35.0853 20.8456 34.9874 21.2001 35.0013C21.8401 35.0013 22.2901 35.3313 22.2901 35.9013C22.2849 36.1155 22.2068 36.3214 22.0686 36.4851C21.9303 36.6488 21.7404 36.7603 21.5301 36.8013C21.6491 36.8026 21.7667 36.8275 21.8761 36.8746C21.9855 36.9216 22.0844 36.9899 22.1672 37.0755C22.2499 37.1611 22.3149 37.2622 22.3583 37.3731C22.4018 37.4839 22.4227 37.6023 22.4201 37.7213C22.4201 38.4913 21.7601 38.8013 21.0901 38.8013C20.741 38.8153 20.3948 38.7322 20.0901 38.5613L20.2201 38.1813C20.4729 38.3267 20.7585 38.4058 21.0501 38.4113C21.5901 38.4113 21.8901 38.1113 21.8901 37.7113C21.8901 37.1613 21.3601 37.0013 20.8901 37.0013H20.6701V36.6313H20.8901C20.9927 36.6468 21.0973 36.6417 21.1979 36.6163C21.2984 36.5908 21.3929 36.5456 21.4758 36.4832C21.5587 36.4208 21.6283 36.3425 21.6805 36.2529C21.7328 36.1633 21.7666 36.0642 21.7801 35.9613C21.7801 35.5913 21.5201 35.3813 21.1101 35.3813C20.8387 35.3938 20.5759 35.4802 20.3501 35.6313L20.2001 35.2813Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M26.1501 36.8612C26.1798 37.105 26.1574 37.3524 26.0847 37.587C26.0119 37.8215 25.8902 38.038 25.7277 38.2222C25.5653 38.4064 25.3656 38.5541 25.142 38.6557C24.9183 38.7572 24.6757 38.8102 24.4301 38.8112C24.1951 38.8056 23.9638 38.7514 23.7509 38.6519C23.5379 38.5524 23.3479 38.4098 23.1928 38.2331C23.0378 38.0564 22.921 37.8495 22.85 37.6254C22.779 37.4013 22.7552 37.165 22.7801 36.9312C22.7551 36.6893 22.7808 36.4447 22.8555 36.2132C22.9303 35.9817 23.0525 35.7684 23.2143 35.5868C23.3762 35.4052 23.5741 35.2593 23.7955 35.1585C24.0169 35.0577 24.2569 35.0041 24.5001 35.0012C24.7342 35.0041 24.9651 35.0558 25.178 35.1531C25.391 35.2504 25.5812 35.3911 25.7366 35.5662C25.8919 35.7414 26.009 35.947 26.0802 36.17C26.1514 36.393 26.1752 36.6285 26.1501 36.8612ZM23.2801 36.9212C23.2801 37.7112 23.7101 38.4212 24.4601 38.4212C25.2101 38.4212 25.6401 37.7212 25.6401 36.8912C25.6401 36.0612 25.2601 35.3812 24.4701 35.3812C23.6801 35.3812 23.2801 36.1112 23.2801 36.9212Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.92 36.8612C29.9512 37.1045 29.9303 37.3516 29.8587 37.5862C29.7872 37.8208 29.6665 38.0375 29.5048 38.2219C29.3431 38.4063 29.144 38.5542 28.9208 38.6558C28.6976 38.7574 28.4553 38.8104 28.21 38.8112C27.9737 38.8085 27.7406 38.7563 27.5257 38.6579C27.3108 38.5595 27.1189 38.4171 26.9625 38.2399C26.806 38.0627 26.6885 37.8547 26.6175 37.6293C26.5464 37.4039 26.5234 37.1661 26.55 36.9312C26.5251 36.6901 26.5505 36.4464 26.6246 36.2156C26.6988 35.9848 26.8201 35.7719 26.9809 35.5904C27.1417 35.409 27.3384 35.2629 27.5586 35.1615C27.7788 35.06 28.0176 35.0055 28.26 35.0012C28.495 35.0027 28.727 35.0534 28.9411 35.1501C29.1552 35.2467 29.3467 35.3873 29.5031 35.5625C29.6596 35.7378 29.7775 35.944 29.8493 36.1677C29.9211 36.3914 29.9452 36.6276 29.92 36.8612ZM27.06 36.9212C27.06 37.7112 27.48 38.4212 28.23 38.4212C28.98 38.4212 29.42 37.7212 29.42 36.8912C29.42 36.0612 29.03 35.3812 28.24 35.3812C27.45 35.3812 27.06 36.1112 27.06 36.9212Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.2101 35.0613V38.7513H32.7801V35.4613H31.0701V38.7513H30.5901V35.0613H33.2101Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.64 37.5913L34.26 38.7513H33.78L35.04 35.0613H35.61L36.87 38.7513H36.36L35.97 37.5913H34.64ZM35.85 37.2113L35.49 36.1513C35.41 35.9113 35.35 35.6913 35.3 35.4813C35.24 35.7013 35.18 35.9213 35.11 36.1513L34.78 37.2113H35.85Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.4001 35.1013C37.7009 35.052 38.0053 35.0286 38.3101 35.0313C38.6696 35.0129 39.024 35.1228 39.3101 35.3413C39.4157 35.439 39.499 35.5584 39.5542 35.6913C39.6094 35.8242 39.6353 35.9675 39.6301 36.1113C39.6425 36.4009 39.5422 36.6841 39.3501 36.9013C39.1977 37.0396 39.0194 37.1463 38.8253 37.2149C38.6313 37.2836 38.4256 37.313 38.2201 37.3013C38.0906 37.3178 37.9596 37.3178 37.8301 37.3013V38.7813H37.3601L37.4001 35.1013ZM37.8701 36.8813C38.0029 36.8987 38.1373 36.8987 38.2701 36.8813C38.8401 36.8813 39.1901 36.6013 39.1901 36.0913C39.1901 35.5813 38.8501 35.3713 38.3201 35.3713C38.1708 35.3503 38.0194 35.3503 37.8701 35.3713V36.8813Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M40.7801 35.0613V36.6913H40.9301L42.2001 35.0613H42.7801L41.3901 36.7513C41.6326 36.7916 41.8575 36.9036 42.0358 37.0729C42.214 37.2423 42.3374 37.4611 42.3901 37.7013C42.4986 38.0589 42.6288 38.4095 42.7801 38.7513H42.2601C42.1261 38.4709 42.0157 38.1799 41.9301 37.8813C41.8875 37.6483 41.7636 37.438 41.5805 37.2879C41.3973 37.1377 41.1668 37.0574 40.9301 37.0613H40.7801V38.7513H40.3101V35.0613H40.7801Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.559998 45.7312L1.64 47.2212H1.07L0 45.7312L1.07 44.2412H1.64L0.559998 45.7312ZM1.89 45.7312L2.97 47.2212H2.4L1.33 45.7312L2.4 44.2412H2.97L1.89 45.7312Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.84003 42.3113V42.9013H4.48003V44.3713C4.7031 44.3561 4.92696 44.3561 5.15003 44.3713C5.64472 44.3546 6.13388 44.4795 6.56003 44.7313C6.78347 44.8742 6.96564 45.073 7.08844 45.3081C7.21124 45.5432 7.2704 45.8063 7.26003 46.0713C7.26951 46.2891 7.23349 46.5066 7.15429 46.7097C7.07509 46.9129 6.95444 47.0973 6.80003 47.2513C6.26071 47.6911 5.57427 47.9091 4.88003 47.8613C4.51167 47.8503 4.14433 47.8169 3.78003 47.7613V42.3113H6.84003ZM4.48003 47.2313C4.67629 47.2491 4.87377 47.2491 5.07003 47.2313C5.49371 47.2482 5.90696 47.0973 6.22003 46.8113C6.3204 46.7044 6.3986 46.5786 6.45009 46.4413C6.50158 46.304 6.52535 46.1578 6.52003 46.0113C6.53052 45.8375 6.49545 45.6639 6.41828 45.5078C6.34111 45.3517 6.22451 45.2185 6.08003 45.1213C5.78145 44.9317 5.43363 44.8343 5.08003 44.8413H4.52003L4.48003 47.2313Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 45.2113H8.88004V47.2113H11.24V47.8013H8.17004V42.3113H11.17V42.9013H8.93005V44.6313H11.05L11 45.2113Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.9601 42.3113V47.7713H15.2401V42.9013H13.4401V44.5613C13.4401 45.7413 13.4401 47.0613 12.6201 47.5613C12.3689 47.7228 12.0785 47.8127 11.7801 47.8213L11.6901 47.2513C11.9052 47.2064 12.1051 47.1065 12.2701 46.9613C12.69 46.2538 12.8689 45.4292 12.7801 44.6113V42.3113H15.9601Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.8801 42.3113V44.3113C18.0401 44.3113 18.3501 44.3113 18.5301 44.3113C19.6601 44.3113 20.6401 44.8113 20.6401 46.0213C20.6513 46.4471 20.5013 46.8614 20.2201 47.1813C19.9461 47.4157 19.6281 47.593 19.2846 47.7029C18.9412 47.8128 18.5793 47.853 18.2201 47.8213C17.8559 47.8238 17.4919 47.8037 17.1301 47.7613V42.3113H17.8801ZM17.8801 47.2313C18.0697 47.2494 18.2606 47.2494 18.4501 47.2313C19.2301 47.2313 19.9001 46.8313 19.9001 46.0113C19.9001 45.1913 19.1701 44.8313 18.4401 44.8313C18.2536 44.8197 18.0666 44.8197 17.8801 44.8313V47.2313ZM21.9601 42.3113V47.7713H21.2501V42.3113H21.9601Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.88 42.3113V44.6113C23.88 45.4813 23.88 46.1913 23.82 46.9213C24.1 46.3513 24.44 45.7613 24.82 45.1113L26.58 42.3013H27.29V47.7613H26.62V45.4613C26.62 44.5713 26.62 43.9313 26.69 43.2413C26.3972 43.8758 26.0631 44.4904 25.69 45.0813L23.98 47.7913H23.23V42.3113H23.88ZM24.77 41.1713C24.77 41.4213 24.89 41.6713 25.27 41.6713C25.65 41.6713 25.73 41.4413 25.76 41.1713H26.31C26.31 41.7013 25.94 42.0413 25.25 42.0413C25.1259 42.0567 24.9999 42.0459 24.8803 42.0094C24.7607 41.9729 24.6501 41.9116 24.5557 41.8296C24.4614 41.7475 24.3854 41.6464 24.3327 41.533C24.28 41.4196 24.2518 41.2963 24.25 41.1713H24.77Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M30.96 42.3113V44.7313H31.18L33.05 42.3113H33.9L31.84 44.8213C32.74 44.9413 33.08 45.4913 33.37 46.2213C33.5345 46.751 33.735 47.2689 33.97 47.7713H33.21C33.0117 47.3542 32.8446 46.923 32.71 46.4813C32.6472 46.1292 32.4582 45.8121 32.1784 45.5894C31.8986 45.3667 31.5472 45.2536 31.19 45.2713H30.96V47.7713H30.26V42.3113H30.96Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.48 45.2113H35.36V47.2113H37.72V47.8013H34.65V42.3113H37.6V42.9013H35.36V44.6313H37.48V45.2113Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M39.3401 42.3113V44.6013H41.9801V42.3113H42.7001V47.7713H41.9801V45.2113H39.3401V47.7713H38.6401V42.3113H39.3401Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M46.7801 42.3113V42.9013H44.5801V47.7713H43.8801V42.3113H46.7801Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.9501 42.3113L49.0401 44.6413C49.18 44.9613 49.3101 45.2946 49.4301 45.6413C49.5301 45.3613 49.6301 45.0413 49.7701 44.6413L50.6201 42.3413H51.3701L50.2301 45.1513C49.9755 45.8766 49.64 46.571 49.2301 47.2213C49.0916 47.4236 48.9069 47.5899 48.6912 47.7065C48.4755 47.823 48.2351 47.8864 47.9901 47.8913C47.858 47.9006 47.7255 47.8836 47.6001 47.8413L47.6701 47.2713H47.9301C48.5001 47.2713 48.8301 46.7213 49.0001 46.3313C49.029 46.2811 49.0443 46.2242 49.0443 46.1663C49.0443 46.1084 49.029 46.0514 49.0001 46.0013L47.1801 42.3213L47.9501 42.3113Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M51.9701 42.3812C52.416 42.3054 52.8678 42.2686 53.3201 42.2712C53.868 42.2284 54.4126 42.3886 54.8501 42.7212C55.0089 42.8665 55.1341 43.0446 55.217 43.2432C55.2999 43.4418 55.3385 43.6562 55.3301 43.8712C55.342 44.0829 55.311 44.2948 55.2388 44.4942C55.1666 44.6936 55.0548 44.8762 54.9101 45.0312C54.6869 45.2392 54.4238 45.3996 54.1367 45.5028C53.8497 45.6059 53.5446 45.6496 53.2401 45.6312C53.0507 45.6525 52.8595 45.6525 52.6701 45.6312V47.8112H51.9701V42.3812ZM52.6701 45.0112C52.8636 45.0552 53.0617 45.0754 53.2601 45.0712C54.1101 45.0712 54.6301 44.6512 54.6301 43.9012C54.6301 43.1512 54.1201 42.8312 53.3401 42.8312C53.1151 42.8222 52.8899 42.8424 52.6701 42.8912V45.0112Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M56.3801 42.3113L57.4701 44.6413C57.6101 44.9613 57.7401 45.2813 57.8701 45.6413C57.9701 45.3613 58.0701 45.0413 58.2101 44.6413L59.0601 42.3413H59.7801L58.6401 45.1513C58.3814 45.8749 58.0462 46.5689 57.6401 47.2213C57.4999 47.4219 57.3148 47.5869 57.0995 47.7032C56.8842 47.8196 56.6447 47.884 56.4001 47.8913C56.2647 47.9019 56.1286 47.8849 56.0001 47.8413L56.0701 47.2713H56.3301C56.9001 47.2713 57.2301 46.7213 57.4001 46.3313C57.429 46.2811 57.4443 46.2242 57.4443 46.1663C57.4443 46.1084 57.429 46.0514 57.4001 46.0013L55.5801 42.3213L56.3801 42.3113Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M59.78 44.2412H60.35L61.43 45.7312L60.35 47.2212H59.78L60.86 45.7312L59.78 44.2412ZM61.1 44.2412H61.67L62.78 45.7312L61.7 47.2212H61.14L62.21 45.7312L61.1 44.2412Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }