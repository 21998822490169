<template>
  <g @click="$emit('click')">
    <g clip-path="url(#clip25)">
      <circle
        cx="39.13"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M40.3651 20.2484H37.8949V17.2456H34.8976V14.7708H37.8949V11.8011H40.3651V14.8038H43.3624V17.2786H40.3651V20.2484ZM38.7183 19.4234H39.5417V16.4207H42.539V15.5957H39.5417V12.626H38.7183V15.6287H35.721V16.4537H38.7183V19.4234Z"
        fill="#E73C14"
      />
      <path
        d="M45.9891 22.8717H44.754V22.0467H45.1657V21.6343H45.9891V22.8717Z"
        fill="#E73C14"
      />
      <path
        d="M43.8812 22.8799H42.152V22.055H43.8812V22.8799ZM41.2874 22.8799H39.5582V22.055H41.2874V22.8799ZM38.6936 22.8799H36.9644V22.055H38.7018L38.6936 22.8799ZM36.0916 22.8799H34.3624V22.055H36.0916V22.8799Z"
        fill="#E73C14"
      />
      <path
        d="M33.5061 22.8717H32.2709V21.6343H33.0944V22.0467H33.5061V22.8717Z"
        fill="#E73C14"
      />
      <path
        d="M33.0944 20.7764H32.2709V19.044H33.0944V20.7764ZM33.0944 18.1778H32.2709V16.4455H33.0944V18.1778ZM33.0944 15.5793H32.2709V13.8387H33.0944V15.5793ZM33.0944 12.9725H32.2709V11.2402H33.0944V12.9725Z"
        fill="#E73C14"
      />
      <path
        d="M33.0944 10.3739H32.2709V9.13654H33.5061V9.96148H33.0944V10.3739Z"
        fill="#E73C14"
      />
      <path
        d="M43.8812 9.96148H42.152V9.13654H43.8812V9.96148ZM41.2874 9.96148H39.5582V9.13654H41.2874V9.96148ZM38.6936 9.96148H36.9644V9.13654H38.7018L38.6936 9.96148ZM36.0916 9.96148H34.3624V9.13654H36.0916V9.96148Z"
        fill="#E73C14"
      />
      <path
        d="M45.9891 10.3739H45.1657V9.96148H44.754V9.13654H45.9891V10.3739Z"
        fill="#E73C14"
      />
      <path
        d="M45.9891 20.7764H45.1657V19.044H45.9891V20.7764ZM45.9891 18.1778H45.1657V16.4455H45.9891V18.1778ZM45.9891 15.5793H45.1657V13.8387H45.9891V15.5793ZM45.9891 12.9725H45.1657V11.2402H45.9891V12.9725Z"
        fill="#E73C14"
      />
      <path
        d="M48.13 25H30.13V7H48.13V25ZM30.9534 24.1751H47.3066V7.82493H30.9534V24.1751Z"
        fill="#E73C14"
      />
    </g>
    <path
      d="M30.8101 37.53L30.4301 38.69H29.9301L31.1901 35H31.7601L33.0201 38.69H32.5101L32.1201 37.53H30.8101ZM32.0201 37.15L31.6601 36.09C31.5801 35.85 31.5201 35.63 31.4701 35.42C31.4101 35.64 31.3501 35.86 31.2801 36.09L30.8801 37.15H32.0201Z"
      fill="black"
    />
    <path
      d="M36.2399 35V38.69H35.7599V35.4H34.0399V38.69H33.5699V35H36.2399Z"
      fill="black"
    />
    <path
      d="M37.77 35.4H36.65V35H39.38V35.4H38.25V38.69H37.77V35.4Z"
      fill="black"
    />
    <path
      d="M41.7 36.96H40.27V38.29H41.88V38.69H39.79V35H41.79V35.4H40.27V36.56H41.7V36.96Z"
      fill="black"
    />
    <path
      d="M42.96 35V36.63H43.11L44.37 35H44.95L43.55 36.69C44.16 36.77 44.39 37.14 44.55 37.69C44.6618 38.0478 44.7954 38.3985 44.95 38.74H44.48C44.3406 38.447 44.2269 38.1426 44.14 37.83C44.0974 37.597 43.9735 37.3868 43.7904 37.2366C43.6073 37.0864 43.3768 37.0061 43.14 37.01H42.99V38.7H42.52V35H42.96Z"
      fill="black"
    />
    <path
      d="M46.0801 37.53L45.7001 38.69H45.2101L46.4601 35H47.0401L48.3001 38.69H47.7901L47.3901 37.53H46.0801ZM47.2901 37.15L46.9301 36.09C46.8501 35.85 46.7901 35.63 46.7401 35.42C46.6801 35.64 46.6201 35.86 46.5501 36.09L46.1801 37.15H47.2901Z"
      fill="black"
    />
    <path
      d="M0.56 45.6701L1.65 47.1601H1.07L0 45.6701L1.07 44.1801H1.65L0.56 45.6701ZM1.89 45.6701L2.98 47.1601H2.4L1.33 45.6701L2.4 44.1801H2.98L1.89 45.6701Z"
      fill="black"
    />
    <path
      d="M7.72003 42.25V47.71H7.02003V42.83H4.48003V47.71H3.78003V42.25H7.72003Z"
      fill="black"
    />
    <path
      d="M12.56 42.25V47.71H11.88V42.83H10.04V44.5C10.04 45.68 10.04 47 9.23005 47.5C8.9783 47.6601 8.68825 47.7499 8.39005 47.76L8.30005 47.19C8.51326 47.151 8.71292 47.058 8.88005 46.92C9.29384 46.2033 9.46238 45.3712 9.36005 44.55V42.25H12.56Z"
      fill="black"
    />
    <path
      d="M14.67 46L14.1 47.71H13.37L15.23 42.25H16.08L17.94 47.71H17.19L16.61 46H14.67ZM16.46 45.44L15.88 43.83C15.76 43.48 15.68 43.15 15.6 42.83C15.52 43.15 15.43 43.48 15.33 43.83L14.79 45.41L16.46 45.44Z"
      fill="black"
    />
    <path
      d="M19.45 42.25V44.54H22.09V42.25H22.8V47.71H22.09V45.15H19.45V47.71H18.74V42.25H19.45Z"
      fill="black"
    />
    <path
      d="M26.88 45.15H24.73V47.15H27.09V47.74H24.02V42.25H27.02V42.83H24.73V44.57H26.88V45.15Z"
      fill="black"
    />
    <path
      d="M29.08 42.8301H27.42V42.2301H31.47V42.8301H29.8V47.7101H29.08V42.8301Z"
      fill="black"
    />
    <path
      d="M32.3 46L31.73 47.71H31L32.88 42.25H33.73L35.59 47.71H34.84L34.26 46H32.3ZM34.09 45.44L33.56 43.83C33.4454 43.5021 33.3486 43.1683 33.27 42.83C33.18 43.15 33.1 43.48 32.99 43.83L32.45 45.41L34.09 45.44Z"
      fill="black"
    />
    <path
      d="M37.8801 42.58C38.3066 42.2977 38.8087 42.1513 39.3201 42.16C40.3201 42.16 40.9201 42.66 40.9201 43.5C40.9143 43.8166 40.7998 44.1215 40.5959 44.3636C40.392 44.6058 40.111 44.7704 39.8001 44.83C40.1564 44.8353 40.4961 44.9812 40.7453 45.236C40.9944 45.4908 41.1328 45.8337 41.1301 46.19C41.1301 47.33 40.1301 47.78 39.1301 47.78C38.6309 47.7916 38.1375 47.6708 37.7001 47.43L37.8801 46.89C38.2533 47.1003 38.6719 47.217 39.1001 47.23C39.9101 47.23 40.3601 46.79 40.3601 46.23C40.3601 45.41 39.5701 45.18 38.8801 45.18H38.5801V44.6H38.8801C39.1824 44.6368 39.4869 44.5521 39.7268 44.3647C39.9668 44.1772 40.1226 43.9022 40.1601 43.6C40.1601 43.05 39.7701 42.74 39.1601 42.74C38.7541 42.7525 38.3596 42.8771 38.0201 43.1L37.8801 42.58Z"
      fill="black"
    />
    <path
      d="M45.7799 42.25V47.14H46.2499V49.02H45.6899V47.7H42.1599L42.1099 49.03H41.5599V47.15H41.9799C42.1966 46.7689 42.3741 46.3668 42.5099 45.95C42.7076 45.2 42.7952 44.4252 42.7699 43.65V42.25H45.7799ZM43.3799 43.83C43.4031 44.5834 43.3086 45.3357 43.0999 46.06C42.9884 46.4077 42.841 46.7429 42.6599 47.06H45.0699V42.83H43.3799V43.83Z"
      fill="black"
    />
    <path
      d="M51.74 44.93C51.7902 45.2922 51.7619 45.6609 51.6571 46.0112C51.5523 46.3615 51.3733 46.6851 51.1324 46.9602C50.8915 47.2352 50.5943 47.4553 50.2609 47.6054C49.9274 47.7554 49.5657 47.8321 49.2 47.83C48.8491 47.8256 48.503 47.7472 48.1844 47.6002C47.8657 47.4531 47.5816 47.2406 47.3505 46.9765C47.1194 46.7124 46.9465 46.4026 46.843 46.0672C46.7396 45.7318 46.7079 45.3785 46.75 45.03C46.711 44.6721 46.7471 44.3101 46.8559 43.9669C46.9647 43.6237 47.1439 43.307 47.3819 43.0369C47.62 42.7669 47.9117 42.5494 48.2386 42.3984C48.5654 42.2475 48.9201 42.1663 49.28 42.16C49.629 42.1636 49.9733 42.2401 50.2909 42.3848C50.6084 42.5294 50.8922 42.7389 51.1239 42.9998C51.3556 43.2608 51.5302 43.5673 51.6363 43.8997C51.7424 44.2322 51.7777 44.5831 51.74 44.93ZM47.5 45.02C47.5 46.18 48.13 47.23 49.24 47.23C50.35 47.23 50.99 46.23 50.99 44.96C50.99 43.87 50.43 42.74 49.25 42.74C48.07 42.74 47.5 43.83 47.5 45.02Z"
      fill="black"
    />
    <path
      d="M52.65 42.32C53.0959 42.2442 53.5476 42.2074 54 42.21C54.5497 42.1652 55.0961 42.3295 55.53 42.67C55.6912 42.8155 55.818 42.9951 55.901 43.1958C55.9841 43.3964 56.0213 43.6131 56.01 43.83C56.021 44.0431 55.9896 44.2563 55.9175 44.4572C55.8454 44.658 55.734 44.8425 55.59 45C55.3587 45.2062 55.0877 45.3632 54.7937 45.4612C54.4997 45.5592 54.1888 45.5962 53.88 45.57C53.6939 45.5917 53.506 45.5917 53.32 45.57V47.71H52.61L52.65 42.32ZM53.36 44.95C53.55 44.9946 53.7449 45.0148 53.94 45.01C54.79 45.01 55.31 44.59 55.31 43.84C55.31 43.09 54.8 42.77 54.02 42.77C53.7984 42.7656 53.5771 42.7857 53.36 42.83V44.95Z"
      fill="black"
    />
    <path
      d="M61.56 44.93C61.61 45.2914 61.582 45.6592 61.4777 46.0088C61.3735 46.3584 61.1954 46.6815 60.9556 46.9564C60.7158 47.2313 60.4198 47.4515 60.0876 47.6022C59.7554 47.7529 59.3947 47.8306 59.03 47.83C58.6782 47.8269 58.331 47.7497 58.0112 47.6032C57.6913 47.4568 57.406 47.2445 57.1738 46.9802C56.9416 46.7159 56.7678 46.4056 56.6637 46.0696C56.5597 45.7335 56.5277 45.3793 56.57 45.03C56.5325 44.6716 56.5699 44.3093 56.6799 43.9661C56.7899 43.6229 56.97 43.3064 57.2089 43.0365C57.4477 42.7666 57.74 42.5494 58.0673 42.3985C58.3946 42.2476 58.7496 42.1664 59.11 42.16C59.4585 42.1634 59.8025 42.24 60.1196 42.3847C60.4367 42.5294 60.7199 42.7391 60.9509 43.0003C61.1818 43.2614 61.3554 43.5681 61.4603 43.9005C61.5652 44.2329 61.5992 44.5837 61.56 44.93ZM57.32 45.02C57.32 46.18 57.96 47.23 59.07 47.23C60.18 47.23 60.82 46.23 60.82 44.96C60.82 43.87 60.25 42.74 59.07 42.74C57.89 42.74 57.32 43.83 57.32 45.02Z"
      fill="black"
    />
    <path
      d="M62.4801 42.33C62.9015 42.2461 63.3304 42.2059 63.7601 42.21C64.2918 42.1594 64.8241 42.3013 65.2601 42.61C65.4163 42.723 65.5408 42.8744 65.6214 43.0496C65.702 43.2248 65.736 43.4178 65.7201 43.61C65.7118 43.8826 65.6174 44.1457 65.4503 44.3613C65.2832 44.5769 65.052 44.734 64.7901 44.81V44.83C65.093 44.9004 65.3641 45.069 65.5613 45.3096C65.7584 45.5501 65.8705 45.8491 65.8801 46.16C65.8819 46.3635 65.842 46.5651 65.7629 46.7526C65.6838 46.9401 65.5671 47.1093 65.4201 47.25C64.8744 47.6469 64.2014 47.8285 63.5301 47.76C63.1658 47.7628 62.8018 47.7428 62.4401 47.7L62.4801 42.33ZM63.1801 44.56H63.8201C64.5701 44.56 65.0001 44.17 65.0001 43.65C65.0001 43.13 64.5201 42.75 63.8001 42.75C63.5913 42.7569 63.3837 42.7837 63.1801 42.83V44.56ZM63.1801 47.18C63.3765 47.193 63.5736 47.193 63.7701 47.18C64.5001 47.18 65.1701 46.91 65.1701 46.12C65.1701 45.33 64.5301 45.07 63.7601 45.07H63.1801V47.18Z"
      fill="black"
    />
    <path
      d="M67.57 42.25V44.25C67.73 44.25 68.06 44.25 68.24 44.25C69.36 44.25 70.35 44.75 70.35 45.96C70.3685 46.3869 70.2175 46.8039 69.93 47.12C69.6557 47.3539 69.3376 47.5309 68.9943 47.6408C68.6509 47.7507 68.2892 47.7912 67.93 47.76C67.5791 47.7603 67.2286 47.7402 66.88 47.7V42.25H67.57ZM67.57 47.17C67.7629 47.1878 67.9571 47.1878 68.15 47.17C68.94 47.17 69.61 46.79 69.61 45.96C69.61 45.13 68.88 44.83 68.14 44.83C67.9501 44.8204 67.7599 44.8204 67.57 44.83V47.17Z"
      fill="black"
    />
    <path
      d="M70.78 47.7101C70.869 47.5542 70.9427 47.3901 71 47.2201C71.168 46.5573 71.4672 45.935 71.88 45.3901C72.0057 45.2624 72.16 45.1664 72.33 45.1101C71.9986 45.073 71.6926 44.9146 71.471 44.6653C71.2494 44.416 71.128 44.0936 71.13 43.7601C71.1237 43.5492 71.1654 43.3397 71.252 43.1474C71.3387 42.9551 71.4679 42.785 71.63 42.6501C72.0892 42.3413 72.6379 42.1936 73.19 42.2301C73.6296 42.2257 74.0685 42.2659 74.5 42.3501V47.7101H73.79V45.3501H73.27C73.129 45.3356 72.9865 45.3514 72.8521 45.3962C72.7176 45.441 72.5942 45.5139 72.49 45.6101C72.1481 46.1316 71.9041 46.711 71.77 47.3201C71.71 47.4501 71.67 47.5701 71.59 47.7101H70.78ZM73.78 42.8201C73.5627 42.776 73.3417 42.7526 73.12 42.7501C72.42 42.7501 71.83 43.0201 71.83 43.7501C71.83 44.4801 72.41 44.7501 73.18 44.7501H73.78V42.8201Z"
      fill="black"
    />
    <path
      d="M75.25 44.1801H75.82L76.88 45.6701L75.82 47.1601H75.25L76.33 45.6701L75.25 44.1801ZM76.57 44.1801H77.14L78.22 45.6701L77.14 47.1601H76.58L77.65 45.6701L76.57 44.1801Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip25">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(23.13)"
        />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconPlanetHealth",
};
</script>

<style scoped lang="scss"></style>
