<template>
  <g @click="$emit('click')">
    <circle
      cx="29"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M35.4 25.87H21.55L19.88 19.46L22.88 12.36H34.07L37.07 19.46L35.4 25.87ZM22.32 24.87H34.63L36.02 19.54L33.41 13.36H23.54L20.93 19.54L22.32 24.87Z"
      fill="#E73C14"
    />
    <path d="M31.4299 13.01H30.4299V25.52H31.4299V13.01Z" fill="#E73C14" />
    <path d="M27.34 13.01H26.34V25.52H27.34V13.01Z" fill="#E73C14" />
    <path d="M26.84 18.76H20.4V19.76H26.84V18.76Z" fill="#E73C14" />
    <path d="M36.38 18.76H30.47V19.76H36.38V18.76Z" fill="#E73C14" />
    <path
      d="M29.47 12.86H28.47C28.47 8.51002 25.66 7.25002 25.54 7.19002L25.93 6.27002C26.08 6.33002 29.47 7.82002 29.47 12.86Z"
      fill="#E73C14"
    />
    <path d="M39.18 6.22998H18.5V7.22998H39.18V6.22998Z" fill="#E73C14" />
    <path
      d="M4.14899 41.4172C3.69267 41.6043 3.20195 41.6929 2.70899 41.6772C2.3428 41.6985 1.9763 41.6409 1.63431 41.5083C1.29232 41.3757 0.982827 41.1711 0.726793 40.9084C0.470759 40.6457 0.274163 40.3311 0.15032 39.9858C0.0264764 39.6406 -0.0217229 39.2727 0.00898883 38.9072C-0.0193244 38.5191 0.0362732 38.1294 0.171998 37.7648C0.307724 37.4001 0.520396 37.0689 0.795549 36.7937C1.0707 36.5186 1.40188 36.3059 1.76657 36.1702C2.13126 36.0345 2.5209 35.9789 2.90899 36.0072C3.35492 35.9875 3.79949 36.0695 4.20899 36.2472L4.03899 36.8172C3.6929 36.6641 3.31735 36.5889 2.93899 36.5972C2.64242 36.5739 2.3444 36.6171 2.06663 36.7236C1.78886 36.8301 1.53834 36.9972 1.33333 37.2127C1.12831 37.4283 0.973965 37.6868 0.881528 37.9696C0.789092 38.2523 0.760893 38.5521 0.79899 38.8472C0.769642 39.1348 0.802985 39.4253 0.896719 39.6987C0.990452 39.9722 1.14235 40.2221 1.34195 40.4312C1.54154 40.6403 1.7841 40.8036 2.0529 40.91C2.3217 41.0163 2.61036 41.0631 2.89899 41.0472C3.29435 41.0535 3.68647 40.975 4.04899 40.8172L4.14899 41.4172Z"
      fill="black"
    />
    <path
      d="M5.72893 36.1272V38.4172H8.37893V36.1272H9.08893V41.5972H8.37893V39.0072H5.72893V41.5672H5.02893V36.0972L5.72893 36.1272Z"
      fill="black"
    />
    <path
      d="M14.9789 38.8071C15.0245 39.167 14.9927 39.5324 14.8857 39.879C14.7787 40.2256 14.5991 40.5455 14.3587 40.8171C14.1183 41.0887 13.8227 41.306 13.4916 41.4543C13.1606 41.6026 12.8017 41.6785 12.4389 41.6771C12.0908 41.6722 11.7476 41.5946 11.4312 41.4493C11.1149 41.3041 10.8323 41.0944 10.6017 40.8336C10.371 40.5729 10.1974 40.2668 10.0919 39.9351C9.98637 39.6033 9.95131 39.2532 9.98894 38.9071C9.94385 38.5466 9.97541 38.1806 10.0816 37.8332C10.1877 37.4857 10.366 37.1646 10.6049 36.8908C10.8437 36.617 11.1377 36.3968 11.4675 36.2445C11.7974 36.0922 12.1557 36.0113 12.5189 36.0071C12.8712 36.0086 13.2191 36.0848 13.5398 36.2307C13.8604 36.3767 14.1464 36.589 14.3789 36.8536C14.6114 37.1182 14.7851 37.4292 14.8886 37.766C14.992 38.1027 15.0228 38.4576 14.9789 38.8071ZM10.7389 38.8971C10.7389 40.0571 11.3689 41.1071 12.4789 41.1071C13.5889 41.1071 14.2289 40.1071 14.2289 38.8371C14.2289 37.7571 13.6589 36.6171 12.4889 36.6171C11.3189 36.6171 10.7389 37.6971 10.7389 38.8971Z"
      fill="black"
    />
    <path
      d="M15.9089 36.1272L16.9989 38.4572C17.1389 38.7772 17.2689 39.0972 17.3989 39.4572C17.4989 39.1772 17.5989 38.8572 17.7389 38.4572L18.5889 36.1472H19.3389L18.1989 38.9572C17.9423 39.6817 17.6069 40.3758 17.1989 41.0272C17.0605 41.2295 16.8757 41.3958 16.6601 41.5124C16.4444 41.6289 16.204 41.6923 15.9589 41.6972C15.8263 41.7169 15.6915 41.7169 15.5589 41.6972L15.6289 41.1172C15.7151 41.1307 15.8028 41.1307 15.8889 41.1172C16.4589 41.1172 16.7889 40.5572 16.9589 40.1772C16.9878 40.1252 17.003 40.0667 17.003 40.0072C17.003 39.9477 16.9878 39.8892 16.9589 39.8372L15.1389 36.1572L15.9089 36.1272Z"
      fill="black"
    />
    <path
      d="M22.999 36.1272V36.7272H20.639V38.1872C20.819 38.1872 21.139 38.1872 21.319 38.1872C21.8146 38.1736 22.3039 38.3019 22.729 38.5572C22.9519 38.6974 23.1334 38.8946 23.2547 39.1284C23.376 39.3622 23.4327 39.6242 23.419 39.8872C23.4264 40.1055 23.3905 40.323 23.3133 40.5273C23.236 40.7316 23.119 40.9185 22.969 41.0772C22.4223 41.5096 21.7344 41.7235 21.039 41.6772C20.6714 41.6797 20.3041 41.6596 19.939 41.6172V36.1672L22.999 36.1272ZM20.639 41.0072C20.8383 41.0299 21.0396 41.0299 21.239 41.0072C21.4489 41.0167 21.6586 40.9844 21.856 40.9123C22.0534 40.8402 22.2345 40.7297 22.389 40.5872C22.5889 40.3695 22.6965 40.0827 22.689 39.7872C22.6984 39.6124 22.6618 39.4383 22.5828 39.2821C22.5039 39.1259 22.3853 38.9932 22.239 38.8972C21.9409 38.7065 21.5927 38.6091 21.239 38.6172C21.079 38.6172 20.859 38.6172 20.679 38.6172L20.639 41.0072Z"
      fill="black"
    />
    <path
      d="M28.909 38.8071C28.9544 39.1661 28.9229 39.5307 28.8165 39.8766C28.7101 40.2225 28.5313 40.5419 28.292 40.8133C28.0527 41.0848 27.7583 41.3022 27.4285 41.4512C27.0987 41.6001 26.7409 41.6771 26.379 41.6771C26.0292 41.6749 25.6838 41.5994 25.365 41.4554C25.0462 41.3114 24.7613 41.102 24.5285 40.841C24.2957 40.5799 24.1202 40.2729 24.0135 39.9398C23.9068 39.6066 23.8712 39.2548 23.909 38.9071C23.8652 38.5435 23.8989 38.1748 24.008 37.8253C24.1172 37.4758 24.2992 37.1533 24.542 36.8793C24.7849 36.6052 25.0831 36.3858 25.417 36.2355C25.7509 36.0851 26.1128 36.0073 26.479 36.0071C26.8292 36.0112 27.1746 36.0895 27.4924 36.2367C27.8102 36.384 28.0932 36.5969 28.3228 36.8614C28.5523 37.1259 28.7233 37.4361 28.8243 37.7714C28.9254 38.1068 28.9542 38.4598 28.909 38.8071ZM24.669 38.8971C24.669 40.0571 25.309 41.1071 26.419 41.1071C27.529 41.1071 28.169 40.1071 28.169 38.8371C28.169 37.7571 27.599 36.6171 26.419 36.6171C25.239 36.6171 24.689 37.6971 24.689 38.8971H24.669Z"
      fill="black"
    />
    <path
      d="M29.849 36.1972C30.2948 36.1204 30.7466 36.0836 31.199 36.0872C31.7479 36.0501 32.2916 36.2135 32.729 36.5472C32.8876 36.6905 33.0127 36.8669 33.0957 37.0639C33.1786 37.2608 33.2173 37.4736 33.209 37.6872C33.2258 38.1169 33.0752 38.5363 32.789 38.8572C32.5642 39.0628 32.3009 39.2218 32.0142 39.3248C31.7275 39.4278 31.4232 39.4728 31.119 39.4572C30.9296 39.4792 30.7384 39.4792 30.549 39.4572V41.6472H29.849V36.1972ZM30.549 38.8272C30.7391 38.8712 30.9339 38.8914 31.129 38.8872C31.979 38.8872 32.499 38.4772 32.499 37.7172C32.499 36.9572 31.989 36.6472 31.209 36.6472C30.9875 36.6432 30.7662 36.6633 30.549 36.7072V38.8272Z"
      fill="black"
    />
    <path
      d="M37.349 36.1272V41.0072H37.829V42.8772H37.279V41.5672H33.739V42.8772H33.189V41.0072H33.609C33.8256 40.6261 34.0032 40.224 34.139 39.8072C34.3367 39.0572 34.4243 38.2824 34.399 37.5072V36.1172L37.349 36.1272ZM34.959 37.7472C34.9807 38.5005 34.8862 39.2526 34.679 39.9772C34.569 40.3271 34.418 40.6628 34.229 40.9772H36.649V36.6872H34.959V37.7472Z"
      fill="black"
    />
    <path
      d="M44.379 36.1272V41.5972H43.679V36.7272H41.139V41.5972H40.439V36.1272H44.379Z"
      fill="black"
    />
    <path
      d="M46.499 39.8772L45.909 41.5972H45.179L47.039 36.1272H47.909L49.769 41.5972H49.019L48.429 39.8772H46.499ZM48.289 39.3272L47.749 37.7572C47.629 37.3972 47.549 37.0672 47.469 36.7572C47.389 37.0772 47.299 37.4172 47.199 37.7572L46.659 39.3372L48.289 39.3272Z"
      fill="black"
    />
    <path
      d="M50.569 36.1972C51.0149 36.1209 51.4666 36.0841 51.919 36.0872C52.4683 36.0463 53.0133 36.2102 53.449 36.5472C53.6075 36.6905 53.7327 36.8669 53.8157 37.0639C53.8986 37.2608 53.9373 37.4736 53.929 37.6872C53.94 37.9003 53.9085 38.1135 53.8364 38.3144C53.7643 38.5152 53.653 38.6998 53.509 38.8572C53.2845 39.0633 53.0212 39.2225 52.7344 39.3255C52.4477 39.4286 52.1432 39.4733 51.839 39.4572C51.6496 39.4788 51.4584 39.4788 51.269 39.4572V41.6472H50.569V36.1972ZM51.269 38.8272C51.4624 38.8712 51.6606 38.8914 51.859 38.8872C52.709 38.8872 53.229 38.4772 53.229 37.7172C53.229 36.9572 52.719 36.6472 51.939 36.6472C51.7141 36.6434 51.4895 36.6635 51.269 36.7072V38.8272Z"
      fill="black"
    />
    <path
      d="M55.5689 36.1272V38.5572H55.7889L57.6689 36.1272H58.5189L56.4489 38.6372C56.818 38.6863 57.163 38.8481 57.4368 39.1004C57.7106 39.3527 57.8999 39.6833 57.9789 40.0472C58.1496 40.5747 58.3499 41.0922 58.5789 41.5972H57.8189C57.6223 41.1794 57.4552 40.7484 57.3189 40.3072C57.2583 39.9533 57.0702 39.6338 56.7902 39.4091C56.5102 39.1843 56.1576 39.0698 55.7989 39.0872H55.5689V41.5972H54.9089V36.1272H55.5689Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconParkSnowboard",
};
</script>

<style scoped lang="scss"></style>
