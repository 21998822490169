<template>
  <g @click="$emit('click')">
    <g clip-path="url(#clip3)">
      <circle
        cx="20.63"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M28.36 22.36H25.5V13.44H28.36V22.36ZM26.25 21.61H27.61V14.19H26.25V21.61Z"
        fill="#E73C14"
      />
      <path
        d="M29.51 14.19H24.23V12.06H29.51V14.19ZM24.98 13.44H28.76V12.85H24.98V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M29.51 23.7H24.23V21.61H29.51V23.7ZM24.98 22.95H28.76V22.36H24.98V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M22.12 22.36H19.26V13.44H22.12V22.36ZM20.01 21.61H21.37V14.19H20.02L20.01 21.61Z"
        fill="#E73C14"
      />
      <path
        d="M23.28 14.19H17.99V12.06H23.28V14.19ZM18.74 13.44H22.53V12.85H18.74V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M23.28 23.7H17.99V21.61H23.28V23.7ZM18.74 22.95H22.53V22.36H18.74V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M15.89 22.36H13.0401V13.44H15.89V22.36ZM13.7901 21.61H15.12V14.19H13.77L13.7901 21.61Z"
        fill="#E73C14"
      />
      <path
        d="M17.04 14.19H11.76V12.06H17.04V14.19ZM12.51 13.44H16.29V12.85H12.51V13.44Z"
        fill="#E73C14"
      />
      <path
        d="M17.04 23.7H11.76V21.61H17.04V23.7ZM12.51 22.95H16.29V22.36H12.51V22.95Z"
        fill="#E73C14"
      />
      <path
        d="M30.04 25.26H11.23V22.95H30.04V25.26ZM11.98 24.51H29.29V23.7H11.98V24.51Z"
        fill="#E73C14"
      />
      <path
        d="M30.04 12.85H11.23V10.53H30.04V12.85ZM11.98 12.06H29.29V11.24H11.98V12.06Z"
        fill="#E73C14"
      />
      <path
        d="M29.61 11.28H11.74L20.83 6L29.61 11.28ZM14.53 10.53H26.91L20.82 6.88L14.53 10.53Z"
        fill="#E73C14"
      />
    </g>
    <path
      d="M10.17 35.0629L10.9 36.6329C11 36.8529 11.08 37.0629 11.17 37.2829C11.24 37.0929 11.31 36.8829 11.4 36.6229L11.98 35.0629H12.48L11.71 36.9629C11.5329 37.4538 11.3015 37.9234 11.02 38.3629C10.9299 38.507 10.8046 38.6258 10.6559 38.708C10.5072 38.7902 10.34 38.8332 10.17 38.8329C10.0805 38.8463 9.98952 38.8463 9.90002 38.8329L9.95001 38.4429H10.13C10.51 38.4429 10.73 38.0629 10.85 37.8029C10.8715 37.7702 10.883 37.732 10.883 37.6929C10.883 37.6538 10.8715 37.6155 10.85 37.5829L9.63 35.0929L10.17 35.0629Z"
      fill="black"
    />
    <path
      d="M15.37 38.6329C15.0538 38.7651 14.7125 38.8266 14.37 38.8129C14.1227 38.8283 13.8749 38.7901 13.6438 38.7009C13.4126 38.6116 13.2034 38.4735 13.0306 38.2959C12.8577 38.1183 12.7253 37.9055 12.6423 37.6719C12.5594 37.4384 12.5279 37.1898 12.55 36.9429C12.534 36.6849 12.573 36.4264 12.6642 36.1844C12.7554 35.9425 12.8968 35.7226 13.0792 35.5393C13.2615 35.356 13.4806 35.2135 13.7221 35.121C13.9635 35.0286 14.2219 34.9883 14.48 35.0029C14.7783 34.9907 15.0756 35.0453 15.35 35.1629L15.24 35.5529C15.003 35.4518 14.7477 35.4007 14.49 35.4029C14.291 35.3915 14.0918 35.4237 13.9065 35.4972C13.7211 35.5707 13.5541 35.6837 13.4169 35.8285C13.2798 35.9732 13.1759 36.1461 13.1126 36.3352C13.0492 36.5242 13.0278 36.7248 13.05 36.9229C13.0276 37.118 13.0483 37.3156 13.1107 37.5018C13.1731 37.688 13.2757 37.8582 13.4112 38.0003C13.5467 38.1425 13.7117 38.2531 13.8947 38.3244C14.0777 38.3957 14.274 38.4259 14.47 38.4129C14.7387 38.4185 15.0052 38.3638 15.25 38.2529L15.37 38.6329Z"
      fill="black"
    />
    <path
      d="M16.58 37.5929L16.17 38.7529H15.68L16.93 35.0629H17.51L18.77 38.7529H18.26L17.86 37.5929H16.58ZM17.79 37.2229L17.43 36.1629C17.35 35.9129 17.3 35.7029 17.24 35.4829C17.18 35.7029 17.12 35.9329 17.05 36.1529L16.69 37.2229H17.79Z"
      fill="black"
    />
    <path
      d="M22.01 35.0629V38.3629H22.33V39.6329H21.95V38.7429H19.62V39.6329H19.17V38.3629H19.45C19.5937 38.1041 19.7142 37.833 19.81 37.5529C19.9377 37.0465 19.9948 36.5249 19.98 36.0029V35.0629H22.01ZM20.39 36.1529C20.3922 36.6643 20.318 37.1733 20.17 37.6629C20.092 37.905 19.9915 38.1394 19.87 38.3629H21.5V35.4529H20.36L20.39 36.1529Z"
      fill="black"
    />
    <path
      d="M23.41 35.0629V36.4429C23.5631 36.4323 23.7168 36.4323 23.87 36.4429C24.62 36.4429 25.29 36.7829 25.29 37.5929C25.2964 37.7372 25.2741 37.8814 25.2243 38.017C25.1745 38.1526 25.0982 38.277 25 38.3829C24.8199 38.5397 24.6105 38.6593 24.3839 38.7348C24.1574 38.8103 23.9182 38.8402 23.68 38.8229C23.4303 38.8393 23.1797 38.8393 22.93 38.8229V35.0629H23.41ZM23.41 38.3929C23.5397 38.4042 23.6702 38.4042 23.8 38.3929C24.34 38.3929 24.8 38.1429 24.8 37.5729C24.8 37.0029 24.34 36.7729 23.8 36.7729C23.6702 36.7629 23.5398 36.7629 23.41 36.7729V38.3929Z"
      fill="black"
    />
    <path
      d="M27.97 35.0629V35.4629H26.38V36.4629C26.5332 36.4526 26.6869 36.4526 26.84 36.4629C27.1741 36.4538 27.5037 36.5405 27.79 36.7129C27.9416 36.8073 28.0651 36.9405 28.1477 37.0988C28.2304 37.257 28.2691 37.4346 28.26 37.6129C28.2657 37.7603 28.2412 37.9074 28.1879 38.045C28.1345 38.1826 28.0536 38.3078 27.95 38.4129C27.5861 38.7136 27.1211 38.8639 26.65 38.8329C26.4003 38.8493 26.1498 38.8493 25.9 38.8329V35.0629H27.97ZM26.38 38.3929C26.5131 38.4042 26.6469 38.4042 26.78 38.3929C26.922 38.3998 27.0641 38.3787 27.1979 38.3307C27.3318 38.2826 27.4549 38.2086 27.56 38.1129C27.694 37.9655 27.7657 37.772 27.76 37.5729C27.7659 37.4541 27.7415 37.3357 27.689 37.229C27.6365 37.1223 27.5577 37.0307 27.46 36.9629C27.2497 36.8347 27.006 36.772 26.76 36.7829H26.38V38.3929Z"
      fill="black"
    />
    <path
      d="M29.45 37.5929L29.07 38.7529H28.58L29.83 35.0629H30.41L31.67 38.7529H31.17L30.77 37.5929H29.45ZM30.66 37.2229L30.3 36.1629C30.22 35.9129 30.17 35.7029 30.11 35.4829C30.0606 35.7099 29.9972 35.9337 29.92 36.1529L29.56 37.2229H30.66Z"
      fill="black"
    />
    <path
      d="M1.19 45.7429L2.27002 47.2329H1.70001L0.630005 45.7429L1.70001 44.2529H2.27002L1.19 45.7429ZM2.52002 45.7429L3.60001 47.2329H3.03L1.95999 45.7429L3.03 44.2529H3.60001L2.52002 45.7429Z"
      fill="black"
    />
    <path
      d="M5.10004 42.3229V44.7429H5.32004L7.19003 42.3229H8.04004L5.98004 44.8329C6.88004 44.9529 7.22004 45.5029 7.51004 46.2429C7.6763 46.772 7.87673 47.2897 8.11005 47.7929H7.34003C7.15106 47.3731 6.98745 46.9423 6.85004 46.5029C6.78939 46.149 6.60131 45.8295 6.32129 45.6048C6.04127 45.38 5.68869 45.2655 5.33005 45.2829H5.10004V47.8329H4.40002V42.3229H5.10004Z"
      fill="black"
    />
    <path
      d="M9.14999 42.3229L10.24 44.6429L10.63 45.6429C10.73 45.3529 10.83 45.0429 10.97 44.6429L11.82 42.3429H12.57L11.42 45.1429C11.1634 45.8674 10.828 46.5615 10.42 47.2129C10.2833 47.4176 10.0992 47.5864 9.8833 47.7047C9.66743 47.8231 9.42614 47.8877 9.17999 47.8929C9.05089 47.9145 8.91911 47.9145 8.79001 47.8929L8.86002 47.3129C8.94609 47.3272 9.03392 47.3272 9.12 47.3129C9.68 47.3129 10.02 46.7629 10.19 46.3729C10.219 46.3227 10.2342 46.2658 10.2342 46.2079C10.2342 46.1499 10.219 46.093 10.19 46.0429L8.38 42.3629L9.14999 42.3229Z"
      fill="black"
    />
    <path
      d="M13.88 42.3229V44.6029H16.52V42.3229H17.24V47.8329H16.52V45.2229H13.88V47.8329H13.18V42.3229H13.88Z"
      fill="black"
    />
    <path
      d="M19.15 42.3229V47.1929H21.66V42.3229H22.37V47.2029H22.85L22.8 49.0729H22.24L22.19 47.8329H18.45V42.3229H19.15Z"
      fill="black"
    />
    <path
      d="M26.55 45.2229H24.43V47.2229H26.8V47.8329H23.73V42.3229H26.68V42.9129H24.43V44.6329H26.55V45.2229Z"
      fill="black"
    />
    <path
      d="M27.71 42.3929C28.1353 42.3136 28.5674 42.2767 29 42.2829C29.5302 42.2338 30.0609 42.3718 30.5 42.6729C30.6517 42.7896 30.7721 42.9419 30.8506 43.1164C30.9292 43.2909 30.9633 43.4821 30.95 43.6729C30.9395 43.9435 30.8454 44.2042 30.6807 44.4191C30.5159 44.634 30.2886 44.7925 30.03 44.8729C30.3469 44.9371 30.6321 45.1083 30.8378 45.3577C31.0436 45.6071 31.1573 45.9196 31.16 46.2429C31.1626 46.4489 31.122 46.6531 31.0411 46.8425C30.9602 47.0319 30.8406 47.2024 30.69 47.3429C30.1462 47.7358 29.4779 47.9171 28.81 47.8529C28.4422 47.8531 28.0748 47.8298 27.71 47.7829V42.3929ZM28.42 44.6329H29.06C29.8 44.6329 30.24 44.2429 30.24 43.7129C30.24 43.1829 29.75 42.8229 29.04 42.8229C28.8341 42.7982 28.626 42.7982 28.42 42.8229V44.6329ZM28.42 47.2429C28.6162 47.2623 28.8139 47.2623 29.01 47.2429C29.74 47.2429 30.41 46.9729 30.41 46.1729C30.41 45.3729 29.77 45.1229 29 45.1229H28.42V47.2429Z"
      fill="black"
    />
    <path
      d="M36.7 44.9929C36.7 46.8729 35.56 47.8729 34.17 47.8729C33.8216 47.8678 33.478 47.7903 33.1612 47.6451C32.8443 47.5 32.5612 47.2905 32.3298 47.0299C32.0984 46.7693 31.9237 46.4634 31.8171 46.1316C31.7104 45.7999 31.6739 45.4495 31.71 45.1029C31.671 44.7442 31.7073 44.3813 31.8167 44.0374C31.9261 43.6936 32.1062 43.3764 32.3453 43.1062C32.5844 42.836 32.8774 42.6187 33.2054 42.4683C33.5334 42.3179 33.8892 42.2378 34.25 42.2329C34.5981 42.2349 34.942 42.3101 35.2591 42.4538C35.5762 42.5974 35.8595 42.8062 36.0906 43.0665C36.3217 43.3268 36.4954 43.6329 36.6004 43.9648C36.7054 44.2967 36.7394 44.647 36.7 44.9929ZM32.47 45.0829C32.47 46.2529 33.1 47.2929 34.21 47.2929C35.32 47.2929 35.96 46.2929 35.96 45.0229C35.96 43.9429 35.39 42.8029 34.22 42.8029C33.05 42.8029 32.47 43.8829 32.47 45.0829Z"
      fill="black"
    />
    <path
      d="M37.19 44.2529H37.76L38.88 45.7429L37.8 47.2329H37.24L38.31 45.7429L37.19 44.2529ZM38.51 44.2529H39.08L40.16 45.7429L39.08 47.2329H38.52L39.59 45.7429L38.51 44.2529Z"
      fill="black"
    />
    <defs>
      <clipPath id="clip3">
        <rect width="32" height="32" fill="white" transform="translate(4.63)" />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconManorKuncovo",
};
</script>

<style scoped lang="scss"></style>
