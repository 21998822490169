var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip111)" } }, [
        _c("path", {
          attrs: {
            d: "M24.29 5.53999H24.19L21.91 9.98999L19.56 5.5L16.33 13.61H15.5V14.26H20.07V13.61H19.16L20.07 11.08L21.91 14.26L23.69 11.08L24.59 13.61H23.69V14.26H28.22V13.61H27.43L24.29 5.53999Z",
            fill: "#E73C14",
          },
        }),
        _c("circle", {
          attrs: {
            cx: "22",
            cy: "10",
            r: "9.75",
            fill: "white",
            "fill-opacity": "0.01",
            stroke: "#E73C14",
            "stroke-width": "0.5",
          },
        }),
      ]),
      _c("path", {
        attrs: {
          d: "M0.729996 23.0939V25.5139H0.919998L2.79 23.0939H3.64L1.58 25.5939C1.94856 25.6444 2.2928 25.8066 2.56635 26.0587C2.83991 26.3108 3.02964 26.6407 3.11 27.0039C3.27626 27.533 3.47668 28.0507 3.71 28.5539H2.95C2.7517 28.1368 2.58458 27.7056 2.45 27.2639C2.38699 26.91 2.19653 26.5915 1.91461 26.3685C1.63269 26.1456 1.27886 26.0336 0.919998 26.0539H0.689995V28.5539H0V23.0939H0.729996Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.77997 23.0938L5.91997 25.4138L6.30997 26.4138C6.40997 26.1338 6.50996 25.8138 6.64996 25.4138L7.49997 23.1138H8.24997L7.06996 25.8738C6.81325 26.6015 6.47793 27.2989 6.06996 27.9538C5.93152 28.1561 5.74679 28.3225 5.53111 28.439C5.31544 28.5555 5.07507 28.6189 4.82997 28.6238C4.69816 28.6275 4.56659 28.6106 4.43997 28.5738L4.50996 28.0038H4.76997C5.33997 28.0038 5.66997 27.4538 5.83997 27.0638C5.86893 27.0137 5.88419 26.9568 5.88419 26.8988C5.88419 26.8409 5.86893 26.784 5.83997 26.7338L4.02997 23.0538L4.77997 23.0938Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.49998 23.0939V25.3739H12.14V23.0939H12.86V28.5539H12.14V25.9939H9.49998V28.5539H8.79999V23.0939H9.49998Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.78 23.0939V27.9639H17.29V23.0939H18V27.9839H18.48L18.43 29.8739H17.92V28.5639H14.18V23.0939H14.78Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.19 25.9939H20.06V27.9939H22.43V28.5839H19.36V23.0939H22.31V23.6839H20.06V25.4139H22.19V25.9939Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.34 23.1639C23.766 23.0895 24.1976 23.0527 24.63 23.0539C25.1618 23.0032 25.6941 23.1452 26.13 23.4539C26.2831 23.5693 26.4045 23.7214 26.4832 23.8962C26.5618 24.071 26.5951 24.2628 26.58 24.4539C26.574 24.7257 26.4816 24.9885 26.3162 25.2042C26.1508 25.42 25.9209 25.5775 25.66 25.6539C25.9778 25.7163 26.264 25.887 26.47 26.1368C26.6761 26.3866 26.7892 26.7001 26.79 27.0239C26.7918 27.2282 26.7509 27.4307 26.6699 27.6184C26.589 27.8061 26.4698 27.9748 26.32 28.1139C25.7782 28.511 25.1082 28.6927 24.44 28.6239C24.0721 28.6292 23.7043 28.6058 23.34 28.5539V23.1639ZM24.05 25.4039H24.69C25.43 25.4039 25.87 25.0139 25.87 24.4839C25.87 23.9539 25.38 23.5939 24.67 23.5939C24.4622 23.5909 24.2547 23.6077 24.05 23.6439V25.4039ZM24.05 28.0239C24.2465 28.0369 24.4436 28.0369 24.64 28.0239C25.37 28.0239 26.04 27.7539 26.04 26.9639C26.04 26.1739 25.4 25.9039 24.63 25.9039H24.05V28.0239Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.48 28.3738C31.0244 28.5634 30.5331 28.6521 30.04 28.6338C29.6743 28.6567 29.308 28.6005 28.966 28.469C28.624 28.3375 28.3143 28.1339 28.0581 27.872C27.8019 27.6101 27.6051 27.296 27.4812 26.9512C27.3573 26.6064 27.3091 26.2389 27.34 25.8738C27.3184 25.4921 27.3775 25.1102 27.5134 24.7528C27.6494 24.3955 27.8591 24.0708 28.129 23.8C28.3988 23.5292 28.7228 23.3183 29.0796 23.1812C29.4365 23.044 29.8182 22.9835 30.2 23.0038C30.6425 22.9877 31.083 23.0696 31.49 23.2438L31.32 23.8238C30.9751 23.666 30.5993 23.5874 30.22 23.5938C29.9214 23.5713 29.6215 23.6161 29.3425 23.725C29.0635 23.8339 28.8125 24.004 28.608 24.2228C28.4036 24.4417 28.2509 24.7037 28.1612 24.9894C28.0716 25.2752 28.0472 25.5774 28.09 25.8738C28.0607 26.1614 28.094 26.4519 28.1877 26.7254C28.2815 26.9988 28.4334 27.2487 28.633 27.4578C28.8326 27.6669 29.0751 27.8303 29.3439 27.9366C29.6127 28.043 29.9014 28.0898 30.19 28.0738C30.5854 28.0801 30.9775 28.0017 31.34 27.8438L31.48 28.3738Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.06 23.0939V25.5139H33.28L35.15 23.0939H36L33.92 25.5939C34.2886 25.6444 34.6328 25.8066 34.9064 26.0587C35.18 26.3108 35.3697 26.6407 35.45 27.0039C35.6163 27.533 35.8167 28.0507 36.05 28.5539H35.29C35.0917 28.1368 34.9246 27.7056 34.79 27.2639C34.7272 26.9118 34.5382 26.5947 34.2584 26.372C33.9786 26.1492 33.6272 26.0361 33.2701 26.0539H33.04V28.5539H32.35V23.0939H33.06Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.68 26.8738L37.11 28.5938H36.38L38.24 23.1338H39.09L40.92 28.5538H40.17L39.61 26.8738H37.68ZM39.47 26.3238L38.92 24.7138C38.8 24.3538 38.72 24.0338 38.64 23.7138C38.56 24.0405 38.47 24.3738 38.37 24.7138L37.83 26.2938L39.47 26.3238Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.26 28.5538C41.3431 28.395 41.4165 28.2314 41.48 28.0638C41.627 27.4047 41.9059 26.7822 42.3 26.2338C42.4278 26.1038 42.5858 26.0076 42.76 25.9538C42.4276 25.917 42.1205 25.7589 41.8973 25.5099C41.6741 25.2608 41.5504 24.9383 41.55 24.6038C41.5429 24.3929 41.5843 24.1832 41.671 23.9907C41.7577 23.7983 41.8873 23.6283 42.05 23.4938C42.5132 23.1857 43.0648 23.0381 43.62 23.0738C44.0558 23.0714 44.4909 23.1082 44.92 23.1838V28.5738H44.22V26.1838H43.7C43.557 26.1736 43.4133 26.1922 43.2776 26.2386C43.1419 26.285 43.0169 26.3582 42.91 26.4538C42.5704 26.9729 42.3265 27.5487 42.19 28.1538C42.1384 28.2908 42.0783 28.4244 42.01 28.5538H41.26ZM44.26 23.6638C44.0401 23.6148 43.8153 23.5913 43.59 23.5938C42.89 23.5938 42.3 23.8638 42.3 24.5938C42.3 25.3238 42.89 25.5938 43.66 25.5938H44.26V23.6638Z",
          fill: "black",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip111" } }, [
          _c("rect", {
            attrs: {
              width: "20",
              height: "20",
              fill: "white",
              transform: "translate(12)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }