var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "map-layout",
    { staticStyle: { width: "100%", height: "100%", "max-height": "800px" } },
    [
      _c("map-icon-main-street", {
        attrs: { transform: "translate(641,307)" },
      }),
      _c("svg-arrow-hint", {
        ref: "hint",
        attrs: {
          "hint-path-clone": "hintPathCloneDevelopment",
          "hint-arrow": "hintArrowDevelopment",
          "hint-path": "hintPathDevelopment",
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(546,228)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 322],
                [589, 293],
                [575, 266],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(490,268)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 302],
                [589, 293],
                [530, 279],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoMcd", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(619,378)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 330],
                [770, 342],
                [710, 473],
                [648, 420],
              ],
              hint: "6 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoBkl", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(529,298)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [585, 282],
                [566, 288],
                [560, 295],
              ],
              hint: "7 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroScolkovo", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(126,505)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [645, 340],
                [595, 392],
                [490, 543],
                [174, 515],
              ],
              hint: "10 минут",
              reverse: true,
              dy: -5,
              electric: true,
            })
          },
        },
      }),
      _c("MapIconSber", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(457,405)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 352],
                [576, 368],
                [495, 405],
              ],
              hint: "6 минут",
              reverse: true,
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconProductVkuss", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(779,286)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 329],
                [798, 306],
                [799, 306],
              ],
              hint: "5 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconPolyclinic", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(860,259)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 289],
                [798, 276],
                [857, 283],
              ],
              hint: "9 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconTrcOkeaniya", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(999,314)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 340],
                [750, 349],
                [915, 340],
                [997, 335],
              ],
              hint: "13 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconLdcKutuzov", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(1029,339)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 340],
                [750, 339],
                [915, 330],
                [1037, 365],
              ],
              hint: "13 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconTrcVremena", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(1179,150)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [855, 232],
                [1066, 180],
                [1180, 170],
              ],
              hint: "20 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconProductGlobus", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(1109,180)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [855, 232],
                [1066, 180],
                [1120, 199],
              ],
              hint: "18 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconPerekrestok", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(660,444)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 320],
                [750, 342],
                [719, 423],
                [700, 440],
              ],
              hint: "7 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconPlanetHealth", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(503,171)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [660, 315],
                [664, 302],
                [610, 233],
                [572, 200],
              ],
              hint: "6 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconVtb", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(417,120)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [605, 309],
                [576, 308],
                [445, 180],
              ],
              hint: "7 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconLdc", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(336,215)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 382],
                [436, 400],
                [390, 265],
              ],
              hint: "9 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconTrcKuncovo", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(180,145)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 392],
                [436, 430],
                [220, 199],
              ],
              hint: "10 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconVetHelp", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(306,438)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [645, 340],
                [635, 342],
                [500, 543],
                [390, 455],
              ],
              hint: "16 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconProductVkuss", {
        staticClass: "map-icon__comfort",
        attrs: { transform: "translate(367,375)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 352],
                [576, 368],
                [435, 385],
              ],
              hint: "7 минут",
              car: true,
              reverse: true,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }