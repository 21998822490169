<template>
  <g @click="$emit('click')">
    <circle
      cx="44"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path
      d="M42.4505 23.54C41.7689 23.54 41.1152 23.2693 40.6333 22.7873C40.1513 22.3053 39.8806 21.6516 39.8806 20.97C39.8806 20.2884 40.1513 19.6347 40.6333 19.1528C41.1152 18.6708 41.7689 18.4 42.4505 18.4H45.0305V20.93C45.0345 21.2713 44.9707 21.6101 44.8428 21.9266C44.715 22.2431 44.5255 22.531 44.2856 22.7738C44.0456 23.0165 43.7598 23.2093 43.4449 23.3408C43.1299 23.4723 42.7919 23.54 42.4505 23.54ZM42.4505 19.4C42.1404 19.402 41.8379 19.4958 41.581 19.6695C41.3242 19.8432 41.1245 20.0891 41.0072 20.3761C40.8899 20.6632 40.8602 20.9785 40.9219 21.2824C40.9836 21.5863 41.134 21.8651 41.3539 22.0837C41.5739 22.3022 41.8536 22.4508 42.1579 22.5105C42.4622 22.5703 42.7773 22.5387 43.0636 22.4195C43.3499 22.3004 43.5946 22.0992 43.7666 21.8412C43.9387 21.5833 44.0305 21.2801 44.0305 20.97V19.4H42.4505Z"
      fill="#E73C14"
    />
    <path
      d="M45.0305 18.93H44.0305V13.23C44.0392 12.7623 44.2045 12.311 44.5001 11.9484C44.7956 11.5858 45.2042 11.3328 45.6605 11.23L51.4005 9.94995C51.7029 9.87945 52.0172 9.87787 52.3202 9.9453C52.6233 10.0127 52.9072 10.1475 53.1512 10.3395C53.3951 10.5316 53.5927 10.776 53.7293 11.0548C53.8659 11.3336 53.9381 11.6395 53.9405 11.9499V17.9499H52.9405V11.9499C52.9416 11.7871 52.9058 11.6262 52.8359 11.4792C52.766 11.3321 52.6637 11.2028 52.5368 11.1009C52.4098 10.9989 52.2614 10.927 52.1028 10.8905C51.9441 10.8539 51.7793 10.8538 51.6205 10.89L45.8805 12.18C45.6389 12.2326 45.4227 12.3666 45.2679 12.5595C45.1132 12.7525 45.0294 12.9927 45.0305 13.2399V18.93Z"
      fill="#E73C14"
    />
    <path
      d="M53.3457 12.5082L44.4272 14.5083L44.646 15.4841L53.5645 13.484L53.3457 12.5082Z"
      fill="#E73C14"
    />
    <path
      d="M51.3705 21.93C51.0189 21.952 50.6665 21.9016 50.335 21.7821C50.0036 21.6626 49.7001 21.4765 49.4433 21.2353C49.1866 20.994 48.9819 20.7027 48.842 20.3793C48.7022 20.056 48.63 19.7074 48.63 19.355C48.63 19.0027 48.7022 18.6541 48.842 18.3307C48.9819 18.0073 49.1866 17.716 49.4433 17.4748C49.7001 17.2335 50.0036 17.0474 50.335 16.9279C50.6665 16.8084 51.0189 16.7581 51.3705 16.78H53.9605V19.36C53.9606 19.6992 53.8934 20.035 53.763 20.3482C53.6326 20.6613 53.4415 20.9455 53.2007 21.1844C52.96 21.4233 52.6743 21.6122 52.3602 21.7402C52.046 21.8681 51.7097 21.9327 51.3705 21.93ZM51.3705 17.78C51.0576 17.778 50.7512 17.869 50.49 18.0414C50.2289 18.2138 50.0248 18.4599 49.9037 18.7484C49.7826 19.037 49.7499 19.3549 49.8097 19.6621C49.8695 19.9692 50.0192 20.2517 50.2398 20.4737C50.4603 20.6956 50.7419 20.8471 51.0486 20.9089C51.3554 20.9707 51.6736 20.94 51.9629 20.8207C52.2522 20.7014 52.4995 20.4989 52.6736 20.2389C52.8476 19.9788 52.9405 19.6729 52.9406 19.36V17.78H51.3705Z"
      fill="#E73C14"
    />
    <path
      d="M36.7405 18.54C36.3889 18.5619 36.0365 18.5116 35.705 18.3921C35.3736 18.2726 35.0701 18.0865 34.8133 17.8452C34.5566 17.604 34.3519 17.3127 34.212 16.9893C34.0722 16.6659 34 16.3173 34 15.965C34 15.6127 34.0722 15.2641 34.212 14.9407C34.3519 14.6173 34.5566 14.326 34.8133 14.0848C35.0701 13.8435 35.3736 13.6574 35.705 13.5379C36.0365 13.4184 36.3889 13.3681 36.7405 13.39H39.3105V15.93C39.3145 16.2705 39.251 16.6084 39.1238 16.9242C38.9965 17.2401 38.808 17.5276 38.5691 17.7702C38.3302 18.0128 38.0456 18.2058 37.7318 18.3379C37.4179 18.47 37.081 18.5387 36.7405 18.54ZM36.7405 14.39C36.4272 14.388 36.1204 14.4793 35.859 14.6521C35.5977 14.825 35.3936 15.0716 35.2728 15.3607C35.1519 15.6498 35.1198 15.9684 35.1805 16.2758C35.2411 16.5832 35.3918 16.8656 35.6133 17.0872C35.8349 17.3088 36.1174 17.4595 36.4248 17.5201C36.7322 17.5808 37.0507 17.5486 37.3398 17.4278C37.6289 17.307 37.8756 17.1029 38.0484 16.8416C38.2213 16.5802 38.3125 16.2733 38.3105 15.96V14.39H36.7405Z"
      fill="#E73C14"
    />
    <path
      d="M39.3105 13.93H38.3105V8.22002C38.2984 8.09204 38.3292 7.96365 38.3979 7.85505C38.4667 7.74645 38.5696 7.66378 38.6905 7.62002C39.1005 7.49002 39.3205 7.80002 39.6205 8.23002C39.9205 8.66002 40.7105 9.76002 41.4505 9.76002V10.76C41.022 10.7114 40.6091 10.571 40.2398 10.3484C39.8705 10.1258 39.5535 9.8262 39.3105 9.47002V13.93Z"
      fill="#E73C14"
    />
    <path
      d="M0.542578 43C0.502578 43 0.467578 42.985 0.437578 42.955C0.407578 42.925 0.392578 42.89 0.392578 42.85C0.392578 42.81 0.395078 42.7825 0.400078 42.7675L2.26008 37.915C2.30008 37.805 2.38008 37.75 2.50008 37.75H3.01008C3.13008 37.75 3.21008 37.805 3.25008 37.915L5.10258 42.7675L5.11758 42.85C5.11758 42.89 5.10258 42.925 5.07258 42.955C5.04258 42.985 5.00758 43 4.96758 43H4.58508C4.53008 43 4.48758 42.9875 4.45758 42.9625C4.42758 42.9325 4.40758 42.9025 4.39758 42.8725L3.98508 41.8075H1.52508L1.11258 42.8725C1.10258 42.9025 1.08008 42.9325 1.04508 42.9625C1.01508 42.9875 0.975078 43 0.925078 43H0.542578ZM3.76758 41.1625L2.75508 38.5L1.74258 41.1625H3.76758Z"
      fill="black"
    />
    <path
      d="M9.30949 37.75C9.34949 37.75 9.38449 37.765 9.41449 37.795C9.44449 37.825 9.45949 37.86 9.45949 37.9C9.45949 37.94 9.45199 37.975 9.43699 38.005L7.90699 41.47C7.73199 41.865 7.57449 42.1675 7.43449 42.3775C7.29949 42.5825 7.13949 42.7375 6.95449 42.8425C6.76949 42.9475 6.52949 43 6.23449 43H5.93449C5.88449 43 5.84199 42.985 5.80699 42.955C5.77699 42.92 5.76199 42.8775 5.76199 42.8275V42.5125C5.76199 42.4625 5.77699 42.42 5.80699 42.385C5.84199 42.35 5.88449 42.3325 5.93449 42.3325H6.19699C6.39699 42.3325 6.56199 42.2725 6.69199 42.1525C6.82699 42.0275 6.96449 41.82 7.10449 41.53L5.16199 37.99C5.14699 37.96 5.13949 37.9325 5.13949 37.9075C5.13949 37.8675 5.15449 37.8325 5.18449 37.8025C5.21949 37.7675 5.25699 37.75 5.29699 37.75H5.72449C5.79949 37.75 5.85949 37.7925 5.90449 37.8775L7.46449 40.72L8.71699 37.8775C8.73199 37.8375 8.75699 37.8075 8.79199 37.7875C8.82699 37.7625 8.86449 37.75 8.90449 37.75H9.30949Z"
      fill="black"
    />
    <path
      d="M9.5785 44.0425C9.5285 44.0425 9.486 44.0275 9.451 43.9975C9.421 43.9675 9.406 43.925 9.406 43.87V42.4975C9.406 42.4475 9.421 42.4075 9.451 42.3775C9.486 42.3425 9.5285 42.325 9.5785 42.325H9.736C10.221 42.325 10.4635 41.395 10.4635 39.535V37.9225C10.4635 37.8725 10.4785 37.8325 10.5085 37.8025C10.5435 37.7675 10.586 37.75 10.636 37.75H13.561C13.616 37.75 13.6585 37.7675 13.6885 37.8025C13.7235 37.8325 13.741 37.875 13.741 37.93V42.325H14.1085C14.1585 42.325 14.201 42.3425 14.236 42.3775C14.271 42.4075 14.2885 42.4475 14.2885 42.4975V43.87C14.2885 43.92 14.271 43.96 14.236 43.99C14.206 44.025 14.1635 44.0425 14.1085 44.0425H13.726C13.676 44.0425 13.6335 44.0275 13.5985 43.9975C13.5685 43.9675 13.5535 43.925 13.5535 43.87V43H10.141V43.87C10.141 43.92 10.1235 43.96 10.0885 43.99C10.0585 44.025 10.016 44.0425 9.961 44.0425H9.5785ZM13.006 42.325V38.425H11.1835V39.6325C11.1835 40.3375 11.1435 40.9075 11.0635 41.3425C10.9835 41.7775 10.861 42.105 10.696 42.325H13.006Z"
      fill="black"
    />
    <path
      d="M15.1436 43C15.0936 43 15.0511 42.985 15.0161 42.955C14.9861 42.92 14.9711 42.875 14.9711 42.82V37.93C14.9711 37.875 14.9861 37.8325 15.0161 37.8025C15.0511 37.7675 15.0936 37.75 15.1436 37.75H15.5336C15.5836 37.75 15.6236 37.7675 15.6536 37.8025C15.6886 37.8375 15.7061 37.88 15.7061 37.93V41.8075L18.5036 37.855C18.5436 37.785 18.6061 37.75 18.6911 37.75H19.0586C19.1086 37.75 19.1486 37.7675 19.1786 37.8025C19.2136 37.8325 19.2311 37.875 19.2311 37.93V42.8275C19.2311 42.8775 19.2136 42.92 19.1786 42.955C19.1486 42.985 19.1086 43 19.0586 43H18.6761C18.6261 43 18.5836 42.985 18.5486 42.955C18.5136 42.92 18.4961 42.8775 18.4961 42.8275V38.98L15.7061 42.895C15.6661 42.965 15.6036 43 15.5186 43H15.1436Z"
      fill="black"
    />
    <path
      d="M22.2025 43.075C21.5475 43.075 21.04 42.905 20.68 42.565C20.325 42.22 20.1375 41.7025 20.1175 41.0125C20.1125 40.8625 20.11 40.65 20.11 40.375C20.11 40.1 20.1125 39.89 20.1175 39.745C20.1375 39.07 20.3325 38.5575 20.7025 38.2075C21.0725 37.8525 21.5725 37.675 22.2025 37.675C22.8275 37.675 23.325 37.8525 23.695 38.2075C24.065 38.5575 24.26 39.07 24.28 39.745C24.29 40.035 24.295 40.245 24.295 40.375C24.295 40.5 24.29 40.7125 24.28 41.0125C24.26 41.7025 24.07 42.22 23.71 42.565C23.355 42.905 22.8525 43.075 22.2025 43.075ZM22.2025 42.4375C22.5975 42.4375 22.9125 42.3175 23.1475 42.0775C23.3825 41.8375 23.51 41.47 23.53 40.975C23.54 40.675 23.545 40.475 23.545 40.375C23.545 40.265 23.54 40.065 23.53 39.775C23.51 39.28 23.38 38.9125 23.14 38.6725C22.905 38.4325 22.5925 38.3125 22.2025 38.3125C21.8075 38.3125 21.49 38.4325 21.25 38.6725C21.015 38.9125 20.8875 39.28 20.8675 39.775C20.8625 39.92 20.86 40.12 20.86 40.375C20.86 40.625 20.8625 40.825 20.8675 40.975C20.8875 41.47 21.015 41.8375 21.25 42.0775C21.49 42.3175 21.8075 42.4375 22.2025 42.4375Z"
      fill="black"
    />
    <path
      d="M25.2006 41.065C25.1506 41.065 25.1081 41.05 25.0731 41.02C25.0431 40.985 25.0281 40.9425 25.0281 40.8925V40.6075C25.0281 40.5575 25.0431 40.5175 25.0731 40.4875C25.1081 40.4525 25.1506 40.435 25.2006 40.435H27.4506C27.5006 40.435 27.5406 40.4525 27.5706 40.4875C27.6056 40.5175 27.6231 40.5575 27.6231 40.6075V40.8925C27.6231 40.9425 27.6056 40.985 27.5706 41.02C27.5406 41.05 27.5006 41.065 27.4506 41.065H25.2006Z"
      fill="black"
    />
    <path
      d="M28.6922 43C28.6422 43 28.5997 42.985 28.5647 42.955C28.5347 42.92 28.5197 42.8775 28.5197 42.8275V37.9225C28.5197 37.8675 28.5347 37.825 28.5647 37.795C28.5997 37.765 28.6422 37.75 28.6922 37.75H29.0747C29.1247 37.75 29.1672 37.7675 29.2022 37.8025C29.2372 37.8325 29.2547 37.8725 29.2547 37.9225V42.325H31.0922V37.9225C31.0922 37.8725 31.1072 37.8325 31.1372 37.8025C31.1722 37.7675 31.2147 37.75 31.2647 37.75H31.6472C31.6972 37.75 31.7397 37.7675 31.7747 37.8025C31.8097 37.8325 31.8272 37.8725 31.8272 37.9225V42.325H33.6647V37.9225C33.6647 37.8725 33.6797 37.8325 33.7097 37.8025C33.7447 37.7675 33.7872 37.75 33.8372 37.75H34.2197C34.2747 37.75 34.3172 37.7675 34.3472 37.8025C34.3822 37.8325 34.3997 37.8725 34.3997 37.9225V42.8275C34.3997 42.8775 34.3822 42.92 34.3472 42.955C34.3172 42.985 34.2747 43 34.2197 43H28.6922Z"
      fill="black"
    />
    <path
      d="M39.3197 42.7375C39.3347 42.7575 39.3422 42.785 39.3422 42.82C39.3422 42.87 39.3247 42.9125 39.2897 42.9475C39.2597 42.9825 39.2197 43 39.1697 43H38.7422C38.6922 43 38.6522 42.99 38.6222 42.97C38.5972 42.95 38.5722 42.9225 38.5472 42.8875L37.1072 40.675H36.2147V42.8275C36.2147 42.8775 36.1972 42.92 36.1622 42.955C36.1272 42.985 36.0847 43 36.0347 43H35.6522C35.6022 43 35.5597 42.985 35.5247 42.955C35.4947 42.92 35.4797 42.8775 35.4797 42.8275V37.93C35.4797 37.875 35.4947 37.8325 35.5247 37.8025C35.5597 37.7675 35.6022 37.75 35.6522 37.75H36.0347C36.0897 37.75 36.1322 37.7675 36.1622 37.8025C36.1972 37.8325 36.2147 37.875 36.2147 37.93V40L37.0697 40.0075L38.3972 37.87C38.4472 37.79 38.5322 37.75 38.6522 37.75H39.0572C39.1622 37.75 39.2147 37.8 39.2147 37.9C39.2147 37.925 39.2022 37.9575 39.1772 37.9975L37.7372 40.2925L39.3197 42.7375Z"
      fill="black"
    />
    <path
      d="M41.6711 43.075C41.0161 43.075 40.5086 42.905 40.1486 42.565C39.7936 42.22 39.6061 41.7025 39.5861 41.0125C39.5811 40.8625 39.5786 40.65 39.5786 40.375C39.5786 40.1 39.5811 39.89 39.5861 39.745C39.6061 39.07 39.8011 38.5575 40.1711 38.2075C40.5411 37.8525 41.0411 37.675 41.6711 37.675C42.2961 37.675 42.7936 37.8525 43.1636 38.2075C43.5336 38.5575 43.7286 39.07 43.7486 39.745C43.7586 40.035 43.7636 40.245 43.7636 40.375C43.7636 40.5 43.7586 40.7125 43.7486 41.0125C43.7286 41.7025 43.5386 42.22 43.1786 42.565C42.8236 42.905 42.3211 43.075 41.6711 43.075ZM41.6711 42.4375C42.0661 42.4375 42.3811 42.3175 42.6161 42.0775C42.8511 41.8375 42.9786 41.47 42.9986 40.975C43.0086 40.675 43.0136 40.475 43.0136 40.375C43.0136 40.265 43.0086 40.065 42.9986 39.775C42.9786 39.28 42.8486 38.9125 42.6086 38.6725C42.3736 38.4325 42.0611 38.3125 41.6711 38.3125C41.2761 38.3125 40.9586 38.4325 40.7186 38.6725C40.4836 38.9125 40.3561 39.28 40.3361 39.775C40.3311 39.92 40.3286 40.12 40.3286 40.375C40.3286 40.625 40.3311 40.825 40.3361 40.975C40.3561 41.47 40.4836 41.8375 40.7186 42.0775C40.9586 42.3175 41.2761 42.4375 41.6711 42.4375Z"
      fill="black"
    />
    <path
      d="M47.9786 43C47.9286 43 47.8861 42.985 47.8511 42.955C47.8211 42.92 47.8061 42.8775 47.8061 42.8275V38.425H45.9461V39.6325C45.9461 40.4375 45.8886 41.08 45.7736 41.56C45.6586 42.04 45.4786 42.3925 45.2336 42.6175C44.9886 42.8425 44.6636 42.9675 44.2586 42.9925C44.2086 42.9975 44.1661 42.9825 44.1311 42.9475C44.0961 42.9125 44.0786 42.8675 44.0786 42.8125V42.4825C44.0786 42.4375 44.0911 42.4 44.1161 42.37C44.1461 42.335 44.1861 42.315 44.2361 42.31C44.5611 42.265 44.8061 42.025 44.9711 41.59C45.1411 41.155 45.2261 40.47 45.2261 39.535V37.9225C45.2261 37.8725 45.2411 37.8325 45.2711 37.8025C45.3061 37.7675 45.3486 37.75 45.3986 37.75H48.3686C48.4186 37.75 48.4586 37.7675 48.4886 37.8025C48.5236 37.8325 48.5411 37.875 48.5411 37.93V42.8275C48.5411 42.8775 48.5236 42.92 48.4886 42.955C48.4586 42.985 48.4186 43 48.3686 43H47.9786Z"
      fill="black"
    />
    <path
      d="M49.2691 43C49.2291 43 49.1941 42.985 49.1641 42.955C49.1341 42.925 49.1191 42.89 49.1191 42.85C49.1191 42.81 49.1216 42.7825 49.1266 42.7675L50.9866 37.915C51.0266 37.805 51.1066 37.75 51.2266 37.75H51.7366C51.8566 37.75 51.9366 37.805 51.9766 37.915L53.8291 42.7675L53.8441 42.85C53.8441 42.89 53.8291 42.925 53.7991 42.955C53.7691 42.985 53.7341 43 53.6941 43H53.3116C53.2566 43 53.2141 42.9875 53.1841 42.9625C53.1541 42.9325 53.1341 42.9025 53.1241 42.8725L52.7116 41.8075H50.2516L49.8391 42.8725C49.8291 42.9025 49.8066 42.9325 49.7716 42.9625C49.7416 42.9875 49.7016 43 49.6516 43H49.2691ZM52.4941 41.1625L51.4816 38.5L50.4691 41.1625H52.4941Z"
      fill="black"
    />
    <path
      d="M56.2069 43C56.1569 43 56.1144 42.985 56.0794 42.955C56.0494 42.92 56.0344 42.8775 56.0344 42.8275V37.93C56.0344 37.875 56.0494 37.8325 56.0794 37.8025C56.1144 37.7675 56.1569 37.75 56.2069 37.75H57.9094C58.6644 37.75 59.2094 37.92 59.5444 38.26C59.8794 38.595 60.0544 39.1025 60.0694 39.7825C60.0744 39.9275 60.0769 40.1225 60.0769 40.3675C60.0769 40.6125 60.0744 40.81 60.0694 40.96C60.0544 41.435 59.9744 41.8225 59.8294 42.1225C59.6894 42.4175 59.4669 42.6375 59.1619 42.7825C58.8569 42.9275 58.4519 43 57.9469 43H56.2069ZM57.9094 42.3625C58.2544 42.3625 58.5269 42.315 58.7269 42.22C58.9319 42.125 59.0794 41.975 59.1694 41.77C59.2644 41.56 59.3169 41.28 59.3269 40.93C59.3369 40.63 59.3419 40.4425 59.3419 40.3675C59.3419 40.2875 59.3369 40.1025 59.3269 39.8125C59.3169 39.3175 59.1994 38.9575 58.9744 38.7325C58.7494 38.5025 58.3819 38.3875 57.8719 38.3875H56.7694V42.3625H57.9094Z"
      fill="black"
    />
    <path
      d="M62.5403 43.075C61.9953 43.075 61.5428 42.94 61.1828 42.67C60.8228 42.4 60.6328 42.005 60.6128 41.485C60.6128 41.44 60.6278 41.4025 60.6578 41.3725C60.6878 41.3375 60.7278 41.32 60.7778 41.32H61.1828C61.2878 41.32 61.3528 41.3775 61.3778 41.4925C61.4078 41.8125 61.5303 42.05 61.7453 42.205C61.9603 42.36 62.2303 42.4375 62.5553 42.4375C62.9303 42.4375 63.2178 42.315 63.4178 42.07C63.6228 41.82 63.7253 41.485 63.7253 41.065V38.4025H61.0628C61.0128 38.4025 60.9703 38.3875 60.9353 38.3575C60.9053 38.3225 60.8903 38.28 60.8903 38.23V37.9225C60.8903 37.8725 60.9053 37.8325 60.9353 37.8025C60.9703 37.7675 61.0128 37.75 61.0628 37.75H64.2953C64.3503 37.75 64.3928 37.7675 64.4228 37.8025C64.4578 37.8325 64.4753 37.875 64.4753 37.93V41.08C64.4753 41.69 64.3028 42.175 63.9578 42.535C63.6178 42.895 63.1453 43.075 62.5403 43.075Z"
      fill="black"
    />
    <path
      d="M67.2946 43C67.2446 43 67.2021 42.985 67.1671 42.955C67.1371 42.92 67.1221 42.8775 67.1221 42.8275V37.93C67.1221 37.875 67.1371 37.8325 67.1671 37.8025C67.2021 37.7675 67.2446 37.75 67.2946 37.75H70.1821C70.2371 37.75 70.2796 37.7675 70.3096 37.8025C70.3446 37.8325 70.3621 37.875 70.3621 37.93V38.23C70.3621 38.28 70.3446 38.3225 70.3096 38.3575C70.2796 38.3875 70.2371 38.4025 70.1821 38.4025H67.8571V42.8275C67.8571 42.8775 67.8396 42.92 67.8046 42.955C67.7696 42.985 67.7271 43 67.6771 43H67.2946Z"
      fill="black"
    />
    <path
      d="M70.9954 43C70.9454 43 70.9029 42.985 70.8679 42.955C70.8379 42.92 70.8229 42.8775 70.8229 42.8275V37.93C70.8229 37.875 70.8379 37.8325 70.8679 37.8025C70.9029 37.7675 70.9454 37.75 70.9954 37.75H72.9004C73.4654 37.75 73.9104 37.8875 74.2354 38.1625C74.5604 38.4375 74.7229 38.835 74.7229 39.355C74.7229 39.875 74.5604 40.2725 74.2354 40.5475C73.9154 40.8175 73.4704 40.9525 72.9004 40.9525H71.5729V42.8275C71.5729 42.8775 71.5554 42.92 71.5204 42.955C71.4854 42.985 71.4429 43 71.3929 43H70.9954ZM72.8629 40.3225C73.2279 40.3225 73.5029 40.24 73.6879 40.075C73.8779 39.91 73.9729 39.67 73.9729 39.355C73.9729 39.04 73.8804 38.8 73.6954 38.635C73.5104 38.465 73.2329 38.38 72.8629 38.38H71.5654V40.3225H72.8629Z"
      fill="black"
    />
    <path
      d="M79.0249 37.75C79.0649 37.75 79.0999 37.765 79.1299 37.795C79.1599 37.825 79.1749 37.86 79.1749 37.9C79.1749 37.94 79.1674 37.975 79.1524 38.005L77.6224 41.47C77.4474 41.865 77.2899 42.1675 77.1499 42.3775C77.0149 42.5825 76.8549 42.7375 76.6699 42.8425C76.4849 42.9475 76.2449 43 75.9499 43H75.6499C75.5999 43 75.5574 42.985 75.5224 42.955C75.4924 42.92 75.4774 42.8775 75.4774 42.8275V42.5125C75.4774 42.4625 75.4924 42.42 75.5224 42.385C75.5574 42.35 75.5999 42.3325 75.6499 42.3325H75.9124C76.1124 42.3325 76.2774 42.2725 76.4074 42.1525C76.5424 42.0275 76.6799 41.82 76.8199 41.53L74.8774 37.99C74.8624 37.96 74.8549 37.9325 74.8549 37.9075C74.8549 37.8675 74.8699 37.8325 74.8999 37.8025C74.9349 37.7675 74.9724 37.75 75.0124 37.75H75.4399C75.5149 37.75 75.5749 37.7925 75.6199 37.8775L77.1799 40.72L78.4324 37.8775C78.4474 37.8375 78.4724 37.8075 78.5074 37.7875C78.5424 37.7625 78.5799 37.75 78.6199 37.75H79.0249Z"
      fill="black"
    />
    <path
      d="M79.7739 43C79.7239 43 79.6814 42.985 79.6464 42.955C79.6164 42.92 79.6014 42.8775 79.6014 42.8275V37.93C79.6014 37.875 79.6164 37.8325 79.6464 37.8025C79.6814 37.7675 79.7239 37.75 79.7739 37.75H81.8289C82.3639 37.75 82.7664 37.8775 83.0364 38.1325C83.3064 38.3875 83.4414 38.7225 83.4414 39.1375C83.4414 39.4175 83.3764 39.65 83.2464 39.835C83.1214 40.02 82.9714 40.155 82.7964 40.24C83.0064 40.345 83.1839 40.5075 83.3289 40.7275C83.4739 40.9475 83.5464 41.2 83.5464 41.485C83.5464 41.765 83.4814 42.02 83.3514 42.25C83.2264 42.48 83.0389 42.6625 82.7889 42.7975C82.5389 42.9325 82.2389 43 81.8889 43H79.7739ZM81.7164 39.97C82.0314 39.97 82.2714 39.8975 82.4364 39.7525C82.6064 39.6075 82.6914 39.405 82.6914 39.145C82.6914 38.89 82.6089 38.695 82.4439 38.56C82.2789 38.425 82.0364 38.3575 81.7164 38.3575H80.3364V39.97H81.7164ZM81.7914 42.3925C82.1064 42.3925 82.3514 42.3075 82.5264 42.1375C82.7064 41.9675 82.7964 41.75 82.7964 41.485C82.7964 41.21 82.7064 40.99 82.5264 40.825C82.3514 40.655 82.1064 40.57 81.7914 40.57H80.3364V42.3925H81.7914Z"
      fill="black"
    />
    <path
      d="M84.0337 43C83.9937 43 83.9587 42.985 83.9287 42.955C83.8987 42.925 83.8837 42.89 83.8837 42.85C83.8837 42.81 83.8862 42.7825 83.8912 42.7675L85.7512 37.915C85.7912 37.805 85.8712 37.75 85.9912 37.75H86.5012C86.6212 37.75 86.7012 37.805 86.7412 37.915L88.5937 42.7675L88.6087 42.85C88.6087 42.89 88.5937 42.925 88.5637 42.955C88.5337 42.985 88.4987 43 88.4587 43H88.0762C88.0212 43 87.9787 42.9875 87.9487 42.9625C87.9187 42.9325 87.8987 42.9025 87.8887 42.8725L87.4762 41.8075H85.0162L84.6037 42.8725C84.5937 42.9025 84.5712 42.9325 84.5362 42.9625C84.5062 42.9875 84.4662 43 84.4162 43H84.0337ZM87.2587 41.1625L86.2462 38.5L85.2337 41.1625H87.2587Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconGrooveSchool",
};
</script>

<style scoped lang="scss"></style>
