var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "20",
          cy: "16",
          r: "15.5",
          fill: "white",
          "fill-opacity": "0.01",
          stroke: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.51 12.3198C16.2387 12.3198 17.64 10.9184 17.64 9.18979C17.64 7.46114 16.2387 6.05981 14.51 6.05981C12.7814 6.05981 11.38 7.46114 11.38 9.18979C11.38 10.9184 12.7814 12.3198 14.51 12.3198Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.31 20.5199C26.8067 20.5199 28.02 19.3066 28.02 17.8099C28.02 16.3132 26.8067 15.0999 25.31 15.0999C23.8133 15.0999 22.6 16.3132 22.6 17.8099C22.6 19.3066 23.8133 20.5199 25.31 20.5199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.8 9.18991C21.9101 9.18991 22.81 8.29002 22.81 7.17993C22.81 6.06984 21.9101 5.16992 20.8 5.16992C19.6899 5.16992 18.79 6.06984 18.79 7.17993C18.79 8.29002 19.6899 9.18991 20.8 9.18991Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.7 19.4799C17.0421 19.4799 18.13 18.3919 18.13 17.0499C18.13 15.7078 17.0421 14.6199 15.7 14.6199C14.358 14.6199 13.27 15.7078 13.27 17.0499C13.27 18.3919 14.358 19.4799 15.7 19.4799Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.77 12.1999C26.742 12.1999 27.53 11.4119 27.53 10.4399C27.53 9.46789 26.742 8.67993 25.77 8.67993C24.798 8.67993 24.01 9.46789 24.01 10.4399C24.01 11.4119 24.798 12.1999 25.77 12.1999Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M21.25 7.59985H20.25V26.9199H21.25V7.59985Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.79 16.1798L14.16 9.54984L14.87 8.83984L20.79 14.7698L25.42 10.1398L26.12 10.8498L20.79 16.1798Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.75 23.0199L15.35 17.6099L16.05 16.8999L20.75 21.5999L24.95 17.4099L25.66 18.1099L20.75 23.0199Z",
          fill: "#E73C14",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.95001 36.2201V41.6801H3.24002V36.8101H0.700012V41.6801H0V36.2201H3.95001Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.06 39.9601L5.49002 41.6801H4.76001L6.62 36.2201H7.47L9.33002 41.6801H8.58002L7.99002 39.9601H6.06ZM7.85001 39.4101L7.31 37.8401C7.19 37.4801 7.11 37.1601 7.03 36.8401C6.95 37.1668 6.86001 37.5001 6.76001 37.8401L6.22 39.4201L7.85001 39.4101Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.13 36.33C10.5769 36.2632 11.0282 36.2298 11.48 36.23C12.0309 36.1884 12.5782 36.3483 13.02 36.68C13.1776 36.8257 13.3013 37.0042 13.3825 37.2029C13.4637 37.4015 13.5004 37.6156 13.49 37.83C13.502 38.0417 13.4709 38.2536 13.3987 38.453C13.3265 38.6523 13.2147 38.835 13.07 38.99C12.8469 39.198 12.5837 39.3584 12.2967 39.4615C12.0096 39.5647 11.7045 39.6084 11.4 39.59C11.214 39.612 11.026 39.612 10.84 39.59V41.78H10.13V36.33ZM10.84 38.97C11.03 39.0146 11.2249 39.0348 11.42 39.03C12.27 39.03 12.79 38.61 12.79 37.86C12.79 37.11 12.28 36.79 11.5 36.79C11.2783 36.7802 11.0563 36.8004 10.84 36.85V38.97Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.14 36.2201V38.6401H15.36L17.23 36.2201H18.08L16.01 38.7201C16.3775 38.7733 16.7204 38.9364 16.9934 39.1881C17.2665 39.4397 17.4571 39.7681 17.54 40.1301C17.75 40.6901 17.9 41.1901 18.14 41.6801H17.38C17.1851 41.2628 17.0213 40.8316 16.89 40.3901C16.827 40.0362 16.6365 39.7177 16.3546 39.4948C16.0727 39.2718 15.7189 39.1599 15.36 39.1801H15.14V41.6801H14.44V36.2201H15.14Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.2101 36V36.49C23.5274 36.4847 23.8422 36.546 24.1344 36.6699C24.4265 36.7938 24.6895 36.9776 24.9062 37.2094C25.1229 37.4412 25.2886 37.7159 25.3926 38.0157C25.4967 38.3155 25.5366 38.6338 25.51 38.95C25.5354 39.2658 25.494 39.5835 25.3887 39.8823C25.2833 40.1811 25.1163 40.4544 24.8985 40.6845C24.6806 40.9146 24.4169 41.0963 24.1243 41.2178C23.8317 41.3394 23.5168 41.398 23.2 41.39V41.9H22.5201V41.39C22.206 41.3993 21.8935 41.3425 21.6029 41.2232C21.3122 41.1039 21.05 40.9248 20.833 40.6975C20.6161 40.4702 20.4495 40.1998 20.3438 39.904C20.2382 39.6081 20.1961 39.2933 20.22 38.98C20.1981 38.6617 20.2418 38.3422 20.3485 38.0415C20.4551 37.7408 20.6223 37.4651 20.8398 37.2317C21.0573 36.9982 21.3205 36.8119 21.613 36.6843C21.9054 36.5568 22.221 36.4906 22.54 36.49V36H23.2101ZM22.53 37C22.2899 37.0147 22.0557 37.0799 21.8425 37.1914C21.6293 37.3028 21.442 37.458 21.2929 37.6468C21.1438 37.8355 21.0362 38.0536 20.9771 38.2868C20.918 38.52 20.9088 38.763 20.95 39C20.9224 39.2318 20.9416 39.4668 21.0067 39.691C21.0717 39.9152 21.1812 40.1241 21.3286 40.3051C21.476 40.4861 21.6584 40.6356 21.8648 40.7447C22.0712 40.8538 22.2974 40.9202 22.53 40.94V37ZM23.2 40.89C23.4396 40.8738 23.673 40.8075 23.8854 40.6956C24.0978 40.5837 24.2845 40.4286 24.4333 40.2402C24.5821 40.0518 24.6899 39.8343 24.7496 39.6018C24.8093 39.3693 24.8197 39.1268 24.78 38.89C24.78 37.49 23.99 36.99 23.2 36.95V40.89Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M27.08 36.2201V38.5201C27.08 39.3901 27.08 40.1001 27.02 40.8301C27.3 40.2601 27.64 39.6701 28.02 39.0201L29.82 36.2201H30.53V41.6801H29.82V39.3301C29.82 38.4401 29.82 37.7901 29.89 37.1101C29.5972 37.7446 29.2632 38.3592 28.89 38.9501L27.18 41.6601H26.43V36.2001L27.08 36.2201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.36 36.2201V41.6801H34.65V36.8101H32.82V38.4601C32.82 39.6501 32.82 40.9701 32 41.4601C31.752 41.6203 31.4651 41.7102 31.17 41.7201L31.08 41.1501C31.2923 41.1053 31.4889 41.0053 31.65 40.8601C32.0638 40.1434 32.2323 39.3113 32.13 38.4901V36.1901L35.36 36.2201Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.25 36.2201V38.5201C37.25 39.3901 37.25 40.1001 37.18 40.8301C37.4806 40.2092 37.8144 39.605 38.18 39.0201L39.95 36.2101H40.65V41.6701H39.9901V39.3301C39.9901 38.4401 39.99 37.7901 40.05 37.1101C39.7568 37.7444 39.4228 38.359 39.05 38.9501L37.34 41.6601H36.59V36.2001L37.25 36.2201Z",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }