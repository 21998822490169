var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip2)" } }, [
        _c("circle", {
          attrs: {
            cx: "20.89",
            cy: "16",
            r: "15.5",
            fill: "white",
            "fill-opacity": "0.01",
            stroke: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M23.17 25.34H18.34V19.34H23.17V25.34ZM19.34 24.34H22.17V20.34H19.34V24.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M30.62 25.34H10.89V15.4H12.17V16.4H11.89V24.34H29.62V16.4H29.49V15.4H30.62V25.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M29.38 25.34H12.13V11.91H15.04V12.91H13.13V24.34H28.38V12.91H26.62V11.91H29.38V25.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M27.42 25.34H14.08V11C14.08 9.4087 14.7121 7.88257 15.8373 6.75735C16.9625 5.63214 18.4887 5 20.08 5H21.47C23.0613 5 24.5874 5.63214 25.7126 6.75735C26.8378 7.88257 27.47 9.4087 27.47 11L27.42 25.34ZM15.08 24.34H26.42V11C26.42 9.67392 25.8932 8.40214 24.9555 7.46446C24.0178 6.52678 22.746 6 21.42 6H20.03C18.7039 6 17.4321 6.52678 16.4944 7.46446C15.5567 8.40214 15.03 9.67392 15.03 11L15.08 24.34Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M25.25 18.36H16.25V12.67C16.2526 11.4774 16.7276 10.3343 17.5709 9.49098C18.4143 8.64764 19.5573 8.17269 20.75 8.17004C21.9427 8.17269 23.0857 8.64764 23.929 9.49098C24.7724 10.3343 25.2474 11.4774 25.25 12.67V18.36ZM17.25 17.36H24.25V12.67C24.25 11.7418 23.8812 10.8515 23.2249 10.1952C22.5685 9.53879 21.6783 9.17004 20.75 9.17004C19.8217 9.17004 18.9315 9.53879 18.2751 10.1952C17.6187 10.8515 17.25 11.7418 17.25 12.67V17.36Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M25.0799 13.36H16.4399V14.36H25.0799V13.36Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M24.5799 11.0599H16.9399V12.0599H24.5799V11.0599Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M19.34 8.85999H18.34V17.86H19.34V8.85999Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M21.25 9.17993H20.25V17.8599H21.25V9.17993Z",
            fill: "#E73C14",
          },
        }),
        _c("path", {
          attrs: {
            d: "M23.17 8.85999H22.17V17.86H23.17V8.85999Z",
            fill: "#E73C14",
          },
        }),
      ]),
      _c("path", {
        attrs: {
          d: "M17.17 35.4127H16.05V35.0127H18.7501V35.4127H17.6501V38.7027H17.17V35.4127Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.1899 35.0727C19.4941 35.0235 19.8018 35 20.1099 35.0027C20.4682 34.9815 20.8225 35.0877 21.1099 35.3027C21.2187 35.4006 21.3046 35.5212 21.3616 35.656C21.4186 35.7908 21.4454 35.9364 21.4399 36.0827C21.4518 36.374 21.3475 36.6582 21.15 36.8727C20.9973 37.0106 20.8188 37.1169 20.6249 37.1855C20.4309 37.2542 20.2254 37.2838 20.02 37.2727C19.8938 37.2888 19.7661 37.2888 19.64 37.2727V38.7527H19.1599L19.1899 35.0727ZM19.6699 36.8527C19.8027 36.8701 19.9372 36.8701 20.0699 36.8527C20.6399 36.8527 20.9899 36.5727 20.9899 36.0627C20.9899 35.5527 20.6499 35.3427 20.1199 35.3427C19.9703 35.3278 19.8196 35.3278 19.6699 35.3427V36.8527Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.59 35.0127V38.3027H24.28V35.0127H24.7499V38.3127H25.0699V39.5827H24.6899V38.6927H22.1599V35.0027L22.59 35.0127Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.10004 45.6828L2.18005 47.1728H1.61005L0.540039 45.6828L1.61005 44.1927H2.18005L1.10004 45.6828ZM2.43005 45.6828L3.51007 47.1728H2.94006L1.87006 45.6828L2.94006 44.1927H3.51007L2.43005 45.6828Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.97997 44.9427C9.02538 45.3018 8.99388 45.6664 8.8875 46.0123C8.78112 46.3582 8.60234 46.6775 8.36302 46.949C8.12371 47.2205 7.82934 47.4379 7.4995 47.5868C7.16966 47.7357 6.8119 47.8128 6.45 47.8127C6.10106 47.8092 5.7567 47.7326 5.43913 47.588C5.12156 47.4434 4.83778 47.2338 4.60606 46.9729C4.37435 46.712 4.19984 46.4055 4.09374 46.073C3.98763 45.7406 3.95227 45.3896 3.98998 45.0427C3.9525 44.6843 3.98998 44.322 4.09996 43.9788C4.20995 43.6356 4.39005 43.3191 4.62889 43.0492C4.86773 42.7793 5.16008 42.562 5.48735 42.4112C5.81462 42.2603 6.16969 42.1791 6.53001 42.1727C6.87907 42.1746 7.2238 42.2501 7.54167 42.3943C7.85955 42.5385 8.14336 42.7482 8.37462 43.0096C8.60588 43.2711 8.77934 43.5784 8.88365 43.9116C8.98797 44.2447 9.02079 44.5961 8.97997 44.9427ZM4.74999 45.0727C4.74999 46.2427 5.38999 47.2927 6.49999 47.2927C7.60999 47.2927 8.24999 46.2927 8.24999 45.0227C8.24999 43.7527 7.67999 42.8027 6.49999 42.8027C5.31999 42.8027 4.74999 43.8227 4.74999 45.0727Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.59 42.2627V44.6827H10.81L12.68 42.2627H13.53L11.47 44.7727C12.37 44.8927 12.71 45.4427 13 46.1727C13.1645 46.7024 13.365 47.2203 13.6 47.7227H12.83C12.6394 47.3036 12.4757 46.8727 12.34 46.4327C12.2772 46.0806 12.0882 45.7635 11.8084 45.5408C11.5286 45.3181 11.1772 45.205 10.82 45.2227H10.59V47.7227H9.89001V42.2627H10.59Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.11 45.1627H14.99V47.1627H17.35V47.7527H14.28V42.2927H17.28V42.8827H15.04V44.6127H17.16L17.11 45.1627Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.15 46.0027L18.58 47.7227H17.85L19.71 42.2627H20.56L22.42 47.7227H21.67L21.09 46.0027H19.15ZM20.94 45.4527L20.41 43.8827C20.29 43.5327 20.21 43.2027 20.12 42.8827C20.04 43.2027 19.95 43.5327 19.84 43.8827L19.31 45.4627L20.94 45.4527Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M23.93 42.2627V44.5527H26.57V42.2627H27.28V47.7227H26.57V45.1627H23.93V47.7227H23.22V42.2627H23.93Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.17 42.2627V44.5627C29.17 45.4327 29.17 46.1427 29.1 46.8727C29.38 46.3027 29.72 45.7127 30.1 45.0627L31.87 42.2527H32.57V47.7127H31.91V45.3927C31.91 44.5027 31.91 43.8627 31.97 43.1727C31.6732 43.8052 31.3393 44.4196 30.97 45.0127L29.26 47.7227H28.5V42.2627H29.17Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.3401 47.7227C33.425 47.5648 33.4985 47.401 33.5601 47.2327C33.707 46.5736 33.986 45.951 34.3801 45.4027C34.5095 45.2748 34.6671 45.179 34.8401 45.1227C34.5077 45.0858 34.2005 44.9277 33.9773 44.6787C33.7541 44.4297 33.6305 44.1071 33.6301 43.7727C33.6252 43.562 33.6676 43.353 33.7541 43.1609C33.8406 42.9688 33.9691 42.7986 34.1301 42.6627C34.5933 42.3545 35.1449 42.2069 35.7001 42.2427C36.1358 42.2398 36.571 42.2766 37.0001 42.3527V47.7427H36.3V45.3827H35.7501C35.6077 45.3711 35.4645 45.3881 35.3288 45.4328C35.1931 45.4774 35.0677 45.5488 34.9601 45.6427C34.6264 46.1649 34.3829 46.7396 34.2401 47.3427C34.1884 47.4796 34.1283 47.6132 34.0601 47.7427L33.3401 47.7227ZM36.3401 42.8327C36.1202 42.7837 35.8953 42.7602 35.67 42.7627C34.98 42.7627 34.3801 43.0327 34.3801 43.7627C34.3801 44.4927 34.9701 44.7627 35.7401 44.7627H36.3401V42.8327Z",
          fill: "black",
        },
      }),
      _c("path", {
        attrs: {
          d: "M37.8 44.1927H38.3801L39.4501 45.6828L38.3801 47.1728H37.8101L38.8901 45.6828L37.8 44.1927ZM39.1201 44.1927H39.7501L40.8301 45.6828L39.7501 47.1728H39.1801L40.2601 45.6828L39.1201 44.1927Z",
          fill: "black",
        },
      }),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip2" } }, [
          _c("rect", {
            attrs: {
              width: "32",
              height: "32",
              fill: "white",
              transform: "translate(4.89001)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }