<template>
  <svg
    class="map"
    width="1415"
    height="620"
    viewBox="0 0 1415 620"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip112)">
      <path d="M1455.99 34.783H40.4951V665.933H1455.99V34.783Z" fill="white" />
      <path
        d="M661.86 360.108L660.848 365.005L778.471 389.321L779.483 384.424L661.86 360.108Z"
        fill="#DEDEDE"
      />
      <path
        d="M295.91 722.37L224.205 561.493L224.095 561.063L38.3301 -216.32L49.0001 -219L234.655 557.943L306 718L295.91 722.37Z"
        fill="#DEDEDE"
      />
      <path
        d="M379.06 728.958L356.155 517.101V516.891L353.125 320.253L521.655 137.463L586.615 157.163L666.805 121.523L690.975 80.5831L726.635 48.6431L808.88 -87.66L816 -84L733.075 53.6131L697.235 85.7131L672.395 127.793L587.135 165.683L524.135 146.573L361.175 323.323L364.145 516.561L387 727.998L379.06 728.958Z"
        fill="#DEDEDE"
      />
      <path
        d="M550.835 370.523L539.985 328.293L253.185 320.193L174.045 339.513L172.865 334.653L252.655 315.173L252.985 315.183L543.895 323.403L554.555 364.923L614.915 356.083L641.075 348.033L846.155 241.933L848.445 246.373L642.975 352.683L642.755 352.753L615.835 361.003L550.835 370.523Z"
        fill="#DEDEDE"
      />
      <path
        d="M623.665 373.143L621.575 368.603L649.015 355.933L661.385 359.973L868.715 284.763L1066.3 171.853L1026.02 114.863L1030.1 111.983L1073.57 173.463L870.625 289.393L661.475 365.263L649.325 361.293L623.665 373.143Z"
        fill="#DEDEDE"
      />
      <path
        d="M549.965 151.593L546.635 147.863L577.265 120.553L677.285 67.473L697.155 47.613L751.5 -61.1799L756 -58.9999L701.285 50.553L680.295 71.5429L580.145 124.683L549.965 151.593Z"
        fill="#DEDEDE"
      />
      <path
        d="M623.895 98.023L615.305 66.8031L597.155 50.803L669 -74L673.62 -72.09L603.145 49.4131L619.725 64.0431L628.715 96.6931L623.895 98.023Z"
        fill="#DEDEDE"
      />
      <path
        d="M704.127 -46.1621L615.595 63.8694L619.468 67.0316L708 -42.9999L704.127 -46.1621Z"
        fill="#DEDEDE"
      />
      <path
        d="M626.112 409.308L358.495 419.495L358.686 424.491L626.302 414.304L626.112 409.308Z"
        fill="#DEDEDE"
      />
      <path
        d="M243.325 463.333L201.485 453.333L202.655 448.473L242.465 457.983L356.875 387.043L359.505 391.293L243.325 463.333Z"
        fill="#DEDEDE"
      />
      <path
        d="M1468 550L1336.98 457.012L1336.58 456.382L1200.35 238.213L1200.25 237.453L1181.77 97.0631L1181.99 96.3331L1250.3 -119.05L1257 -117L1188.91 97.6531L1207.09 235.793L1342.13 452.042L1471.92 544.2L1468 550Z"
        fill="#DEDEDE"
      />
      <path
        d="M1220.56 181.233H1219.95C1203.74 180.673 1194.85 178.083 1191.95 173.083C1191.4 172.175 1191.08 171.144 1191.03 170.082C1190.97 169.021 1191.18 167.963 1191.64 167.003L1197.82 170.293C1198.04 169.873 1198.04 169.653 1197.99 169.543C1197.99 169.543 1200.17 173.543 1220.18 174.233H1220.59C1233.51 174.233 1261.33 153.853 1288.24 134.133C1322.69 108.893 1358.31 82.7931 1384.06 79.6331C1404.59 77.1131 1462.06 70.153 1462.57 70.093L1463.39 77.093C1462.88 77.153 1405.39 84.0931 1384.91 86.6231C1360.97 89.5631 1324.53 116.263 1292.38 139.813C1262.06 162.043 1235.81 181.233 1220.56 181.233Z"
        fill="#DEDEDE"
      />
      <path
        d="M609.065 323.533L605.535 319.993L616.475 309.053H645.495L1135.95 51.5629L1217.92 67.9629L1730 -69L1731.39 -64.2L1218.14 73.1029L1136.71 56.8129L646.725 314.053H618.545L609.065 323.533Z"
        fill="#DEDEDE"
      />
      <path
        d="M833.261 213.314L827.023 216.49L1069.76 691.676L1076 688.5L833.261 213.314Z"
        fill="#DEDEDE"
      />
      <path
        d="M944.5 695L939.2 690.43L1021.66 593.423L1021.9 593.223L1289.64 375.423L1294.07 380.853L1026.55 598.463L944.5 695Z"
        fill="#DEDEDE"
      />
      <path
        d="M1380.77 590.233C1347.56 590.233 1319.59 581.873 1298.77 565.553C1268.94 542.123 1253.35 515.383 1252.43 486.073C1250.78 433.433 1296.65 391.883 1298.6 390.143L1303.27 395.363C1302.81 395.763 1257.85 436.543 1259.42 485.893C1260.29 513.013 1274.98 537.963 1303.1 560.053C1335.01 585.113 1378.78 585.383 1409.88 581.183C1430.76 578.327 1451.25 573.131 1470.97 565.693L1473.6 572.173C1453.4 579.865 1432.39 585.21 1410.97 588.103C1400.96 589.488 1390.87 590.199 1380.77 590.233Z"
        fill="#DEDEDE"
      />
      <path
        d="M1468.5 206.5L1406.41 186.343L1334.72 181.263L1335.21 174.283L1407.52 179.403L1470.2 199.71L1468.5 206.5Z"
        fill="#DEDEDE"
      />
      <path
        d="M725.46 730.51L695.295 620.563L714.155 592.293L756.195 607.003L783.445 593.843L799.285 563.173L793.635 519.173L822.355 468.923V378.133H829.355V470.783L800.875 520.613L806.505 564.443L788.595 599.133L756.625 614.573L716.965 600.693L703.135 621.433L732 728L725.46 730.51Z"
        fill="#DEDEDE"
      />
      <path
        d="M822.7 729.52L819 728L858.015 612.763L794.145 580.823L795.935 577.243L863.135 610.853L822.7 729.52Z"
        fill="#DEDEDE"
      />
      <path
        d="M863 697.5L860 694.81C860.31 694.48 920.195 611.513 928.145 592.643C932.585 582.103 933.635 561.163 934.555 542.703C935.315 527.493 935.975 514.373 938.325 508.153C943.445 494.643 958.115 470.893 958.745 469.893L962.145 471.993C961.995 472.243 947.045 496.423 942.065 509.563C939.935 515.193 939.265 528.653 938.545 542.903C937.605 561.693 936.545 583.003 931.825 594.203C923.655 613.693 864.26 696.11 863 697.5Z"
        fill="#DEDEDE"
      />
      <path
        d="M406 713L402.46 711.14L469.935 569.583L504.105 546.093L535.495 565.973L614.365 577.173L627.435 595.643L672.925 601.653L672.405 605.623L625.175 599.373L612.095 580.893L534.085 569.813L504.195 550.883L473.015 572.323L406 713Z"
        fill="#DEDEDE"
      />
      <path
        d="M563.479 470.488L559.497 470.87L569.274 572.973L573.256 572.591L563.479 470.488Z"
        fill="#DEDEDE"
      />
      <path
        d="M1468.32 95.1532L1200.17 228.703L894.165 338.133L641.325 435.413L619.585 345.783L619.395 345.023L594.165 301.233L467.045 203.503L119.21 -98.59L112 -90.2801L459.955 211.883L460.085 211.993L585.735 308.583L609.105 349.103L630.895 438.943L227.055 551.003L226.575 551.153C223.405 552.223 148.455 577.603 111.575 596.053C75.0349 614.323 1.62501 655.593 0.88501 656.003L9.71509 671.693C10.445 671.283 83.475 630.233 119.625 612.153C154.425 594.753 227.425 569.873 232.115 568.283L635.165 456.463L650.045 516.293L688.895 663.193L699.535 660.373L660.705 513.553L645.645 453.033L900.315 355.033L1206.76 245.463L1207.27 245.283L1476.34 111.283L1468.32 95.1532Z"
        fill="white"
      />
      <path
        d="M9.52515 672.393L9.23511 671.983L0.165039 655.853L0.60498 655.613C1.33498 655.203 74.8849 613.863 111.305 595.613C148.305 577.133 223.215 551.753 226.385 550.683L226.875 550.533L630.265 438.633L608.605 349.333L585.315 308.963L459.745 212.433L118.14 -85.2199L126 -94.28L126.38 -93.95L467.345 203.173L594.525 300.943V301.033L619.805 344.873L620.015 345.703L641.615 434.783L893.925 337.703L1200.03 228.233L1468.54 94.483L1477.01 111.483L1476.56 111.703L1207.48 245.703L1206.93 245.903L900.475 355.473L646.245 453.303L661.165 513.433L710.6 706.93L699 710L649.565 516.413L634.805 457.083L232.215 568.803C229.095 569.863 154.825 595.133 119.805 612.643C84.4349 630.323 12.985 670.443 9.95508 672.143L9.52515 672.393ZM1.52515 656.233L9.86499 671.053C16.605 667.273 84.9849 628.933 119.355 611.743C154.445 594.203 228.765 568.923 231.915 567.853L635.525 455.853L650.525 516.163L699.68 708.78L709.35 706.22L660.205 513.683L645.045 452.683L645.455 452.523L900.125 354.523L1207.12 244.773L1475.7 110.993L1468.11 95.7731L1200.45 229.103L894.355 338.553L640.995 436.033L618.995 345.153L593.825 301.523L466.825 203.853L126.1 -92.89L119.55 -85.3399L460.385 211.633L586.085 308.273V308.353L609.505 349.003L631.415 439.313L630.945 439.443L227.165 551.533L226.705 551.673C223.545 552.733 148.705 578.093 111.765 596.543C76.8151 614.013 7.68515 652.773 1.52515 656.233Z"
        fill="#F62700"
      />
      <path
        d="M746.015 401.563L747.295 404.913L747.605 404.793L748.895 400.453L750.065 400.003L748.535 404.563C749.071 404.431 749.636 404.469 750.15 404.672C750.664 404.874 751.102 405.231 751.405 405.693C751.995 406.353 752.475 406.963 753.055 407.513L752.055 407.913C751.56 407.443 751.099 406.938 750.675 406.403C750.406 405.945 749.978 405.603 749.473 405.44C748.968 405.278 748.42 405.307 747.935 405.523L747.625 405.643L748.955 409.103L747.955 409.483L745.055 401.933L746.015 401.563Z"
        fill="black"
      />
      <path
        d="M751.565 399.413L754.305 402.073C754.665 402.44 754.998 402.81 755.305 403.183C755.305 402.753 755.305 402.253 755.245 401.653L755.195 397.993L756.195 397.593L756.105 402.103C756.143 403.245 756.039 404.387 755.795 405.503C755.714 405.856 755.548 406.183 755.313 406.458C755.078 406.733 754.78 406.948 754.445 407.083C754.264 407.162 754.072 407.213 753.875 407.233L753.655 406.403C753.788 406.376 753.918 406.339 754.045 406.293C754.815 405.993 754.985 405.073 755.045 404.433C755.052 404.348 755.037 404.262 755 404.184C754.963 404.107 754.906 404.041 754.835 403.993L750.365 399.853L751.565 399.413Z"
        fill="black"
      />
      <path
        d="M759.375 397.373L757.085 398.253L756.765 397.423L762.355 395.283L762.675 396.113L760.365 396.993L762.945 403.713L761.945 404.093L759.375 397.373Z"
        fill="black"
      />
      <path
        d="M763.925 394.673L766.665 397.333C767.025 397.713 767.375 398.073 767.715 398.453C767.715 398.023 767.715 397.533 767.655 396.923V393.263L768.655 392.873L768.565 397.383C768.603 398.521 768.499 399.66 768.255 400.773C768.174 401.127 768.009 401.456 767.774 401.733C767.539 402.01 767.241 402.226 766.905 402.363C766.725 402.445 766.532 402.492 766.335 402.503L766.115 401.673C766.25 401.656 766.381 401.619 766.505 401.563C767.275 401.263 767.445 400.343 767.505 399.713C767.512 399.626 767.497 399.539 767.46 399.46C767.423 399.381 767.366 399.313 767.295 399.263L762.825 395.123L763.925 394.673Z"
        fill="black"
      />
      <path
        d="M769.475 393.043C769.922 392.43 770.542 391.963 771.255 391.703C772.565 391.203 773.735 391.533 774.175 392.703C774.336 393.144 774.34 393.628 774.187 394.072C774.034 394.516 773.733 394.894 773.335 395.143C773.582 395.052 773.844 395.011 774.107 395.021C774.37 395.032 774.628 395.094 774.867 395.205C775.106 395.315 775.32 395.472 775.498 395.665C775.676 395.859 775.815 396.086 775.905 396.333C776.505 397.893 775.385 399.053 774.005 399.573C773.322 399.856 772.576 399.949 771.845 399.843V398.983C772.475 399.066 773.116 398.997 773.715 398.783C774.825 398.363 775.215 397.523 774.895 396.693C774.455 395.563 773.245 395.693 772.285 396.023L771.835 396.193L771.555 395.453L771.985 395.293C772.425 395.18 772.802 394.898 773.036 394.509C773.27 394.12 773.341 393.654 773.235 393.213C773.17 393.041 773.069 392.884 772.939 392.755C772.808 392.625 772.651 392.524 772.479 392.461C772.306 392.397 772.121 392.372 771.938 392.386C771.754 392.4 771.576 392.454 771.415 392.543C770.862 392.775 770.385 393.156 770.035 393.643L769.475 393.043Z"
        fill="black"
      />
      <path
        d="M782.895 391.623C783.895 394.213 782.895 396.203 780.895 396.943C778.895 397.683 776.895 396.693 776.025 394.413C775.155 392.133 776.025 389.883 778.025 389.103C780.025 388.323 782.025 389.373 782.895 391.623ZM777.065 393.993C777.695 395.613 779.115 396.723 780.665 396.133C782.215 395.543 782.535 393.773 781.875 392.063C781.295 390.553 779.875 389.283 778.285 389.913C776.695 390.543 776.435 392.343 777.065 393.993Z"
        fill="black"
      />
      <path
        d="M782.785 387.553C783.324 387.212 783.897 386.927 784.495 386.703C785.197 386.349 786.004 386.263 786.765 386.463C787.031 386.534 787.275 386.67 787.476 386.859C787.676 387.047 787.827 387.282 787.915 387.543C788.05 387.923 788.061 388.336 787.946 388.723C787.831 389.109 787.596 389.449 787.275 389.693C787.745 389.612 788.23 389.696 788.645 389.932C789.061 390.167 789.382 390.538 789.555 390.983C789.669 391.265 789.722 391.567 789.71 391.871C789.697 392.174 789.621 392.471 789.485 392.743C788.947 393.58 788.116 394.186 787.155 394.443C786.654 394.641 786.139 394.805 785.615 394.933L782.785 387.553ZM784.945 390.273L785.825 389.933C786.825 389.543 787.245 388.753 786.965 388.033C786.625 387.153 785.825 387.033 784.845 387.433C784.55 387.532 784.268 387.667 784.005 387.833L784.945 390.273ZM786.335 393.873C786.619 393.808 786.897 393.718 787.165 393.603C788.165 393.223 788.955 392.493 788.535 391.393C788.115 390.293 787.095 390.283 786.025 390.693L785.225 390.993L786.335 393.873Z"
        fill="black"
      />
      <path
        d="M796.795 390.433C796.261 390.934 795.628 391.316 794.935 391.553C794.441 391.778 793.904 391.894 793.361 391.892C792.818 391.891 792.282 391.772 791.789 391.544C791.296 391.316 790.859 390.985 790.506 390.572C790.153 390.159 789.893 389.675 789.745 389.153C789.509 388.638 789.384 388.079 789.38 387.513C789.376 386.947 789.492 386.386 789.721 385.868C789.95 385.35 790.286 384.886 790.707 384.508C791.129 384.13 791.626 383.845 792.165 383.673C792.772 383.419 793.427 383.3 794.085 383.323L794.145 384.213C793.589 384.18 793.031 384.273 792.515 384.483C792.099 384.616 791.717 384.838 791.395 385.134C791.074 385.43 790.821 385.793 790.655 386.196C790.488 386.6 790.412 387.035 790.431 387.472C790.45 387.908 790.564 388.335 790.765 388.723C790.874 389.137 791.072 389.522 791.345 389.852C791.618 390.182 791.96 390.448 792.347 390.632C792.733 390.815 793.155 390.913 793.583 390.916C794.012 390.92 794.435 390.83 794.825 390.653C795.376 390.445 795.879 390.129 796.305 389.723L796.795 390.433Z"
        fill="black"
      />
      <path
        d="M796.195 382.243L797.475 385.593L797.785 385.473L799.075 381.133L800.245 380.683L798.715 385.243C799.251 385.111 799.816 385.149 800.33 385.352C800.844 385.554 801.282 385.911 801.585 386.373C802.175 387.033 802.655 387.643 803.235 388.193L802.235 388.593C801.74 388.123 801.279 387.619 800.855 387.083C800.586 386.625 800.158 386.283 799.653 386.12C799.148 385.958 798.6 385.987 798.115 386.203L797.805 386.323L799.135 389.783L798.135 390.163L795.235 382.613L796.195 382.243Z"
        fill="black"
      />
      <path
        d="M802.205 379.963L803.425 383.133C803.885 384.323 804.245 385.333 804.565 386.363C804.653 385.356 804.797 384.354 804.995 383.363L805.935 378.543L806.935 378.163L809.835 385.713L808.915 386.063L807.675 382.853C807.215 381.633 806.875 380.733 806.595 379.743C806.522 380.772 806.378 381.794 806.165 382.803L805.255 387.473L804.205 387.873L801.305 380.333L802.205 379.963Z"
        fill="black"
      />
      <path
        d="M809.565 377.123L810.785 380.303C811.245 381.493 811.605 382.493 811.925 383.533C812.013 382.526 812.156 381.524 812.355 380.533L813.295 375.703L814.295 375.333L817.195 382.873L816.275 383.233L815.035 380.013C814.575 378.793 814.235 377.893 813.955 376.913C813.882 377.945 813.738 378.971 813.525 379.983L812.615 384.653L811.565 385.053L808.665 377.513L809.565 377.123ZM810.195 375.073C810.365 375.403 810.615 375.703 811.135 375.503C811.655 375.303 811.655 374.933 811.555 374.503L812.305 374.213C812.371 374.395 812.396 374.588 812.38 374.781C812.364 374.973 812.306 375.16 812.211 375.328C812.117 375.496 811.986 375.641 811.83 375.755C811.673 375.868 811.494 375.946 811.305 375.983C811.14 376.075 810.957 376.13 810.769 376.145C810.581 376.161 810.392 376.136 810.214 376.073C810.036 376.009 809.874 375.909 809.738 375.778C809.602 375.647 809.495 375.488 809.425 375.313L810.195 375.073Z"
        fill="black"
      />
      <path
        d="M823.865 371.633L826.765 379.173L825.765 379.553L823.175 372.833L819.675 374.173L822.255 380.903L821.255 381.283L818.355 373.733L823.865 371.633Z"
        fill="black"
      />
      <path
        d="M825.565 371.073C826.143 370.733 826.748 370.442 827.375 370.203C828.113 369.856 828.953 369.792 829.735 370.023C830.029 370.139 830.294 370.318 830.513 370.545C830.732 370.773 830.9 371.045 831.005 371.343C831.126 371.635 831.188 371.947 831.188 372.263C831.188 372.579 831.126 372.891 831.005 373.183C830.806 373.589 830.526 373.951 830.182 374.245C829.838 374.539 829.437 374.759 829.005 374.893C828.745 375.003 828.473 375.083 828.195 375.133L829.355 378.133L828.355 378.503L825.565 371.073ZM827.935 374.333C828.225 374.297 828.507 374.219 828.775 374.103C829.945 373.653 830.445 372.793 830.045 371.773C829.645 370.753 828.765 370.563 827.695 370.973C827.383 371.082 827.084 371.226 826.805 371.403L827.935 374.333Z"
        fill="black"
      />
      <path
        d="M839.295 369.943C840.295 372.523 839.245 374.523 837.295 375.263C835.345 376.003 833.295 375.013 832.425 372.723C831.555 370.433 832.425 368.193 834.425 367.423C836.425 366.653 838.435 367.693 839.295 369.943ZM833.475 372.303C834.095 373.933 835.525 375.033 837.075 374.443C838.625 373.853 838.945 372.093 838.285 370.383C837.705 368.873 836.285 367.603 834.695 368.223C833.105 368.843 832.845 370.663 833.475 372.303Z"
        fill="black"
      />
      <path
        d="M847.215 371.043C846.688 371.55 846.057 371.936 845.365 372.173C844.871 372.396 844.334 372.511 843.792 372.509C843.249 372.506 842.714 372.387 842.221 372.16C841.729 371.932 841.291 371.601 840.938 371.189C840.585 370.777 840.325 370.294 840.175 369.773C839.939 369.258 839.815 368.699 839.81 368.133C839.806 367.567 839.922 367.006 840.151 366.488C840.38 365.97 840.716 365.506 841.137 365.128C841.559 364.75 842.056 364.465 842.595 364.293C843.199 364.029 843.857 363.909 844.515 363.943L844.575 364.833C844.019 364.79 843.46 364.88 842.945 365.093C842.527 365.226 842.144 365.448 841.822 365.745C841.5 366.041 841.246 366.405 841.079 366.81C840.912 367.215 840.836 367.652 840.856 368.089C840.876 368.527 840.992 368.955 841.195 369.343C841.304 369.75 841.499 370.13 841.768 370.454C842.037 370.779 842.373 371.041 842.753 371.224C843.133 371.406 843.548 371.504 843.97 371.511C844.391 371.518 844.809 371.433 845.195 371.263C845.749 371.062 846.254 370.745 846.675 370.333L847.215 371.043Z"
        fill="black"
      />
      <path
        d="M851.105 361.163L854.005 368.703L853.005 369.083L850.415 362.353L846.915 363.703L849.495 370.433L848.495 370.813L845.595 363.263L851.105 361.163Z"
        fill="black"
      />
      <path
        d="M858.235 363.023L855.315 364.143L856.365 366.863L859.625 365.603L859.945 366.423L855.695 368.063L852.785 360.513L856.875 358.943L857.185 359.763L854.085 360.953L855.005 363.333L857.925 362.213L858.235 363.023Z"
        fill="black"
      />
      <path
        d="M859.285 358.013L860.575 361.363L860.875 361.243L862.175 356.903L863.335 356.463L861.815 361.013C862.351 360.883 862.914 360.923 863.427 361.127C863.939 361.332 864.375 361.69 864.675 362.153C865.265 362.813 865.745 363.423 866.325 363.973L865.325 364.373C864.827 363.902 864.366 363.394 863.945 362.853C863.673 362.399 863.244 362.06 862.74 361.899C862.235 361.739 861.689 361.769 861.205 361.983L860.895 362.103L862.225 365.563L861.225 365.933L858.325 358.393L859.285 358.013Z"
        fill="black"
      />
      <path
        d="M866.455 356.243L864.165 357.123L863.845 356.293L869.435 354.143L869.745 354.973L867.445 355.863L870.025 362.573L869.025 362.953L866.455 356.243Z"
        fill="black"
      />
      <path
        d="M587.835 299.303C588.295 299.548 588.734 299.832 589.145 300.153C589.676 300.5 590.06 301.03 590.225 301.643C590.269 301.885 590.259 302.134 590.197 302.373C590.135 302.611 590.021 302.833 589.865 303.023C589.724 303.225 589.545 303.397 589.337 303.529C589.129 303.662 588.898 303.751 588.655 303.793C588.307 303.825 587.955 303.787 587.622 303.68C587.289 303.574 586.98 303.401 586.715 303.173C586.534 303.044 586.369 302.893 586.225 302.723L584.695 304.723L584.045 304.233L587.835 299.303ZM586.635 302.203C586.779 302.376 586.944 302.53 587.125 302.663C587.905 303.263 588.675 303.243 589.185 302.563C589.695 301.883 589.475 301.223 588.775 300.673C588.573 300.515 588.355 300.377 588.125 300.263L586.635 302.203Z"
        fill="black"
      />
      <path
        d="M593.215 303.693L591.495 306.123C591.255 306.443 591.015 306.753 590.765 307.063L591.935 306.833L594.745 306.353L595.165 307.093L591.695 307.573C590.822 307.739 589.932 307.796 589.045 307.743C588.764 307.726 588.491 307.64 588.251 307.494C588.01 307.348 587.809 307.145 587.665 306.903C587.578 306.776 587.517 306.633 587.485 306.483L588.095 306.213C588.127 306.311 588.171 306.405 588.225 306.493C588.405 306.718 588.645 306.888 588.918 306.982C589.191 307.076 589.485 307.09 589.765 307.023C589.834 307.02 589.9 306.997 589.956 306.958C590.013 306.92 590.057 306.866 590.085 306.803L592.725 302.863L593.215 303.693Z"
        fill="black"
      />
      <path
        d="M597.295 310.773L596.705 311.113L595.345 308.753L593.885 309.603C594.005 309.753 594.205 310.073 594.315 310.253C594.611 310.737 594.764 311.295 594.755 311.863C594.746 312.167 594.656 312.464 594.494 312.723C594.332 312.981 594.105 313.192 593.835 313.333C593.4 313.597 592.88 313.679 592.385 313.563C591.633 313.273 591.018 312.708 590.665 311.983C590.451 311.619 590.261 311.242 590.095 310.853L595.535 307.713L597.295 310.773ZM591.025 311.253C591.104 311.466 591.205 311.67 591.325 311.863C591.556 312.296 591.947 312.623 592.415 312.773C592.585 312.814 592.76 312.82 592.932 312.79C593.104 312.761 593.268 312.698 593.415 312.603C593.593 312.512 593.744 312.377 593.855 312.211C593.965 312.044 594.031 311.852 594.045 311.653C594.062 311.23 593.951 310.811 593.725 310.453C593.635 310.303 593.495 310.083 593.375 309.913L591.025 311.253Z"
        fill="black"
      />
      <path
        d="M600.165 315.733L594.715 318.883L594.295 318.173L599.165 315.363L598.165 313.573L596.515 314.523C595.335 315.203 593.985 315.913 593.025 315.443C592.717 315.289 592.46 315.049 592.285 314.753L592.795 314.343C592.957 314.536 593.173 314.675 593.415 314.743C594.095 314.943 595.185 314.363 596.055 313.863L598.355 312.533L600.165 315.733Z"
        fill="black"
      />
      <path
        d="M599.615 321.473L598.395 319.363L596.395 320.493L597.765 322.863L597.165 323.203L595.395 320.123L600.845 316.983L602.555 319.933L601.965 320.273L600.665 318.033L598.945 319.033L600.165 321.143L599.615 321.473Z"
        fill="black"
      />
      <path
        d="M603.085 320.993C603.413 321.369 603.701 321.778 603.945 322.213C604.301 322.708 604.468 323.315 604.415 323.923C604.386 324.133 604.307 324.334 604.186 324.507C604.064 324.681 603.903 324.824 603.715 324.923C603.439 325.071 603.123 325.129 602.813 325.088C602.502 325.046 602.213 324.908 601.985 324.693C602.105 325.044 602.1 325.426 601.971 325.775C601.841 326.123 601.596 326.416 601.275 326.603C601.072 326.727 600.844 326.806 600.608 326.833C600.371 326.861 600.131 326.837 599.905 326.763C599.198 326.451 598.632 325.888 598.315 325.183C598.099 324.821 597.912 324.444 597.755 324.053L603.085 320.993ZM598.655 324.493C598.74 324.705 598.844 324.909 598.965 325.103C599.385 325.823 600.035 326.343 600.835 325.883C601.635 325.423 601.515 324.643 601.065 323.883L600.735 323.303L598.655 324.493ZM601.255 322.983L601.625 323.623C602.055 324.363 602.705 324.623 603.225 324.263C603.745 323.903 603.845 323.263 603.435 322.563C603.32 322.352 603.183 322.154 603.025 321.973L601.255 322.983Z"
        fill="black"
      />
      <path
        d="M602.565 332.113C602.114 331.765 601.743 331.326 601.475 330.823C601.253 330.467 601.108 330.069 601.049 329.654C600.991 329.238 601.02 328.815 601.135 328.412C601.25 328.009 601.448 327.634 601.717 327.312C601.986 326.99 602.319 326.728 602.695 326.543C603.063 326.301 603.477 326.139 603.911 326.068C604.346 325.997 604.79 326.018 605.216 326.13C605.642 326.242 606.039 326.443 606.382 326.719C606.724 326.996 607.005 327.341 607.205 327.733C607.474 328.171 607.644 328.662 607.705 329.173L607.025 329.323C606.985 328.891 606.848 328.474 606.625 328.103C606.472 327.799 606.254 327.532 605.987 327.32C605.72 327.109 605.409 326.958 605.078 326.879C604.746 326.8 604.402 326.794 604.068 326.862C603.734 326.93 603.419 327.071 603.145 327.273C602.839 327.405 602.566 327.602 602.344 327.851C602.123 328.1 601.958 328.395 601.863 328.714C601.768 329.034 601.744 329.37 601.792 329.7C601.841 330.03 601.961 330.345 602.145 330.623C602.37 331.014 602.673 331.354 603.035 331.623L602.565 332.113Z"
        fill="black"
      />
      <path
        d="M608.775 330.673L606.365 332.073L606.485 332.293L609.985 332.763L610.465 333.603L606.775 332.983C606.943 333.379 606.984 333.817 606.89 334.236C606.797 334.656 606.575 335.036 606.255 335.323C605.815 335.863 605.405 336.323 605.055 336.813L604.625 336.063C604.928 335.626 605.262 335.212 605.625 334.823C605.943 334.561 606.153 334.19 606.216 333.783C606.279 333.375 606.189 332.959 605.965 332.613L605.835 332.383L603.335 333.823L602.935 333.123L608.385 329.973L608.775 330.673Z"
        fill="black"
      />
      <path
        d="M610.835 340.403C608.965 341.483 607.305 340.923 606.505 339.533C605.705 338.143 606.215 336.433 607.865 335.483C609.515 334.533 611.335 334.893 612.185 336.353C613.035 337.813 612.455 339.463 610.835 340.403ZM608.305 336.213C607.135 336.893 606.455 338.123 607.095 339.213C607.735 340.303 609.095 340.363 610.375 339.653C611.465 339.023 612.265 337.803 611.595 336.653C610.925 335.503 609.495 335.523 608.305 336.233V336.213Z"
        fill="black"
      />
      <path
        d="M612.765 344.233L611.545 342.123L609.545 343.253L610.915 345.613L610.325 345.963L608.545 342.883L613.995 339.743L615.705 342.693L615.115 343.033L613.815 340.793L612.095 341.793L613.315 343.903L612.765 344.233Z"
        fill="black"
      />
      <path
        d="M617.805 347.573L612.355 348.893L612.835 350.893L618.295 349.573L618.475 350.353L613.025 351.673L613.505 353.673L618.965 352.353L619.145 353.133L613.035 354.613L611.505 348.293L617.625 346.813L617.805 347.573Z"
        fill="black"
      />
      <path
        d="M617.755 360.443C615.665 360.953 614.225 359.943 613.845 358.383C613.757 357.99 613.752 357.583 613.829 357.188C613.907 356.793 614.066 356.418 614.297 356.088C614.527 355.758 614.824 355.479 615.168 355.27C615.512 355.061 615.896 354.926 616.295 354.873C618.235 354.403 619.795 355.293 620.195 356.933C620.595 358.573 619.585 360.003 617.755 360.443ZM616.515 355.703C615.195 356.023 614.195 357.023 614.515 358.273C614.835 359.523 616.145 359.953 617.515 359.613C618.745 359.323 619.865 358.373 619.515 357.063C619.165 355.753 617.855 355.383 616.515 355.703Z"
        fill="black"
      />
      <path
        d="M616.165 366.453C615.824 365.993 615.588 365.464 615.475 364.903C615.353 364.499 615.319 364.073 615.376 363.655C615.433 363.237 615.579 362.835 615.805 362.478C616.03 362.122 616.33 361.818 616.684 361.587C617.038 361.357 617.437 361.205 617.855 361.143C618.277 361.013 618.722 360.974 619.16 361.027C619.598 361.081 620.02 361.227 620.398 361.456C620.775 361.685 621.101 361.991 621.352 362.354C621.603 362.717 621.774 363.129 621.855 363.563C621.995 364.057 622.022 364.577 621.935 365.083H621.235C621.333 364.659 621.333 364.217 621.235 363.793C621.173 363.459 621.038 363.143 620.841 362.866C620.645 362.589 620.39 362.359 620.095 362.19C619.8 362.022 619.472 361.919 619.133 361.891C618.795 361.862 618.454 361.907 618.135 362.023C617.804 362.065 617.487 362.179 617.205 362.357C616.923 362.534 616.684 362.772 616.504 363.052C616.324 363.332 616.207 363.649 616.163 363.979C616.118 364.309 616.146 364.645 616.245 364.963C616.34 365.409 616.531 365.829 616.805 366.193L616.165 366.453Z"
        fill="black"
      />
      <path
        d="M617.345 371.533C617.008 371.072 616.776 370.543 616.665 369.983C616.541 369.58 616.505 369.154 616.561 368.736C616.616 368.317 616.762 367.916 616.987 367.559C617.212 367.202 617.511 366.898 617.865 366.667C618.218 366.437 618.618 366.285 619.035 366.223C619.457 366.093 619.902 366.054 620.34 366.108C620.778 366.161 621.2 366.307 621.578 366.536C621.956 366.765 622.281 367.07 622.532 367.433C622.783 367.797 622.955 368.209 623.035 368.643C623.175 369.134 623.202 369.65 623.115 370.153H622.425C622.506 369.725 622.489 369.284 622.375 368.863C622.314 368.528 622.181 368.211 621.985 367.933C621.788 367.655 621.533 367.424 621.238 367.255C620.942 367.087 620.613 366.985 620.274 366.957C619.935 366.929 619.594 366.975 619.275 367.093C618.945 367.134 618.628 367.246 618.346 367.423C618.064 367.6 617.825 367.836 617.644 368.116C617.464 368.395 617.347 368.711 617.303 369.04C617.258 369.37 617.286 369.706 617.385 370.023C617.483 370.473 617.677 370.896 617.955 371.263L617.345 371.533Z"
        fill="black"
      />
      <path
        d="M621.035 375.043L620.455 372.673L618.255 373.203L618.895 375.853L618.225 376.013L617.395 372.563L623.515 371.083L624.315 374.403L623.655 374.563L623.045 372.043L621.115 372.513L621.685 374.883L621.035 375.043Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip112">
        <rect
          width="1415"
          height="619"
          fill="white"
          transform="translate(0 0.719971)"
        />
      </clipPath>
    </defs>
    <slot />
  </svg>
</template>

<script>
export default {
  name: "MapLayout",
};
</script>

<style scoped lang="scss">
.map {
  min-width: 992px !important;
  @media screen and (min-width: $screen-lg) {
    width: 100% !important;
  }
}
</style>
