var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "map-layout",
    { staticStyle: { width: "100%", height: "100%", "max-height": "800px" } },
    [
      _c("map-icon-main-street", {
        attrs: { transform: "translate(641,307)" },
      }),
      _c("svg-arrow-hint", {
        ref: "hint",
        attrs: {
          "hint-path-clone": "hintPathCloneHarmony",
          "hint-arrow": "hintArrowHarmony",
          "hint-path": "hintPathHarmony",
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(546,228)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 322],
                [589, 293],
                [575, 266],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovo", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(490,268)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [599, 302],
                [589, 293],
                [530, 279],
              ],
              hint: "8 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoMcd", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(619,378)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 330],
                [770, 342],
                [710, 473],
                [648, 420],
              ],
              hint: "6 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroKuncovoBkl", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(529,298)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [585, 282],
                [566, 288],
                [560, 295],
              ],
              hint: "7 минут",
              man: true,
              reverse: true,
              manLeft: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconMetroScolkovo", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(126,505)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [645, 340],
                [595, 392],
                [490, 543],
                [174, 515],
              ],
              hint: "10 минут",
              reverse: true,
              dy: -5,
              electric: true,
            })
          },
        },
      }),
      _c("MapIconSroptClub", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(581,195)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [656, 306],
                [604, 292],
                [623, 249],
                [623, 246],
              ],
              hint: "5 минут",
              reverse: true,
              car: true,
            })
          },
        },
      }),
      _c("MapIconManorKuncovo", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(681,175)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 306],
                [694, 282],
                [696, 243],
                [697, 230],
              ],
              hint: "7 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconCinemaKoro", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(787,72)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [666, 306],
                [784, 192],
                [786, 160],
                [796, 128],
              ],
              hint: "15 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconInstituteModern", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(751,226)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [686, 326],
                [724, 312],
                [741, 303],
                [767, 284],
              ],
              hint: "5 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconGrooveSchool", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(881,250)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [687, 326],
                [724, 332],
                [801, 323],
                [907, 297],
              ],
              hint: "5 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconWordClass", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(477,355)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 372],
                [576, 368],
                [525, 370],
              ],
              hint: "11 минут",
              manLeft: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconWordClass", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(337,225)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [646, 330],
                [625, 392],
                [476, 388],
                [395, 280],
              ],
              hint: "10 минут",
              car: true,
              reverse: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconGallery", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(679,458)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [730, 349],
                [735, 400],
                [720, 450],
              ],
              hint: "10 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconCinemaImax", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(953,320)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 329],
                [805, 350],
                [980, 335],
              ],
              hint: "13 минут",
              car: true,
              dy: -5,
            })
          },
        },
      }),
      _c("MapIconSportWegym", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(1027,369)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 340],
                [750, 379],
                [815, 410],
                [1019, 385],
              ],
              hint: "13 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconMuseumWin", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(1207,235)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [690, 330],
                [750, 309],
                [915, 330],
                [1209, 255],
              ],
              hint: "15 минут",
              car: true,
            })
          },
        },
      }),
      _c("MapIconScoolSauls", {
        staticClass: "map-icon__development",
        attrs: { transform: "translate(551,520)" },
        on: {
          click: function ($event) {
            return _vm.$refs.hint.animate({
              points: [
                [688, 330],
                [690, 322],
                [698, 443],
                [606, 523],
              ],
              hint: "10 минут",
              car: true,
              reverse: true,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }