<template>
  <g @click="$emit('click')">
    <path
      d="M32.8268 38.6374C32.5081 38.7599 32.1681 38.8177 31.8268 38.8074C31.5795 38.8228 31.3318 38.7846 31.1006 38.6953C30.8694 38.6061 30.6602 38.4679 30.4874 38.2904C30.3146 38.1128 30.1821 37.8999 30.0992 37.6664C30.0163 37.4329 29.9848 37.1842 30.0068 36.9374C30.0171 36.6107 30.1072 36.2914 30.2694 36.0076C30.4316 35.7238 30.6609 35.4841 30.9372 35.3095C31.2135 35.1348 31.5284 35.0305 31.8543 35.0057C32.1803 34.981 32.5073 35.0365 32.8068 35.1674L32.6968 35.5574C32.4622 35.448 32.2057 35.3933 31.9468 35.3974C31.7473 35.3875 31.548 35.421 31.3627 35.4957C31.1774 35.5704 31.0105 35.6844 30.8735 35.8299C30.7366 35.9754 30.6329 36.1489 30.5696 36.3384C30.5063 36.5278 30.4849 36.7288 30.5068 36.9274C30.486 37.1217 30.5079 37.3182 30.5711 37.5031C30.6342 37.6881 30.737 37.857 30.8723 37.998C31.0076 38.139 31.1721 38.2487 31.3542 38.3194C31.5364 38.3902 31.7318 38.4202 31.9268 38.4074C32.1942 38.41 32.4595 38.359 32.7068 38.2574L32.8268 38.6374Z"
      fill="black"
    />
    <path
      d="M36.0868 35.0574V38.7574H35.5868V35.4674H33.8668V38.7674H33.3968V35.0674L36.0868 35.0574Z"
      fill="black"
    />
    <path
      d="M40.0768 36.8674C40.1158 37.1144 40.1008 37.367 40.0328 37.6077C39.9648 37.8484 39.8454 38.0715 39.6829 38.2616C39.5203 38.4517 39.3185 38.6043 39.0913 38.7089C38.8641 38.8135 38.6169 38.8675 38.3668 38.8674C38.1304 38.8647 37.8973 38.8124 37.6824 38.714C37.4675 38.6156 37.2757 38.4732 37.1192 38.2961C36.9628 38.1189 36.8453 37.9109 36.7742 37.6855C36.7032 37.46 36.6802 37.2222 36.7068 36.9874C36.6819 36.7457 36.7072 36.5015 36.7813 36.2702C36.8554 36.0389 36.9766 35.8253 37.1372 35.6431C37.2978 35.4609 37.4944 35.3139 37.7146 35.2113C37.9349 35.1088 38.1739 35.053 38.4168 35.0474C38.648 35.0493 38.8763 35.099 39.0875 35.1932C39.2986 35.2875 39.488 35.4243 39.6439 35.5952C39.7997 35.766 39.9186 35.9672 39.9931 36.1861C40.0675 36.405 40.096 36.6369 40.0768 36.8674ZM37.2168 36.9274C37.2168 37.7174 37.6468 38.4274 38.3968 38.4274C39.1468 38.4274 39.5768 37.7274 39.5768 36.8874C39.5768 36.0474 39.1968 35.3874 38.3968 35.3874C37.5968 35.3874 37.2168 36.1174 37.2168 36.9274Z"
      fill="black"
    />
    <path
      d="M40.6967 35.1074C41.0008 35.0578 41.3087 35.0344 41.6168 35.0374C41.9749 35.0179 42.3285 35.124 42.6168 35.3374C42.7255 35.4354 42.8114 35.556 42.8684 35.6908C42.9254 35.8255 42.9522 35.9712 42.9467 36.1174C42.9586 36.4088 42.8543 36.6929 42.6568 36.9074C42.5041 37.0453 42.3256 37.1517 42.1317 37.2203C41.9378 37.289 41.7322 37.3186 41.5268 37.3074C41.4006 37.3235 41.2729 37.3235 41.1468 37.3074V38.7874H40.6667L40.6967 35.1074ZM41.1768 36.8874C41.3062 36.9049 41.4374 36.9049 41.5668 36.8874C42.1468 36.8874 42.4968 36.6074 42.4968 36.0974C42.4968 35.5874 42.1468 35.3674 41.6268 35.3674C41.4772 35.3511 41.3263 35.3511 41.1768 35.3674V36.8874Z"
      fill="black"
    />
    <path
      d="M44.3168 35.4674H43.1968V35.0574H45.9268V35.4674H44.7968V38.7574H44.3168V35.4674Z"
      fill="black"
    />
    <path
      d="M47.0368 37.0974V37.4674H45.6868V37.1174L47.0368 37.0974Z"
      fill="black"
    />
    <path
      d="M48.0868 35.0574V36.6974H48.2368L49.5068 35.0574H50.0768L48.6868 36.7574C48.9293 36.7976 49.1542 36.9097 49.3325 37.079C49.5107 37.2483 49.6341 37.4672 49.6868 37.7074C49.8018 38.0654 49.9388 38.4161 50.0968 38.7574H49.5868C49.4588 38.4745 49.3486 38.1839 49.2568 37.8874C49.2163 37.6526 49.0933 37.44 48.91 37.2878C48.7267 37.1356 48.495 37.0539 48.2568 37.0574H48.0968V38.7574H47.5868V35.0574H48.0868Z"
      fill="black"
    />
    <path
      d="M53.2168 35.0574V38.7574H52.7368V35.4674H51.5068V36.5874C51.5068 37.3874 51.5068 38.2774 50.9568 38.5874C50.7901 38.6976 50.5964 38.7598 50.3968 38.7674L50.3368 38.3874C50.4783 38.3539 50.6091 38.2851 50.7168 38.1874C50.9993 37.7047 51.1152 37.1425 51.0468 36.5874V35.0574H53.2168Z"
      fill="black"
    />
    <path
      d="M54.1768 35.0574L54.9168 36.6374C55.0068 36.8574 55.0968 37.0674 55.1768 37.2874C55.2368 37.0974 55.3068 36.8774 55.4068 36.6174L55.9768 35.0574H56.4868L55.7068 36.9574C55.5316 37.4491 55.3001 37.9189 55.0168 38.3574C54.9233 38.4952 54.7983 38.6087 54.6523 38.6887C54.5062 38.7687 54.3432 38.8128 54.1768 38.8174C54.0912 38.8367 54.0024 38.8367 53.9168 38.8174V38.4374H54.0868C54.4668 38.4374 54.6968 38.0674 54.8068 37.8074C54.826 37.7721 54.8362 37.7326 54.8362 37.6924C54.8362 37.6522 54.826 37.6126 54.8068 37.5774L53.5768 35.0874L54.1768 35.0574Z"
      fill="black"
    />
    <path
      d="M58.9668 35.0574V35.4674H57.3768V36.4674C57.5266 36.4571 57.677 36.4571 57.8268 36.4674C58.1595 36.4585 58.4882 36.5415 58.7768 36.7074C58.9317 36.8013 59.0582 36.9356 59.1428 37.0959C59.2273 37.2562 59.2668 37.4364 59.2568 37.6174C59.2614 37.7647 59.2363 37.9114 59.183 38.0488C59.1298 38.1863 59.0494 38.3116 58.9468 38.4174C58.576 38.7116 58.1092 38.8578 57.6368 38.8274C57.3903 38.8423 57.1432 38.8423 56.8968 38.8274V35.0574H58.9668ZM57.3768 38.3874C57.5098 38.3997 57.6437 38.3997 57.7768 38.3874C58.0617 38.3961 58.3384 38.2919 58.5468 38.0974C58.6163 38.0261 58.6708 37.9416 58.7068 37.8488C58.7429 37.756 58.7599 37.6569 58.7568 37.5574C58.762 37.4401 58.7371 37.3235 58.6846 37.2185C58.6321 37.1135 58.5537 37.0236 58.4568 36.9574C58.2438 36.826 57.9969 36.7599 57.7468 36.7674H57.3768V38.3874Z"
      fill="black"
    />
    <path
      d="M0.559998 45.7373L1.64001 47.2273H1.07001L0 45.7373L1.07001 44.2473H1.64001L0.559998 45.7373ZM1.89001 45.7373L2.97 47.2273H2.40002L1.33002 45.7373L2.40002 44.2473H2.97L1.89001 45.7373Z"
      fill="black"
    />
    <path
      d="M6.44001 42.0974V42.5874C6.75731 42.5821 7.0722 42.6434 7.36435 42.7673C7.65651 42.8912 7.91944 43.075 8.13617 43.3068C8.3529 43.5386 8.51861 43.8133 8.62262 44.1131C8.72664 44.4129 8.76663 44.7312 8.74003 45.0474C8.76242 45.3616 8.71887 45.677 8.61219 45.9735C8.5055 46.2699 8.33803 46.5407 8.12052 46.7686C7.90301 46.9964 7.64025 47.1763 7.34913 47.2967C7.058 47.417 6.74495 47.4752 6.43003 47.4674V47.9774H5.75V47.4674C5.43597 47.4767 5.1235 47.4199 4.83286 47.3006C4.54222 47.1813 4.27993 47.0022 4.06302 46.7749C3.84612 46.5476 3.67944 46.2772 3.57383 45.9813C3.46822 45.6854 3.42603 45.3707 3.45002 45.0574C3.4266 44.7387 3.46926 44.4187 3.57532 44.1173C3.68138 43.8159 3.84857 43.5396 4.06638 43.3059C4.28419 43.0721 4.54794 42.8858 4.8411 42.7588C5.13426 42.6317 5.45051 42.5665 5.77002 42.5674V42.0774L6.44001 42.0974ZM5.76001 43.0974C5.52486 43.1129 5.29545 43.1769 5.08619 43.2852C4.87692 43.3936 4.69231 43.5441 4.54395 43.7272C4.39559 43.9103 4.28666 44.1221 4.22403 44.3493C4.16141 44.5764 4.14644 44.8141 4.18003 45.0474C4.15235 45.2792 4.1716 45.5142 4.23664 45.7384C4.30168 45.9626 4.41117 46.1715 4.5586 46.3525C4.70602 46.5335 4.88836 46.683 5.09476 46.7921C5.30117 46.9011 5.5274 46.9676 5.76001 46.9874V43.0974ZM6.43003 46.9874C6.66956 46.9711 6.90302 46.9049 7.11542 46.793C7.32782 46.6811 7.51445 46.5259 7.66327 46.3376C7.8121 46.1492 7.91984 45.9317 7.97956 45.6992C8.03928 45.4666 8.04965 45.2242 8.01001 44.9874C8.01001 43.5874 7.22003 43.0874 6.43003 43.0474V46.9874Z"
      fill="black"
    />
    <path
      d="M10.31 42.3174V44.6174C10.31 45.4874 10.31 46.1974 10.24 46.9274C10.52 46.3574 10.86 45.7674 11.24 45.1174L13.01 42.3074H13.71V47.7674H13.05V45.4674C13.05 44.5774 13.05 43.9374 13.11 43.2474C12.8132 43.8799 12.4793 44.4943 12.11 45.0874L10.4 47.7974H9.64001V42.3174H10.31Z"
      fill="black"
    />
    <path
      d="M18.59 42.3174V47.7774H17.87V42.9074H16.07V44.5574C16.07 45.7474 16.02 47.0674 15.25 47.5574C14.9989 47.7189 14.7085 47.8088 14.41 47.8174L14.33 47.2474C14.5413 47.2001 14.7374 47.1003 14.9 46.9574C15.3138 46.2407 15.4823 45.4085 15.38 44.5874V42.2874L18.59 42.3174Z"
      fill="black"
    />
    <path
      d="M20.47 42.3174V44.6174C20.47 45.4874 20.47 46.1974 20.41 46.9274C20.69 46.3574 21.03 45.7674 21.41 45.1174L23.17 42.3074H23.88V47.7674H23.21V45.4674C23.21 44.5774 23.21 43.9374 23.28 43.2474C22.9872 43.8819 22.6532 44.4965 22.28 45.0874L20.57 47.7974H19.82V42.3174H20.47Z"
      fill="black"
    />
    <path d="M26.77 45.3274V45.8474H24.77V45.3274H26.77Z" fill="black" />
    <path
      d="M31.36 42.3174V47.2074H31.8401L31.79 49.0774H31.2401L31.19 47.7674H27.7V49.0774H27.1501L27.1 47.2074H27.51C27.7248 46.8253 27.9023 46.4235 28.04 46.0074C28.2377 45.2574 28.3253 44.4826 28.3 43.7074V42.3174H31.36ZM28.9601 43.9274C28.9832 44.6808 28.8887 45.4331 28.6801 46.1574C28.5686 46.5051 28.4211 46.8403 28.2401 47.1574H30.7V42.8574H29.01L28.9601 43.9274Z"
      fill="black"
    />
    <path
      d="M33.63 46.0574L33.07 47.7774H32.34L34.19 42.3174H35.04L36.91 47.7774H36.15L35.57 46.0574H33.63ZM35.42 45.5074L34.89 43.9374C34.77 43.5774 34.69 43.2574 34.61 42.9374C34.53 43.2674 34.44 43.5974 34.33 43.9374L33.78 45.4674L35.42 45.5074Z"
      fill="black"
    />
    <path
      d="M37.7 42.3874C38.1225 42.3125 38.5509 42.2757 38.98 42.2774C39.5118 42.2268 40.0441 42.3687 40.48 42.6774C40.6363 42.7904 40.7607 42.9418 40.8413 43.117C40.9219 43.2922 40.9559 43.4852 40.94 43.6774C40.9318 43.95 40.8373 44.2131 40.6702 44.4287C40.5031 44.6443 40.272 44.8014 40.01 44.8774C40.3269 44.9416 40.6121 45.1127 40.8178 45.3621C41.0235 45.6115 41.1373 45.9241 41.14 46.2474C41.1418 46.4508 41.1019 46.6525 41.0228 46.84C40.9437 47.0275 40.8271 47.1967 40.68 47.3374C40.1343 47.7343 39.4613 47.9159 38.79 47.8474C38.4254 47.8532 38.0609 47.8298 37.7 47.7774V42.3874ZM38.4 44.6274H39.04C39.79 44.6274 40.22 44.2374 40.22 43.7074C40.22 43.1774 39.74 42.8174 39.02 42.8174C38.8138 42.7984 38.6063 42.7984 38.4 42.8174V44.6274ZM38.4 47.2474C38.5965 47.2604 38.7936 47.2604 38.99 47.2474C39.72 47.2474 40.39 46.9774 40.39 46.1874C40.39 45.3974 39.75 45.1274 38.98 45.1274H38.4V47.2474Z"
      fill="black"
    />
    <path
      d="M42.8 42.3174V44.3174C42.96 44.3174 43.27 44.3174 43.45 44.3174C44.57 44.3174 45.55 44.8174 45.55 46.0274C45.5664 46.454 45.4157 46.8701 45.13 47.1874C44.5938 47.6494 43.896 47.8797 43.19 47.8274C42.8254 47.8332 42.4609 47.8098 42.1 47.7574V42.3174H42.8ZM42.8 47.2374C42.9896 47.256 43.1805 47.256 43.3701 47.2374C44.1501 47.2374 44.82 46.8374 44.82 46.0174C44.82 45.1974 44.09 44.8374 43.36 44.8374C43.1736 44.8258 42.9865 44.8258 42.8 44.8374V47.2374ZM46.88 42.3174V47.7774H46.16V42.3174H46.88Z"
      fill="black"
    />
    <path
      d="M51.87 42.3174V47.2074H52.35V49.0774H51.8L51.7 47.7774H48.26L48.21 49.0874H47.7V47.2174H48.11C48.3321 46.8377 48.5132 46.4354 48.65 46.0174C48.8433 45.2666 48.9308 44.4924 48.91 43.7174V42.3174H51.87ZM49.47 43.9274C49.491 44.68 49.4 45.4315 49.2 46.1574C49.0813 46.5039 48.9307 46.8387 48.75 47.1574H51.17V42.8574H49.47V43.9274Z"
      fill="black"
    />
    <path
      d="M57.83 44.9873C57.83 46.8673 56.69 47.8673 55.3 47.8673C54.9516 47.8622 54.608 47.7847 54.2911 47.6395C53.9743 47.4944 53.6912 47.2848 53.4598 47.0243C53.2283 46.7637 53.0537 46.4578 52.947 46.126C52.8403 45.7943 52.8039 45.4439 52.84 45.0973C52.8026 44.7389 52.84 44.3766 52.95 44.0334C53.0599 43.6902 53.2401 43.3736 53.4789 43.1038C53.7178 42.8339 54.0101 42.6166 54.3373 42.4657C54.6646 42.3149 55.0197 42.2337 55.38 42.2273C55.7281 42.2293 56.0719 42.3045 56.389 42.4481C56.7062 42.5918 56.9895 42.8005 57.2206 43.0609C57.4517 43.3212 57.6254 43.6273 57.7304 43.9592C57.8354 44.2911 57.8693 44.6414 57.83 44.9873ZM53.6 45.0773C53.6 46.2473 54.23 47.2973 55.34 47.2973C56.45 47.2973 57.09 46.2973 57.09 45.0273C57.09 43.9373 56.52 42.8073 55.35 42.8073C54.18 42.8073 53.6 43.8773 53.6 45.0773Z"
      fill="black"
    />
    <path
      d="M58.75 42.3874C59.1725 42.3125 59.6009 42.2757 60.03 42.2774C60.5618 42.2268 61.094 42.3687 61.53 42.6774C61.6863 42.7904 61.8107 42.9418 61.8913 43.117C61.9719 43.2922 62.0059 43.4852 61.99 43.6774C61.9818 43.95 61.8873 44.2131 61.7202 44.4287C61.5531 44.6443 61.322 44.8014 61.06 44.8774C61.3769 44.9416 61.6621 45.1127 61.8678 45.3621C62.0735 45.6115 62.1873 45.9241 62.19 46.2474C62.1918 46.4508 62.152 46.6525 62.0728 46.84C61.9937 47.0275 61.877 47.1967 61.73 47.3374C61.1843 47.7343 60.5113 47.9159 59.84 47.8474C59.4754 47.8532 59.1109 47.8298 58.75 47.7774V42.3874ZM59.45 44.6274H60.09C60.84 44.6274 61.27 44.2374 61.27 43.7074C61.27 43.1774 60.79 42.8174 60.08 42.8174C59.8705 42.798 59.6596 42.798 59.45 42.8174V44.6274ZM59.45 47.2474C59.6465 47.2604 59.8436 47.2604 60.04 47.2474C60.77 47.2474 61.44 46.9774 61.44 46.1874C61.44 45.3974 60.8 45.1274 60.03 45.1274H59.45V47.2474Z"
      fill="black"
    />
    <path
      d="M67.7 44.9873C67.7454 45.3477 67.7136 45.7135 67.6067 46.0607C67.4998 46.4078 67.3203 46.7282 67.0801 47.0006C66.8398 47.273 66.5444 47.4912 66.2133 47.6406C65.8823 47.79 65.5232 47.8673 65.16 47.8673C64.8119 47.8624 64.4687 47.7848 64.1523 47.6396C63.8359 47.4943 63.5534 47.2846 63.3228 47.0238C63.0921 46.7631 62.9185 46.457 62.813 46.1253C62.7075 45.7935 62.6724 45.4434 62.71 45.0973C62.6726 44.7397 62.7097 44.3782 62.8191 44.0357C62.9285 43.6932 63.1078 43.3771 63.3455 43.1074C63.5833 42.8377 63.8744 42.6202 64.2005 42.4687C64.5266 42.3172 64.8805 42.235 65.24 42.2273C65.5885 42.2294 65.9326 42.3047 66.2502 42.4482C66.5677 42.5917 66.8517 42.8003 67.0835 43.0605C67.3154 43.3206 67.4901 43.6265 67.5963 43.9585C67.7024 44.2904 67.7378 44.6409 67.7 44.9873ZM63.46 45.0773C63.46 46.2473 64.09 47.2973 65.2 47.2973C66.31 47.2973 66.95 46.2973 66.95 45.0273C66.95 43.9373 66.39 42.8073 65.21 42.8073C64.03 42.8073 63.5 43.8773 63.5 45.0773H63.46Z"
      fill="black"
    />
    <path
      d="M70.1201 47.7774V47.3374L70.6801 46.7974C72.0201 45.5174 72.6301 44.7974 72.6801 44.0474C72.6899 43.9092 72.669 43.7705 72.619 43.6413C72.569 43.512 72.491 43.3955 72.3907 43.2999C72.2904 43.2044 72.1701 43.1322 72.0386 43.0885C71.9071 43.0449 71.7676 43.0308 71.63 43.0474C71.2176 43.0628 70.8227 43.2179 70.51 43.4874L70.29 42.9874C70.6878 42.6597 71.1848 42.4764 71.7 42.4674C71.9093 42.4488 72.1202 42.4749 72.3185 42.5441C72.5169 42.6133 72.6983 42.724 72.8506 42.8687C73.0029 43.0135 73.1227 43.189 73.2019 43.3836C73.2811 43.5782 73.318 43.7874 73.31 43.9974C73.31 44.9974 72.6101 45.7574 71.4901 46.8174L71.07 47.2074H73.44V47.7974L70.1201 47.7774Z"
      fill="black"
    />
    <path
      d="M77.7701 45.0874C77.7701 46.8774 77.11 47.8674 75.94 47.8674C74.77 47.8674 74.2101 46.8674 74.2001 45.1574C74.19 43.4474 74.96 42.4274 76.03 42.4274C77.1 42.4274 77.7701 43.4074 77.7701 45.0874ZM74.91 45.1674C74.91 46.5374 75.33 47.3174 75.98 47.3174C76.6301 47.3174 77.06 46.4674 77.06 45.1174C77.06 43.7674 76.7301 42.9774 75.9901 42.9774C75.2501 42.9774 74.91 43.7374 74.91 45.1674Z"
      fill="black"
    />
    <path
      d="M81.9301 45.0874C81.9301 46.8774 81.26 47.8674 80.1 47.8674C78.94 47.8674 78.37 46.8674 78.35 45.1574C78.33 43.4474 79.1201 42.4274 80.1801 42.4274C81.2401 42.4274 81.9301 43.4074 81.9301 45.0874ZM79.07 45.1674C79.07 46.5374 79.4901 47.3174 80.1401 47.3174C80.7901 47.3174 81.2101 46.4674 81.2101 45.1174C81.2101 43.7674 80.8801 42.9774 80.1401 42.9774C79.4001 42.9774 79.07 43.7374 79.07 45.1674Z"
      fill="black"
    />
    <path
      d="M86.0801 45.0874C86.0801 46.8774 85.42 47.8674 84.25 47.8674C83.08 47.8674 82.5201 46.8674 82.51 45.1574C82.5 43.4474 83.2701 42.4274 84.3401 42.4274C85.4101 42.4274 86.0801 43.4074 86.0801 45.0874ZM83.22 45.1674C83.22 46.5374 83.64 47.3174 84.29 47.3174C84.94 47.3174 85.3701 46.4674 85.3701 45.1174C85.3701 43.7674 85.0301 42.9774 84.3001 42.9774C83.5701 42.9774 83.22 43.7374 83.22 45.1674Z"
      fill="black"
    />
    <path
      d="M86.56 44.2473H87.13L88.21 45.7373L87.13 47.2273H86.57L87.64 45.7373L86.56 44.2473ZM87.88 44.2473H88.45L89.53 45.7373L88.45 47.2273H87.89L88.96 45.7373L87.88 44.2473Z"
      fill="black"
    />
    <g clip-path="url(#clip4)">
      <circle
        cx="44.5"
        cy="16"
        r="15.5"
        fill="white"
        fill-opacity="0.01"
        stroke="#E73C14"
      />
      <path
        d="M53 20.09H52C52 19.6921 51.842 19.3106 51.5607 19.0293C51.2794 18.748 50.8978 18.59 50.5 18.59C50.1022 18.59 49.7207 18.748 49.4394 19.0293C49.1581 19.3106 49 19.6921 49 20.09H48C48 19.6921 47.842 19.3106 47.5607 19.0293C47.2794 18.748 46.8978 18.59 46.5 18.59C46.1022 18.59 45.7207 18.748 45.4394 19.0293C45.1581 19.3106 45 19.6921 45 20.09H44C44 19.6921 43.842 19.3106 43.5607 19.0293C43.2794 18.748 42.8978 18.59 42.5 18.59C42.1022 18.59 41.7207 18.748 41.4394 19.0293C41.1581 19.3106 41 19.6921 41 20.09H40C40 19.6921 39.842 19.3106 39.5607 19.0293C39.2794 18.748 38.8978 18.59 38.5 18.59C38.1022 18.59 37.7207 18.748 37.4394 19.0293C37.1581 19.3106 37 19.6921 37 20.09H36C36.0028 19.5678 36.169 19.0596 36.4753 18.6367C36.7815 18.2138 37.2126 17.8974 37.7078 17.732C38.2031 17.5665 38.7377 17.5603 39.2367 17.7142C39.7357 17.8681 40.174 18.1743 40.49 18.59C40.7229 18.2795 41.0248 18.0275 41.372 17.8539C41.7191 17.6803 42.1019 17.59 42.49 17.59C42.8781 17.59 43.2609 17.6803 43.608 17.8539C43.9552 18.0275 44.2572 18.2795 44.49 18.59C44.7229 18.2795 45.0248 18.0275 45.372 17.8539C45.7191 17.6803 46.1019 17.59 46.49 17.59C46.8781 17.59 47.2609 17.6803 47.608 17.8539C47.9552 18.0275 48.2572 18.2795 48.49 18.59C48.8036 18.173 49.2403 17.865 49.7384 17.7096C50.2364 17.5542 50.7707 17.5592 51.2658 17.7239C51.7609 17.8885 52.1918 18.2046 52.4976 18.6273C52.8034 19.0501 52.9686 19.5582 52.97 20.08L53 20.09Z"
        fill="#E73C14"
      />
      <path
        d="M53 23.22H52C52 22.8221 51.842 22.4406 51.5607 22.1593C51.2794 21.878 50.8978 21.72 50.5 21.72C50.1022 21.72 49.7207 21.878 49.4394 22.1593C49.1581 22.4406 49 22.8221 49 23.22H48C48 22.8221 47.842 22.4406 47.5607 22.1593C47.2794 21.878 46.8978 21.72 46.5 21.72C46.1022 21.72 45.7207 21.878 45.4394 22.1593C45.1581 22.4406 45 22.8221 45 23.22H44C44 22.8221 43.842 22.4406 43.5607 22.1593C43.2794 21.878 42.8978 21.72 42.5 21.72C42.1022 21.72 41.7207 21.878 41.4394 22.1593C41.1581 22.4406 41 22.8221 41 23.22H40C40 22.8221 39.842 22.4406 39.5607 22.1593C39.2794 21.878 38.8978 21.72 38.5 21.72C38.1022 21.72 37.7207 21.878 37.4394 22.1593C37.1581 22.4406 37 22.8221 37 23.22H36C36.0028 22.6978 36.169 22.1896 36.4753 21.7667C36.7815 21.3438 37.2126 21.0274 37.7078 20.862C38.2031 20.6965 38.7377 20.6903 39.2367 20.8442C39.7357 20.9981 40.174 21.3043 40.49 21.72C40.7229 21.4095 41.0248 21.1575 41.372 20.9839C41.7191 20.8103 42.1019 20.72 42.49 20.72C42.8781 20.72 43.2609 20.8103 43.608 20.9839C43.9552 21.1575 44.2572 21.4095 44.49 21.72C44.7241 21.4113 45.0265 21.1609 45.3734 20.9886C45.7204 20.8163 46.1026 20.7266 46.49 20.7266C46.8774 20.7266 47.2596 20.8163 47.6066 20.9886C47.9535 21.1609 48.2559 21.4113 48.49 21.72C48.8041 21.3023 49.2417 20.9941 49.7408 20.8389C50.2398 20.6837 50.775 20.6895 51.2706 20.8555C51.7661 21.0214 52.1969 21.339 52.502 21.7634C52.807 22.1878 52.9707 22.6974 52.97 23.22H53Z"
        fill="#E73C14"
      />
      <path
        d="M44.84 11.82L41.84 15.45C42.0574 15.3675 42.2875 15.3235 42.52 15.32C43.0504 15.32 43.5591 15.5307 43.9342 15.9058C44.3093 16.2809 44.52 16.7896 44.52 17.32C44.5215 16.9207 44.6425 16.531 44.8673 16.2011C45.0922 15.8711 45.4107 15.6161 45.7818 15.4687C46.1529 15.3213 46.5596 15.2884 46.9496 15.3742C47.3396 15.46 47.695 15.6605 47.97 15.95L48.23 15.59L48.69 15.02L44.84 11.82Z"
        fill="#E73C14"
      />
      <path
        d="M44.67 14.29L41.67 11.75C41.6176 11.7042 41.5606 11.664 41.4999 11.63L39.1 10.34C38.975 10.2743 38.8655 10.183 38.7784 10.072C38.6913 9.96092 38.6286 9.83271 38.5946 9.69576C38.5606 9.5588 38.556 9.41619 38.5811 9.27732C38.6061 9.13844 38.6603 9.00644 38.74 8.88999C38.879 8.68284 39.0905 8.53516 39.3329 8.47595C39.5753 8.41675 39.831 8.45029 40.05 8.56999L42.66 9.96999C42.7226 10.0007 42.78 10.0413 42.83 10.09L45.83 12.62L44.67 14.29Z"
        fill="#E73C14"
      />
      <path
        d="M49 12.06C50.1211 12.06 51.03 11.1511 51.03 10.03C51.03 8.90886 50.1211 8 49 8C47.8789 8 46.97 8.90886 46.97 10.03C46.97 11.1511 47.8789 12.06 49 12.06Z"
        fill="#E73C14"
      />
    </g>
    <defs>
      <clipPath id="clip4">
        <rect width="32" height="32" fill="white" transform="translate(28.5)" />
      </clipPath>
    </defs>
  </g>
</template>

<script>
export default {
  name: "MapIconSroptClub",
};
</script>

<style scoped lang="scss"></style>
