<template>
  <g @click="$emit('click')">
    <circle
      cx="28"
      cy="16"
      r="15.5"
      fill="white"
      fill-opacity="0.01"
      stroke="#E73C14"
    />
    <path d="M30.22 18.03H25.6V19.03H30.22V18.03Z" fill="#E73C14" />
    <path d="M28.41 16.2201H27.41V20.8401H28.41V16.2201Z" fill="#E73C14" />
    <path
      d="M38.62 24.7901H17.2V10.1301H38.62V24.7901ZM18.2 23.7901H37.62V11.1301H18.2V23.7901Z"
      fill="#E73C14"
    />
    <path d="M38.56 13.3201H17.67V14.3201H38.56V13.3201Z" fill="#E73C14" />
    <path
      d="M23.77 15.4701H19.19V11.9801H23.77V15.4701ZM20.19 14.4701H22.77V12.9801H20.19V14.4701Z"
      fill="#E73C14"
    />
    <path
      d="M36.63 15.5701H32.05V12.0801H36.63V15.5701ZM33.05 14.5701H35.63V13.0801H33.05V14.5701Z"
      fill="#E73C14"
    />
    <path
      d="M31.57 11.13H24.25V9.73C24.2487 9.37196 24.3181 9.01719 24.4542 8.68603C24.5903 8.35487 24.7904 8.05384 25.0431 7.8002C25.2958 7.54657 25.5961 7.34532 25.9268 7.208C26.2575 7.07068 26.612 7 26.97 7H28.85C29.2081 7 29.5626 7.07068 29.8932 7.208C30.2239 7.34532 30.5242 7.54657 30.7769 7.8002C31.0296 8.05384 31.2297 8.35487 31.3658 8.68603C31.502 9.01719 31.5713 9.37196 31.57 9.73V11.13ZM25.25 10.13H30.57V9.73C30.57 9.2729 30.3891 8.83438 30.0669 8.51023C29.7446 8.18608 29.3071 8.00264 28.85 8H26.97C26.5129 8.00264 26.0755 8.18608 25.7532 8.51023C25.4309 8.83438 25.25 9.2729 25.25 9.73V10.13Z"
      fill="#E73C14"
    />
    <path
      d="M16.82 35.04V36.68H16.97L18.23 35.04H18.81L17.41 36.73C17.6581 36.7763 17.8865 36.8965 18.065 37.075C18.2435 37.2535 18.3638 37.4819 18.41 37.73C18.5232 38.0711 18.6568 38.405 18.81 38.73H18.3C18.167 38.4481 18.0534 38.1574 17.96 37.86C17.9174 37.6271 17.7935 37.4168 17.6104 37.2666C17.4272 37.1165 17.1967 37.0362 16.96 37.04H16.81V38.73H16.34V35.04H16.82Z"
      fill="black"
    />
    <path
      d="M21.9399 35.04V38.73H21.4599V35.44H20.2399V36.56C20.2399 37.36 20.2399 38.25 19.6899 38.56C19.5202 38.6715 19.3229 38.7338 19.1199 38.74L19.0599 38.35C19.205 38.3238 19.3399 38.258 19.4499 38.16C19.729 37.6761 19.8414 37.114 19.7699 36.56V35L21.9399 35.04Z"
      fill="black"
    />
    <path
      d="M23.2199 35.04V36.6001C23.2199 37.1801 23.2199 37.67 23.2199 38.16C23.4248 37.7416 23.6518 37.3344 23.8999 36.94L25.0999 35.04H25.5599V38.73H25.1099V37.16C25.1099 36.56 25.1099 36.16 25.1599 35.66C24.9583 36.0866 24.7312 36.5007 24.4799 36.9L23.3199 38.73H22.8099V35.04H23.2199Z"
      fill="black"
    />
    <path
      d="M26.85 35.04V36.59H28.64V35.04H29.12V38.73H28.64V37H26.85V38.73H26.38V35.04H26.85Z"
      fill="black"
    />
    <path
      d="M30.4 35.04V36.6001C30.4 37.1801 30.4 37.67 30.35 38.16C30.5548 37.7416 30.7818 37.3344 31.03 36.94L32.23 35.04H32.7V38.73H32.25V37.16C32.25 36.56 32.25 36.16 32.25 35.66C32.0483 36.0866 31.8212 36.5007 31.57 36.9L30.41 38.73H29.9V35.04H30.4Z"
      fill="black"
    />
    <path
      d="M34.0299 35.04V36.68H34.1699L35.4399 35.04H36.0099L34.6199 36.73C34.8687 36.7749 35.0977 36.8948 35.2765 37.0735C35.4552 37.2522 35.575 37.4813 35.6199 37.73C35.7364 38.0713 35.8734 38.4052 36.0299 38.73H35.5599C35.4303 38.4479 35.3201 38.1572 35.2299 37.86C35.1873 37.6271 35.0634 37.4168 34.8803 37.2666C34.6972 37.1165 34.4667 37.0362 34.2299 37.04H34.0799V38.73H33.5599V35.04H34.0299Z"
      fill="black"
    />
    <path
      d="M37.1499 37.5701L36.77 38.73H36.27L37.53 35.04H38.0999L39.36 38.73H38.8499L38.4599 37.5701H37.1499ZM38.36 37.2L38 36.14C37.92 35.9 37.86 35.6801 37.81 35.4601C37.75 35.6801 37.6899 35.9101 37.6199 36.1301L37.2599 37.2H38.36Z"
      fill="black"
    />
    <path
      d="M1.56 46.16L2.64999 47.65H2.06999L1 46.16L2.06999 44.67H2.64999L1.56 46.16ZM2.89 46.16L3.98 47.65H3.39999L2.33 46.16L3.39999 44.67H3.98L2.89 46.16Z"
      fill="black"
    />
    <path
      d="M9.39996 45.8001C9.39996 45.0401 9.30996 44.1301 9.31996 43.4401C9.13996 44.0801 8.90997 44.7601 8.62997 45.5201L7.62997 48.1701H7.09996L6.20996 45.5701C5.94996 44.8001 5.72997 44.0901 5.57997 43.4401C5.57997 44.1301 5.57997 45.0401 5.47997 45.8601L5.32997 48.2001H4.65997L5.03996 42.7401H5.93997L6.86996 45.3801C7.08543 45.9834 7.26902 46.5976 7.41997 47.2201C7.55997 46.6701 7.74996 46.0701 7.98996 45.3801L8.98996 42.7401H9.87997L10.22 48.2001H9.55997L9.39996 45.8001Z"
      fill="black"
    />
    <path
      d="M12.13 46.5001L11.56 48.2001H10.83L12.6799 42.7401H13.56L15.42 48.2001H14.67L14.06 46.5001H12.13ZM13.92 45.9501L13.38 44.3801C13.26 44.0201 13.1799 43.7001 13.0999 43.3801C13.0199 43.7068 12.93 44.0401 12.83 44.3801L12.2899 45.9601L13.92 45.9501Z"
      fill="black"
    />
    <path
      d="M16.88 43.3401H15.22V42.7401H19.22V43.3401H17.56V48.2001H16.85L16.88 43.3401Z"
      fill="black"
    />
    <path
      d="M20.56 42.7401V44.7401C20.72 44.7401 21.0499 44.7401 21.2399 44.7401C22.3499 44.7401 23.34 45.2301 23.34 46.4401C23.3578 46.8699 23.2071 47.2898 22.92 47.6101C22.6456 47.844 22.3276 48.021 21.9842 48.1309C21.6409 48.2408 21.2791 48.2813 20.92 48.2501C20.552 48.2557 20.1842 48.2323 19.8199 48.1801V42.7401H20.56ZM20.56 47.6601C20.7562 47.6783 20.9537 47.6783 21.1499 47.6601C21.9299 47.6601 22.5999 47.2801 22.5999 46.4401C22.5999 45.6001 21.92 45.2601 21.14 45.2601C20.9467 45.2502 20.7532 45.2502 20.56 45.2601V47.6601Z"
      fill="black"
    />
    <path
      d="M26.66 42.7401V45.0401C26.66 45.9101 26.6599 46.6201 26.5999 47.3501C26.8799 46.7801 27.2199 46.1901 27.5999 45.5401L29.36 42.7301H30.0699V48.1901H29.3999V45.8701C29.3999 44.9801 29.4 44.3301 29.47 43.6401C29.1772 44.2746 28.8431 44.8893 28.47 45.4801L26.7599 48.1901H26.0099V42.7301L26.66 42.7401Z"
      fill="black"
    />
    <path
      d="M36.7799 42.7401V47.6301H37.2599V49.5001H36.7099V48.1901H33.2699L33.2199 49.5001H32.5599V47.6301H32.9699C33.188 47.2483 33.3688 46.8465 33.5099 46.4301C33.7021 45.6791 33.7896 44.9051 33.7699 44.1301V42.7401H36.7799ZM34.3799 44.3501C34.4003 45.1027 34.3093 45.8541 34.1099 46.5801C33.9913 46.9267 33.8406 47.2614 33.6599 47.5801H36.0799V43.2801H34.3799V44.3501Z"
      fill="black"
    />
    <path
      d="M38.8099 42.7401V45.0401C38.8099 45.9101 38.8099 46.6201 38.7499 47.3501C39.0299 46.7801 39.3699 46.1901 39.7499 45.5401L41.5099 42.7301H42.2199V48.1901H41.5599V45.8701C41.5599 44.9801 41.5599 44.3301 41.6299 43.6401C41.3371 44.2746 41.0031 44.8893 40.6299 45.4801L38.9199 48.1901H38.1699V42.7301L38.8099 42.7401Z"
      fill="black"
    />
    <path
      d="M44.56 43.3401H42.9V42.7401H46.9V43.3401H45.24V48.2001H44.56V43.3401Z"
      fill="black"
    />
    <path
      d="M46.97 48.2C47.052 48.0421 47.1221 47.8783 47.18 47.7101C47.3353 47.052 47.6172 46.4304 48.01 45.88C48.1356 45.7524 48.2899 45.6564 48.46 45.6001C48.1294 45.5609 47.8246 45.4018 47.6035 45.153C47.3823 44.9042 47.2601 44.5829 47.26 44.25C47.2529 44.0391 47.2943 43.8294 47.381 43.6369C47.4676 43.4445 47.5973 43.2745 47.76 43.14C48.2179 42.8284 48.7675 42.6804 49.32 42.72C49.759 42.7172 50.1975 42.754 50.63 42.83V48.22H49.92V45.8501H49.41C49.2666 45.8355 49.1218 45.8521 48.9854 45.8987C48.8491 45.9453 48.7244 46.0208 48.62 46.12C48.2803 46.6391 48.0364 47.2149 47.9 47.8201C47.84 47.9601 47.8 48.07 47.72 48.22L46.97 48.2ZM49.97 43.31C49.7536 43.2601 49.532 43.2367 49.31 43.2401C48.61 43.2401 48.02 43.5101 48.02 44.2401C48.02 44.9701 48.6 45.2401 49.37 45.2401H49.97V43.31Z"
      fill="black"
    />
    <path
      d="M51.4299 44.67H51.9999L53.0799 46.16L51.9999 47.65H51.4399L52.5099 46.16L51.4299 44.67ZM52.7499 44.67H53.3199L54.3999 46.16L53.3199 47.65H52.7599L53.8299 46.16L52.7499 44.67Z"
      fill="black"
    />
  </g>
</template>

<script>
export default {
  name: "MapIconClinic",
};
</script>

<style scoped lang="scss"></style>
